import React, { useEffect, useState, useContext } from "react"
import { AccountContext } from "../../auth/Account"
import styled from "styled-components"
import media from "styled-media-query"
import Image from "./../../images/ic_settings.svg"
import ImgGreen from "./../../images/ic_status_green.svg"
import ImgPurple from "./../../images/ic_status_purple.svg"
import ImgRed from "./../../images/ic_status_red.svg"
import ImgYellow from "./../../images/ic_status_yellow.svg"
import WingnutConfigTabHeader from "./../wingnuts/WingnutConfigTabHeader"
import WingnutSensorConfigFormCell from "./WingnutSensorConfigFormCell"
import PopupContainer from "./../popup/PopupContainer"
import Modal from "react-modal"
import { COLORS } from "../../styles/colors"

const Container = styled.div`
  /* border: 1px yellow dashed; */
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  background: #0e1c36;
  padding: 20px;
  margin-top: -10px;
  padding-top: 50px;
  ${media.lessThan("743px")`
  justify-content: center;
  `}
  ${media.between("744px", "large")`
  `}
  ${media.greaterThan("large")`
    `}
`

const SubHeaderContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: row;
`

const WingnutIcon = styled.img`
  width: 24px;
  height: 24px;
  align-self: center;
  margin-right: 10px;
  /* margin-bottom: 20px; */
`

const InfoText = styled.h2`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  /* line-height: 130%; */
  display: flex;
  align-items: center;
  color: ${COLORS.HighlightLight};
`

export default function WingnutConfiguration(props) {
const { schemaConfig, wingnutDetails } = props


  const [state, setState] = useState({
    thermistor: true,
    transducer: false,
    general: false,
    relay: false
  })

  const { getSession } = useContext(AccountContext)

  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [nextTab, setNextTab] = useState(null)
  // const [currentTab, setCurrentTab] = useState(0)
  // const [isOpen, setIsOpen] = useState(false)
  const [isAuth, setIsAuth ] = useState(false)

    useEffect(() => {
      getSession().then(({ isAuth }) => {
        setIsAuth(isAuth)
      })
  }, [])

  const setInputTab = (tab) => {

    if(unsavedChanges && tab != props.currentTab){
      // if unsaved changes and new tab requested
      setNextTab(tab)
      props.setupIsTabOpen(true)
    } else {
      tabSwitch(tab)
    }
  }

  const tabSwitch = (tab) => {

    props.trackCurrentTab(tab)

    switch(tab){
      case 0: 
      setState({thermistor: true, transducer: false, general: false, relay: false})
      break
      case 1: 
      setState({thermistor: false, transducer: true, general: false, relay: false})
      break
      case 2: 
      setState({thermistor: false, transducer: false, general: true, relay: false})
      break
      case 3: 
      setState({thermistor: false, transducer: false, general: false, relay: true})
      break
      default: 
      setState({thermistor: true, transducer: false, general: false, relay: false})
      break
    }

    setNextTab(null)
  }

  const setUnsavedChangesStatus = (saved) => {
    setUnsavedChanges(saved)
    props.configChange(saved)
  }

  const closePopup = () => {
    props.setupIsTabOpen(false)
  }

  const goToNextTab = () => {
    setUnsavedChanges(false)
    props.setupIsTabOpen(false)
    tabSwitch(nextTab)
  }

  useEffect(() => {
    tabSwitch(props.currentTab)
}, [props.refresh])

useEffect(() => {
if(props.didConfigChange == false){
  setUnsavedChanges(false)
}
}, [props.didConfigChange])

  return (
    <div>
      <Modal isOpen={props.isTabOpen} onRequestClose={closePopup} style={customStyles} contentLabel='Wingnut Login'>
        <PopupContainer closePopup={closePopup} saveAll={props.saveAll} popupNumber={3} goToNextTab={goToNextTab} alertArray={props.alertArray} discardChanges={props.discardChanges} setTab={tabSwitch}/>
      </Modal>

      <Container>
                {/* <button ref={myRef} onClick={handleClickInside}>
            {clickedOutside ? 'Bye!' : 'Hello!'}
        </button> */}
        <SubHeaderContainer>
          <WingnutIcon src={Image} />
          <InfoText>{Object.keys(wingnutDetails.config.sensors).length}/{schemaConfig.totalConfigurations} Inputs configured</InfoText>
        </SubHeaderContainer>
        <WingnutConfigTabHeader unsavedChanges={unsavedChanges} setInputTab={setInputTab} state={state} />
        
        {
        state.thermistor ? 
        <div>
          <WingnutSensorConfigFormCell id={"a01"} icon={ImgRed} schema={schemaConfig.thermistorInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"a02"} icon={ImgRed} schema={schemaConfig.thermistorInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"a03"} icon={ImgRed} schema={schemaConfig.thermistorInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"a04"} icon={ImgRed} schema={schemaConfig.thermistorInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"a05"} icon={ImgRed} schema={schemaConfig.thermistorInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"a06"} icon={ImgRed} schema={schemaConfig.thermistorInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"a07"} icon={ImgRed} schema={schemaConfig.thermistorInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"a08"} icon={ImgRed} schema={schemaConfig.thermistorInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"a09"} icon={ImgRed} schema={schemaConfig.thermistorInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"a10"} icon={ImgRed} schema={schemaConfig.thermistorInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"a11"} icon={ImgRed} schema={schemaConfig.thermistorInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"a12"} icon={ImgRed} schema={schemaConfig.thermistorInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"a13"} icon={ImgRed} schema={schemaConfig.thermistorInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"a14"} icon={ImgRed} schema={schemaConfig.thermistorInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"a15"} icon={ImgRed} schema={schemaConfig.thermistorInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"a16"} icon={ImgRed} schema={schemaConfig.thermistorInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
        </div>
        :
        null
        }
      {
        state.transducer ?
        <div>
          <WingnutSensorConfigFormCell id={"a17"} icon={ImgPurple} schema={schemaConfig.transducerInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"a18"} icon={ImgPurple} schema={schemaConfig.transducerInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"a19"} icon={ImgPurple} schema={schemaConfig.transducerInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"a20"} icon={ImgPurple} schema={schemaConfig.transducerInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"a21"} icon={ImgPurple} schema={schemaConfig.transducerInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"a22"} icon={ImgPurple} schema={schemaConfig.transducerInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"a23"} icon={ImgPurple} schema={schemaConfig.transducerInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"a24"} icon={ImgPurple} schema={schemaConfig.transducerInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"a25"} icon={ImgPurple} schema={schemaConfig.transducerInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"a26"} icon={ImgPurple} schema={schemaConfig.transducerInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"a27"} icon={ImgPurple} schema={schemaConfig.transducerInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"a28"} icon={ImgPurple} schema={schemaConfig.transducerInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"a29"} icon={ImgPurple} schema={schemaConfig.transducerInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"a30"} icon={ImgPurple} schema={schemaConfig.transducerInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"a31"} icon={ImgPurple} schema={schemaConfig.transducerInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"a32"} icon={ImgPurple} schema={schemaConfig.transducerInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
        </div>
        :
        null
        }

        {
        state.general ? 
        <div>
          <WingnutSensorConfigFormCell id={"d01"} icon={ImgYellow} schema={schemaConfig.generalPurposeInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"d02"} icon={ImgYellow} schema={schemaConfig.generalPurposeInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"d03"} icon={ImgYellow} schema={schemaConfig.generalPurposeInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"d04"} icon={ImgYellow} schema={schemaConfig.generalPurposeInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
        </div>
        :
        null
        }

        {
        state.relay ? 
        <div>
          <WingnutSensorConfigFormCell id={"d05"} icon={ImgGreen} schema={schemaConfig.generalPurposeInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"d06"} icon={ImgGreen} schema={schemaConfig.generalPurposeInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"d07"} icon={ImgGreen} schema={schemaConfig.generalPurposeInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"d08"} icon={ImgGreen} schema={schemaConfig.generalPurposeInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"d09"} icon={ImgGreen} schema={schemaConfig.generalPurposeInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"d10"} icon={ImgGreen} schema={schemaConfig.generalPurposeInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"d11"} icon={ImgGreen} schema={schemaConfig.generalPurposeInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"d12"} icon={ImgGreen} schema={schemaConfig.generalPurposeInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"d13"} icon={ImgGreen} schema={schemaConfig.generalPurposeInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"d14"} icon={ImgGreen} schema={schemaConfig.generalPurposeInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"d15"} icon={ImgGreen} schema={schemaConfig.generalPurposeInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
          <WingnutSensorConfigFormCell id={"d16"} icon={ImgGreen} schema={schemaConfig.generalPurposeInputs} wingnutDetails={wingnutDetails} updateSensor={(s) => props.updateSensor(s)} unsavedChanges={setUnsavedChangesStatus} addAlert={props.addAlert} removeAlert={props.removeAlert} checkAlert={props.checkAlert} alertSignal={props.alertSignal} isAuth={isAuth}/>
        </div>
        :
        null
        }
      </Container>
    </div>
  )
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: COLORS.Primary,
    borderRadius: "30px",
    border: "none",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.7)",
    zIndex: 10,
  },
}




