/**
 * List of endpoints available to the React app. Unauthenticated endpoints are available to use
 * without authorization. Authenticated endpoints require a Bearer token to be passed in the header,
 * for example:
 *
 *   import { API, Auth } from "aws-amplify"
 *   ...
 *   const jwtToken = (await Auth.currentSession()).getIdToken().getJwtToken()
 *   API.get("FrontendAPI-dashboards", "", {
 *     headers: { Authorization: `Bearer ${jwtToken}` }
 *   })
 *
 * See examples/ for example responses
 */


const endpoints = [
  // ==== Unauthenticated endpoints ====
  {
    name: "FrontendAPI-users",
    endpoint: `https://fe.${process.env.REACT_APP_API_ZONENAME}/v1/users`,
    service: "lambda",
    region: `${process.env.REACT_APP_API_REGION}`,
  },
  {
    name: "FrontendAPI-schema",
    endpoint: `https://fe.${process.env.REACT_APP_API_ZONENAME}/v1/wingnuts/schema`,
  },
  // ===== Authenticated endpoints =====
  /**
   * Dashboards endpoint for fetching dashboards. List of available routes:
   *
   * - GET /v1/dashboards - returns a list of dashboard IDs
   * - GET /v1/dashboards/{UUID} - returns an embed URL for the specified UUID if the user has
   *                               permissions to view the dashboard.
   *   * Required - 2 query string parameters
   *     * jwtToken - the JWT token used to authenticate
   *     * payloadSub - the payload sub of the ID token of the current session from
   *                    (await Auth.currentSession()).getIdToken().payload.sub
   */
  {
    name: "FrontendAPI-dashboards",
    endpoint: `https://fe.${process.env.REACT_APP_API_ZONENAME}/v1/dashboards`,
    service: "lambda",
    region: `${process.env.REACT_APP_API_REGION}`,
  },
  /**
   * Properties endpoint for fetching and updating properties. List of available routes:
   * - GET /v1/properties - returns a list of properties and their linked wingnuts
   * - GET /v1/properties/{UUID} - returns details for the specified UUID
   * - POST /v1/properties - creates a new property
   *   * Request - {
   *                 externalId: String,
   *                 config: {
   *                   name: String,
   *                   address: String,
   *                   assets: {},         // Unused for now
   *                   portfolio: String
   *                 }
   *               }
   * - POST /v1/properties/{UUID} - updates an existing property
   *   * Request - {
   *                 externalId: String,
   *                 config: {
   *                   name: String,
   *                   address: String,
   *                   assets: {},         // Unused for now
   *                   portfolio: String
   *                 }
   *               }
   * - DELETE /v1/properties{UUID} - removes an existing property
   *   * Not yet implemented
   */

  {
    name: "FrontendAPI-properties",
    endpoint: `https://fe.${process.env.REACT_APP_API_ZONENAME}/v1/properties`,
    service: "lambda",
    region: `${process.env.REACT_APP_API_REGION}`,
  },
  /**
   * Wingnuts endpoint for fetching and updating wingnuts. List of available routes:
   *
   * - GET /v1/wingnuts - returns a list of wingnuts and their properties
   * - GET /v1/wingnuts/{UUID} - returns wingnut details and its property if the user has access
   * - POST /v1/wingnuts - adds a wingnut to the user's company
   *   * Request - {
   *                 wingnutId: String  // UUID of wingnut
   *               }
   * - POST /v1/wingnuts/{UUID} - updates fields of a wingnut
   *   * Request - {
   *                 alarmsEnabled: Boolean,
   *                 sensors: {
   *                   String: {        // Key is the name of the input, e.g. "AN1"
   *                     label: String,
   *                     type: String,
   *                     alarmEnabled: Boolean,
   *                     alarmThresholds: [{
   *                       type: String,
   *                       value: Float
   *                     }]
   *                   }
   *                 }
   *               }
   * - POST /v1/wingnuts/{UUID}/link - links/unlinks a wingnut to a property.
   *   * Request - {
   *                 propertyId: String // UUID of property, empty string to unlink
   *               }
   */
  {
    name: "FrontendAPI-wingnuts",
    endpoint: `https://fe.${process.env.REACT_APP_API_ZONENAME}/v1/wingnuts`,
    service: "lambda",
    region: `${process.env.REACT_APP_API_REGION}`,
  },
  /**
   * Notifications endpoint for fetching and updating notifications. List of available routes:
   * 
   * - GET /v1/notifications - returns a list of notifications for the user's company
   * - GET /v1/notifications?propertyId={UUID} - returns the notification for a property
   * - GET /v1/notifications/{UUID} - returns a notification with specified UUID
   * - POST /v1/notifications - create a notification (TBD)
   * - POST /v1/notifications/{UUID} - update a notification
   *   * Request - {
   *                 entityStatus: String            // "enabled" or "disabled"
   *                 config: {
   *                   thresholds: {
   *                     [key: String]: {            // key is the sensor label
   *                       min: Float,
   *                       max: Float,
   *                       thresholdStatus: String,  // "enabled" or "disabled"
   *                       recipients: [
   *                         {
   *                           type: String,         // "slack", "sms", or "email"
   *                           value: String         // endpoint, phone number (+10001112222), or email respectively
   *                         }
   *                       ]
   *                     }
   *                   }
   *                 }
   *               }
   */
  {
    name: "FrontendAPI-notifications",
    endpoint: `https://fe.${process.env.REACT_APP_API_ZONENAME}/v1/notifications`,
    service: "lambda",
    region: `${process.env.REACT_APP_API_REGION}`,
  },

  {
    name: "FrontendAPI-thresholds",
    endpoint: `https://fe.${process.env.REACT_APP_API_ZONENAME}/v1/thresholds`,
    service: "lambda",
    region: `${process.env.REACT_APP_API_REGION}`,
  },

  {
    name: "FrontendAPI-companies",
    endpoint: `https://fe.${process.env.REACT_APP_API_ZONENAME}/v1/companies`,
    service: "lambda",
    region: `${process.env.REACT_APP_API_REGION}`,
  },

  {
    name: "FrontendAPI-analytics",
    endpoint: `https://fe.${process.env.REACT_APP_API_ZONENAME}/v1/analytics`,
    service: "lambda",
    region: `${process.env.REACT_APP_API_REGION}`,
  },

]

export default endpoints



