import React, { useEffect, useState, useContext } from "react"
import { API, Auth } from "aws-amplify"
import styled from "styled-components"
import { Link } from "react-router-dom"
import media from "styled-media-query"
import ImgArrowRightWhite from "./../../images/ic_arrow_right_white.svg"
import ImgArrowRight from "./../../images/ic_arrow_right_blue.svg"
import ImgArrowDown from "./../../images/ic_arrow_down_white.svg"
import ImgProperty from "./../../images/ic_property.svg"
import ImgWingnut from "./../../images/ic_wingnuts.svg"
import Image6 from "./../../images/ic_arrow_right_green_fill.svg"
import EditPropertyDetails from "./EditPropertyDetails"
import NotificationThresholds from "./../notification/NotificationThresholds"
import WingnutStatus from "../wingnuts/WingnutStatus.enum"
import { DateTime } from "luxon"
import { useMediaQuery } from 'react-responsive'
import { AccountContext } from "../../auth/Account"
import { COLORS } from "../../styles/colors"
import Alerts from "./Alerts"
import Modal from "react-modal"
import PopupContainer from "./../popup/PopupContainer"

export default function PropertyDetail(props) {
  const { params } = props.match
  const isDesktopSize = useMediaQuery({ query: '(min-width: 744px)' })

  // const [isEditNotificationHovering, setIsEditNotificationHovering] = useState(false)

  const [propertyDetails, setPropertyDetails] = useState()
  const [notificationDetails, setNotificationDetails] = useState()
  const [alerts, setAlerts] = useState([])
  const [breaches, setBreaches] = useState({})
  const [currentThresholds, setCurrentThresholds] = useState()
  const [disable, setDisabled] = useState(true)
  const [notificationId, setNotificationId] = useState(null)
  const [isEditOpen, setIsEditOpen] = useState(false)
  const [isEditNotificationOpen, setIsEditNotificationOpen] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  const [isPropertyHovering, setIsPropertyHovering] = useState(false)
  const [isLinkedWingnutHovering, setIsLinkedWingnutHovering] = useState(false)
  const [isRemoveButtonHovering, setIsRemoveButtonHovering] = useState(false)

  const [refreshAlerts, setRefreshAlerts] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const { getSession } = useContext(AccountContext)
  const [isAuth, setIsAuth ] = useState(false)

  useEffect(() => {
    getSession().then(({ isAuth }) => {
      setIsAuth(isAuth)
    })
}, [])

  useEffect(() => {
    let cancelled = false

    async function getPropertyDetails() {
      const token = (await Auth.currentSession()).getIdToken()
      const jwt = token.getJwtToken()
      const init = {
        headers: { Authorization: `Bearer ${jwt}` },
      }
      const response = await API.get('FrontendAPI-properties', `/${params.id}`, init)
      if (response.success && !cancelled) {
        setDisabled(false)
        setPropertyDetails(response.data)
      }
    }

    getPropertyDetails()
    return () => {
      cancelled = true
    }
  }, [formSubmitted])

  const closePopup = () => {
    setIsOpen(false)
  }

  const updatePropertyDetails = () => {
    setFormSubmitted(!formSubmitted)
  }

  const updateAlerts = (alerts) => {
    setAlerts(alerts)
  }

  const updateBreaches = (breaches) => {
    setBreaches(breaches)
  }

  const updateCurrentThresholds = (thresholds) => {
    setCurrentThresholds(thresholds)
  }

  const willRefreshAlerts = () => {
    setRefreshAlerts(!refreshAlerts)
  }

  const wingnutStatus = (item) => {
    if (item.config.latestPost) {
      var date = DateTime.utc()
      var apiDateTime = item.config.latestPost ? DateTime.fromFormat(item.config.latestPost, "yyyy-MM-dd HH:mm:ss") : DateTime.utc()
      var difference = date.diff(apiDateTime, 'minutes')
      if (difference.minutes > 15) {
        return WingnutStatus.Disconnected.value
      }
    } else {
      return WingnutStatus.Disconnected.value
    }

      if (item.config.propertyId === undefined) {
        return WingnutStatus.Unlinked.value
      }

      if (!(Object.keys(item.config.sensors).length > 0)) {
        return WingnutStatus.Unconfigured.value
      }

    return WingnutStatus.Live.value
  }

  const toggleNotificationDetails = (open, target) => {
    if(open){
        const element = isDesktopSize ? document.getElementById('top') : document.getElementById('cardTop');
        element.scrollIntoView({ behavior: 'smooth' });
        setTimeout(() => {
            setIsEditNotificationOpen(false)
        }, 400)
    } else {
        setIsEditNotificationOpen(true)
        setTimeout(() => {
            const element = document.getElementById(target);
            element.scrollIntoView({ behavior: 'smooth' });
        }, 100)
    }
}

  const toggleDetails = () => {
    if(isRemoveButtonHovering == false){
      if(isEditOpen){
        const element = isDesktopSize ? document.getElementById('top') : document.getElementById('cardTop');
        element.scrollIntoView({ behavior: 'smooth' });
        setTimeout(() => {
          setIsEditOpen(false)
        }, 400)
      } else {
        setIsEditOpen(true)
        setTimeout(() => {
        const element = document.getElementById('edit');
        element.scrollIntoView({ behavior: 'smooth' });
        }, 100)
      }
    }
  }

  const openEditPropertyDetails = () => {
    if(disable !== true){
      setIsEditOpen(true)
      setTimeout(() => {
        const element = document.getElementById('edit');
        element.scrollIntoView({ behavior: 'smooth' });
      }, 100)
    }
  }

       // Use propertyDetails to fetch the notificationDetails
      useEffect(() => {
        let cancelled = false

        async function getNotificationDetails() {
          const token = (await Auth.currentSession()).getIdToken()
          const jwt = token.getJwtToken()
          const init = {
            headers: { Authorization: `Bearer ${jwt}` },
          }
  
          if(propertyDetails){
            const response = await API.get('FrontendAPI-notifications', `?propertyId=${propertyDetails.id}`, init) 
            if (response.success && !cancelled) {
              if(response.data[0] !== null && response.data[0] !== undefined){
                setNotificationId(propertyDetails.id)
                setNotificationDetails(response.data)
              }
            }
          }      
        }
        getNotificationDetails()

        return () => {
          cancelled = true
        }
    }, [propertyDetails, refreshAlerts])

  return (
    <OuterContainer id='top'>
      <Modal isOpen={isOpen} onRequestClose={closePopup} style={customStyles} contentLabel=''>
        <PopupContainer uuid={propertyDetails ? propertyDetails.id : null} closePopup={closePopup} popupNumber={2} />
      </Modal>
      <Container>
        <StatusCard>

        {/*----------------Alerts Start----------  */}
          <Alerts 
            currentThresholds={currentThresholds} 
            notificationId={notificationId} 
            toggleNotificationDetails={toggleNotificationDetails} 
            alerts={alerts} updateAlerts={updateAlerts} 
            refreshAlerts={refreshAlerts} 
            willRefreshAlerts={willRefreshAlerts}
            updateBreaches={updateBreaches}
            />
          <DetailsContainer>

          {/*----------------Property Details Start----------  */}

            <WingnutDetails id="cardTop">
              <DetailsInfo>
                <HeaderContainer style={{marginBottom: 20}}>
                  <IconProperty src={ImgProperty} />
                  <HeaderText>Property Details</HeaderText>
                </HeaderContainer>
                <DetailCell>
                  <DetailTextCell style={{minWidth: 230}}>
                    <Title>Property Name</Title>
                    <InfoText>{propertyDetails && propertyDetails.config.name}</InfoText>
                  </DetailTextCell>
                  <DetailTextCell>
                    <Title>Portfolio</Title>
                    <InfoText>{propertyDetails && propertyDetails.config.portfolio}</InfoText>
                  </DetailTextCell>
                </DetailCell>
                <DetailCell>
                  <DetailTextCell style={{minWidth: 230}}>
                    <Title>Property ID</Title>
                    <InfoText>{propertyDetails && propertyDetails.externalId}</InfoText>
                  </DetailTextCell>
                  <DetailTextCell >
                    <Title>Date Created</Title>
                    <InfoText>{propertyDetails && propertyDetails.createdAt ? propertyDetails.createdAt.split(" ")[0] : "-"}</InfoText>
                  </DetailTextCell>
                </DetailCell>
                <Title>Address</Title>
                <InfoText>{propertyDetails && `${propertyDetails.config.address.streetAddress} ${propertyDetails.config.address.city} ${propertyDetails.config.address.provinceState} ${propertyDetails.config.address.country} ${propertyDetails.config.address.postalZip}`}</InfoText>
              </DetailsInfo>
            <div style={{pointerEvents: disable ? "none" : "auto", opacity: disable ? "0.4" : "1.0" }} >
                  <BtnEditProperty 
                    style={{display: isAuth ? "flex" : "none"}}
                    onClick={openEditPropertyDetails}
                    onMouseEnter={(e) => {
                      setIsPropertyHovering(true)
                    }}
                    onMouseLeave={(e) => {
                      setIsPropertyHovering(false)
                    }}>
                  <IconArrow
                      style={{
                        opacity: isPropertyHovering == true ? "100" : "0",
                        display: isPropertyHovering == true ? "inline" : "none",
                      }}
                      src={Image6}
                    />
                    <ButtonText style={{ marginLeft: isPropertyHovering == true ? "10px" : "0px", color: isPropertyHovering == true ? COLORS.Green : null}}>
                      EDIT PROPERTY DETAILS
                    </ButtonText>
                  </BtnEditProperty>
              </div>
            </WingnutDetails>

       {/*----------------Linked Wingnuts Start----------  */}

            <WingnutDetailsRight>
              <DetailsInfo style={{justifyContent: 'space-between', height: '100%'}}>
                <HeaderContainer>
                  <IconProperty src={ImgWingnut} />
                  <HeaderText>Linked Wingnuts</HeaderText>
                </HeaderContainer>
                <BtnWingnutContainer>
     
                  {propertyDetails && propertyDetails.linkedWingnuts.map((item, index) => {
                    return (
                      <WingnutContainer>
                          <WingnutTag styles={{cursor: "none"}}>
                            <IconWingnut src={wingnutStatus(item)} />
                            <WingnutLabel>{(item.externalId).substr(0,4)}</WingnutLabel>
                          </WingnutTag>

                        <Link key={`linked-wingnut-${item.id}`} to={`/portal/wingnut/detail/${item.id}`} style={{ textDecoration: "none", alignSelf: 'center' }}>
                            <Button>GO TO WINGNUT</Button>
                        </Link>
                      </WingnutContainer>
                    )
                  })}

                  {propertyDetails && propertyDetails.linkedWingnuts.length === 0 ?
                      <NoWingnutContainer>
                          <NoWingnutText>No Wingnuts currently linked to this Property.</NoWingnutText>
                          <NoWingnutText>You must link a wingnut to start collecting data.</NoWingnutText>
                        </NoWingnutContainer>
                :
                  null               
                }

                  </BtnWingnutContainer>
                  <div></div>
                </DetailsInfo>
              <div style={{pointerEvents: disable? "none" : "auto", opacity: disable ? "0.4" : "1.0", display: "block" }} >
                <Link to={`/portal/property/link/${propertyDetails && propertyDetails.id}`} style={{ textDecoration: "none" }}>
                  <BtnLinkWingnut 
                    style={{display: isAuth ? "flex" : "none"}}
                    onMouseEnter={(e) => {
                      setIsLinkedWingnutHovering(true)
                    }}
                    onMouseLeave={(e) => {
                      setIsLinkedWingnutHovering(false)
                    }}>
                    <IconArrow
                        style={{
                          opacity: isLinkedWingnutHovering == true ? "100" : "0",
                          display: isLinkedWingnutHovering == true ? "inline" : "none",
                        }}
                        src={Image6}
                      />
                    <ButtonText  style={{ marginLeft: isLinkedWingnutHovering == true ? "10px" : "0px", color: isLinkedWingnutHovering == true ? COLORS.Green : null}}>
                      LINK A NEW WINGNUT
                    </ButtonText>
                  </BtnLinkWingnut>
                </Link>
              </div>
                  
            </WingnutDetailsRight>
          </DetailsContainer>

         {/*----------------Edit Notification Start----------  */}

          <div id='editThresholds'/>
          <NotificationThresholds 
              params={params} 
              alerts={alerts} 
              breaches={breaches}
              disable={disable}
              willRefreshAlerts={willRefreshAlerts}
              propertyDetails={propertyDetails} 
              notificationDetails={notificationDetails} 
              toggleNotificationDetails={toggleNotificationDetails} 
              isEditNotificationOpen={isEditNotificationOpen}
              updateCurrentThresholds={updateCurrentThresholds}
              />
  
         {/*----------------Edit Property Start----------  */}

          <div id='edit' />
          <EditDetailsContainer style={{pointerEvents: disable? "none" : "auto", opacity: disable ? "0.4" : "1.0", display: isAuth ? "flex" : "none" }}>
            <ConfigContainer
                  onClick={() => !isLoading ? isRemoveButtonHovering ? setIsOpen(true) : toggleDetails() : null} 
                  style={{ cursor: !isLoading ? 'pointer' : 'default', background: isEditOpen ? null : isHovering ? COLORS.Secondary : null}}
                  onMouseEnter={(e) => {setIsHovering(true)}}
                  onMouseLeave={(e) => {setIsHovering(false)}}>
              <HeaderText >
                  Edit Property Details
              </HeaderText>
              <RightContainer>
                <BtnRemoveProperty 
                  onMouseEnter={(e) => {setIsRemoveButtonHovering(true)}}
                  onMouseLeave={(e) => {setIsRemoveButtonHovering(false)}}
                  style={{opacity: isRemoveButtonHovering ? 0.8 : 1}}>
                  <ButtonPropertyText>REMOVE PROPERTY</ButtonPropertyText>
                </BtnRemoveProperty>
                <ArrowIcon
                  src={isEditOpen? ImgArrowDown : ImgArrowRightWhite}/>
              </RightContainer>
            </ConfigContainer>
          </EditDetailsContainer>

          {isEditOpen && 
          (<EditPropertyDetails 
          disable={disable} 
          propertyDetails={propertyDetails} 
          editOpen={setIsEditOpen} 
          updatePropertyDetails={() => updatePropertyDetails()} />)
          }

        </StatusCard>
      </Container>
    </OuterContainer>
  )
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: COLORS.Primary,
    borderRadius: "30px",
    border: "none",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.7)",
    zIndex: 10,
  },
}

const OuterContainer = styled.div`
  /* border: 1px red dashed; */
`;

const Container = styled.div`
  /* border: 1px yellow dashed; */
  max-width: 1440px;
  display: flex;
  height: auto;
  flex-direction: column;
  background-color: ${COLORS.Base};
  margin-bottom: 20px;
  margin-top: 20px;
`

const StatusCard = styled.div`
  /* border: 1px yellow dashed; */
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-right: 30px;
  ${media.lessThan("743px")`
        width: 95%;
        margin-right: 0px;
        align-self: center;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`;

const HeaderContainer = styled.div`
  /* border: 1px blue dashed; */
  display: flex;
  flex-direction: row;
`;

const HeaderText = styled.h1`
  font-family: Blinker;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 110%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
`

const Line = styled.div`
  width: auto;
  height: 0.69px;
  background: ${COLORS.HighlightMedium};
  margin-top: 30px;
  margin-bottom: 30px;
`

const DetailsContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 20px;
  ${media.lessThan("743px")`
      flex-direction: column;
      margin-right: 0px;
  `}
  ${media.between("744px", "large")`
  flex-direction: row;
  `}
`

//------------------------

const WingnutDetails = styled.div`
   /* border: 1px red dashed; */
   background: #0e1c36;
   display: flex;
   flex-direction: column;
   width: 50%;
   /* padding: 20px; */
   padding-bottom: 0px;
   border-radius: 10px;
   margin-right: 8px;
   ${media.lessThan("743px")`
         width: 100%;
         margin-right: 0px;
     `}
   ${media.between("744px", "large")`
     `}
     ${media.greaterThan("large")`
     `}
 `

const DetailsInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const WingnutDetailsRight = styled.div`
  /* border: 1px red dashed; */
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   width: 50%;
   /* min-height: 200px; */
   background: #0e1c36;
   /* padding: 20px; */
   padding-bottom: 0px;
   border-radius: 10px;
   margin-left: 8px;
  ${media.lessThan("743px")`
    width: 100%;
    margin-left: 0px;
    max-width: 700px;
    margin-top: 16px;
  `}
  ${media.between("744px", "large")`
    `}
  ${media.greaterThan("large")`
  `}
`

const DetailCell = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  ${media.lessThan("743px")`
  flex-wrap: wrap;
  `}
  ${media.between("744px", "large")`
  flex-wrap: wrap;
  `}
  `;

const DetailTextCell = styled.div`
  display: flex;
  margin-bottom: 10px;
  flex-direction: column;
  width: 50%;
`;

const Title = styled.h3`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 129.5%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color: ${COLORS.HighlightMedium};
`

const InfoText = styled.h2`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: ${COLORS.HighlightLight};
  margin-top: 2px;
`

// const BtnLinkWingnut = styled.button`
//     display: flex;
//     flex-direction: row;
//     justify-content: center;
//     align-items: center;
//     padding: 14px 40px;
//     gap: 9px;
//     background: transparent;
//     width: 100%;
//     height: 40px;
//     border: 1px solid #63C085;
//     border-radius: 30px;
//     margin-right: 20px;
//     :hover {
//       background: ${COLORS.Secondary};
//   }
// `;

const BtnEditProperty = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 40px;
    background: transparent;
    width: 100%;
    height: 49px;
    border: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: ${COLORS.Secondary};
`;

const BtnLinkWingnut = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 40px;
    background: transparent;
    width: 100%;
    height: 49px;
    border: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: ${COLORS.Secondary};
`;

const BtnWingnutContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${media.lessThan("743px")`
    margin-bottom: 15px;
    margin-top: 20px;
  `}
  /* justify-content: center; */
`


const WingnutContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  width: 50%;
  min-width: 225px;
  margin-bottom: 10px;
  ${media.lessThan("743px")`

    `}
`;

const WingnutLabel = styled.p`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
  align-self: center;
`


const ButtonText = styled.p`
  /* border: 1px red dashed; */
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${COLORS.White};
  align-self: center;
  transition-duration: 0.5s;
  ${media.lessThan("743px")`
        font-size: 15px;
        /* width: 170px; */
    `}
  ${media.between("744px", "large")`
        font-size: 15px;
    `}
    ${media.greaterThan("large")`
    `}
`

const BtnText2 = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 129.5%;
  display: flex;
  align-items: center;
  color: ${COLORS.White};
`

const Button = styled.button`
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  border: none;
  padding: 0px;
  background: transparent;
  cursor: pointer;
  color: ${COLORS.White};

  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 129.5%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${COLORS.Green};
  margin-left: 15px;

  :hover {
    color: #63C085;
  }
`

const WingnutTag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  border: none;
  width: 105px;
  height: 43px;
  background: ${COLORS.Secondary};
  border-radius: 30px;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`

    `}
    ${media.greaterThan("large")`
    `}
`

const NoWingnutContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const NoWingnutText = styled.p`
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 130%;
  color: ${COLORS.White};
`;

const IconWingnut = styled.img`
  margin-right: 8px;
  width: 12.77px;
  height: 12.77px;
  align-self: center;
  transition-duration: 0.5s;
`

const IconProperty = styled.img`
  /* border: 1px blue dashed; */
  width: 23px;
  height: 23px;
  align-self: center;
  justify-self: center;
  margin-right: 23px;
  transition-duration: 0.5s;
`

const IconArrow = styled.img`
  width: 6;
  height: 10px;
  /* margin-left: 20px; */
  align-self: center;
  transition-duration: 0.5s;
  ${media.lessThan("medium")`
    `}
  ${media.between("medium", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const EditDetailsContainer = styled.div`
  /* border: 1px blue dashed; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* padding: 20px; */
  height: 69px;
  border-radius: 10px;
  margin-top: 15px;
  margin-right: 20px;
  background-color: #0e1c36;
  ${media.lessThan("743px")`
    margin-right: 0px;
  `}
`

const ConfigContainer = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
`;

const RightContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  align-content: center;
`;

const EditNotifyHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const BtnRemoveProperty = styled.button`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 40px;
  border: 1px solid #63c085;
  border-radius: 30px;
  color: ${COLORS.White};
  background-color: #0E1C36;
  cursor: pointer;
  text-align: center;
  margin-right: 15px;
  align-self: center;
  ${media.lessThan("743px")`
    /* display: none; */
    `}
  ${media.between("744px", "large")`
    /* display: none; */

    `}
    ${media.greaterThan("large")`
    `}
`

const ButtonPropertyText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transition-duration: 0.5s;
  color: ${COLORS.White};
  ${media.lessThan("743px")`
    /* display: none; */
    `}
  ${media.between("744px", "large")`
  `}
  ${media.greaterThan("large")`
    /* display: none; */
    `}
`

const ArrowIcon = styled.img`
  width: 24px;
  height: 24px;
  align-self: center;
`

const NotifyContainer = styled.div`
  /* border: 1px blue dashed; */
  display: flex;
  flex-direction: row;
  justify-content: center;  
  margin-right: 22px;
`;

const NotifyIcon = styled.img`
    width: 25px;
    align-self: center;
    margin-right: 9px;
`;

const NotifyTag = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-content: center;
  /* padding: 0px 12px; */
  /* gap: 10px; */
  width: 32px;
  height: 18px;
  background: ${COLORS.White};
  border-radius: 100px;
  align-self: center;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`

    `}
    ${media.greaterThan("large")`
    `}
`

const NotifyText = styled.p`
  /* border: 1px red dashed; */
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 129.5%;
  display: flex;
  align-self: center;
  margin-top: 0.5px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${COLORS.Base};
`;


const SaveConfigContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  ${media.lessThan("743px")`
    display: none;
    `}
  ${media.between("744px", "large")`
    display: none;
  `}
  ${media.greaterThan("large")`
    `}
`;

const SaveConfigContainerMobile = styled(SaveConfigContainer)`
  /* border: 1px red dashed; */
  background: #0e1c36;
  height: 60px;
  margin-top: -10px;
  padding-left: 20px;
  ${media.lessThan("743px")`
    display: flex;
    width: 100%;
    `}
  ${media.between("744px", "large")`
    display: flex;
  `}
  ${media.greaterThan("large")`
    display: none;
      height: 10px;
    `}
`;

const SaveConfig = styled.button`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 40px;
  gap: 9px;
  width: 241px;
  height: 40px;
  border: 1px solid #63C085;
  border-radius: 30px;
  background: none;
  align-self: center;
  ${media.lessThan("743px")`
        display: none;
    `}
  ${media.between("744px", "large")`
      display: none;
    `}
    ${media.greaterThan("large")`
    `}
`

const SaveConfigMobile = styled(SaveConfig)`
    ${media.lessThan("743px")`
      display: flex;
    `}
    ${media.between("744px", "large")`
      display: flex;
    `}
    ${media.greaterThan("large")`
      display: none;
    `}
`;

const BtnText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transition-duration: 0.5s;
  color: ${COLORS.White};
  ${media.lessThan("743px")`
      display: none;
    `}
    ${media.between("744px", "large")`
      display: none;
    `}
    ${media.greaterThan("large")`
      display: flex;
    `}
`

const BtnTextMobile = styled(BtnText)`
      ${media.lessThan("743px")`
      display: flex;
    `}
    ${media.between("744px", "large")`
      display: flex;
    `}
    ${media.greaterThan("large")`
      display: none;
    `}
`;

const ButtonText2 = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: ${COLORS.White};
  text-align: center;
  align-self: center;
  margin-right: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transition-duration: 0.5s;
  cursor: pointer;
  :hover {
    color: #63C085; 
  }
    ${media.lessThan("743px")`
    display: none;
    `}
  ${media.between("744px", "large")`
    /* display: none; */
    `}
    ${media.greaterThan("large")`
    `}
  `;

  const ButtonText2Mobile = styled(ButtonText2)`
    margin-left: 20px;
  ${media.lessThan("743px")`
    display: flex;
  `}
  ${media.between("744px", "large")`
    display: flex;
  `}
  ${media.greaterThan("large")`
    display: none;
  `}
  `;
