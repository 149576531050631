import React, { useState } from "react"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import media from "styled-media-query"
import { COLORS } from "../../styles/colors"
import { ButtonSpinner } from "./../custom/Spinner"

const Form = styled.form`
  /* border: 1px blue dashed; */
  width: auto;
  display: flex;
  flex-direction: column;
`

const Label = styled.label`
  /* border: 1px blue dashed; */
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 129.5%;
  letter-spacing: 0.18em;
  color: ${COLORS.White};
  margin-bottom: 8px;
  margin-top: 16px;
`

const Input = styled.input`
  /* border: 1px blue dashed; */
  width: 453.23px;
  height: 49.65px;
  outline: none;
  border: none;
  color: ${COLORS.White};
  font-family: "Blinker", sans-serif;
  font-style: normal;
  font-size: 15px;
  letter-spacing: 0.1em;
  font-weight: 400;
  background-color: #1d283e;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 10px;
  :focus {
    outline: blue;
    border: 1px solid blue;
    border-color: #719ece;
  }
  ${media.lessThan("743px")`
    width: 260px;
    `}
  ${media.between("744px", "large")`
      width: 300px;
    `}
    ${media.greaterThan("large")`
    `}
`

const Textarea = styled.textarea`
  resize: none;
  width: 453.23px;
  height: 173px;
  outline: none;
  border: none;
  color: ${COLORS.White};
  font-family: "Blinker", sans-serif;
  font-style: normal;
  font-size: 15px;
  letter-spacing: 0.1em;
  font-weight: 400;
  background-color: #1d283e;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 10px;
  :focus {
    outline: blue;
    border: 1px solid blue;
    border-color: #719ece;
  }
  ${media.lessThan("743px")`
    width: 260px;
    `}
  ${media.between("744px", "large")`
    width: 300px;
    `}
    ${media.greaterThan("large")`
    `}
`

const Button = styled.button`
  /* border: 1px blue dashed; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 255.55px;
  height: 69.62px;
  margin-top: 44px;
  background-color: transparent;
  border: 2px solid #63c085;
  box-sizing: border-box;
  border-radius: 36px;
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 129.5%;
  text-align: center;
  letter-spacing: 0.05em;
  color: ${COLORS.White};
  align-self: flex-end;
  /* start of Hover code */
  transition-duration: 0.5s;
  :hover {
    background-color: #63c085;
  }
  /* end of hover code */
  ${media.lessThan("743px")`
      align-self: center;
    `}
  ${media.between("744px", "large")`
      align-self: center;
    `}
`

const Error = styled.p`
  /* border: 1px blue dashed; */
  color: red;
  margin-top: 5px;
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 129.5%;
  letter-spacing: 0.05em;
`

const Success = styled.p`
  /* border: 1px blue dashed; */
  color: green;
  margin-top: 10px;
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 129.5%;
  letter-spacing: 0.05em;
  width: 255.55px;
  align-self: flex-end;
  text-align: center;
  ${media.lessThan("743px")`
      align-self: center;
    `}
  ${media.between("744px", "large")`
      align-self: center;
    `}
`

const ButtonText = styled.p`
  /* border: 1px red dashed; */
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${COLORS.White};
  ${media.lessThan("743px")`
        font-size: 15px;
    `}
  ${media.between("744px", "large")`
        font-size: 15px;
    `}
    ${media.greaterThan("large")`
    `}
`

export default function ContactUs() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({ reValidateMode: "onSubmit" })

  const [disabled, setDisabled] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)

  const onSubmit = async (data) => {
    setDisabled(true)
    setShowSpinner(true)

    try {
      let result = await fetch(
                // "https://10ww8j15w1.execute-api.us-east-2.amazonaws.com/prod/sendcontactus", // info@spherescreative.com
                "https://3qvbz9e2j8.execute-api.us-west-2.amazonaws.com/prod/messages", // Wingnut
        {
        method: "post",
        mode: "no-cors",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          fullname: data.fullname,
          company: data.company,
          email: data.email,
          message: data.message,
        }),
      })
      reset({ fullname: "", company: "", email: "", message: "" })
      setDisabled(false)
      setShowSpinner(false)
    } catch (e) {
      console.log("Error", e)
      setDisabled(false)
      setShowSpinner(false)
    }
  }

  return (
    <div>
      <Form autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
        <Label>FULL NAME*</Label>
        <Input type='text' name='fullname' {...register("fullname", { required: true, maxLength: 45 })} />
        {errors.fullname && <Error>Username required</Error>}

        <Label>COMPANY</Label>
        <Input type='text' name='company' {...register("company", { maxLength: 45 })} />

        <Label>EMAIL*</Label>
        <Input
          type='text'
          name='email'
          {...register("email", {required: true,maxLength: 45,pattern:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,})}
        />
        {errors.email && <Error>Email required and must be valid</Error>}

        <Label>MESSAGE</Label>
        <Textarea type='textarea' rows={6} name='message' {...register("message", { maxLength: 200 })} />

        <Button disabled={disabled} type='submit' style={{opacity: disabled ? "0.8" : "1.0"}}>
            <ButtonText style={{marginLeft: disabled ? "22px" : null}}>SUBMIT</ButtonText>
            {showSpinner && <ButtonSpinner animation='border' />}
        </Button>
        {isSubmitSuccessful ? <Success>Message Sent</Success> : null}
      </Form>
    </div>
  )
}
