import React, { useEffect, useState, useContext } from "react"
import { API, Auth } from "aws-amplify"
import styled from "styled-components"
import media from "styled-media-query"
import  NotificationDefaults  from './NotificationDefaults'
import Modal from "react-modal"
import PopupContainer from "../popup/PopupContainer"
import { COLORS } from "../../styles/colors"


export default function NotificationSaveDiscard(props) {

  const [didConfigChange, setDidConfigChange] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [popupNumber, setPopupNumber] = useState(4)
  const [wingnutDetails, setWingnutDetails] = useState()
  // const [refresh, setRefresh] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  const [saveDisabled, setSaveDisabled] = useState(false)
  const [errorArray, setErrorArray] = useState([])
  

    useEffect(() => {
        setDidConfigChange(props.activeChange)
    }, [props.activeChange])

    useEffect(() => {
        if(props.saveAllPopup === true){
            showSavePopup()
        } else {
            setShowSpinner(false)
        }
    }, [props.saveAllPopup])

    useEffect(() => {
        if(props.discardAllPopup === true){
            showPopup(5)
        }
    }, [props.discardAllPopup])

    useEffect(() => {
      if(props.saveOrAbandonPopup === true){
          showPopup(7)
      } 
  }, [props.saveOrAbandonPopup])

  const showSavePopup = () => {
    if(errorArray && errorArray.length !== 0){
      showPopup(6)
    } else {
      showPopup(4)
    }
  }

  const showPopup = (number) => {
    setPopupNumber(number)
    setIsOpen(true)
  }

    const closePopup = () => {
      setIsOpen(false)
      props.resetPopupState()
  }


    //  Passed into the Popup
    const saveConfiguration = async () => {
      setShowSpinner(true)
      setTimeout(() => {
        props.didPushSaveData()
        setShowSpinner(false)
    }, 1000)
}

    const discardChanges = () => {
          setDidConfigChange(false)
          props.willRefreshData();
          setShowSpinner(true)
          setTimeout(() => {
            setShowSpinner(false)
        }, 1000)
    }

    

  return (

    <OuterContainer>
        <Modal isOpen={isOpen} onRequestClose={closePopup} style={customStyles} contentLabel=''>
          <PopupContainer uuid={wingnutDetails && wingnutDetails.id} closePopup={closePopup} popupNumber={popupNumber} saveAll={saveConfiguration} discardAll={discardChanges} showSpinner={showSpinner} saveDisabled={saveDisabled}/>
        </Modal>

            <SaveConfigContainer style={{display: props.activeChange ? 'flex' : 'none'}} disabled={!didConfigChange} >
                <ButtonText2  onClick={props.triggerDiscardAllPopup} style={{marginRight: 18, alignSelf: 'center'}}>
                    DISCARD CHANGES
                </ButtonText2>
                <SaveConfig onClick={props.triggerSaveAllPopup}>
                    <BtnText >SAVE CONFIGURATION</BtnText>
                </SaveConfig>
            </SaveConfigContainer>
      </OuterContainer>
  )

}


const OuterContainer = styled.div`
  /* border: 1px blue dashed; */
  display: flex;
  align-content: center;
  margin-right: 22px; 
  /* min-height: 1400px; */
`;

const SaveConfigContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  /* margin-right: 20px; */
  ${media.lessThan("743px")`
    display: none;
    `}
  ${media.between("744px", "large")`
    display: none;
  `}
  ${media.greaterThan("large")`
    `}
`;

const SaveConfigContainerMobile = styled(SaveConfigContainer)`
  /* border: 1px red dashed; */
  background: #0e1c36;
  height: 60px;
  margin-top: -10px;
  padding-left: 20px;
  ${media.lessThan("743px")`
    display: flex;
    width: 100%;
    `}
  ${media.between("744px", "large")`
    display: flex;
  `}
  ${media.greaterThan("large")`
    display: none;
      height: 10px;
    `}
`;

const SaveConfig = styled.button`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 40px;
  gap: 9px;
  width: 241px;
  height: 40px;
  border: 1px solid #63C085;
  border-radius: 30px;
  background: none;
  align-self: center;
  transition-duration: 0.5s;
  :hover {
    opacity: 0.8; 
  }
  ${media.lessThan("743px")`
        display: none;
    `}
  ${media.between("744px", "large")`
      display: none;
    `}
    ${media.greaterThan("large")`
    `}
`

const SaveConfigMobile = styled(SaveConfig)`
    ${media.lessThan("743px")`
      display: flex;
    `}
    ${media.between("744px", "large")`
      display: flex;
    `}
    ${media.greaterThan("large")`
      display: none;
    `}
`;

const BtnText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transition-duration: 0.5s;
  color: ${COLORS.White};
  ${media.lessThan("743px")`
      display: none;
    `}
    ${media.between("744px", "large")`
      display: none;
    `}
    ${media.greaterThan("large")`
      display: flex;
    `}
`

const BtnTextMobile = styled(BtnText)`
      ${media.lessThan("743px")`
      display: flex;
    `}
    ${media.between("744px", "large")`
      display: flex;
    `}
    ${media.greaterThan("large")`
      display: none;
    `}
`;

const ButtonText2 = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: ${COLORS.White};
  text-align: center;
  align-self: center;
  margin-right: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transition-duration: 0.5s;
  z-index: 10;
  cursor: pointer;
  :hover {
    color: #63C085; 
  }
    ${media.lessThan("743px")`
        display: none;
    `}
  ${media.between("744px", "large")`
        display: none;
    `}
    ${media.greaterThan("large")`
        display: flex;
    `}
  `;

  const ButtonText2Mobile = styled(ButtonText2)`
    margin-left: 20px;
  ${media.lessThan("743px")`
    display: flex;
  `}
  ${media.between("744px", "large")`
    display: flex;
  `}
  ${media.greaterThan("large")`
    display: none;
  `}
  `;

const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      background: COLORS.Primary,
      borderRadius: "30px",
      border: "none",
    },
    overlay: {
      background: "rgba(0, 0, 0, 0.7)",
      zIndex: 10,
    },
  }


  // const setError = (isError, label, minOrMax) => {
  //       const errorName = label + minOrMax

  //       if(isError === true){
  //       // add error
  //       if(errorArray.some(error => error === errorName)){
  //           // error already been set
  //       } else {
  //           setErrorArray([...errorArray, errorName])
  //       }

  //       } else {
  //       // remove error
  //       let localArray = errorArray

  //       if(localArray.length !== 0){
  //       let index = localArray.findIndex(error => {
  //           return error === errorName
  //       })
  //       localArray.splice(index, 1)
  //       setErrorArray(localArray)
  //       }
  //   }
  // }