import React, { useEffect, useState, useContext } from "react"
import { API, Auth } from "aws-amplify"
import styled from "styled-components"
import media from "styled-media-query"
import { COLORS } from "../../styles/colors"
import IconAlert from "./../../images/ic_alert.svg";
import IconRefreshBlue from "./../../images/ic_refresh_blue.svg";
import IconRefresh from "./../../images/ic_refresh.svg";
import AlertCard from "./AlertCard";
import { SpinnerContainer,  StyledRelativeSpinner } from "../custom/Spinner"


export default function Alerts(props) {

  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [isRefresh, setIsRefresh] = useState(false)
  const [isRefreshAlertHovering, setIsRefreshAlertHovering] = useState(false)
  const [time, setTime] = useState(new Date());

      // DEV ONLY ONLY UNTIL WE FIND OUT IF WE SHOULD KEEP TIMER
      // REFRESH DATA ONCE A MINUTE
      useEffect(() => {
        const interval = setInterval(() => {
          setTime(new Date());
        }, 60000);
        return () => clearInterval(interval);
      }, []);
      //-------------------------------------------

      const manualRefresh = () => {
        props.willRefreshAlerts()
        setTime(new Date());
      }

      // Use notificationId to fetch the alarms
      useEffect(() => {
        let cancelled = false
        async function getAlertDetails() {
          setIsRefresh(true)

          const token = (await Auth.currentSession()).getIdToken()
          const jwt = token.getJwtToken()
          const init = {
            headers: { 
              Authorization: `Bearer ${jwt}`
            },
          }
          const response = await API.get('FrontendAPI-thresholds', `/${props.notificationId}`, init) 

          if (response.success && !cancelled) {
           props.updateBreaches(response.message)
           filterAlerts(response.data)
          //  setTime(new Date());
           setIsRefresh(false)
          }

        }
        if(props.notificationId !== null){
          getAlertDetails()
        }

        return () => {
          cancelled = true
        }
    }, [props.notificationId, time, props.currentThresholds])


    const filterAlerts = (alerts) => {
      var filteredAlerts = {}

      if(alerts !== undefined && alerts !== null){

        let alertKeys = Object.keys(alerts)

        alertKeys.forEach((alertKey)=>{

        if(props.currentThresholds !== undefined && props.currentThresholds !== null ){
            const thresholds = props.currentThresholds[alertKey]
            const alertValue = Number(alerts[alertKey])
          // latest thresholds
            if(thresholds !== undefined && thresholds !== null){

            const {min, max, thresholdStatus} = thresholds

            if((alertValue > Number(max) || alertValue < Number(min)) && thresholdStatus === 'enabled'){

               const newAlerts  = {
                  ...filteredAlerts,
                  [alertKey] : alertValue
                }

                filteredAlerts = newAlerts
            } 
          }
        }
      })
      props.updateAlerts(filteredAlerts)
      setIsFirstLoad(false)
    }

    }

  // const lastUpdate = 'Last Update : ' + time.getHours() + ':' + time.getMinutes() + ":" + time.getSeconds();
  const currentTime = 'Last Update : ' + time.toLocaleTimeString();
  const length = Object.keys(props.alerts).length

  return (
    <OuterContainer 
      style={{display: props.alerts && Object.keys(props.alerts).length === 0 ? "none": "block"}}
      >
      <Card>
        { isFirstLoad
        ? <SpinnerContainer style={{marginLeft: 20}}><StyledRelativeSpinner animation='border' /></SpinnerContainer>
        : 
        <div>
            <HeaderContainer>
                <Icon  src={IconAlert} />
                <HeaderTextContainer>
                    <HeaderText>{`${length} Alarms Active`}</HeaderText>      
                    <UpdateText style={{marginTop: 10}}>{`${currentTime}`}</UpdateText>
                </HeaderTextContainer>
                  {isRefresh 
                  ? <MiniSpinnerContainer style={{marginLeft: 20}}><StyledRelativeSpinner animation='border' /></MiniSpinnerContainer>
                  : <Icon 
                  onMouseEnter={(e) => {
                    setIsRefreshAlertHovering(true)
                  }}
                  onMouseLeave={(e) => {
                    setIsRefreshAlertHovering(false)
                  }}
                  onClick={()=>{manualRefresh()}} 
                  src={isRefreshAlertHovering ? IconRefresh : IconRefreshBlue} 
                  style={{marginLeft: 20, width: 32, height: 32, cursor: 'pointer'}} /> 
                }
                          
            </HeaderContainer>
            <AlertContainer>
            {props.alerts 
            ? Object.keys(props.alerts).map((alert, index)=>{
                    return <AlertCard key={index} {...props} label={alert} value={props.alerts[alert]}/>
                  })
                : null
              }

            </AlertContainer>
          </div>
        }
      </Card>
    </OuterContainer>
  )
}

const OuterContainer = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  margin-right: 20px;
  ${media.lessThan("743px")`
  justify-content: center;
  width: 100%;
  `}
  `;

const Card = styled.div`
   /* border: 1px red dashed; */
   display: flex;
   width: 100%;
   flex-direction: row;
   justify-content: space-between;
   margin-right: 20px;
   margin-bottom: 15px;
   background: #0e1c36;
   border-radius: 10px;
   min-height: 80px;
   padding: 20px;
   flex-wrap: wrap;
   ${media.lessThan("743px")`
        flex-direction: column;
        width: 100%;
         align-self: center;
         padding: 20px 20px;
         margin-right: 0px;
     `}
   ${media.between("744px", "large")`
     `}
     ${media.greaterThan("large")`
     `}
 `

 const HeaderContainer = styled.div`
    /* border: 1px yellow dashed; */
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
; `;

 const HeaderTextContainer = styled.div`
     /* border: 1px green dashed; */
    display: flex;
    flex-direction: column;
    justify-content: center;
 `;

const HeaderText = styled.h1`
  font-family: Blinker;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 110%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
`

const UpdateText = styled.h5`
  font-family: Blinker;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  /* line-height: 110%; */
  display: flex;
  align-items: center;
  /* letter-spacing: -0.01em; */
  color: ${COLORS.White};
`

const InfoText = styled.h2`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: ${COLORS.HighlightLight};
  margin-top: 5px;
`

const Icon = styled.img`
  margin-right: 23px;
  width: 23px;
  height: 23px;
  align-self: center;
  transition-duration: 0.5s;
`

const AlertContainer = styled.div`
    /* border: 1px yellow dashed; */
    display: flex;
    flex-direction: row;
    width: auto;
    flex-wrap: wrap;
    ${media.lessThan("743px")`
        min-width: 250px;
    `}
    ${media.between("744px", "large")`
    `}
`;

const MiniSpinnerContainer = styled.div`
    /* border: 1px yellow dashed; */
  display: flex;
  width: 35px;
  height: 35px;
  justify-content: center;
  align-self: center;
`