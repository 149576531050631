import React, { Component } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Image from "./../../images/LOGO_ALT.svg"
import Image2 from "./../../images/Ellipse_crop.svg"
import Image3 from "./../../images/wingnut-UI-mockup.png"
import { Link } from "react-router-dom"
import Aos from "aos"
import "aos/dist/aos.css"

const Grid = styled.div`
  /* border: 1px blue dashed; */
  grid-area: 1 / 1 / 2 / 2;
  max-width: 1440px;
  display: flex;
  width: auto;
  height: auto;
  flex-direction: row;
  justify-self: center;
  align-content: center;
  margin-bottom: 20px;
  margin-left: 30px;
  ${media.lessThan("743px")`
        flex-direction: column;
        align-content: center;
        justify-content: center;
        margin-left: 0px;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const LeftInfoContainer = styled.div`
  /* border: 1px green dashed; */
  margin-top: 56.65px;
  width: auto;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  /* z-index: 1; */
  ${media.lessThan("743px")`
        width: 70%;
        align-self: center;
    `}
  ${media.between("744px", "large")`
        width: auto;
        max-width: 500px;
    `}
    ${media.greaterThan("large")`
    `}
`

const Logo = styled.img`
  width: 157px;
  height: 45px;
  z-index: 0;
  transition-duration: 0.5s;
  :hover {
    transform: scale(1.2);
  }
`

const HeaderText = styled.h1`
  /* border: 1px red dashed; */
  margin-top: 163px;
  font-family: "Blinker", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 79.4322px;
  line-height: 66px;
  letter-spacing: -0.01em;
  color: rgb(255, 255, 255);

  ${media.lessThan("743px")`
        font-size: 52px;
        flex-wrap: wrap;
    `}
  ${media.between("744px", "large")`
        margin-top: 113px;
        font-size: 60px;
    `}
`

const BlurbText = styled.p`
  margin-top: 32px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 300;
  font-size: 28px;
  line-height: 129.5%;
  letter-spacing: -0.01em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
  flex-wrap: wrap;
  ${media.between("744px", "large")`
        font-size: 20px;
    `}
`

const BtnContainer = styled.div`
  /* border: 1px red dashed; */
  width: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 80px;
  ${media.lessThan("743px")`
        flex-direction: column;
        margin-top: 22px;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const BtnLogin = styled.button`
  width: 245px;
  height: 69.62px;
  border: none;
  background: #63c085;
  border-radius: 36px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 15px;
  line-height: 129.5%;
  text-align: center;
  letter-spacing: 0.05em;
  margin-right: 20px;
  margin-bottom: 10px;
  z-index: 0;
  color: black;
  transition-duration: 0.5s;
  :hover {
    opacity: 0.8;
  }
  ${media.lessThan("743px")`
        width: 314px;
        height: 70px;
        margin-right: 0px;
    `}
  ${media.between("744px", "large")`
        width: 230px;
        height: 62px;
        margin-bottom: 10px;
    `}
    ${media.greaterThan("large")`
    `}
`

const Ellipse = styled.img`
  position: absolute;
  width: auto;
  top: 0;
  right: 0;
  height: 827.33px;
  z-index: 0;
  overflow: hidden;
  ${media.lessThan("743px")`
    display: none;
    `}
  ${media.between("744px", "large")`
    display: none;
    `}
    ${media.greaterThan("large")`
    margin-left: 272px;
    `}
`

const RightInfoContainer = styled.div`
  /* border: 1px red dashed; */
  width: 50%;
  height: auto;
  ${media.lessThan("743px")`
        width: 70%;
        height: 450px;
        justify-self: center;
        align-self: center;
    `}
  ${media.between("744px", "large")`
    width: auto;
    `}
    ${media.greaterThan("large")`
    margin-top: 50px;
    `}
`

const LandingImg = styled.img`
  /* border: 1px yellow dashed; */
  width: auto;
  height: 625.9px;
  ${media.lessThan("743px")`
        width: auto;
        height: 386px;
        margin-top: 22px;
        overflow: none;
        justify-self: center;
        align-self: center;
    `}
  ${media.between("744px", "large")`
        width: auto;
        height: 508px;
        margin-right: -200px;
        margin-top: 80px;

    `}
    ${media.greaterThan("large")`
    `}
`

export default class LandingPage extends Component {
  componentDidMount() {
    Aos.init()
  }

  render() {
    return (
      <Grid id='landing'>
        <Ellipse src={Image2} />
        <LeftInfoContainer>
          <Logo src={Image} />
          <HeaderText
            data-aos='slide-right'
            data-aos-duration='1000'
            data-aos-easing='ease-in-sine'
            data-aos-once='true'
          >
            Remote System Monitoring + Analytics
          </HeaderText>
          <BlurbText
            data-aos='slide-right'
            data-aos-duration='1000'
            data-aos-easing='ease-in-sine'
            data-aos-once='true'
          >
            Improve the reliability and safety of your mechanical system with Wingnut's remote monitoring platform
          </BlurbText>
          <BtnContainer>
            <BtnLogin onClick={this.props.openModal}>LOG IN</BtnLogin>
          </BtnContainer>
        </LeftInfoContainer>
        <RightInfoContainer
          data-aos='slide-left'
          data-aos-duration='1000'
          data-aos-easing='ease-in-sine'
          data-aos-once='true'
        >
          <LandingImg src={Image3} />
        </RightInfoContainer>
      </Grid>
    )
  }
}
