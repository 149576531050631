import React from "react"
import styled from "styled-components"
import Marquee from "react-marquee-slider"
import media from "styled-media-query"
import Image from "./../../images/DataPoints_scroll.png"
import Image2 from "./../../images/DataPoints_scroll2.png"
import Image3 from "./../../images/DataPoints_scroll3.png"

const SliderImage = styled.img`
  height: 70px;
  width: auto;
  margin-top: 10px;
  /* z-index: 0; */
  ${media.lessThan("743px")`
    height: 62px;
        `}
  ${media.between("744px", "large")`
    `}
`

function MarqueeSlider() {
  var Photos = [Image]
  var Photos2 = [Image2]
  var Photos3 = [Image3]

  return (
    <div>
      <Marquee velocity={5}>
        {Photos.map((item) => (
          <SliderImage key='0' src={item} />
        ))}
      </Marquee>
      <Marquee velocity={5} direction='clockwise'>
        {Photos3.map((item) => (
          <SliderImage key='1' src={item} />
        ))}
      </Marquee>
      <Marquee velocity={5}>
        {Photos2.map((item) => (
          <SliderImage key='0' src={item} />
        ))}
      </Marquee>
    </div>
  )
}

export default MarqueeSlider
