import React from "react"
import ReactDOM from "react-dom"
import "./index.scss"
import "./config.js"
import App from "./App"
import "bootstrap/dist/css/bootstrap.min.css"

ReactDOM.render(
    <App />,
  document.getElementById("root")
);
