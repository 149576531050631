import Enum from "enum"
import IconStatusLive from "./../../images/ic_status_green.svg"
import IconStatusDisconnected from "./../../images/ic_status_red.svg"
import IconStatusUnlinked from "./../../images/ic_status_purple.svg"
import IconStatusUnconfigured from "./../../images/ic_status_yellow.svg"

const WingnutStatus = new Enum({
  Live: IconStatusLive,
  Disconnected: IconStatusDisconnected,
  Unlinked: IconStatusUnlinked,
  Unconfigured: IconStatusUnconfigured,
})

export default WingnutStatus
