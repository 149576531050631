import React, {useState, useEffect, useContext} from 'react'
import { AccountContext } from "../../auth/Account"
import styled from "styled-components"
import media from "styled-media-query"
import ImgSearch from "./../../images/ic_arrow_down_white.svg"
import ImgPhone from "./../../images/ic_phone.svg"
import ImgEmail from "./../../images/ic_email.svg"
import ImgPhoneDark from "./../../images/ic_phone_blue.svg"
import ImgEmailDark from "./../../images/ic_email_blue.svg"
import ImgClose from "./../../images/ic_close.svg"
import MediaQuery from "react-responsive"
import { COLORS } from '../../styles/colors'

// Employee to notify cell

export default function EmployeeCell(props) {

  const [employee, setEmployee] = useState(props.employee)

  const { getSession } = useContext(AccountContext)
  const [isAuth, setIsAuth ] = useState(false)

  useEffect(() => {
    getSession().then(({ isAuth }) => {
      setIsAuth(isAuth)
    })
    }, [])

  const removeEmployee = () => {
    props.removeEmployee(employee.id)
  }

  const toggleEmailNotification = () => {
    props.setEmail(employee.id, !employee.notifyByEmail)
  }

  const togglePhoneNotification = () => {
    props.setPhone(employee.id, !employee.notifyByPhone)
  }

  useEffect(() => {
    setEmployee(props.employee)
  }, [props.employee])

  return (
    <EmployeeContainer>
        <EmployeeTextContainer>
            <NameText>{employee.name}</NameText>

              <MediaQuery maxWidth={743}>
              <InfoTextContainerMobile>
                <InfoText>{`${employee.email}`}</InfoText>
                <InfoText>{employee.phoneNumber}</InfoText>
            </InfoTextContainerMobile>
              </MediaQuery>

              <MediaQuery minWidth={744}>
              <InfoTextContainer style={{flexDirection: employee.email.length > 25 ? 'column' : 'row'}}>
                <InfoText>{`${employee.email} |`}</InfoText>
                <InfoText>{employee.phoneNumber}</InfoText>
            </InfoTextContainer>
              </MediaQuery>

        </EmployeeTextContainer>
        <IconContainer>
            <Icon style={{visibility: 'hidden'}} onClick={togglePhoneNotification}  src={employee.notifyByPhone ? ImgPhone : ImgPhoneDark}  />
            <Icon onClick={isAuth ? toggleEmailNotification : null} style={{opacity: isAuth ? "1.0" : "0.5", pointerEvents: isAuth ? "auto" : "none"}} src={employee.notifyByEmail ? ImgEmail : ImgEmailDark} />
            <Icon style={{display: isAuth ? "block" : "none"}} src={ImgClose} onClick={removeEmployee}/>
        </IconContainer>
    </EmployeeContainer>
  )
}


const EmployeeContainer = styled.div`
  /* border: 1px green dashed; */
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-right: 68px;
    ${media.lessThan("743px")`
    `}
    ${media.between("744px", "large")`
    margin-right: 18px;
    `}
    ${media.greaterThan("large")`
    `}
`;

const EmployeeTextContainer = styled.div`
    /* border: 1px white dashed; */
    display: flex;
    flex-direction: column;
    width: 280px;                     // width of container
    ${media.lessThan("743px")`
    width: 190px;                // min width when small
    `}
`;

const NameText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
  font-size: 18px;
    margin-bottom: 1px;
`;

const InfoTextContainer = styled.div`
  /* border: 1px red dashed; */
    display: flex;
    flex-direction: row;
`;

const InfoTextContainerMobile = styled.div`
  /* border: 1px green dashed; */
    display: flex;
    flex-direction: column;
`;


const InfoText = styled.h3`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  color: ${COLORS.HighlightMedium};
  margin-right: 3px;
  margin-bottom: 4px;
`

const IconContainer = styled.div`
  /* border: 1px red dashed; */
    display: flex;
    flex-direction: row;
`;

const Icon = styled.img`
    width: 16x;
    height: 16px;
    align-self: center;
    margin-right: 15px;
    cursor: pointer;
`;
