import React, { useState, useContext, useEffect, useMemo } from "react"
import { Link } from "react-router-dom"
import { AccountContext } from "../../auth/Account"
import { CognitoUserAttribute } from "amazon-cognito-identity-js"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import media from "styled-media-query"
import ImgProperty from "./../../images/ic_property.svg"
import { SpinnerContainer, StyledSpinner } from "../custom/Spinner"
import {
  Error,
  EmptyError
} from "../custom/ValidatorStyles"
import { ValidatorStrings } from "../util/VaildatorStrings"
import AddressSearch from "./AddressSearch"
import { useMediaQuery } from 'react-responsive'
import { COLORS } from "../../styles/colors"
import { API, Auth } from "aws-amplify"

const Container = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: column;
  width: 98%;
  max-width: 1048px;
  height: auto;
  background: #0e1c36;
  border-radius: 10px;
  padding: 35px 35px;
  align-content: center;
  margin-top: 20px;
  margin-bottom: 40px;
  ${media.lessThan("743px")`
      align-self: center;
      width: 100%;
      background: ${COLORS.Base};
    `}
`

const Form = styled.form`
  /* border: 1px red dashed; */
  /* width: 439px; */
  display: flex;
  flex-direction: column;
  ${media.lessThan("743px")`
  align-self: center;
  width: 100%;
  margin-top: 0px;
  `}
  ${media.between("744px", "large")`
  max-width: 382px;
        /* width: 384px; */
    `}
`

const HeaderText = styled.h2`
  font-family: Blinker;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 84.5%;
  letter-spacing: -0.01em;
  color: ${COLORS.White} ;
  margin-bottom: 11px;
  margin-top: 20px;
`

const Label = styled.label`
  /* border: 1px blue dashed; */
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 129.5%;
  letter-spacing: 0.03em;
  color: ${COLORS.HighlightLight};
  margin-bottom: 2px;
  /* margin-top: 10px; */
`

const Input = styled.input`
  /* border: 1px blue dashed; */
  width: 100%;
  max-width: 378px;
  height: 45px;
  outline: none;
  border: none;
  color: ${COLORS.White};
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 129.5%;
  font-weight: normal;
  background-color: ${COLORS.Secondary};
  border-radius: 8px;
  box-sizing: border-box;
  padding-left: 10px;
  ::placeholder {
    color: ${COLORS.HighlightMedium};
  }
  :hover {
    border: 1px solid ${COLORS.HighlightMedium};
    padding-left: 9px;
  }
  :focus {
    border: 1px solid ${COLORS.HighlightLight};
    padding-left: 9px;
  }
  ${media.lessThan("743px")`
        width: 100%;
        height: 55px;
    `}
  ${media.between("744px", "large")`
        width: 100%;
        height: 55px;
    `}
    ${media.greaterThan("large")`
    `}
`

const Input2 = styled(Input)`
  width: 96%;
  ${media.lessThan("743px")`
        width: 100%;
        height: 55px;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
      margin-right: 10px
    `}
`

const Input3 = styled(Input)`
  width: 100%;
  ${media.lessThan("743px")`
        width: 100%;
        height: 55px;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  align-self: flex-end;
  width: 160px;
  height: 40px;
  border: none;
  background: #63c085;
  cursor: pointer;
  border-radius: 36px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 13px;
  text-align: center;
  letter-spacing: 0.05em;
  margin-top: 28px;
  transition-duration: 0.5s;
  :hover {
    opacity: 0.8;
  }
  ${media.lessThan("743px")`
        align-self: flex-start;
        height: 55px;
        width: 200px;
    `}
  ${media.between("744px", "large")`
        height: 55px;
        width: 200px;
    `}
    ${media.greaterThan("large")`
    `}
`

const ButtonText = styled.p`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #0c182f;
  :hover {
    color: ${COLORS.Base};
  }
`


const OuterContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  ${media.lessThan("743px")`
        flex-direction: column;
    `}
  ${media.between("744px", "large")`
    flex-direction: column;
    `}
`;


const SectionContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  min-width: 382px;
  max-width: 378px;
  margin-right: 20px;
  /* width: 50%; */
  flex-direction: column;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
`

const StatusIcon = styled.img`
  width: 23px;
  height: 23px;
  /* margin-bottom: 20px; */
  margin-top: 20px;
`

const RowContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 378px;
  ${media.lessThan("743px")`
        flex-direction: column;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const ColumnContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: column;
  width: 100%;
`

const HeaderText2 = styled(HeaderText)`
  margin-top: 0px;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${COLORS.HighlightMedium};
  margin-top: 21px;
  margin-right: 30px;
  ${media.lessThan("743px")`
      align-self: center;
      /* width: 90% */
      margin-right: 0px;
    `}
`

// https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform

export default function AddProperty(props) {
  const {propertyData} = props

  const [address, setAddress]= useState()
  const isDesktopSize = useMediaQuery({ query: '(min-width: 1170px)' })

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    trigger,
    setError,
    clearErrors,
    formState: { errors, isSubmitSuccessful, isValid },
  } = useForm({ 
    reValidateMode: "onChange",
    defaultValues: useMemo(() => {
      return props.propertyData;
    }, [props])
  })

  const checkForm = async (data) => {
  // Always run custom validation for externalId
  const externalIdError = await validateExternalId(data.externalId);

  if (externalIdError === true) {
    props.loadFormData(data);
  } else {
    // Set errors manually if validation fails
    if (externalIdError !== true) {
      setError("externalId", {
        type: "externalIdInvalid",
        message: "Property ID is not valid",
      });
    }
  }
};

  const selectAddress = (newAddress) => {
    setAddress(newAddress)
    setValue("street", newAddress.streetAddress)
    setValue("city", newAddress.city)
    setValue("provinceState", newAddress.provinceState)
    setValue("postalZipCode", newAddress.postalZip)
    setValue("country", newAddress.country)
  }

  useEffect(() => {
    reset(props.propertyData);
  }, [props.propertyData])

  const validateExternalId = (value) => {
  return Auth.currentSession()
    .then(session => session.getIdToken())
    .then(token => token.getJwtToken())
    .then(jwt => {
      const init = { headers: { Authorization: `Bearer ${jwt}` } };
      return API.get('FrontendAPI-companies', `/external/${value}`, init);
    })
    .then(response => {
      if (response.success) {
        // console.log("DATA", response.data);
        return true;
      } else {
        // console.log("Not Successful", response.data);
        return "Property ID is not valid";
      }
    });
};

  return (
    <Container>
    {props.linkView ? 
        <div>
          <HeaderText style={{ marginTop: 0 }}>Add Property</HeaderText>
          <Line />
        </div>
        :
      null
    }


      <Form onSubmit={handleSubmit(checkForm)} autoComplete='off'>
        <StatusIcon src={ImgProperty} />
        <OuterContainer style={{  flexDirection: props.linkView ? isDesktopSize ? 'row' : 'column' : null}}>
          <SectionContainer>
          <HeaderText>General Information</HeaderText>
              <Label>Property Name</Label>
              <Input 
                type='text' 
                name='propertyName' 
                placeholder='Property Name'
                {...register("propertyName", { required: true, maxLength: 45 })}
                style={{ border: errors.propertyName ? "1px solid #D25353" : null, paddingLeft: errors.propertyName ? '9px' : null }}
              />
              {errors.propertyName ? <Error>{ValidatorStrings(errors.propertyName?.type)}</Error> : <EmptyError />}

              <Label>Property ID</Label>
              <Input 
                type='text' 
                name='externalId'
                placeholder='Property ID'
                {...register("externalId", { 
                  required: true, 
                })}
                style={{ border: errors.externalId ? "1px solid #D25353" : null, paddingLeft: errors.externalId ? '9px' : null }}
              />
              {errors.externalId ? <Error>{ValidatorStrings(errors.externalId?.type)}</Error> : <EmptyError />}

              <Label>Portfolio</Label>
              <Input 
                type='text' 
                name='portfolio' 
                placeholder='Portfolio Name' 
                {...register("portfolio", { required: false, maxLength: 45 })}
                style={{ border: errors.portfolio ? "1px solid #D25353" : null, paddingLeft: errors.portfolio ? '9px' : null }}
              />
              {errors.portfolio ? <Error>{ValidatorStrings(errors.portfolio?.type)}</Error> : <EmptyError />}
            </SectionContainer>

            <SectionContainer>
              <HeaderText>Address</HeaderText>
              <Label>Street Address</Label>
              <AddressSearch selectAddress={selectAddress} errors={errors}/>
              {errors.street ? <Error>{ValidatorStrings(errors.street?.type)}</Error> : <EmptyError />}

              <Label>City</Label>
              <Input 
                type='text' 
                name='city'
                placeholder='City'
                {...register("city", { required: true, maxLength: 45 })}
                style={{ border: errors.city ? "1px solid #D25353" : null, paddingLeft: errors.city ? '9px' : null }}
              />
              {errors.city ? <Error>{ValidatorStrings(errors.city?.type)}</Error> : <EmptyError />}

              <Label>Country</Label>
              <Input 
                type='text' 
                name='country' 
                placeholder='Country' 
                {...register("country", { required: true })}
                style={{ border: errors.country ? "1px solid #D25353" : null, paddingLeft: errors.country ? '9px' : null }}
              />
              {errors.country ? <Error>{ValidatorStrings(errors.country?.type)}</Error> : <EmptyError />}
              <RowContainer>
                <ColumnContainer>
                  <Label>Province / State</Label>
                  <Input2 
                  type='text' 
                    name='provinceState' 
                    placeholder='Province / State'
                    {...register("provinceState", { required: true, maxLength: 20 })}
                    style={{ border: errors.provinceState ? "1px solid #D25353" : null, paddingLeft: errors.provinceState ? '9px' : null }}
                  />
                  {errors.provinceState ? <Error>{ValidatorStrings(errors.provinceState?.type)}</Error> : <EmptyError />}
                </ColumnContainer>
                <ColumnContainer>
                  <Label>Postal / ZIP Code</Label>
                  <Input3 
                    type='text' 
                    name='postalZipCode' 
                    placeholder='Postal / ZIP Code'
                    {...register("postalZipCode", { required: true, maxLength: 20 })}
                    style={{ border: errors.postalZipCode ? "1px solid #D25353" : null, paddingLeft: errors.postalZipCode ? '9px' : null }}
                  />
                  {errors.postalZipCode ? <Error>{ValidatorStrings(errors.postalZipCode?.type)}</Error> : <EmptyError />}
                </ColumnContainer>
              </RowContainer>
              <RowContainer style={{ justifyContent: "flex-end" }}>             
                <Button type='submit'>
                  <ButtonText>CONTINUE</ButtonText>
                </Button>
              </RowContainer>
            </SectionContainer>
        </OuterContainer>
      </Form>
    </Container>
  )
}
