import React, { useEffect, useState, useContext } from "react"
import { AccountContext } from "../../../auth/Account"
import styled from "styled-components"
import media from "styled-media-query"
import ImgProperty from "./../../../images/ic_property.svg"
import ImgAdd from "./../../../images/ic_add.svg"
import Select from "react-dropdown-select"
import Fuse from "fuse.js"
import { DarkButtonSpinner } from "../../custom/Spinner"
import { COLORS } from "../../../styles/colors"
import { useHistory } from 'react-router-dom'
import { checkIfUUID } from "./../../util/CleanUUID"

const fuzzyOptions = {
  threshold: 0.1,
  keys: ["config.name", "externalId"],
}

export default function PropertyDropdownUI(props) {

  const { data } = props
  const history = useHistory();

  const [isSelected, setIsSelected] = useState(false)
  const [customUUID, setCustomUUID] = useState({})
  const [isHovering, setIsHovering] = useState(false)
  const [selectedProperty, setSelectedProperty] = useState(null)
  const [searchOptions, setSearchOptions] = useState([])
  const fuseSearch = new Fuse(data, fuzzyOptions)
  const [searchValue, setSearchValue] = useState("")

  const { getSession } = useContext(AccountContext)
  const [isAuth, setIsAuth ] = useState(false)

  useEffect(() => {
    getSession().then(({ isAuth }) => {
      setIsAuth(isAuth)
    })
}, [])

  useEffect(() => {
    setSearchOptions(mapRawData(data))
  }, [data])

  useEffect(() => {
    if(Object.keys(props.params).length !== 0){
      setCustomUUID(props.params)
    }
  }, [props.params])

  useEffect(() => {
    if(Object.keys(customUUID).length !== 0 && searchOptions.length !== 0){

      const id = String(customUUID.id)
      const item = searchOptions.find(item => {      
        if(item.key === id){
          return true
        }
      })

      if(item !== undefined){
        setSelectedProperty(item)
        props.loadProperty(item)
        setIsSelected(true)
      }

    }

  }, [searchOptions])

  const mapRawData = (data) => {
    const options = data.map((item) => ({
      key: item.id,
      label: `${item.externalId} - ${item.config.name}`,
      value: item.id,
    }))
    return options
  }

  const filterOptions = (filter) => {
    if (filter) {
      setSearchOptions(mapRawData(fuseSearch.search(filter).map((s) => s.item)))
    } else {
      setSearchOptions(mapRawData(fuseSearch.getIndex().docs))
    }
  }

  const enterSearchValue = (value) => {
    setSearchValue(value)
    filterOptions(value)
  }

  const onSelected = (item) => {
    if (item[0]) {
      setSelectedProperty(item[0])
      props.loadProperty(item[0])
      setIsSelected(true)
      if(Object.keys(customUUID).length !== 0){

        // only if a property UUID is present, not if it's a custom timestamp
        if(checkIfUUID(customUUID.id) === true){
          history.push(`/portal/dashboard/0`) 
        } 
      }
      setCustomUUID({})

      // setSearchOptions(mapRawData(fuseSearch.getIndex().docs))    // What was this doing?
    } else {
      setSelectedProperty(null)
      props.loadProperty(null)
      setIsSelected(false)
      setSearchValue("")
      filterOptions("")
    }
  }

  return (
    <Container>
      <WingnutDetails>
        <div     
            onMouseEnter={(e) => {
            setIsHovering(true)
            }}
            onMouseLeave={(e) => {
            setIsHovering(false)
          }}>
        <StyledSelect
          // disabled={isAuth ? false : true}
          dropdownGap={0}
          keepOpen={false}
          clearable={props.propertyDetails || searchValue.length !== 0   ? true : false}
          placeholder={"Select or search for a property"}
          onChange={(value) => onSelected(value)}
          options={searchOptions}
          values={selectedProperty ? [selectedProperty] : []}
          inputRenderer={
            ({ props, state, methods, inputRef }) => ( 
               <Input 
                id="inputID" 
                autoComplete="off"
                value={searchValue}
                onChange={(event) => enterSearchValue(event.target.value)}
                hidden={selectedProperty ? true: false} 
                placeholder="Select or search for a property" 
                ref={inputRef} 
                style={{ cursor: 'pointer', color: COLORS.White, fontSize: "18px", fontWeight: "300"}} 
              /> 
            )
          }
          searchable={true}
          style={{
            border: isHovering ? "1px solid #d5d5d5" : isSelected ? "1px solid #d5d5d5" : "1px solid transparent",
            borderRadius: 8,
            fontWeight: "300",
            paddingLeft: "12px",
            fontFamily: "Roboto Condensed",
            fontSize: "18px"
          }}
          />
          </div>
      </WingnutDetails>
    </Container>
  )

}


const Container = styled.div`
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
`

//  default color

const WingnutDetails = styled.div`
  /* border: 1px dashed red; */
  border-radius: 10px;
  /* box-sizing: border-box; */
  display: flex;
  flex-direction: column;
  flex-wrap: none;
  width: 300px;
  ${media.lessThan("743px")`
      max-width: 300px;
      min-width: 270px;
    `}
  ${media.between("744px", "large")`
  /* height: 350px; */
    `}
    ${media.greaterThan("large")`
    `}
`

const StyledSelect = styled(Select)`
  height: 45px;
  width: 384px;
  margin-bottom: 10px;
  color: ${COLORS.White};
  background: ${COLORS.Secondary};

  ${media.lessThan("743px")`
        /* width: 90%; */
        height: 55px;
    `}
  ${media.between("744px", "large")`
        /* width: 100%; */
        height: 55px;
    `}
    ${media.greaterThan("large")`
    `}

    .react-dropdown-select-clear {
      /* border: 1px red dashed; */
      height: 28px;
      :hover {
      color: ${COLORS.White};
    }
    }
    .react-dropdown-select-dropdown-handle {
      /* border: 1px red dashed; */
    color: ${COLORS.White};
    height: 20px;
  }
  .react-dropdown-select-option {
  }
  .react-dropdown-select-item {
  }
  .react-dropdown-select-input {
    color: ${COLORS.White};             // cursor select
  }
  .react-dropdown-select-dropdown {
    background: ${COLORS.Secondary};            // card background
    border-radius: 8px;
    border: 1px solid ${COLORS.HighlightMedium};
  }
  .react-dropdown-select-item {
    color: ${COLORS.White};                     // list item
    border-bottom: none;
    padding-top: 12px;
    padding-bottom: 12px;
    /* height: 60px; */
    font-size: 16px;
    :hover {
      background: ${COLORS.HighlightMedium};
    }
  }
  .react-dropdown-select-item.react-dropdown-select-item-selected{
    color: ${COLORS.White};                   // selected item
    background: ${COLORS.HighlightMedium};
    border-bottom: none;
  }
  .react-dropdown-select-item.react-dropdown-select-item-active {
    //background: #111;
    /* border-bottom: 1px solid #333; */

    /* font-weight: bold; */

  }
  .react-dropdown-select-item.react-dropdown-select-item-disabled {
    /* color: #ccc; */
  }
  ::placeholder {
    color: red;
  }
  :focus {
    outline: ${COLORS.White};
    border: 1px solid ${COLORS.White};
  }
`

const Input = styled.input`
background: transparent;
border: none;
outline: none;
width: 100%;
/* width: 384px; */
/* height: 100%; */
color: ${COLORS.White};
/* font-family: "Roboto Condensed", sans-serif; */
`;
