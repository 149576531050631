import React, { useEffect, useState, useContext } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import { COLORS } from "../../../styles/colors"
import TimeSeriesZoom from "../chart/TimeSeriesZoom"
import { SpinnerContainer, StyledSpinner } from "./../../custom/Spinner"
import {FormatLabel} from "../../util/FormatLabel"
import AnalyticsModuleUI from "./AnalyticsModuleUI"

import TimeSeriesAMChart from "../chart/TimeSeriesAMChart"

export default function TimeSeriesAMChartsUI(props) {

  const [temperatureData, setTemperatureData] = useState([])
  const [currentPressureData, setCurrentPressureData] = useState([])
  const [chartStateData, setChartStateData] = useState([])

  // FULL CHART DATA
   useEffect(() => {
    // console.log("START PROCESS TEMP PRESS CURRENT CHART DATA")
    const temperatureSets = []
    const currentPressureSets = []

    if(props.data !== undefined && props.data !== null ){
      const dataLabels = Object.keys(props.data)


      // // For logging
      // if(props.data[dataLabels[0]] !== undefined){
      //   if (props.incrementTime !== 2419200 && props.incrementTime !== 1209600 && props.incrementTime !== 0){
      //     var dataLength = props.data[dataLabels[0]].length
      //     console.log("FIRST RETURN DATA", new Date(props.data[dataLabels[0]][dataLength - 1][0]))
      //     console.log("LAST RETURN DATA", new Date(props.data[dataLabels[0]][0][0]))
      //   } else {
      //     var dataLength = props.data[dataLabels[0]].length
      //     console.log("FIRST RETURN DATA", new Date(props.data[dataLabels[0]][0][0]))
      //     console.log("LAST RETURN DATA", new Date(props.data[dataLabels[0]][dataLength - 1][0]))
      //   }
      // }
 
      dataLabels.forEach((label)=>{

        const dataSet = {
          name: FormatLabel(label),
          data: props.incrementTime !== 2419200 && props.incrementTime !== 1209600 && props.incrementTime !== 0  ? props.data[label].reverse() : props.data[label]
        }

        var minMax = dataSet.data.shift()

        if(minMax != null){
          dataSet.min = minMax[0]
          dataSet.max = minMax[1]
        }

        if(label.includes('Pressure') || label.includes('Current')){
          currentPressureSets.push(dataSet)
        }  else {
          temperatureSets.push(dataSet)
        }

      })
    }

   const sortedTemps = sortByLabel(temperatureSets)
   const sortedCurrentPressure = sortByLabel(currentPressureSets)

    // if(sortedTemps[0] !== null && sortedTemps[0] !== undefined && sortedCurrentPressure[0] !== null && sortedCurrentPressure[0] !== undefined){
    //   setTemperatureData([sortedTemps[0]])
    //   setCurrentPressureData([sortedCurrentPressure[0]])
    // } else {
      setTemperatureData(sortedTemps)
      setCurrentPressureData(sortedCurrentPressure)
    // }


    // console.log("END PROCESS TEMP PRESS CURR CHART DATA")
   }, [props.data]) 

      // FULL STATE DATA 
      useEffect(() => {

        const dataSets = []

        if(props.stateData !== undefined && props.stateData !== null ){
            // console.log("START PROCESS STATE CHART DATA")
            const dataLabels = Object.keys(props.stateData)
      
             dataLabels.forEach((label)=>{

              const dataSet = {
                name: FormatLabel(label),
                data: props.incrementTime !== 2419200 && props.incrementTime !== 1209600 && props.incrementTime !== 0  ? props.stateData[label].reverse() : props.stateData[label]
              }

                dataSet.data.shift()     // remove first item minMax [null, null]
                dataSets.push(dataSet)
              })
            }
      
            const sortedStats = sortByLabel(dataSets)
      
            setChartStateData(sortedStats)
            // console.log("END PROCESS STATE CHART DATA")
           }, [props.stateData]) 


     const sortByLabel = (arr) => {
      return arr.sort((a, b) => {
        const propA = a['name'].toLowerCase();
        const propB = b['name'].toLowerCase();
        if (propA < propB) {
          return -1;
        }
        if (propA > propB) {
          return 1;
        }
        return 0;
      });
    }
     
  return (
    <OuterContainer style={{opacity: props.isLoading ? "0.5" : "1.0"}}>
            <HeaderContainer>

                    <TimeSeriesAMChart 
                    {...props}
                    temperatureData={temperatureData} 
                    currentPressureData={currentPressureData} 
                    stateData={chartStateData}  
                    />

                    <AnalyticsModuleUI unit={"PSI"} upperLimit={100} {...props} />
                  
            </HeaderContainer>
      </OuterContainer>
  )
}


  const OuterContainer = styled.div`
      /* border: 1px blue dashed; */
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 20px;
  `

const HeaderContainer = styled.div`
    /* border: 1px blue dashed; */
    display: flex;
    flex-direction: column;
    margin-bottom: 21px;
    ${media.lessThan("743px")`
        align-self: center;
        margin-right: 0px;
  `}
`

const HeaderText = styled.h2`
    /* border: 1px red dashed; */
    font-family: Blinker;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 84.5%;
    letter-spacing: -0.01em;
    color: ${COLORS.White};
`

const EmptyText = styled.p`
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 129.5%;
  display: flex;
  align-items: center;
  color: ${COLORS.HighlightLight};
`;

const ModeText = styled.h2`
  font-family: Blinker;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 84.5%;
  letter-spacing: -0.01em;
  color: ${COLORS.ErrorOrange};
  padding-top: 10px;
  padding-bottom: 10px;
  ${media.lessThan("743px")`
        align-self: center;
        max-width: 95%;
        text-wrap: wrap;
        text-align: center;

    `}
`
