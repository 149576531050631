import React, { Component } from "react"
import styled from "styled-components"
import LandingPage from "./LandingPage"
import Features from "./Features"
import Hardware from "./Hardware"
import Sliders from "./Sliders"
import Details from "./Details"
import TechSpecs from "./TechSpecs"
import ContactForm from "./ContactForm"
import Footer from "./Footer"
import Modal from "react-modal"
import LoginModal from "./../layout/LoginModal"
import { COLORS } from "../../styles/colors"

const GridContainer = styled.div`
  display: grid;
  grid-template: auto auto auto auto auto auto auto auto/ 100vw;
  overflow: hidden;
  background-color: ${COLORS.Base};
  z-index: -1;
`

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "linear-gradient(150.4deg, #041129 52.86%, #0A204A 81.89%)",
    borderRadius: "30px",
    border: "none",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.7)",
    zIndex: 10,
  },
}

Modal.setAppElement("#root")

export default class HomeLayout extends Component {
  state = {
    isOpen: false,
  }

  openModal = () => {
    this.setState({ isOpen: true })
  }

  afterOpenModal = () => {}

  closeModal = () => {
    this.setState({ isOpen: false })
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.state.isOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel='Wingnut Login'
        >
          <LoginModal closeModal={this.closeModal} />
        </Modal>

        <GridContainer>
          <LandingPage openModal={this.openModal} />
          <Features />
          <Hardware />
          <Sliders />
          <Details />
          <TechSpecs />
          <ContactForm />
          <Footer />
        </GridContainer>
      </div>
    )
  }
}
