import React, { useEffect, useState, useContext } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import ImgEye from "./../../images/eye_blue.svg"
import ImgEyeActive from "./../../images/eye.svg"
import ImgEyeOff from "./../../images/ic_eye_off.svg"
import { COLORS } from "../../styles/colors"

const StatusCard = styled.div`
  /* border: 1px red dashed; */
  width: 287px;
  height: 70px;
  /* min-width: 340px; */
  /* max-width: 380px; */
  /* min-height: 85px; */
  /* max-height: 90px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: flex-end;
  background: #0e1c36;
  border-radius: 10px;
  margin-bottom: 17px;
  ${media.lessThan("743px")`
    align-self: center;
    width: 90%;
    margin-bottom: 11px;
    `}
  ${media.between("744px", "large")`
  min-width: 300px;
  max-width: 300px;
    `}
    ${media.greaterThan("large")`
    `}
`

const LeftContainer = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StatusIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 20px;
  margin-right: 20px;
  align-self: center;
`

const CardTextContainer = styled.div`
  /* border: 1px yellow dashed; */
  /* width: 144px; */
  display: flex;
  flex-direction: column;
`

const CardHeader = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 129.5%;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color: ${COLORS.HighlightMedium};
  margin-top: 2px;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
  font-size: 12pt;
    `}
    ${media.greaterThan("large")`
    `}
`

const CardNumber = styled.h1`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 129.5%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  text-transform: capitalize;
  color: ${COLORS.White};
`

const EyeButton = styled.button`
  display: flex;
  width: 42px;
  height: 42px;
  border: 1px solid ${COLORS.Secondary};
  box-sizing: border-box;
  border-radius: 5px;
  background: #0e1c36;
  align-content: center;
  justify-content: center;
  margin-right: 15px;
  `

const EyeIcon = styled.img`
  /* border: 1px dashed red; */
  /* padding: 0.5px; */
  /* margin-top: 1px; */
  width: 24px;
  height: auto;
  align-self: center;
  justify-self: center;
`

export default function WingnutStatusCard(props) {
  const [activeStatus, setActiveStatus] = React.useState(false)
  const [isHovering, setIsHovering] = React.useState(false)
  const [allOff, setAllOff] = React.useState(true)

  const toggleStatus = () => {
    setActiveStatus(!activeStatus)
    // tell Overview a status has been toggled
    props.toggleStatus(props.status)
  }

  useEffect(() => {
    if(props.wingnutState){
        if (!props.wingnutState.Live && !props.wingnutState.Disconnected && !props.wingnutState.Unlinked && !props.wingnutState.Unconfigured) {
          setAllOff(true)
        } else {
          setAllOff(false)
        }
    }
  })

  return (
    <StatusCard>
      <LeftContainer>
        <StatusIcon src={props.status} />
        <CardTextContainer>
          <CardHeader>{props.status.key} Wingnuts</CardHeader>
          <CardNumber>{props.wingnutCount}</CardNumber>
        </CardTextContainer>
      </LeftContainer>

          <EyeButton 
            onClick={toggleStatus}
            onMouseEnter={(e) => {
              setIsHovering(true)
            }}
            onMouseLeave={(e) => {
              setIsHovering(false)
            }}
            style={{ background: activeStatus ? COLORS.Secondary : isHovering ? COLORS.Secondary : null }}
          >
            <EyeIcon src={activeStatus ? ImgEyeActive : isHovering ? ImgEyeActive : allOff ? ImgEye : ImgEyeOff }  style={{ marginTop: activeStatus ? null : isHovering ? null : allOff ? null : 2}}/>
          </EyeButton>

    </StatusCard>
  )
}
