import { Spinner } from "react-bootstrap"
import styled from "styled-components"
import { COLORS } from "../../styles/colors"

export const SpinnerContainer = styled.div`
/* border: 1px dashed blue;  */
  display: flex;
  justify-content: center;
  margin-top: 2px;

`

export const StyledSpinner = styled(Spinner)`
  position: fixed;
  color: ${COLORS.White};
`

export const StyledRelativeSpinner = styled(Spinner)`
  position: relative;
  color: ${COLORS.White};
`

// Inside Button
export const ButtonSpinner = styled(Spinner)`
  /* position: fixed; */
  color: ${COLORS.White};
  width: 12px;
  height: 12px;
  margin-left: 10px;
`

// Inside Button
export const DarkButtonSpinner = styled(Spinner)`
  /* position: fixed; */
  color: black;
  width: 12px;
  height: 12px;
  margin-left: 10px;
`