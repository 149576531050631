import React, { useEffect, useState, useContext } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import IconLaunch from "./../../../images/ic_launch_green.svg"
import ImgGreen from "./../../../images/ic_status_green.svg"
import { Link } from "react-router-dom"
import { COLORS } from "../../../styles/colors"
import Analytics from "../chart/Analytics"

const NoDataText = styled.p`
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 130%;
  color: ${COLORS.HighlightLight};
`;

export default function AnalyticsModuleUI(props) {

  return (
    <OuterContainer>
            <HeaderContainer>
              {
                props.analyticData && props.analyticData.length > 0 
                ? <HeaderText>{`${props.label} Analytics`}</HeaderText>
                : null
              }
                
                <WidgetContainer>
                  {props.analyticData && props.analyticData.length > 0 ? (
                    props.analyticData.map((analytics, index) => {
                      if (analytics.label !== "adjusted_time") {
                        return <Analytics key={index} analytics={analytics} {...props} />;
                      }
                      return null;
                    })
                  ) : (
                    <NoDataText>No Data</NoDataText>
                  )}
                </WidgetContainer>



            </HeaderContainer>
      </OuterContainer>
  )
}



  const OuterContainer = styled.div`
  /* border: 1px red dashed; */
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 300px; */
    `

const HeaderContainer = styled.div`
    /* border: 1px blue dashed; */
    display: flex;
    flex-direction: column;
    /* margin-bottom: 21px; */
    ${media.lessThan("743px")`
        /* align-self: center; */
        /* margin-right: 0px; */
  `}
`

const WidgetContainer = styled.div`
    /* border: 1px yellow dashed; */
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    ${media.lessThan("743px")`
    `}
    ${media.between("744px", "large")`
      flex-wrap: wrap;
    `}
`

const HeaderText = styled.h2`
    /* border: 1px red dashed; */
    font-family: Blinker;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 84.5%;
    letter-spacing: -0.01em;
    color: ${COLORS.White};
    margin-bottom: 20px;
`

const EmptyText = styled.p`
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 129.5%;
  display: flex;
  align-items: center;
  color: ${COLORS.HighlightLight};
`;
