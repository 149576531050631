import React, { Component } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Image from "./../../images/Tech_horizontal.svg"
import ContactUs from "./ContactUs"
import Aos from "aos"
import "aos/dist/aos.css"
import { COLORS } from "../../styles/colors"

const Grid = styled.div`
  /* border: 1px blue dashed; */
  grid-area: 7 / 1 / 8 / 2;
  display: flex;
  width: 95%;
  max-width: 1440px;
  flex-direction: row;
  justify-content: space-around;
  justify-self: center;
  margin-bottom: 20px;
  ${media.lessThan("743px")`
        flex-wrap: wrap;
    `}
  ${media.between("744px", "large")`
        /* flex-wrap: wrap; */
    `}
    ${media.greaterThan("large")`
    `}
`

const LeftContainer = styled.div`
  /* border: 1px blue dashed; */
  width: auto;
  display: flex;
  flex-direction: column;
`

const HeaderText = styled.h1`
  width: 500px;
  font-family: "Blinker", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 52.3918px;
  line-height: 53px;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
  ${media.lessThan("743px")`
        font-size: 35px;
        line-height: 101.5%;
        width: 80%;
        align-self: center;
        margin-top: 55px;
        margin-bottom: 40px;
    `}
  ${media.between("744px", "large")`
        width: 350px;
    `}
    ${media.greaterThan("large")`
    `}
`

const TechImg = styled.img`
  width: 420px;
  height: 66px;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: -90px;
  ${media.lessThan("743px")`
        display: none;
    `}
  ${media.between("744px", "large")`
        width: 346.41px;
        height: 67.14px;    
    `}
    ${media.greaterThan("large")`
    `}
`

const ContactCard = styled.div`
  width: 624px;
  height: 700px;
  margin: 30px;
  margin-top: 0px;
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  background-color: #0c182f;
  border-radius: 50px;
  ${media.lessThan("743px")`
        width: 90%;
        margin: 0px;
    `}
  ${media.between("744px", "large")`
        margin: 10px;
    `}
`

export class ContactForm extends Component {
  componentDidMount() {
    Aos.init()
  }

  render() {
    return (
      <Grid>
        <LeftContainer>
          <HeaderText>Fill out the contact form to request a demo or ask any other questions you may have</HeaderText>
          <TechImg data-aos='fade-right' data-aos-duration='1000' src={Image} />
        </LeftContainer>
        <ContactCard>
          <ContactUs></ContactUs>
        </ContactCard>
      </Grid>
    )
  }
}

export default ContactForm
