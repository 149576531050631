import React, { useEffect, useState, useContext } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Image3 from "./../../images/ic_arrow_right_green.svg"
import ImgClose from "./../../images/ic_close.svg"
import ImgCheckmark from "./../../images/ic_check_circle.svg"
import ImgProperty from "./../../images/ic_property.svg"
import { COLORS } from "../../styles/colors"

const Container = styled.div`
  /* border: 1px yellow dashed; */
  width: 496px;
  height: 340px;
  display: flex;
  flex-direction: column;
  /* padding-bottom: 50px; */
  background-color: ${COLORS.Primary};
  border-radius: 20px;
  ${media.lessThan("743px")`
    width: 350px;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const WingnutDetails = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: column;
  width: 350px;
  height: 250px;
  justify-content: space-between;
  align-self: center;
  ${media.lessThan("743px")`
        padding-right: 0px;
        /* width: 100%; */
    `}
  ${media.between("744px", "large")`
        padding-right: 17px;
    `}
    ${media.greaterThan("large")`
    `}
`

const BtnClose = styled.button`
  height: 24px;
  width: 24px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 11px;
  background: transparent;
  border: none;
  outline: none;
  align-self: flex-end;
`

const CloseIcon = styled.img`
  width: 14px;
  height: 14px;
  align-self: center;
`

const TopContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: column;
`;

const WingnutIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-bottom: 52px;
  align-self: center;
`

const HeaderText = styled.div`
  font-family: Blinker;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 110%;
  display: flex;
  align-self: center;
  text-align: center;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
`

const InfoText = styled.h2`
  width: 300px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  text-align: center;
  color: ${COLORS.White};
  margin-top: 12px;
  align-self: center;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 40px;
  border: 1px solid #63c085;
  border-radius: 30px;
  background: #63c085;
  color: black;
  cursor: pointer;
  ${media.lessThan("743px")`
        align-self: flex-start;
        margin-top: 28px;
        height: 55px;
    `}
  ${media.between("744px", "large")`
        height: 55px;
    `}
    ${media.greaterThan("large")`
    `}
`

const BtnNo = styled(Button)`
  background: transparent;
  color: ${COLORS.White};
  margin-left: 5px;
  ${media.lessThan("743px")`
        align-self: center;
    `}
`

const ButtonText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transition-duration: 0.5s;
`

const IconArrow = styled.img`
  width: 6;
  height: 10px;
  align-self: center;
  transition-duration: 0.5s;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

export default function ChangesSaved(props) {
  const [isHovering, setIsHovering] = useState(false)

  return (
    <Container>
      <BtnClose onClick={props.goToNextTab}>
        <CloseIcon src={ImgClose} />
      </BtnClose>
      <WingnutDetails>
        <TopContainer>
          <WingnutIcon src={ImgCheckmark} />
          <HeaderText>Changes saved</HeaderText>
        </TopContainer>
        <ButtonContainer>
          <Button onClick={props.goToNextTab}>
            <ButtonText>CONTINUE</ButtonText>
          </Button>
          <BtnNo onClick={props.goToNextTab}>
            <ButtonText>CLOSE</ButtonText>
          </BtnNo>
        </ButtonContainer>
      </WingnutDetails>
    </Container>
  )
}
