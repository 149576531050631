import React, { useState, useContext, useEffect } from "react"
import { AccountContext } from "./../../auth/Account"
import { CognitoUserAttribute } from "amazon-cognito-identity-js"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import media from "styled-media-query"
import Image from "./../../images/ic_alert.svg"
import Image2 from "./../../images/eye.svg"
import Image3 from "./../../images/ic_success.svg"
import ImageBlueEye from "./../../images/eye_blue.svg"
import { ButtonSpinner } from "./../custom/Spinner"
import { EmailValidatorStrings, ServerStrings } from "./../util/VaildatorStrings"
import {
  ErrorContainer,
  Icon,
  Error,
  EmptyError,
  SuccessContainer,
  SuccessIcon,
  SuccessIcon2,
  Success,
  EmptySuccess,
} from "./../custom/ValidatorStyles"
import { COLORS } from "../../styles/colors"

const Container = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: column;
  align-content: center;
  width: auto;
  ${media.lessThan("743px")`
        align-self: center;
        width: 100%;

        `}
`

const Form = styled.form`
  /* border: 1px blue dashed; */
  /* width: 500px; */
  display: flex;
  flex-direction: column;
  margin-top: 41px;
  /* margin-right: 83px; */
  ${media.lessThan("743px")`
        align-self: center;
        width: 100%;
        margin-right: 0px;
        margin-top: 30px;
    `}
  ${media.between("744px", "large")`
        /* width: 384px; */
    `}
`

const InputContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  width: 100%;
`

const EyeIcon = styled.img`
  /* border: 1px red dashed; */
  align-self: center;
  transition-duration: 0.5s;
  color: ${COLORS.White};
  margin-left: -50px;
  /* margin-top: 46px;
        margin-bottom: 8px; */
  ${media.lessThan("743px")`
        `}
  ${media.between("744px", "large")`
        `}
        ${media.greaterThan("large")`
        `}
`

const HeaderText = styled.h1`
  font-family: Blinker;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 84.5%;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
  margin-bottom: 20px;
  ${media.lessThan("743px")`
        /* align-self: center; */
    `}
`

const Label = styled.label`
  /* border: 1px blue dashed; */
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 129.5%;
  letter-spacing: 0.03em;
  color: ${COLORS.HighlightLight};
  margin-bottom: 2px;
`

const Input = styled.input`
  /* border: 1px blue dashed; */
  width: 100%;
  max-width: 378px;
  height: 45px;
  outline: none;
  border: none;
  color: ${COLORS.White};
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 129.5%;
  font-weight: 400;
  background-color: ${COLORS.Secondary};
  border-radius: 8px;
  box-sizing: border-box;
  padding-left: 10px;
  ::placeholder {
    color: ${COLORS.HighlightMedium};
  }
  :hover {
    border: 1px solid ${COLORS.HighlightMedium};
    padding-left: 9px;
  }
  :focus {
    border: 1px solid ${COLORS.HighlightLight};
    padding-left: 9px;
  }
  ${media.lessThan("743px")`
        width: 100%;
        height: 55px;
    `}
  ${media.between("744px", "large")`
        width: 100%;
        height: 55px;
    `}
    ${media.greaterThan("large")`
    `}
`

const ButtonContainer = styled.div`
  /* border: 1px blue dashed; */
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  ${media.lessThan("743px")`
    `}
`

const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 133px;
  height: 40px;
  border: 1px solid #63c085;
  background: transparent;
  border-radius: 30px;
  color: ${COLORS.White};
  cursor: pointer;
  text-align: center;
  transition-duration: 0.5s;
  :hover {
    background: ${COLORS.Secondary};
  }
  ${media.lessThan("743px")`
        align-self: flex-start;
        margin-bottom: 18px;
        height: 55px;
    `}
  ${media.between("744px", "large")`
        height: 55px;
    `}
    ${media.greaterThan("large")`
    `}
`

const ButtonText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${COLORS.White};
  ${media.lessThan("743px")`
        font-size: 15px;
    `}
  ${media.between("744px", "large")`
        font-size: 15px;
    `}
    ${media.greaterThan("large")`
    `}
`

const IconArrow = styled.img`
  width: 6;
  height: 10px;
  /* margin-left: 20px; */
  align-self: center;
  transition-duration: 0.5s;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

export default function ChangeEmailForm() {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, isSubmitSuccessful },
  } = useForm({ reValidateMode: "onSubmit" })

  const { getSession, authenticateChangeEmail } = useContext(AccountContext)
  const [success, setSuccess] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  const [passwordShown, setPasswordShown] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [serverError, setServerError] = useState(null)
  const [isHovering, setIsHovering] = useState(false)

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true)
  }

  const onSubmit = async (data) => {
    setServerError(null)
    setDisabled(true)
    setSuccess(false)
    setShowSpinner(true)

    getSession().then(({ user, email }) => {
      authenticateChangeEmail(email, data.password)
        .then(() => {
          const attributes = [new CognitoUserAttribute({ Name: "email", Value: data.newEmail })]

          user.updateAttributes(attributes, (err, results) => {
            if (err) {
              console.log("Error", err)
              setServerError(ServerStrings(err.name))
              setShowSpinner(false)
              setSuccess(false)
              setDisabled(false)
            } else {
              reset({ email: "", newEmail: "", password: "" })
              setServerError(null)
              setSuccess(true)
              setShowSpinner(false)
              setDisabled(false)
            }
          })
        })
        .catch((err) => {
          setServerError(ServerStrings(err.name))
          setShowSpinner(false)
          setSuccess(false)
          setDisabled(false)
        })
    })
  }

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess(false)
      }, 2000)
      return () => clearTimeout(timer)
    }
  }, [success])

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <HeaderText>Change Email</HeaderText>
        <Label>New Email</Label>
        <Input
          type='text'
          name='email'
          placeholder='Email'
          {...register("email", {required: true, maxLength: 45,validate: { emailEqual: (value) => value === getValues().newEmail },pattern:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          })}
          style={{ border: errors.email ? "1px solid #D25353" : null, paddingLeft: errors.email ? '9px' : null }}
        />
        {errors.email ? <Error>{EmailValidatorStrings(errors.email?.type)}</Error> : <EmptyError />}

        <Label>Confirm New Email</Label>
        <Input
          type='text'
          name='newEmail'
          placeholder='Email'
          {...register("newEmail", {required: true, maxLength: 45, validate: { emailEqual: (value) => value === getValues().email }, pattern:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          })}
          style={{ border: errors.newEmail ? "1px solid #D25353" : null, paddingLeft: errors.newEmail ? '9px' : null  }}
        />
        {errors.newEmail ? <Error>{EmailValidatorStrings(errors.newEmail?.type)}</Error> : <EmptyError />}

        <Label>Password</Label>
        <div style={{ display: "flex" }}>
          {" "}
          <InputContainer>
            <Input
              autoComplete='new-password'
              type={passwordShown ? "text" : "password"}
              name='password'
              placeholder='••••••••'
              {...register("password", { required: true, maxLength: 45 })}
              style={{ border: errors.password ? "1px solid #D25353" : null, paddingLeft: errors.password ? '9px' : null }}
            />
            <EyeIcon onClick={togglePasswordVisiblity} src={passwordShown ? Image2 : ImageBlueEye} />
          </InputContainer>{" "}
        </div>
        {errors.password ? (
          <Error>{EmailValidatorStrings(errors.password?.type)}</Error>
        ) : serverError ? (
          <ErrorContainer>
            <Icon src={Image} />
            <Error>{serverError}</Error>
          </ErrorContainer>
        ) : (
          <EmptyError />
        )}
        <ButtonContainer>
          <Button disabled={disabled} type='submit'>
            <ButtonText style={{marginLeft: disabled ? "22px" : null}}>SAVE</ButtonText>
            {showSpinner && <ButtonSpinner animation='border' />}
          </Button>
          {success ? (
            <SuccessContainer style={{ alignSelf: 'start', marginTop: 5 }}>
              <SuccessIcon2 src={Image3} style={{ marginLeft: 0 }}/>
              <Success>Your settings have been saved!</Success>
            </SuccessContainer>
          ) : (
            <EmptySuccess></EmptySuccess>
          )}
        </ButtonContainer>
      </Form>
    </Container>
  )
}

// https://codesandbox.io/s/showhide-password-on-toggle-in-react-hooks-95qcz?file=/src/App.js:1079-1084
// https://dev.to/detoner777/show-hide-password-on-toggle-in-react-hooks-1lph
