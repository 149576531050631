import styled from "styled-components"
import media from "styled-media-query"
import { COLORS } from "../../styles/colors"

export const ErrorContainer = styled.div`
  /* border: 1px blue dashed; */
  display: flex;
  flex-direction: row;
  align-content: center;
  /* align-self: center; */
  /* margin-bottom: 10px; */
  /* width: auto; */
`

export const Icon = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 6px;
  margin-top: 2px;
  transition-duration: 0.5s;
  color: ${COLORS.White};
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
  `}
`

export const Error = styled.p`
  /* border: 1px blue dashed; */
  height: 16px;
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 129.5%;
  color: ${COLORS.White};
  flex: none;
  margin-top: 5px;
  margin-bottom: 5px;
  ${media.lessThan("743px")`
        width: 90%;
    `}
  ${media.between("744px", "large")`

    `}
`

export const EmptyError = styled(Error)`
  /* border: 1px blue dashed; */
  height: 0px;
`

export const SuccessContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  align-content: center;
  height: 36px;
  align-self: center;
  ${media.lessThan("743px")`
        align-self: flex-start;
        `}
`

export const SuccessIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 13px;
  transition-duration: 0.5s;
  color: ${COLORS.White};
  align-self: center;
  ${media.lessThan("743px")`
        `}
  ${media.between("744px", "large")`
        `}
        ${media.greaterThan("large")`
    `}
`

export const SuccessIcon2 = styled(SuccessIcon)`
  ${media.lessThan("743px")`
            margin-left: 0px
        `}
  ${media.between("744px", "large")`
            margin-left: 20px;
        `}
        ${media.greaterThan("large")`
            margin-left: 20px;
        `}
`

export const Success = styled.p`
  /* border: 1px blue dashed; */
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: ${COLORS.White};
  align-self: center;
  ${media.lessThan("743px")`
            width: 90%;
        `}
  ${media.between("744px", "large")`
        `}
`

export const EmptySuccess = styled(Success)`
  /* border: 1px blue dashed; */
  /* margin-bottom: 50px; */
  height: 36px;
  height: 0px;
`
