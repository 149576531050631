import React, { useEffect, useState, useContext } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import { COLORS } from "../../styles/colors"
import ImgArrowDown from "./../../images/ic_arrow_down_white.svg"
import ImgArrowRightWhite from "./../../images/ic_arrow_right_white.svg"
import ImgArrowRight from "./../../images/ic_arrow_right_blue.svg"
import { SpinnerContainer, StyledRelativeSpinner } from "../custom/Spinner"
import CustomTimeUI from './../analytic/component/CustomTimeUI'

export default function CustomTimeModule(props) {

  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const [isHovering, setIsHovering] = useState(false)
  const [isOpen, setIsOpen] = useState(false)         // set to TRUE for dev

  const toggleChart = () => {
    if(isOpen){
      setIsOpen(false)
    } else {
    setIsOpen(true)
    }
}

  return (
    <OuterContainer >
      <Card>
        <Container
              onClick={!isLoading ? toggleChart : null} 
              style={{ cursor: !isLoading ? 'pointer' : 'default', background: isOpen ? null : isHovering ? COLORS.Secondary : null}}
              onMouseEnter={(e) => {setIsHovering(true)}}
              onMouseLeave={(e) => {setIsHovering(false)}}>
          <LeftContainer>
              <HeaderContainer>
                  <HeaderText >Custom Date & Timezone</HeaderText>
              </HeaderContainer>
              { isLoading
                ? <SpinnerContainer style={{justifyContent: "flex-start", marginLeft: 20,  position: 'relative'}} ><StyledRelativeSpinner style={{ width: "26px", height: "26px" }}  animation='border' /></SpinnerContainer>
                : null }
          </LeftContainer>  

          <RightContainer>
              <ArrowIcon 
                src={isOpen ? ImgArrowDown : ImgArrowRightWhite }/>
          </RightContainer>
          </Container>

          {
            isOpen 
            ? <CustomTimeUI data={data} {...props}/> 

            : null
          }
      </Card>
    </OuterContainer>
  )
}

const OuterContainer = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  ${media.lessThan("743px")`
    justify-content: center;
  `}
  `;

const Card = styled.div`
   /* border: 1px red dashed; */
   display: flex;
   width: 100%;
   max-width: 1440px;
   margin-right: 30px;
   flex-direction: row;
   justify-content: space-between;
   margin-right: 20px;
   margin-bottom: 15px;
   background: #0e1c36;
   border-radius: 10px;
   min-height: 68px;
   /* padding: 20px; */
   flex-wrap: wrap;
   ${media.lessThan("743px")`
      width: 90%;
      align-self: center;
      margin-right: 0px;
    `}
   ${media.between("744px", "large")`
     `}
     ${media.greaterThan("large")`
     `}
 `

const Container = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  `;


const HeaderText = styled.h2`
  /* border: 1px red dashed; */
  font-family: Blinker;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  /* line-height: 84.5%; */
  letter-spacing: -0.01em;
  color: ${COLORS.White};
`

const LeftContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: row;
  /* height: 100px; */
`;

const RightContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: row;
  /* height: 100px; */
`;

const ArrowIcon = styled.img`
  width: 24px;
  height: 24px;
  align-self: center;
`

const HeaderContainer = styled.div`
    /* border: 1px blue dashed; */
    display: flex;
    flex-direction: row;
    align-items: center;
    ${media.lessThan("743px")`
        align-self: center;
        margin-right: 0px;
  `}
`

const StatusIcon = styled.img`
    /* border: 1px red dashed; */
    width: 12px;
    height: 12px;
    margin-right: 22px;
`
