import React, { useEffect, useState, useContext } from "react"
import { AccountContext } from "../../auth/Account"
import styled from "styled-components"
import media from "styled-media-query"
import ToggleTriState from './ToggleTriState'
import { SpinnerContainer, StyledSpinner } from "../custom/Spinner"
import { API, Auth } from "aws-amplify"
import NotificationEmployeeSelect from './NotificationEmployeeSelect'
import NotificationConfiguration from './NotificationConfiguration'
import NoConfiguration from './NotificationNoConfiguration'
import NotificationEmployee from './NotificationEmployee'
import { COLORS } from "../../styles/colors"

/**
 * 
 *Last thing I did was pass props notificationDetails and property into notification employee
 * So employee won't have to make db call
 * employee db call lifted into this component
 * lift config save call into this component
 * save everything all together
 */


export default function NotificationDetails(props) {

  const { params } = props
  const { getSession } = useContext(AccountContext)

  const [masterToggle, setMasterToggle] = useState(0)
  const [recipients, setRecipients] = useState([])
  const [isConfigured, setIsConfigured] = useState(null)
  const [isLinked, setIsLinked] = useState(null)
  const [propertyId, setPropertyId] = useState(null)
  const [isAuth, setIsAuth ] = useState(false)
  

          
      useEffect(() => {
          getSession().then(({ isAuth }) => {
            setIsAuth(isAuth)
          })
      }, [])
 
    const updateMaster = async (val) => {
      setMasterToggle(val)
      props.isActiveChange(true)
    }

    const updateRecipients = async (val) => {
      setRecipients(val)
      // props.isActiveChange(true)
    }

      // Update master toggle
      useEffect(() => {  
        if(props.notificationDetails && props.notificationDetails[0]){
          setMasterToggle(props.notificationDetails[0].entityStatus === "enabled" ? 1 : 0)
        }

        }, [props.notificationDetails])

    // Determine if Configured
      useEffect(() => {
        if(props.propertyDetails){
          setPropertyId(props.propertyDetails.id)
          // check for linked wingnuts
                  if(props.propertyDetails.linkedWingnuts.length !== 0){
                    // go inside each linked wingnut 
                    props.propertyDetails.linkedWingnuts.map(wingnut => {
                      // check for any configured sensors
                      setIsLinked(true)
                      Object.keys(wingnut.config.sensors).map((key, index) => {
                        // Only Analog inputs can have notifications
                        if( key.substring(0,1) === "A"){
                          return setIsConfigured(true)
                        }
                      })
                    })
                } else {
                  setIsConfigured(false)
                }
            }
      }, [props.propertyDetails])


    return (
      <Container>
        { isConfigured 
                  ? <div>
                      <InstructionText>Enable notifications for this property</InstructionText>
                      <ToggleTriState disable={isAuth ? 1 : 0} toggle={masterToggle} showLabel={true} updateToggle={updateMaster} />
                      <NotificationEmployee {...props} updateRecipients={updateRecipients}/>
                      <NotificationConfiguration {...props} key={props.refreshKey} disable={masterToggle} recipients={recipients}/>
                    </div> 
                  : isConfigured !== null || isLinked !== null 
                    ? <NoConfiguration isLinked={isLinked} propertyId={propertyId} propertyDetails={props.propertyDetails} />
                    : null     
        }
      </Container>
    )
}


const Container = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: column;
  background-color: #0e1c36;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 20px;
  margin-right: 20px;
  margin-top: -8px;
  ${media.lessThan("743px")`
      width: 100%;
      align-self: center;
      margin-right: 0px;
    `}
`

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${COLORS.HighlightMedium};
  margin-top: 21px;
  margin-right: 30px;
  ${media.lessThan("743px")`
      align-self: center;
      margin-right: 0px;
    `}
`

const InstructionText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
  margin-bottom: 7px;
  font-size: 18px;
  padding-top: 20px;
  ${media.lessThan("743px")`
  `}
  ${media.between("744px", "large")`
  `}
  ${media.greaterThan("large")`
  `}
`
