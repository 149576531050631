import React, { useEffect, useState, useContext } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import media from "styled-media-query"
import ImgProperty from "./../../images/ic_property_white.svg"
import { COLORS } from "../../styles/colors"

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const WingnutDetails = styled.div`
  background: ${COLORS.Primary};
  border: 1px solid ${COLORS.HighlightMedium};
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: none;
  height: 340px;
  padding: 15px;
  ${media.lessThan("743px")`
        padding-right: 0px;
        width: 100%;
     height: 350px;
    `}
  ${media.between("744px", "large")`
        /* padding-right: 17px; */
        height: 340px;
    `}
    ${media.greaterThan("large")`
    `}
`

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const WingnutIcon = styled.img`
  width: 23px;
  height: auto;
  margin-bottom: 8px;
`

const WingnutIcon2 = styled.img`
  width: 12px;
  height: 12px;
  align-self: center;
  margin-left: 10px;
`

const HeaderText = styled.div`
  font-family: Blinker;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 110%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
`

const WingnutDetailsRight = styled(WingnutDetails)`
  /* border: 1px green dashed; */
  padding-right: 0px;
  /* width: 50%; */
`

const Title = styled.h3`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 129.5%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color: ${COLORS.HighlightMedium};
  margin-top: 13px;
`

const InfoText = styled.h2`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: ${COLORS.HighlightLight};
  margin-top: 1px;
`

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
`;


const Button = styled.button`
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  border: none;
  /* border: 1px red dashed; */
  /* border-radius: 30px; */
  background: transparent;
  color: ${COLORS.White};
  cursor: pointer;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 13px;
  letter-spacing: 0.05em;
  margin-bottom: 10px;
  margin-top: 20px;
  margin-left: -5px;
  ${media.lessThan("743px")`
  height: 55px;
    `}
  ${media.between("744px", "large")`
  height: 55px;
    `}
    ${media.greaterThan("large")`
    `}
`

const BtnBack = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 235px;
  height: 40px;
  border: 1px solid #63c085;
  border-radius: 30px;
  color: ${COLORS.White};
  background: transparent;
  cursor: pointer;
  text-align: center;
  margin-top: 17px;
  align-self: flex-end;
  :hover {
    background: ${COLORS.Secondary};
  }
  ${media.lessThan("743px")`
  height: 55px;
    `}
  ${media.between("744px", "large")`
  height: 55px;
    `}
    ${media.greaterThan("large")`
    `}
`

const ButtonText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transition-duration: 0.5s;
  :hover {
  }
`

export default function PropertyDetailsCard(props) {
  const { propertyDetails } = props

  return (
    <Container>
      <WingnutDetails>
        <TopContainer>
        <WingnutIcon src={ImgProperty} />
          <HeaderText>{propertyDetails.config.name}</HeaderText>
          <Title>Property ID</Title>
          <InfoText>{propertyDetails.externalId}</InfoText>
          <Title>Portfolio</Title>
          <InfoText>{propertyDetails.config.portfolio}</InfoText>
          <Title>Address</Title>
          <InfoText>{propertyDetails && `${propertyDetails.config.address.streetAddress} ${propertyDetails.config.address.city} ${propertyDetails.config.address.provinceState} ${propertyDetails.config.address.country} ${propertyDetails.config.address.postalZip}`}</InfoText>
        </TopContainer>
        <BottomContainer>
          <Link to={`/portal/property/detail/${propertyDetails.id}`} style={{ textDecoration: "none" }}>
            <BtnBack>
              <ButtonText>{props.onLeftSide ? "BACK TO PROPERTY" : "GO TO PROPERTY"}</ButtonText>
              <WingnutIcon2 src={ImgProperty} />
            </BtnBack>
          </Link>
        </BottomContainer>
      </WingnutDetails>
    </Container>
  )
}
