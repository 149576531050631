import React, { useState, useContext } from "react"
import { AccountContext } from "./../../auth/Account"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import media from "styled-media-query"
import Image2 from "./../../images/eye.svg"
import Image from "./../../images/ic_alert.svg"
import { SpinnerContainer, StyledSpinner } from "./../custom/Spinner"
import { ValidatorStrings, ServerStrings } from "./../util/VaildatorStrings"
import { ErrorContainer, Icon, Error, EmptyError } from "./../custom/ValidatorStyles"
import Pool from "./../../auth/UserPool"
import { CognitoUser } from "amazon-cognito-identity-js"
import { ProSidebar } from "react-pro-sidebar"
import { COLORS } from "../../styles/colors"


export default function ResetPasswordForm(props) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({ reValidateMode: "onSubmit" })

  const { getSession } = useContext(AccountContext)
  const [showSpinner, setShowSpinner] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [validatorError, setValidatorError] = useState(null)

  const onSubmit = async (data) => {
    setDisabled(true)
    setShowSpinner(true)

    const getUser = () => {
      return new CognitoUser({
        Username: data.email.toLowerCase(),
        Pool,
      })
    }

    getUser().forgotPassword({
      onSuccess: (d) => {
        setValidatorError(null)
        setShowSpinner(false)
        setDisabled(false)
        props.setEmail(data.email)
        props.goToStepNumber(1)
      },
      onFailure: (err) => {
        console.log("Error", err)
        setValidatorError(ServerStrings(err.name))
        setShowSpinner(false)
        setDisabled(false)
      },
    })
  }

  return (
    <Container>
      <SpinnerContainer>{showSpinner && <StyledSpinner animation='border' />}</SpinnerContainer>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Label>EMAIL</Label>

        <Input
          type='text'
          name='email'
          placeholder='Email'
          {...register("email", {required: true, maxLength: 45, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, })}
          style={{ border: errors.email ? "1px solid #D25353" : null, paddingLeft: errors.email ? '9px' : null }}
        />
        {errors.email ? <Error>{ValidatorStrings(errors.email?.type)}</Error> : <EmptyError />}

        <BtnInstructions disabled={disabled} type='submit'>
          SEND RECOVERY LINK
        </BtnInstructions>
      </Form>
    </Container>
  )
}

const Container = styled.div`
  /* border: 1px red dashed;; */
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  /* justify-self: center; */
  width: auto;
  align-self: center;
  ${media.lessThan("743px")`
      width: 90%;

    `}
`

const Form = styled.form`
  /* border: 1px blue dashed; */
  width: 384px;
  display: flex;
  flex-direction: column;
  justify-self: center;
  margin-top: 71px;
  ${media.lessThan("743px")`
        width: 100%;
        align-self: center;
        `}
  ${media.between("744px", "large")`
            align-self: center;
            width: 384px;
        `}
`

const Label = styled.label`
  /* border: 1px blue dashed; */
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  letter-spacing: 0.03em;
  color: ${COLORS.HighlightLight};
  margin-bottom: 2px;
  /* margin-top: 16px; */
`

const Input = styled.input`
  /* border: 1px blue dashed; */
  width: 384px;
  height: 45px;
  padding-left: 10px;
  outline: none;
  border: none;
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  color: ${COLORS.White};
  font-weight: 400;
  background-color: #1d283e;
  border-radius: 8px;
  box-sizing: border-box;
  ::placeholder {
    color: ${COLORS.HighlightMedium};
  }
  :hover {
    border: 1px solid ${COLORS.HighlightMedium};
    padding-left: 9px;
  }
  :focus {
    border: 1px solid ${COLORS.HighlightLight};
    padding-left: 9px;
  }
  ${media.lessThan("743px")`
    width: 100%;
    height: 55px;
    `}
  ${media.between("744px", "large")`
    width: 100%;
    height: 55px;
    `}
    ${media.greaterThan("large")`
    `}
`

const BtnInstructions = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  align-self: center;
  width: 206px;
  height: 40px;
  border: none;
  background: #63c085;
  cursor: pointer;
  border-radius: 36px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  line-height: 129.5%;
  text-align: center;
  letter-spacing: 0.05em;
  margin-bottom: 10px;
  margin-top: 38px;
  color: ${COLORS.Base};
  ${media.lessThan("743px")`
    height: 55px;
    `}
  ${media.between("744px", "large")`
    height: 55px;
    `}
    ${media.greaterThan("large")`
    `}
`