import React, { useState, useContext } from "react"
import { useHistory } from "react-router-dom"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import media from "styled-media-query"
import Image2 from "./../../images/eye.svg"
import ImageBlueEye from "./../../images/eye_blue.svg"
import Image from "./../../images/ic_alert.svg"
import { AccountContext } from "./../../auth/Account"
import { DarkButtonSpinner } from "./../custom/Spinner"
import { ErrorContainer, Icon, Error, EmptyError } from "./../custom/ValidatorStyles"
import { ValidatorStrings, ServerStrings } from "./../util/VaildatorStrings"
import { COLORS } from "../../styles/colors"

const Container = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: column;
  align-self: center;
  width: auto;
  ${media.lessThan("743px")`
      width: 90%;
    `}
`

const InputContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  width: 100%;
`

const EyeIcon = styled.img`
  /* border: 1px red dashed; */
  align-self: center;
  transition-duration: 0.5s;
  color: ${COLORS.White};
  margin-left: -50px;
  /* margin-top: 46px;
        margin-bottom: 8px; */
  ${media.lessThan("743px")`
        `}
  ${media.between("744px", "large")`
        `}
        ${media.greaterThan("large")`
        `}
`

const Form = styled.form`
  /* border: 1px blue dashed; */
  width: 384px;
  display: flex;
  flex-direction: column;
  margin-top: 46px;
  ${media.lessThan("743px")`
    width: 100%;
    align-self: center;
    `}
  ${media.between("744px", "large")`
    align-self: center;
    width: 384px;
    /* margin-top: 46px; */
    `}
`

const Label = styled.label`
  /* border: 1px blue dashed; */
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 129.5%;
  letter-spacing: 0.03em;
  color: ${COLORS.HighlightLight};
  margin-bottom: 2px;
  margin-top: 6px;
  ${media.lessThan("743px")`
    /* margin-top: 16px; */
    `}
`

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  margin-top: 20px;
`

const LblCheckbox = styled.label`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  margin-left: 14px;
  font-size: 17px;
  line-height: 129.5%;
  align-self: center;
  text-align: center;
  color: ${COLORS.White};
`

const Input = styled.input`
  /* border: 1px blue dashed; */
  width: 384px;
  height: 45px;
  outline: none;
  border: none;
  color: ${COLORS.White};
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 129.5%;
  padding-left: 10px;
  background-color: #1d283e;
  border-radius: 8px;
  box-sizing: border-box;
  ::placeholder {
    color: ${COLORS.HighlightMedium};
  }
  :hover {
    border: 1px solid ${COLORS.HighlightMedium};
    padding-left: 9px;
  }
  :focus {
    outline: ${COLORS.White};
    border: 1px solid ${COLORS.HighlightLight};
    padding-left: 9px;
  }
  ${media.lessThan("743px")`
        width: 100%;
        height: 55px;
    `}
  ${media.between("744px", "large")`
         width: 100%;
         height: 55px;
    `}
    ${media.greaterThan("large")`
    `}
`

const ButtonText = styled.p`
  /* border: 1px red dashed; */
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: black;
  ${media.lessThan("743px")`
        font-size: 15px;
    `}
  ${media.between("744px", "large")`
        font-size: 15px;
    `}
    ${media.greaterThan("large")`
    `}
`

const Checkbox = styled.input`
  width: 20px;
  height: 20px;
`

const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 160px;
  height: 40px;
  border: none;
  background: #63c085;
  cursor: pointer;
  border-radius: 36px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 13px;
  text-align: center;
  letter-spacing: 0.05em;
  margin-bottom: 10px;
  margin-top: 50px;
  transition-duration: 0.5s;
  :hover {
    opacity: 0.8;
  }
  color: ${COLORS.Base};
  ${media.lessThan("743px")`
        margin-top: 36px;
        height: 55px;
    `}
  ${media.between("744px", "large")`
        height: 55px;
    `}
    ${media.greaterThan("large")`
    `}
`

const Success = styled.p`
  /* border: 1px blue dashed; */
  color: green;
  margin-top: 10px;
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 129.5%;
  letter-spacing: 0.05em;
  width: 255.55px;
  align-self: flex-end;
  text-align: center;
  ${media.lessThan("743px")`
      align-self: center;
    `}
  ${media.between("744px", "large")`
      align-self: center;
    `}
`

export default function LoginForm() {
  const history = useHistory()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({ reValidateMode: "onSubmit" })

  const { authenticate } = useContext(AccountContext)

  const [showSpinner, setShowSpinner] = useState(false)
  const [passwordShown, setPasswordShown] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [validatorError, setValidatorError] = useState(null)

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true)
  }

  const onSubmit = async (data) => {
    setValidatorError(null)
    setShowSpinner(true)
    setDisabled(true)

    authenticate(data.email, data.password, data.setRemember)
      .then((data) => {
        setShowSpinner(false)
        setValidatorError(null)
        reset({ email: "", password: "" })
        setDisabled(false)
        history.push("/portal/dashboard/0")
      })
      .catch((err) => {
        console.error("Failed to login", err)
        setValidatorError(ServerStrings(err.name))
        setShowSpinner(false)
        setDisabled(false)
      })
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Label>EMAIL</Label>
        <Input
          autoComplete='username'
          type='email'
          name='email'
          placeholder='Email'
          {...register("email", {required: true,maxLength: 45,pattern:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          })}
          style={{ border: errors.email ? "1px solid #D25353" : null, paddingLeft: errors.email ? '9px' : null }}
        />
        {errors.email ? <Error>{ValidatorStrings(errors.email?.type)}</Error> : <EmptyError />}

        <Label>PASSWORD</Label>
        <div style={{ display: "flex" }}>
          {" "}
          <InputContainer>
            <Input
              autoComplete='current-password'
              type={passwordShown ? "text" : "password"}
              name='password'
              placeholder='••••••••'
              {...register("password", { required: true, maxLength: 45 })}
              style={{ border: errors.password ? "1px solid #D25353" : null, paddingLeft: errors.password ? '9px' : null }}
            />
            <EyeIcon onClick={togglePasswordVisiblity} src={passwordShown ? Image2 : ImageBlueEye} />
          </InputContainer>{" "}
        </div>
        {errors.password ? (
          <Error>{ValidatorStrings(errors.password?.type)}</Error>
        ) : validatorError ? (
          <ErrorContainer>
            <Icon src={Image} />
            <Error>{validatorError}</Error>
          </ErrorContainer>
        ) : (
          <EmptyError />
        )}

        <CheckboxContainer style={{ display: "none" }}>
          <Checkbox
            type='checkbox'
            name='setRemember'
            {...register("setRemember")}
            style={{ border: errors.setRemember ? "1px solid #D25353" : null }}
          />
          <LblCheckbox>Remember Me </LblCheckbox>
        </CheckboxContainer>

        {/* <Button disabled={disabled} type='submit'>
          LOG IN
        </Button> */}

        <Button disabled={disabled} type='submit'>
            <ButtonText style={{marginLeft: disabled ? "22px" : null}}>SUBMIT</ButtonText>
            {showSpinner && <DarkButtonSpinner animation='border' />}
        </Button>

      </Form>
    </Container>
  )
}


