import React, { useState } from "react"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import media from "styled-media-query"
import Image2 from "./../../images/eye.svg"
import Image from "./../../images/ic_alert.svg"
import ImageBlueEye from "./../../images/eye_blue.svg"
import { SpinnerContainer, StyledSpinner } from "./../custom/Spinner"
import { ErrorContainer, Icon, Error, EmptyError } from "./../custom/ValidatorStyles"
import Pool from "./../../auth/UserPool"
import { CognitoUser } from "amazon-cognito-identity-js"
import { ValidatorStrings, ServerStrings } from "./../util/VaildatorStrings"
import { COLORS } from "../../styles/colors"


export default function ResetPasswordCodeForm(props) {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, isSubmitSuccessful },
  } = useForm({ reValidateMode: "onSubmit" })

  const [showSpinner, setShowSpinner] = useState(false)
  const [newPasswordShown, setNewPasswordShown] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false)
  const [validatorError, setValidatorError] = useState(null)

  const toggleNewPasswordVisiblity = () => {
    setNewPasswordShown(newPasswordShown ? false : true)
  }

  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(confirmPasswordShown ? false : true)
  }

  const onSubmit = async (data) => {
    setShowSpinner(true)
    setDisabled(true)

    const getUser = () => {
      return new CognitoUser({
        Username: props.email.toLowerCase(), 
        Pool,
      })
    }

    getUser().confirmPassword(props.code, data.newPassword, {
      onSuccess(data) {
        setValidatorError(null)
        setShowSpinner(false)
        setDisabled(false)
        reset({ newPassword: "", confirmPassword: "" })
        props.goToStepNumber(3)
      },
      onFailure(err) {
        console.log("Error", err)
        setValidatorError(ServerStrings(err.name))
        setShowSpinner(false)
        setDisabled(false)
      },
    })
  }

  return (
    <Container style={{ alignSelf: "center" }}>
      <SpinnerContainer>{showSpinner && <StyledSpinner animation='border' />}</SpinnerContainer>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>


        <Label>New Password</Label>
        <div style={{ display: "flex" }}>
          {" "}
          <InputContainer>
            <Input
              autoComplete='new-password'
              type={newPasswordShown ? "text" : "password"}
              name='newPassword'
              placeholder='••••••••••••••••'
              {...register("newPassword", {required: true,maxLength: 45, validate: { emailEqual: (value) => value === getValues().confirmPassword },})}
              style={{ border: errors.newPassword ? "1px solid #D25353" : null, paddingLeft: errors.newPassword ? '9px' : null }}
            />
            <EyeIcon onClick={toggleNewPasswordVisiblity} src={newPasswordShown ? Image2 : ImageBlueEye} />
          </InputContainer>{" "}
        </div>
        {errors.newPassword ? <Error>{ValidatorStrings(errors.newPassword?.type)}</Error> : <EmptyError />}
        <Label>Confirm New Password</Label>
        <div style={{ display: "flex" }}>
          {" "}
          <InputContainer>
            <Input
              type={confirmPasswordShown ? "text" : "password"}
              name='confirmPassword'
              placeholder='••••••••••••••••'
              {...register("confirmPassword", {required: true,maxLength: 45,validate: { emailEqual: (value) => value === getValues().newPassword },})}
              style={{ border: errors.confirmPassword ? "1px solid #D25353" : null, paddingLeft: errors.confirmPassword ? '9px' : null }}
            />
            <EyeIcon onClick={toggleConfirmPasswordVisiblity} src={confirmPasswordShown ? Image2 : ImageBlueEye} />
          </InputContainer>{" "}
        </div>
        {errors.confirmPassword ? (
          <Error>{ValidatorStrings(errors.confirmPassword?.type)}</Error>
        ) : validatorError ? (
          <ErrorContainer>
            <Icon src={Image} />
            <Error>{validatorError}</Error>
          </ErrorContainer>
        ) : (
          <EmptyError />
        )}
        <BtnConfirmPassword disabled={disabled} type='submit'>
          CONFIRM NEW PASSWORD
        </BtnConfirmPassword>
      </Form>
    </Container>
  )
}

const Container = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: auto;
  align-self: center;
  /* margin-top: 28px; */
  ${media.lessThan("743px")`
    width: 90%;

    `}
`

const InputContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  width: 100%;
`

const EyeIcon = styled.img`
  /* border: 1px red dashed; */
  align-self: center;
  transition-duration: 0.5s;
  color: ${COLORS.White};
  margin-left: -50px;
  /* margin-top: 46px;
    margin-bottom: 8px; */
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const Form = styled.form`
  /* border: 1px blue dashed; */
  width: 384px;
  display: flex;
  flex-direction: column;
  justify-self: center;
  margin-top: 26px;
  ${media.lessThan("743px")`
    width: 100%;
      align-self: center;
    `}
  ${media.between("744px", "large")`
        align-self: center;
        width: 384px;
    `}
`

const Label = styled.label`
  /* border: 1px blue dashed; */
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 129.5%;
  letter-spacing: 0.03em;
  color: ${COLORS.HighlightLight};
  margin-bottom: 2px;
  /* margin-top: 6px; */
`

const Input = styled.input`
  /* border: 1px blue dashed; */
  width: 384px;
  height: 45px;
  outline: none;
  border: none;
  color: ${COLORS.White};
  font-family: "Blinker", sans-serif;
  padding-left: 10px;
  font-style: normal;
  font-size: 15px;
  letter-spacing: 0.1em;
  font-weight: 400;
  background-color: #1d283e;
  border-radius: 8px;
  box-sizing: border-box;
  ::placeholder {
    color: ${COLORS.HighlightMedium};
  }
  :hover {
    border: 1px solid ${COLORS.HighlightMedium};
    padding-left: 9px;
  }
  :focus {
    border: 1px solid ${COLORS.HighlightLight};
    padding-left: 9px;
  }
  ${media.lessThan("743px")`
        width: 100%;
        height: 55px;
        
    `}
  ${media.between("744px", "large")`
        width: 100%;
        height: 55px;
    `}
    ${media.greaterThan("large")`
    `}
`

const BtnConfirmPassword = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  align-self: center;
  width: 211px;
  height: 40px;
  border: none;
  background: #63c085;
  cursor: pointer;
  border-radius: 36px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  /* line-height: 129.5%; */
  text-align: center;
  letter-spacing: 0.05em;
  margin-bottom: 10px;
  margin-top: 33px;
  color: ${COLORS.Base};
  ${media.lessThan("743px")`
        height: 55px;
    `}
  ${media.between("744px", "large")`
        height: 55px;
    `}
    ${media.greaterThan("large")`
    `}
`

const BtnBack = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  align-self: center;
  width: 206px;
  height: 50px;
  border: 1px solid #63c085;
  border-radius: 30px;
  background: transparent;
  cursor: pointer;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  color: ${COLORS.White};
  line-height: 129.5%;
  text-align: center;
  letter-spacing: 0.05em;
  margin-bottom: 10px;
  margin-top: 13px;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`