import React, { useEffect, useState, useContext } from "react"
import { API, Auth } from "aws-amplify"
import styled from "styled-components"
import media from "styled-media-query"
import { COLORS } from "../../styles/colors"
import Icon from "./../../images/ic_alert.svg";
// import AlertCard from "./AlertCard";
import { SpinnerContainer, StyledSpinner } from "../custom/Spinner"
import PropertyTimeUI from "./component/PropertyTimeUI"
import PropertyTimeDropdownUI from "./component/PropertyDropdownUI"


export default function PropertyTimeData(props) {

  return (
    <OuterContainer >
      <Card>
        { props.isFirstLoad
        ? <SpinnerContainer style={{marginLeft: 20}}><StyledSpinner animation='border' /></SpinnerContainer>
        : 
          <PropertyTimeUI {...props}/> 
        }
      </Card>
    </OuterContainer>
  )
}

const OuterContainer = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  width: 100%;
  max-width: 1440px;
  ${media.lessThan("743px")`
  justify-content: center;
  `}
  `;

const Card = styled.div`
   /* border: 1px red dashed; */
   display: flex;
   width: 100%;
   max-width: 1440px;
   /* margin-right: 30px; */
   flex-direction: row;
   justify-content: space-between;
   margin-right: 20px;
   /* margin-bottom: 15px; */
   background: #0e1c36;
   border-radius: 10px;
   min-height: 80px;
   padding: 20px;
   flex-wrap: wrap;
   ${media.lessThan("743px")`
      width: 90%;
      align-self: center;
      margin-right: 0px;
    `}
   ${media.between("744px", "large")`
     `}
     ${media.greaterThan("large")`
     `}
 `
