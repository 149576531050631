import React, { useEffect, useState, useContext } from "react"
import { API, Auth } from "aws-amplify"
import styled from "styled-components"
import media from "styled-media-query"
import { COLORS } from "../../styles/colors"
import ImgArrowDown from "./../../images/ic_arrow_down_white.svg"
import ImgArrowRightWhite from "./../../images/ic_arrow_right_white.svg"
import ImgArrowRight from "./../../images/ic_arrow_right_blue.svg"
import Icon from "./../../images/ic_alert.svg";
// import AlertCard from "./AlertCard";
import { SpinnerContainer, StyledRelativeSpinner } from "../custom/Spinner"
import PropertyTimeUI from "./component/PropertyTimeUI"
import PrimaryTempsUI from "./component/SystemOverviewUI"
import TimeSeriesAMChartsUI from "./component/TimeSeriesAMChartsUI"


export default function TimeSeriesModule(props) {

  const [chart1Loaded, setChart1Loaded] = useState(false)
  const [chart2Loaded, setChart2Loaded] = useState(false)
  const [chart3Loaded, setChart3Loaded] = useState(false)

  const [stateChunk1, setStateChunk1] = useState()
  const [stateChunk2, setStateChunk2] = useState()
  const [stateChunk3, setStateChunk3] = useState()
  const [stateChunk4, setStateChunk4] = useState()

  const [chunk1, setChunk1] = useState()
  const [chunk2, setChunk2] = useState()
  const [chunk3, setChunk3] = useState()
  const [chunk4, setChunk4] = useState()

  const [data, setData] = useState()
  const [stateData, setStateData] = useState()
  const [analyticData, setAnalyticData] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const [isHovering, setIsHovering] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    // if(data){
      console.log("V2.1 SHOULD KICKOFF LOAD CYCLE", props.refresh)
      setData()
      setStateData()
      setAnalyticData()
      multiDataLoad()
      loadAnalyticData()
      setChunk1()
      setChunk2()
      setChunk3()
      setChunk4()
      setStateChunk1()
      setStateChunk2()
      setStateChunk3()
      setStateChunk4()
      setChart1Loaded(false)
      setChart2Loaded(false)
      setChart3Loaded(false)
      setIsLoading(true)
      setIsOpen(false)
    // } 
  }, [props.refresh])

  const multiDataLoad = () => {

    console.log("FIRST REQUEST DATE", props.startTime, new Date(props.startTime))
    console.log("LAST REQUEST DATE", props.endTime, new Date(props.endTime))
    // console.log("props.incrementTime", props.incrementTime )

    if (props.incrementTime !== 2419200 && props.incrementTime !== 1209600 && props.incrementTime !== 0){
      loadChartData(0, props.startTime, props.endTime)     // Calls not needing split
      loadStateData(0, props.startTime, props.endTime)
    } else {

      // Calculate the total duration and the duration of each chunk
      const totalDuration = props.endTime - props.startTime;
      const chunkDuration = Math.floor(totalDuration / 4);

      // Define the end timestamp for week 1
      const chunk1Start = props.startTime;
      const chunk1End = chunk1Start + chunkDuration - 1000;

      const chunk2Start = chunk1End + 1;
      const chunk2End = chunk2Start + chunkDuration - 1000;

      const chunk3Start = chunk2End + 1;
      const chunk3End = chunk3Start + chunkDuration - 1000;

      const chunk4Start = chunk3End + 1;
      const chunk4End = chunk4Start + chunkDuration - 1000;

      // Ensure that the end of week 4 doesn't exceed the original endTime
      const finalChunk4End = chunk4End > props.endTime ? props.endTime : chunk4End;

      // Print the start timestamps for each week and the end timestamp for week 1
      // console.log("Fetch Chunk 1 Start:", new Date(chunk1Start), "Chunk 1 End:", new Date(chunk1End));
      // console.log("Fetch Chunk 2 Start:", new Date(chunk2Start), "Chunk 2 End:", new Date(chunk2End));
      // console.log("Fetch Chunk 3 Start:", new Date(chunk3Start), "Chunk 3 End:", new Date(chunk3End));
      // console.log("Fetch Chunk 4 Start:", new Date(chunk4Start), "Chunk 4 End:", new Date(finalChunk4End));

      loadChartData(1, chunk1Start, chunk1End)
      loadChartData(2, chunk2Start, chunk2End)
      loadChartData(3, chunk3Start, chunk3End)
      loadChartData(4, chunk4Start, finalChunk4End)

      loadStateData(1, chunk1Start, chunk1End)
      loadStateData(2, chunk2Start, chunk2End)
      loadStateData(3, chunk3Start, chunk3End)
      loadStateData(4, chunk4Start, finalChunk4End)
    } 

  }

  // chunks = [chunk1, chunk2, chunk3, chunk4]
  function stitchData(chunks) {
    // Initialize an object to hold the stitched data
    const stitchedData = {};
  
    // Iterate over each chunk
    chunks.forEach(chunk => {
      // Iterate over each label in the chunk
      Object.keys(chunk).forEach(label => {
        // If the label doesn't exist in the stitchedData, initialize it with an empty array
        if (!stitchedData[label]) {
          stitchedData[label] = [];
        }
        // Concatenate the current chunk's data to the stitchedData under the label
        stitchedData[label] = stitchedData[label].concat(chunk[label].reverse());
      });
    });
  
    return stitchedData;
  }

  function isFirstLabelTimestampsOrdered(data) {
    // Get the first label key in the object
    const firstLabel = Object.keys(data)[0];
    
    // Get the array for the first label
    const timeSeries = data[firstLabel];
  
    // Check that each timestamp is greater than the one before it
    for (let i = 1; i < timeSeries.length; i++) {
      if (timeSeries[i][0] < timeSeries[i - 1][0]) {
        // If a timestamp is less than the one before, the order is incorrect
        return false;
      }
    }
    
    // If all timestamps are in the correct order, return true
    return true;
  }
  

  useEffect(() => {
      if (chunk1 !== undefined && chunk2 !== undefined && chunk3 !== undefined && chunk4 !== undefined) {
          // console.log("GOT 4 CHUNKS - START STITCH DATA")
          const data = stitchData([chunk1, chunk2, chunk3, chunk4]);
          setData(data);  
          // console.log("GOT 4 CHUNKS - END STITCH DATA")
          // console.log("IS MAIN DATA ORDERED", isFirstLabelTimestampsOrdered(data))

          if(Object.keys(data).length > 0){
            setIsOpen(true)
          } else {
            setIsLoading(false)
          }
      }
  }, [chunk1, chunk2, chunk3, chunk4])

  useEffect(() => {
    if (stateChunk1 !== undefined && stateChunk2 !== undefined && stateChunk3 !== undefined && stateChunk4 !== undefined) {
        // console.log("GOT 4 CHUNKS - START STITCH DATA")
        const data = stitchData([stateChunk1, stateChunk2, stateChunk3, stateChunk4]);
        // console.log("GOT 4 CHUNKS - END STITCH DATA")
        // console.log("IS STATE DATA ORDERED", isFirstLabelTimestampsOrdered(data))
        setStateData(data);  
        // setIsOpen(true)      
    }
}, [stateChunk1, stateChunk2, stateChunk3, stateChunk4])

  useEffect(() => {

      let labels = [];

      if (data && stateData) {
          labels = [...Object.keys(data), ...Object.keys(stateData)];
      } else if (data) {
          labels = [...Object.keys(data)];
      } else if (stateData) {
          labels = [...Object.keys(stateData)];
      }

      function containsKeywords(array, keywords) {
        return array.some(element => keywords.some(keyword => element.includes(keyword)));
    }

      const chart1 = containsKeywords(labels, ['Temperature'])
      const chart2 = containsKeywords(labels, ['Pressure', 'Current'])
      const chart3 = containsKeywords(labels, ['State'])

      if(chart1 && chart2 && chart3){
        if (chart1Loaded === true && chart2Loaded === true && chart3Loaded === true) {
          setIsLoading(false);
        }
      } else if (chart1 && chart2){
        if (chart1Loaded === true && chart2Loaded === true) {
          setIsLoading(false);
        }
      } else if (chart1 && chart3){
        if (chart1Loaded === true && chart3Loaded === true) {
          setIsLoading(false);
        }
      } else if (chart2 && chart3){
        if (chart2Loaded === true && chart3Loaded === true) {
          setIsLoading(false);
        }
      } else if (chart1){
        if (chart1Loaded === true) {
          setIsLoading(false);
        }
      } else if (chart2){
        if (chart2Loaded === true) {
          setIsLoading(false);
        }
      } else if(chart3){
        if (chart3Loaded === true) {
          setIsLoading(false);
        }
      } 

    }, [chart1Loaded, chart2Loaded, chart3Loaded])

  const chartLoaded = (id) => {
    // console.log(`CHART ${id} LOADED :)`)
    if(id === 1){
      setChart1Loaded(true)
    } else if (id === 2){
      setChart2Loaded(true)
    } else if (id === 3){
      setChart3Loaded(true)
    }
  }

  const loadChartData = async (id, startTime, endTime) => {

    if(props.propertyDetails){
      // console.time(`${props.chartURL}`);
      const token = (await Auth.currentSession()).getIdToken()
      const jwt = token.getJwtToken()
      const init = {
        headers: { Authorization: `Bearer ${jwt}` },
      }

      const response = await API.get('FrontendAPI-analytics', `/${props.chartURL}?uuid=${props.propertyDetails.id}&startTime=${startTime}&endTime=${endTime}&offset=${props.offset}`, init)
      // console.log("response", response)
      if(response === undefined){
        setIsLoading(false)
      } else {

        if (response.success) {

          switch (id) {
            case 0:
              // console.log("SINGLE TEMP/PRESS/CURRENT API CALL RETURNED DATA")
              setData(response.data)
              if(Object.keys(response.data).length > 1){
                setIsOpen(true)
              } else {
                setIsLoading(false)
              }

              // packageSize(response.data, props.chartURL) // dev
              break;
            case 1:
              setChunk1(response.data)
              // console.log("CHUNK 1 RETURNED DATA")
              break;
            case 2:
              // console.log("CHUNK 2 RETURNED DATA")
              setChunk2(response.data)
              break;
            case 3:
              // console.log("CHUNK 3 RETURNED DATA")
              setChunk3(response.data)
              break;
            case 4:
              // console.log("CHUNK 4 RETURNED DATA")
              setChunk4(response.data)
              break;
          }
        } else {
          console.log("Failed to load chart data")
          setIsLoading(false)
        }
      }
    }
  }

  const loadStateData = async (id, startTime, endTime) => {
    if(props.propertyDetails){
      // console.time(`${props.stateURL}`);
      const token = (await Auth.currentSession()).getIdToken()
      const jwt = token.getJwtToken()
      const init = {
        headers: { Authorization: `Bearer ${jwt}`},
      }
      const response = await API.get('FrontendAPI-analytics', `/${props.stateURL}?uuid=${props.propertyDetails.id}&startTime=${startTime}&endTime=${endTime}&offset=${props.offset}`, init)

      if (response.success) {
        setStateData(response.data) // MAIN DATA LOAD HERE
        if (response.success) {
          switch (id) {
            case 0:
              // console.log("SINGLE STATE API CALL RETURNED DATA")
              setStateData(response.data)
              // packageSize(response.data, props.chartURL) // dev
              break;
            case 1:
              setStateChunk1(response.data)
              // console.log("STATE CHUNK 1 RETURNED DATA")
              break;
            case 2:
              // console.log("STATE CHUNK 2 RETURNED DATA")
              setStateChunk2(response.data)
              break;
            case 3:
              // console.log("STATE CHUNK 3 RETURNED DATA")
              setStateChunk3(response.data)
              break;
            case 4:
              // console.log("STATE CHUNK 4 RETURNED DATA")
              setStateChunk4(response.data)
              break;
          }
        } else {
          console.log("Failed to load state chart data")
          setIsLoading(false)
        }
      }
    }
  }

  const loadAnalyticData = async () => {
    if(props.propertyDetails){
      const token = (await Auth.currentSession()).getIdToken()
      const jwt = token.getJwtToken()
      const init = {
        headers: { Authorization: `Bearer ${jwt}` },
      }      
      const response = await API.get('FrontendAPI-analytics', `/${props.analyticURL}?uuid=${props.propertyDetails.id}&startTime=${props.startTime}&endTime=${props.endTime}&offset=${props.offset}`, init)
      
      if (response.success) {
        setAnalyticData(response.data)
      }
    }
  }

  const packageSize = (data, url) => {

    const fileSize = data
    const jsonString = JSON.stringify(fileSize);
    const bytes = Buffer.from(jsonString).length;
    const kilobytes = bytes / 1024;
    const megabytes = kilobytes / 1024;

    let points = Object.values(data)

    if(points.length > 0){
      // console.log(`<---------- TIME TO LOAD  ------>`)
      // console.timeEnd(`${url}`)

      var keyCount = Object.keys(data).length

      // console.log("Points per label", points[0].length, ' x ', keyCount, 'labels = ', (points[0].length * keyCount), "total" )
      // console.log(`Package size is: ${megabytes.toFixed(2)} MB`);
      // console.log(`Package has ${keyCount} labels`)
      // console.log("data", data)
    }

  }

  return (
    // <OuterContainer style={{display: props.show === true ? 'block' : "none"}}>
    <OuterContainer>
      <Card>
      <Container>
        <LeftContainer>
            { isLoading
              ? <SpinnerContainer style={{justifyContent: "flex-start", marginTop: -1, position: 'relative'}} ><StyledRelativeSpinner style={{ width: "26px", height: "26px" }}  animation='border' /></SpinnerContainer>
              : null
               }
        </LeftContainer>  
      </Container>
      {

      // <TimeSeriesAMChartsUI  data={data} stateData={stateData} analyticData={analyticData} {...props}/>    

        isOpen
        ? <TimeSeriesAMChartsUI chartLoaded={chartLoaded} data={data} stateData={stateData} analyticData={analyticData} {...props}/>
        : !isLoading 
        ? <NoDataText>No Data</NoDataText>
        : null
      }

      </Card>
    </OuterContainer>
  )
}

// key={props.refresh === false ? 0 : 1} 

// { isFirstLoad || data === undefined
//   ? <SpinnerContainer style={{marginLeft: 20}}><StyledSpinner animation='border' /></SpinnerContainer>
//   : Object.keys(data).length === 0 
//     ? null
//     : null
// }

const NoDataText = styled.p`
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 130%;
  color: ${COLORS.HighlightLight};
  margin-left: 10px;
  padding-bottom: 10px;
`;

const OuterContainer = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  width: 100%;
  margin-top: -16px;
  ${media.lessThan("743px")`
  justify-content: center;
  `}
  `;

const Card = styled.div`
   /* border: 1px red dashed; */
   display: flex;
   width: 100%;
    max-width: 1440px;
   flex-direction: row;
   justify-content: space-between;
   margin-right: 20px;
   margin-bottom: 15px;
   background: #0e1c36;
   border-radius: 10px;
   min-height: 68px;
   /* padding: 20px; */
   flex-wrap: wrap;
   ${media.lessThan("743px")`
      /* width: 90%; */
      align-self: center;
      margin-right: 0px;
    `}
   ${media.between("744px", "large")`
     `}
     ${media.greaterThan("large")`
     `}
 `

const Container = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
`;

const HeaderText = styled.h2`
  /* border: 1px red dashed; */
  font-family: Blinker;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  /* line-height: 84.5%; */
  letter-spacing: -0.01em;
  color: ${COLORS.White};
`

const LeftContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: row;
  height: 30px;
`;

const RightContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: row;
  /* height: 100px; */
`;

const ArrowIcon = styled.img`
  width: 24px;
  height: 24px;
  align-self: center;
`