import React from 'react'
import styled from "styled-components"
import media from "styled-media-query"
import { COLORS } from "./../../../styles/colors"
import {FormatLabel} from "./../../util/FormatLabel"

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: #17253E;
  border-radius: 4px;
  max-width: 457px;
  padding-top: 21px;
  padding-bottom: 27px;
  padding-left: 31px;
  padding-right: 31px;
  margin-right: 18px;
  margin-bottom: 18px;
`;

const CardHeader = styled.h3`
  font-family: 'Blinker';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 110%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #FFFFFF;
`;


const Container = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1440px;
  ${media.lessThan("743px")`
    `}
  `

const HeaderContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  ${media.lessThan("743px")`
    `}
`

const ColumnContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: column;
  border-left: 2px solid #1A2B4A;
  padding-left: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
  ${media.lessThan("743px")`
  border-left: none;
  padding-left: 0px;
  `}
`

const RowContainer = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  flex-direction: row;
  ${media.lessThan("743px")`
  `}
`

const TextContainer = styled.div`
    /* border: 1px blue dashed; */
    display: flex;
    flex-direction: column;
    min-width: 116px;
    ${media.lessThan("743px")`
    `}
`

const TextContainer2 = styled.div`
    /* border: 1px blue dashed; */
    display: flex;
    flex-direction: column;
    min-width: 66px;
    ${media.lessThan("743px")`
    `}
`

const Title = styled.h3`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 129.5%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color: ${COLORS.HighlightMedium};
`

const Title2 = styled.h3`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 129.5%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color: ${COLORS.HighlightMedium};
`

const InfoText = styled.h2`
  font-family: 'Blinker';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 110%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
`

const InfoText2 = styled.h2`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: ${COLORS.White};
`

const HhmmText = styled.h2`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 100;
  font-size: 14px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: ${COLORS.White};
`

const Line = styled.div`
  border: 1px solid #1A2B4A;
`;

const CycleHeader = styled.h3`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 129.5%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color: ${COLORS.White};
  margin-bottom: 6px;
`;


export default function Analytics(props) {

    const {analytics} = props

    const convertToTime = (milliseconds) => {
        const seconds = milliseconds / 1000
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;
        const roundedSeconds = remainingSeconds.toFixed(0)
        
        if(hours === 0 && minutes === 0 && roundedSeconds != 0){
          return `>${hours.toString().padStart(2, '0') + ':'}${minutes.toString().padStart(2, '0')}`
        } else {
          return`${hours.toString().padStart(2, '0') + ':'}${minutes.toString().padStart(2, '0')}`
        }

    };

  return (
    <Card>
      <HeaderContainer>
        <CardHeader>{FormatLabel(analytics.label)}</CardHeader>
        <HhmmText>hh:mm</HhmmText>
      </HeaderContainer>
      {/* Cycle Count Row */}
      <Container>

          <TextContainer style={{marginTop: 10}}>
            <Title>Cycle Count</Title>
            <InfoText>{analytics.cycle_count}</InfoText>
          </TextContainer>

          <ColumnContainer>
            <CycleHeader>Cycle On Time</CycleHeader>
            <RowContainer>
              <TextContainer2>
                <Title2>Min</Title2>
                <InfoText2>{convertToTime(analytics.min_cycle_on_time)}</InfoText2>
              </TextContainer2>
              <TextContainer2>
                <Title2>Avg</Title2>
                <InfoText2>{convertToTime(analytics.average_cycle_on_time)}</InfoText2>
              </TextContainer2>
              <TextContainer2>
                <Title2>Max</Title2>
                <InfoText2>{convertToTime(analytics.max_cycle_on_time)}</InfoText2>
              </TextContainer2>
              <TextContainer2>
                <Title2>Total</Title2>
                <InfoText2>{convertToTime(analytics.total_cycle_on_time)}</InfoText2>
              </TextContainer2>
            </RowContainer>
          </ColumnContainer>

        </Container>

        <Line />
      {/* Avg. Cycle Time Row */}
        <Container>

          <TextContainer style={{marginTop: 10}}>
            <Title>Avg. Cycle Time</Title>
            <InfoText>{convertToTime(analytics.average_cycle_time)}</InfoText>
          </TextContainer>

          <ColumnContainer>
            <CycleHeader>Cycle Off Time</CycleHeader>
            <RowContainer>
              <TextContainer2>
                <Title2>Min</Title2>
                <InfoText2>{convertToTime(analytics.min_cycle_off_time)}</InfoText2>
              </TextContainer2>
              <TextContainer2>
                <Title2>Avg</Title2>
                <InfoText2>{convertToTime(analytics.average_cycle_off_time)}</InfoText2>
              </TextContainer2>
              <TextContainer2>
                <Title2>Max</Title2>
                <InfoText2>{convertToTime(analytics.max_cycle_off_time)}</InfoText2>
              </TextContainer2>
              <TextContainer2>
                <Title2>Total</Title2>
                <InfoText2>{convertToTime(analytics.total_cycle_off_time)}</InfoText2>
              </TextContainer2>
            </RowContainer>
          </ColumnContainer>

        </Container>

    </Card>
  )

}
