import React, { useEffect, useState, useContext } from "react"
import { API, Auth } from "aws-amplify"
import { Link, useHistory } from "react-router-dom"
import styled from "styled-components"
import media from "styled-media-query"
import { DarkButtonSpinner } from "./../custom/Spinner"
import { useMediaQuery } from 'react-responsive'
import { COLORS } from "../../styles/colors"

const Container = styled.div`
  /* border: 1px yellow dashed; */
  max-width: 1260px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  padding-top: 18px;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const StatusCard = styled.div`
  /* border: 1px yellow dashed; */
  width: 90%;
  max-width: 744px;
  height: auto;
  display: flex;
  flex-direction: column;
  background: #0e1c36;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 35px 35px;
  margin-right: 30px;
  ${media.lessThan("743px")`
        padding: 20px 20px;
        width: 95%;
        margin-right: 0px;
        align-self: center;
      background-color: transparent;

    `}
  ${media.between("744px", "large")`
        width: 95%;
        padding: 25px 25px;
    `}
    ${media.greaterThan("large")`
    `}
`

const HeaderText = styled.h1`
  font-family: Blinker;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 110%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
  margin-bottom: 25px;
`

const Line = styled.div`
  width: auto;
  height: 0.69px;
  background: ${COLORS.HighlightDark};
  margin-top: 30px;
  margin-bottom: 30px;
`

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${media.lessThan("743px")`
    `}
`

const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  align-self: flex-end;
  width: 189px;
  height: 40px;
  border: none;
  background: #63c085;
  cursor: pointer;
  border-radius: 36px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.05em;
  margin-bottom: 10px;
  margin-top: 0px;
  transition-duration: 0.5s;
  :hover {
    opacity: 0.8;
  }
  ${media.lessThan("743px")`
      align-self: flex-start;
      height: 55px;
      font-size: 15px;
    `}
  ${media.between("744px", "large")`
      height: 55px;
      font-size: 15px;
    `}
    ${media.greaterThan("large")`
    `}
`

const ButtonText = styled.p`
  /* border: 1px red dashed; */
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: black;
  ${media.lessThan("743px")`
        font-size: 15px;
    `}
  ${media.between("744px", "large")`
        font-size: 15px;
    `}
    ${media.greaterThan("large")`
    `}
`

const WingnutDetails = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-bottom: 29px;
  ${media.lessThan("743px")`
    width: 100%;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const Title = styled.h3`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 129.5%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color: ${COLORS.HighlightMedium};
  margin-top: 10px;
`

const InfoText = styled.h2`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: ${COLORS.White};
  margin-top: 2px;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  ${media.lessThan("743px")`
  align-self: flex-start;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`;

const BtnConfirm = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 214px;
  height: 40px;
  border: 1px solid #63c085;
  border-radius: 30px;
  background: #63c085;
  cursor: pointer;
  margin-top: 11px;
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: black;
  transition-duration: 0.5s;
  :hover {
    opacity: 0.8;
  }
  ${media.lessThan("743px")`
    align-self: center;
        width: 300px;
        height: 55px;
        font-size: 15px;
    `}
  ${media.between("744px", "large")`
        height: 55px;
        font-size: 15px;
        margin-right: 20px;
    `}
    ${media.greaterThan("large")`
        margin-right: 20px;
    `}
`


const BtnEdit = styled(BtnConfirm)`
  background: transparent;
  :hover {
    background: ${COLORS.Secondary};
    opacity: 1;
    border: 1px solid #63c085;
  }
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`


export default function AddPropertyConfirm(props) {
  const {form} = props
  
  const history = useHistory()
  const [isHovering, setIsHovering] = useState(false)
  const [isEditHovering, setIsEditHovering] = useState(false)

  const isDesktopSize = useMediaQuery({ query: '(min-width: 1170px)' })

  return (
    <Container>
      <StatusCard>
        <DetailsContainer>
          <WingnutDetails>
            <HeaderText>Confirm New Property Details</HeaderText>
            <Title>Property Name</Title>
            <InfoText>{form.propertyName}</InfoText>
            <Title>Property ID</Title>
            <InfoText>{form.externalId}</InfoText>
            <Title>Portfolio</Title>
            <InfoText>{form.portfolio}</InfoText>
            <Title>Address</Title>
            <InfoText>{`${form.street} ${form.city} ${form.provinceState} ${form.country} ${form.postalZipCode}`}</InfoText>
          </WingnutDetails>


          <ButtonContainer style={{flexDirection : props.linkView ? isDesktopSize ? 'row' : 'column' : null}}>

              <BtnConfirm disabled={props.disabled}  onClick={props.confirm}>
                  <ButtonText style={{marginLeft: props.disabled ? "22px" : null}}>CONFIRM NEW PROPERTY</ButtonText>
                  {props.showSpinner && <DarkButtonSpinner animation='border' />}
              </BtnConfirm>

            <BtnEdit onClick={(e) => {props.backToEdit()}}>
                  <ButtonText style={{color: COLORS.White}}>EDIT DETAILS</ButtonText>
            </BtnEdit>
          </ButtonContainer>
        </DetailsContainer>
      </StatusCard>
    </Container>
  )
}
