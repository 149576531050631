import React, { useState } from "react"
import RemoveWingnutPopup from "./../popup/RemoveWingnutPopup"
import RemoveWingnutPopupSuccess from "./../popup/RemoveWingnutSuccessPopup"
import LeaveChangesSavedPopup from "./../popup/LeaveChangesSavedPopup"
import LeavePagePopup from "./../popup/LeavePagePopup"
import SaveConfigPopup from "./../popup/SaveConfigPopup"
import SaveOrAbandonPopup from "./../popup/SaveOrAbandonPopup"
import SaveConfigErrorPopup from "./../popup/SaveConfigErrorPopup"
import DiscardConfigPopup from "./../popup/DiscardConfigPopup"
import DeletePropertyPopup from "./DeletePropertyPopup"
import DeletePropertySuccessPopup from "./DeletePropertyPopupSuccess"
import UnlinkPropertyPopup from "./UnlinkPropertyPopup"
import UnlinkPropertySuccessPopup from "./UnlinkPropertySuccessPopup"

export default function PopupContainer(props) {
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)

  const setSuccess = () => {
    setIsSuccess(true)
  }

  const setError = () => {
    setIsError(true)
  }

  switch (props.popupNumber) {
    case 0:
      return isSuccess ? (
        <RemoveWingnutPopupSuccess closePopup={props.closePopup} />
      ) : (
        <RemoveWingnutPopup wingnutUUID={props.uuid} setSuccess={setSuccess} closePopup={props.closePopup} />
      )
    case 1:
      return isSuccess ? (
        <UnlinkPropertySuccessPopup closePopup={props.closePopup} />
      ) : (
        <UnlinkPropertyPopup wingnutUUID={props.uuid} setSuccess={setSuccess} closePopup={props.closePopup} />
      )
    case 2:
      return isSuccess ? (
        <DeletePropertySuccessPopup closePopup={props.closePopup} />
      ) : (
        <DeletePropertyPopup propertyUUID={props.uuid} setSuccess={setSuccess} closePopup={props.closePopup} />
      )
    case 3:
      return isSuccess ? (
        <LeaveChangesSavedPopup goToNextTab={props.goToNextTab} closePopup={props.closePopup}  />
      ) : (
        <LeavePagePopup goToNextTab={props.goToNextTab}  closePopup={props.closePopup} setSuccess={setSuccess} saveAll={props.saveAll} alertArray={props.alertArray} discardChanges={props.discardChanges}/>
      )
      case 4:
        return isError ? (
          <SaveConfigErrorPopup closePopup={props.closePopup}/>
        ) : (
          <SaveConfigPopup closePopup={props.closePopup} saveAll={props.saveAll} alertArray={props.alertArray} setError={setError} showSpinner={props.showSpinner} saveDisabled={props.saveDisabled}/>
        )
      case 5:
        return  (
          <DiscardConfigPopup closePopup={props.closePopup} discardAll={props.discardAll} showSpinner={props.showSpinner} saveDisabled={props.saveDisabled}/>
        )
      case 6:
        return  (
          <SaveConfigErrorPopup closePopup={props.closePopup}/>
        )
      case 7:
        return isError ? (
            <SaveConfigErrorPopup closePopup={props.closePopup}/>
        ) : (
            <SaveOrAbandonPopup closePopup={props.closePopup} saveAll={props.saveAll} alertArray={props.alertArray} setError={setError} showSpinner={props.showSpinner} saveDisabled={props.saveDisabled} discardAll={props.discardAll} />
        )
    default:
      return null
  }
}

