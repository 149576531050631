import React, { useEffect, useState, useContext } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import { COLORS } from "../../styles/colors"
import ImgSearch from "./../../images/ic_search.svg"
import WingnutStatus from "./WingnutStatus.enum"
import WingnutStatusCard from "./WingnutStatusCard"

const Container = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  background-color: ${COLORS.Base};
  height: auto;
  margin-bottom: 10px;
  justify-content: space-between;
  ${media.lessThan("743px")`
      max-height: 840px;
        align-items: center;
        margin-bottom: 0px;
        padding-top: 23px;
        margin-right: 0px;
        justify-content: center;
        flex-direction: column-reverse;
    `}
  ${media.between("744px", "large")`
    flex-direction: column-reverse;
        height: auto;
    `}
    ${media.greaterThan("large")`
    `}
`

const SearchContainer = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: auto;
  max-width: 1500px;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
        flex-direction: row;
        width: 90%;
        justify-content: flex-start;
    `}
    ${media.greaterThan("large")`
    `}
`

const SearchBarContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  align-self: center;
    width: 100%;
`
const SearchContainerTop = styled.div`
  /* border: 1px blue dashed; */
  display: flex;
  flex-direction: column;
  width: 400px;
  ${media.lessThan("743px")`
  margin-top: 10px;
  align-content: center;
  max-width: 400px;
  width: 100%;
  `}
  ${media.between("744px", "large")`
      margin-top: 10px;
      width: 90%;
      max-width: 400px;
    `}
    ${media.greaterThan("large")`
    `}
`
const SearchBar = styled.input`
  /* border: 1px blue dashed; */
  width: 100%;
  height: 50px;
  /* min-width: 300px; */
  /* max-width: 500px; */
  outline: none;
  border: 1px solid ${COLORS.White};
  color: ${COLORS.White};
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 129.5%;
  font-weight: normal;
  background-color: ${COLORS.Primary};
  border-radius: 8px;
  box-sizing: border-box;
  padding-left: 10px;
  ::placeholder {
    color: ${COLORS.HighlightMedium};
  }
  :focus {
    outline: ${COLORS.White};
    border: 2px solid ${COLORS.White};
  }
  ${media.lessThan("743px")`
        width: 100%;
    `}
  ${media.between("744px", "large")`
        width: 100%;
    `}
    ${media.greaterThan("large")`
    `}
`

const SearchIcon = styled.img`
  /* border: 1px red dashed; */
  height: 20px;
  width: 20px;
  transition-duration: 0.5s;
  color: ${COLORS.White};
  margin-left: -40px;
  margin-top: 15px;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const StatusContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: row;
  max-width: 800px;
  margin-bottom: 10px;
  ${media.lessThan("743px")`
        flex-direction: column;
        width: auto;
        min-width: 360px;
        margin-bottom: 0px;
    `}
  ${media.between("744px", "large")`
  width: 90%;
    `}
    ${media.greaterThan("large")`
    `}
`

const StatusPair = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  ${media.lessThan("743px")`
        flex-direction: column;
        width: auto;
        align-content: center;
    `}
  ${media.between("744px", "large")`
  margin-right: 8.5px;
  flex-direction: column;
    `}
    ${media.greaterThan("large")`
    margin-right: 8.5px;
    `}
`

const StatusPairRight = styled(StatusPair)`
  ${media.between("744px", "large")`
  margin-left: 8.5px;
    `}
    ${media.greaterThan("large")`
    margin-left: 8.5px;
    `}

`;

const CardHeader = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 129.5%;
  letter-spacing: 0.03em;
  color: ${COLORS.HighlightMedium};
`


const ResultContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: column;
  ${media.lessThan("743px")`
        margin-top: 16px;
        margin-bottom: 16px;
        `}
  ${media.between("744px", "large")`
        margin-top: 20px;
        margin-left: 20px;
        `}
        ${media.greaterThan("large")`
        `}
`

const SearchText = styled(CardHeader)`
  font-size: 14px;
  padding-top: 10px;
  ${media.lessThan("743px")`
            font-size: 12px;
            text-align: center;
        `}
  ${media.between("744px", "large")`
            font-size: 12px;
        `}
        ${media.greaterThan("large")`
        `}
`

const ResultHeader = styled(CardHeader)`
  font-size: 14px;
  ${media.lessThan("743px")`
            align-self: center;
        `}
  ${media.between("744px", "large")`
        `}
        ${media.greaterThan("large")`
        `}
`

const ResultNumber = styled(CardHeader)`
  font-family: Blinker;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 110%;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
  ${media.lessThan("743px")`
            align-self: center;
        `}
  ${media.between("744px", "large")`
        `}
        ${media.greaterThan("large")`
        `}
`


export default function WingnutController(props) {
  const countStatus = (status) => {
    var total = 0
    props.tableData.forEach((data) => {
      if (data.status.live && status === WingnutStatus.Live) {
        total += 1
      }
      if (data.status.disconnected && status === WingnutStatus.Disconnected) {
        total += 1
      }
      if (data.status.unlinked && status === WingnutStatus.Unlinked) {
        total += 1
      }
      if (data.status.unconfigured && status === WingnutStatus.Unconfigured) {
        total += 1
      }
    })
    return total
  }

  return (
    <Container>
      <SearchContainer>
        <SearchContainerTop>
          <SearchBarContainer>
            <SearchBar placeholder='Search Wingnuts' onChange={props.onInputChange} />
            <SearchIcon src={ImgSearch} />
          </SearchBarContainer>
          <SearchText>Search by city name, wingnut serial number, property name, etc.</SearchText>
        </SearchContainerTop>
        <ResultContainer>
          <ResultHeader>Showing</ResultHeader>
          <ResultNumber>{props.tableData.length} Results</ResultNumber>
        </ResultContainer>
      </SearchContainer>
      <StatusContainer>
        <StatusPair>
          <WingnutStatusCard
            wingnutCount={countStatus(WingnutStatus.Live)}
            status={WingnutStatus.Live.value}
            toggleStatus={props.toggleStatus}
            wingnutState={props.wingnutState}
          />
          <WingnutStatusCard
            wingnutCount={countStatus(WingnutStatus.Unconfigured)}
            status={WingnutStatus.Unconfigured.value}
            toggleStatus={props.toggleStatus}
            wingnutState={props.wingnutState}
          />
        </StatusPair>
        <StatusPairRight>
          <WingnutStatusCard
            wingnutCount={countStatus(WingnutStatus.Disconnected)}
            status={WingnutStatus.Disconnected.value}
            toggleStatus={props.toggleStatus}
            wingnutState={props.wingnutState}
          />
          <WingnutStatusCard
            wingnutCount={countStatus(WingnutStatus.Unlinked)}
            status={WingnutStatus.Unlinked.value}
            toggleStatus={props.toggleStatus}
            wingnutState={props.wingnutState}
          />
        </StatusPairRight>
      </StatusContainer>
    </Container>
  )
}
