import React, {useState, useEffect, useContext} from 'react'
import styled from "styled-components"
import media from "styled-media-query"
import ToggleTriState from './ToggleTriState'
import Icon from "./../../images/ic_alert.svg";
import { AccountContext } from "../../auth/Account"
import {FormatLabel} from "./../util/FormatLabel"
import SensorCellAlertError from './SensorCellAlertError'

import {
  EmptyError,
  Error,
} from "./../custom/ValidatorStyles"
import { COLORS } from '../../styles/colors'

// Notification I/O Cell

export default function SensorCell(props) {

  // const [sensor, setSensor] = useState(props.sensor)
  const [minError, setMinError] = useState(false)
  const [maxError, setMaxError] = useState(false)
  const [minRangeError, setMinRangeError] = useState(false)
  const [maxRangeError, setMaxRangeError] = useState(false)

  const [userMinValue, setUserMinValue] = useState(null)
  const [userMaxValue, setUserMaxValue] = useState(null)
  const [userToggle, setUserToggle] = useState(null)
  const [disableClear, setDisableClear] = useState(true)
  const [isButtonHovering, setButtonHovering] = useState(false)

  const { getSession } = useContext(AccountContext)


  const handleMinValueChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, '');

    setDisableClear()
    props.configChanged(true)
    if(value.length < 4){
      if(value.length > 0){
        if(userMaxValue !== null && value >= userMaxValue){
          setMinError(true)
          props.setError(true, props.sensor.label, "min")
        } 
        else {
          setMinError(false)
          props.setError(false, props.sensor.label, "min")
        }
      } else {
          // Reset to blank
          setUserMinValue(null);
          setMinError(false)
          props.setError(false, props.sensor.label, "min")
      }
      setUserMinValue(value ? value : null);
    }
  }

  const handleMaxValueChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, '');  // filter letters out

    setDisableClear()
    props.configChanged(true)
    if(value.length < 4){
      if(value.length > 0){
        if(userMinValue !== null && value <= userMinValue){
          setMaxError(true)
          props.setError(true, props.sensor.label, "max")
        }
        else {
          setMaxError(false)
          props.setError(false, props.sensor.label, "max")
        }
      } else {
          // Reset to blank
          setUserMaxValue(null);
          setMaxError(false)
          props.setError(false, props.sensor.label, "max")
      }
      setUserMaxValue(value ? value : null);
    }
  };

  const updateToggle = (toggle) => {
    props.configChanged(true)
    setUserToggle(toggle)
    setDisableClear()
  }

  const clearNotification = () => {
    setUserToggle(null)
    setUserMinValue(null)
    setUserMaxValue(null)
    setMinError(false)
    setMaxError(false)
    setDisableClear(true)
    props.configChanged(true)
    props.updateSensor(props.sensor.label, null, null, null)
  }

  useEffect(() => {
    if(props.sensor){

      // ThresholdStatus is null for default values - if it's set then it's a User Notification
        if(props.sensor.thresholdStatus !== null){
          setUserMinValue(props.sensor.userMinValue)
          setUserMaxValue(props.sensor.userMaxValue)
          setUserToggle(props.sensor.thresholdStatus)
          setDisableClear(false)
        } 
    }
  }, [props.sensor])

  const updateSensor = () => {
        if(props.sensor){
          if(userToggle !== null && userMinValue === null && userMaxValue === null){
            setUserMinValue(props.sensor.minValue)
            setUserMaxValue(props.sensor.maxValue)
            props.updateSensor(props.sensor.label, props.sensor.minValue, props.sensor.maxValue, userToggle)
          } else if(userToggle === null && userMinValue !== null && userMaxValue === null){
            setUserToggle(0)
            setUserMaxValue(props.sensor.maxValue)
            props.updateSensor(props.sensor.label, userMinValue, props.sensor.maxValue, 0)
          } else if(userToggle === null && userMinValue === null && userMaxValue !== null){
            setUserToggle(0)
            setUserMinValue(props.sensor.minValue)
            props.updateSensor(props.sensor.label, props.sensor.minValue, userMaxValue, 0)
          } else {
            props.updateSensor(props.sensor.label, userMinValue, userMaxValue, userToggle)
          }
        }
  }

  useEffect(() => {
    if(userToggle !== null || userMinValue !== null || userMaxValue !== null){
      updateSensor()
    }
  }, [userToggle, userMinValue, userMaxValue])

  return (

    <Container id={`${props.sensor && props.sensor.label ? props.sensor.label : ""}`} style={{border: props.inAlert ? `1px solid ${COLORS.ErrorOrange}` : null}}>
        {/* <Line style={{marginTop: 44}}/> */}
        <ModeText style={{display: process.env.REACT_APP_API_DOMAIN !== "prod" ? "block" : "none"}}>{`- ${props.breachCount} Breaches found in last 10 minutes -`}</ModeText>
        <HeaderContainer>
          <ToggleContainer>
            <ToggleTriState toggle={userToggle ? userToggle : 0} showLabel={true} updateToggle={updateToggle} disable={ props.isAuth ? props.disable : 0}/>
          </ToggleContainer>
          <SectionHeader>{FormatLabel(props.sensor && props.sensor.label ? props.sensor.label : "")}</SectionHeader>
         
         {/* FOR DEVELOPMENT TESTING */}

        </HeaderContainer>
        <SearchContainer>
            <TopContainer>
              <SearchContainerTop>
                    <SearchTitle>{`Min ${props.sensor.unit ? props.sensor.unit : "Value"}`}</SearchTitle>
                    <SearchBarContainer>
                        <SearchBar disabled={props.isAuth ? !props.disable : true} placeholder={props.sensor.min} value={userMinValue ? userMinValue : userMinValue === 0 ? 0 : ""} onChange={handleMinValueChange} style={{ border: minError ? "1px solid #D25353" : null, border: userToggle ? `1px solid ${COLORS.HighlightLight}` : null }}/>
                    </SearchBarContainer>
                    { props.inAlert 
                      ? <SensorCellAlertError alertValue={props.alertValue} sensor={props.sensor}/>
                      : minError 
                        ? <Error>{`Max value must be lower than min value`}</Error> 
                        : <EmptyError />
                      }
              </SearchContainerTop>

              <SearchContainerTop>
              <SearchTitle>{`Max ${props.sensor.unit ? props.sensor.unit : "Value"}`}</SearchTitle>
                    <SearchBarContainer>
                      <SearchBar disabled={props.isAuth ? !props.disable : true}  placeholder={props.sensor.max}  value={userMaxValue ? userMaxValue : ""} onChange={handleMaxValueChange} style={{ border: maxError ? "1px solid #D25353" : null, border: userToggle ? `1px solid ${COLORS.HighlightLight}` : null}}/>
                    </SearchBarContainer>   
                      { props.inAlert 
                      ? <EmptyError/>
                      : maxError 
                        ? <Error>{`Max value must be higher than min value`}</Error> 
                        : <EmptyError />
                      }
              </SearchContainerTop>
            </TopContainer>
            <IconAlert src={Icon} style={{display: props.inAlert ? "block" : "none"}} />
        </SearchContainer>
        <BtnConfirm 
          onClick={() => clearNotification()} 
          disabled={props.isAuth ? !props.disable : disableClear}
          onMouseEnter={(e) => {
            setButtonHovering(true)
          }}
          onMouseLeave={(e) => {
            setButtonHovering(false)
          }}
          style={{display: props.isAuth ? "block" : "none", opacity: disableClear ? '0.5' : null, border: disableClear ? '1px solid #B6B6B6' : null, background: isButtonHovering ? COLORS.Secondary : null, pointerEvents: props.disable? "auto" : "none"}}>
          <ButtonText  style={{color: disableClear ? COLORS.White : null, opacity: disableClear ? '0.5' : null }}>
            SET DEFAULTS
          </ButtonText>
        </BtnConfirm>
    </Container>
  )
}

const ModeText = styled.h2`
  font-family: Blinker;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 84.5%;
  letter-spacing: -0.01em;
  color: ${COLORS.ErrorOrange};
  padding-top: 10px;
  padding-bottom: 10px;
  ${media.lessThan("743px")`
        align-self: center;
        max-width: 95%;
        text-wrap: wrap;
        text-align: center;

    `}
`

const Container = styled.div`
  border: 1px solid ${COLORS.HighlightMedium};
  border-radius: 10px;
  padding: 30px;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`

const HeaderContainer = styled.div`
  /* border: 1px dashed red; */
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  /* flex-wrap: wrap; */
  align-items: center;
  /* align-content: flex-start; */
`

const SearchContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: auto;
  /* max-width: 1500px; */
  ${media.lessThan("743px")`
        flex-direction: column;
    `}
  ${media.between("744px", "large")`
        flex-direction: row;
        /* width: 90%; */
        justify-content: flex-start;
    `}
    ${media.greaterThan("large")`
    `}
`

const SearchContainerTop = styled.div`
  /* border: 1px blue dashed; */
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 90%;
  ${media.lessThan("743px")`
    margin-top: 10px;
    align-content: center;
    max-width: 400px;
  width: 100%;
    `}
  ${media.between("744px", "large")`
        /* margin-top: 10px; */
        width: 50%;
        max-width: 400px;
    `}
    ${media.greaterThan("large")`
    `}
`;

// wraps search bar/icon and instruction
const SearchBarContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  align-self: center;
  width: 100%;
`
// wraps search bar and search icon


const SearchBar = styled.input`
  /* border: 1px blue dashed; */
  width: 377px;
  height: 45px;
  outline: none;
  color: ${COLORS.White};
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 129.5%;
  font-weight: normal;
  background-color: ${COLORS.Secondary};
  border-radius: 8px;
  box-sizing: border-box;
  padding-left: 10px;
  border: 1px solid transparent;
  ::placeholder {
    color: ${COLORS.HighlightMedium};
  }
  :focus {
    border: 1px solid ${COLORS.White};
  }
  ${media.lessThan("743px")`
        width: 100%;
    `}
  ${media.between("744px", "large")`
        width: 95%;
    `}
    ${media.greaterThan("large")`
    `}
`

const SectionHeader = styled.h2`
    font-family: 'Blinker';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 110%;
    display: flex;
    align-items: center;
    letter-spacing: -0.01em;
    color: #FFFFFF;
    margin-top: 10px;
    margin-right: 30px;
    margin-left: 10px;
`;

const SearchTitle = styled.h3`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 129.5%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color: ${COLORS.HighlightLight};
  /* margin-top: 10px; */
`

const ToggleContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  align-self: center;
  margin-top: 10px;
`;

const BtnConfirm = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 40px;
  border: 1px solid #63c085;
  border-radius: 30px;
  /* color: black; */
  background: transparent;
  cursor: pointer;
  text-align: center;
  margin-top: 12px;
  :hover {
    /* background: ${COLORS.Secondary}; */
  }
  ${media.lessThan("743px")`
    margin-bottom: 5px;
    `}
  ${media.between("744px", "large")`
    /* margin-bottom: 50px; */
    `}
    ${media.greaterThan("large")`
    `}
`

const ButtonText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transition-duration: 0.5s;
  color: ${COLORS.White};
  :hover {
  }
`

const TopContainer = styled.div`
    /* border: 1px yellow dashed; */
    display: flex;
    flex-direction: row;
    width: 100%;

    flex-wrap: wrap;
    ${media.lessThan("743px")`
        min-width: 250px;
    `}
    ${media.between("744px", "large")`
    `}
`;

const IconAlert = styled.img`
  /* margin-right: 23px; */
  width: 23px;
  height: 23px;
  align-self: center;
  margin-top: 8px;
  transition-duration: 0.5s;
  ${media.lessThan("743px")`
    display: none;
    `}
`

//------Remove

const IconAlertSm = styled(IconAlert)`
  width: 11px;
  height: 11px;
  margin-bottom: 4px;
  margin-right: 6px;
  margin-top: 0px;
  ${media.lessThan("743px")`
    display: flex;
    `}
`;

const ErrorContainer = styled.div`
    /* border: 1px yellow dashed; */
    display: flex;
    flex-direction: row;
    /* width: 100%; */

    /* flex-wrap: wrap; */
    ${media.lessThan("743px")`
        min-width: 250px;
    `}
    ${media.between("744px", "large")`
    `}
`;