import React, { useState } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import media from "styled-media-query"
import AddWingnutForm from "../form/AddWingnutForm"
import Image2 from "./../../images/ic_check_circle.svg"
import Image3 from "./../../images/ic_arrow_right.svg"
import HelpForm from "../form/HelpForm"
import { COLORS } from "../../styles/colors"

const Container = styled.div`
  /* border: 1px blue dashed; */
  display: flex;
  flex-direction: column;
  ${media.lessThan("743px")`
      margin-bottom: 30px;
      padding: 16px;
    `}
`

const StatusCard = styled.div`
  /* border: 1px yellow dashed; */
  width: 90%;
  max-width: 744px;
  height: 476px;
  display: flex;
  flex-direction: column;
  background: #0e1c36;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 35px 35px;
  margin-right: 30px;
  margin-top: 20px;
  ${media.lessThan("743px")`
        padding: 20px 20px;
        width: 95%;
        margin-right: 0px;
        align-self: center;
      background-color: transparent;

    `}
  ${media.between("744px", "large")`
        width: 95%;
        padding: 25px 25px;
    `}
    ${media.greaterThan("large")`
    `}
`


const Icon = styled.img`
  width: 35px;
  height: 35px;
  transition-duration: 0.5s;
  color: ${COLORS.White};
  margin-bottom: 10px;
  ${media.lessThan("743px")`
    /* align-self: center; */
    margin-top: 0px;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
  `}
`

const Title = styled.h1`
  font-family: Blinker;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 84.5%;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
  ${media.lessThan("743px")`
        /* align-self: center; */
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
  `}
`

const BlurbText = styled.p`
  /* width: 430px; */
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: ${COLORS.White};
  margin-top: 11px;
  ${media.lessThan("743px")`
        /* align-self: center; */
        /* text-align: center; */
        width: 80%;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
  `}
`

const ButtonContainer = styled.div`
  /* border: 1px blue dashed; */
  display: flex;
  flex-direction: row;
  height: auto;
  width: auto;
  margin-top: 22px;
  ${media.lessThan("743px")`
        flex-direction: column;
        align-self: flex-start;
    `}
  ${media.between("744px", "large")`
  `}
`

const BtnLinkWingnut = styled.button`
  width: 191px;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 30px;
  margin-right: 10px;
  border: none;
  color: ${COLORS.White};
  background: #63c085;
  cursor: pointer;
  text-align: center;
  transition-duration: 0.5s;
  :hover {
    opacity: 0.8;
  }
  ${media.lessThan("743px")`
        margin-top: 10px;
        height: 55px;
    `}
  ${media.between("744px", "large")`
        height: 55px;
    `}
    ${media.greaterThan("large")`
    `}
`

const BtnProperty = styled(BtnLinkWingnut)`
  width: 191px;
  border-radius: 30px;
  background: transparent;
  border: 1px solid #63c085;
  margin-right: 10px;
  margin-top: 0px;
  :hover {
    background: ${COLORS.Secondary};
    opacity: 1.0;
  }
  ${media.lessThan("743px")`
    margin-right: 0px;
    margin-top: 10px;
    /* width: 100%; */
  `}
  ${media.between("744px", "large")`
  `}
  ${media.greaterThan("large")`
  `}
  `

const ButtonText = styled.p`
  color: black;
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transition-duration: 0.5s;
  ${media.lessThan("743px")`
        font-size: 15px;
    `}
  ${media.between("744px", "large")`
        font-size: 15px;
    `}
    ${media.greaterThan("large")`
    `}
`

const ButtonText2 = styled(ButtonText)`
  color: ${COLORS.White};
  :hover {
    color: ${COLORS.White};
  }
`;

const IconArrow = styled.img`
  width: 6;
  height: 10px;
  /* margin-left: 20px; */
  align-self: center;
  transition-duration: 0.5s;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

export default function AddWingnutSuccess(props) {

  return (
    <Container>
      <StatusCard>

      <Icon src={Image2} />
      <Title>Success!</Title>
      <BlurbText>Property successfully added. Link it to a Wingnut now to start collecting data.</BlurbText>
      <ButtonContainer>
        <Link to={`/portal/property/link/${props.propertyUUID}`} style={{ textDecoration: "none" }}>
          <BtnLinkWingnut>
            <ButtonText>
              LINK WINGNUT
            </ButtonText>
          </BtnLinkWingnut>
        </Link>
        <Link to={`/portal/property/detail/${props.propertyUUID}`} style={{ textDecoration: "none" }}>
          <BtnProperty>
            <ButtonText2>
              GO TO PROPERTY
            </ButtonText2>
          </BtnProperty>
        </Link>
      </ButtonContainer>
      </StatusCard>

    </Container>
  )
}
