import React, { useState, useEffect, useContext } from "react"
import media from "styled-media-query"
import styled from "styled-components"
import NotificationTable from "./NotificationTable"
// import IconLaunch from "./../../images/ic_launch_green.svg"
import { API, Auth } from "aws-amplify"
import IconActive from "./../../images/ic_pencil_blue.svg"
import ToggleTriState from './ToggleTriState'

import IconSort from "./../../images/ic_arrows_sort.svg"
import { useHistory } from "react-router-dom"
import { Link } from "react-router-dom"
import ScreenSize from "../wingnuts/ScreenSize.enum"
import MediaQuery from "react-responsive"
import { COLORS } from "../../styles/colors"
// import WingnutStatus from "../wingnuts/WingnutStatus.enum"
// import { DateTime } from "luxon"


// Notification table design

// Sort key
// https://stackoverflow.com/questions/63927644/how-does-one-supply-a-custom-sort-function-for-react-table-7

function NotificationTableStructure(props) {

  const updateTableCell = async (toggle, notificationId) => {
    
    if(toggle !== null && notificationId !== null){
      var newConfig =  {
        "entityStatus": "",
     }
  
     newConfig['entityStatus'] = toggle === 1 ? "enabled" : "disabled"

            const token = (await Auth.currentSession()).getIdToken()
            const jwt = token.getJwtToken()
            const init = {
              headers: { Authorization: `Bearer ${jwt}` },
              body: newConfig
            }
            const response = await API.post('FrontendAPI-notifications', `/${notificationId}`, init)
            if (response.success) {
              // success save
            } 
    }
  }

  const tableColumns = React.useMemo(
    () => [
      {
        // Turned int function to get access to column props { props.column.isSorted ? props.column.isSortedDesc ? ' 🔽' : ' 🔼' : ''}
        Header: (props) => {
          return (
            <HeaderDiv>
              <Text>Property</Text>
              <BtnIcon>
                <SortIcon src={IconSort} />
              </BtnIcon>
            </HeaderDiv>
          )
        },
        accessor: "property",
        sortType: "basic",
        // maxWidth: 301,
        // minWidth: 200,
        width: '20%',
        Cell: (props) => {
          return (
              <CellDiv>
                <ContentDiv>
                  <Text>{props.propertyName}</Text>
                </ContentDiv>
              </CellDiv>
          )
        },
        // Cell: props => <Link to="/portal/property" style={{ textDecoration: 'none', color: 'white'  }}>{props.test}</Link>  // this works use everywhere
      },
      {
        Header: (
          <HeaderDiv>
            <Text>Property ID</Text>
            <BtnIcon>
              <SortIcon src={IconSort} />
            </BtnIcon>
          </HeaderDiv>
        ),
        accessor: "propertyID",
        sortType: "basic",
        // maxWidth: 301,
        minWidth: 130,
        width: '5%',
        Cell: (props) => {
          return (
              <CellDiv>
                <ContentDiv>
                  <Text>{props.externalId}</Text>
                </ContentDiv>
              </CellDiv>
          )
        },
      },
      {
        Header: (
          <HeaderDiv>
            <Text>Address</Text>
            <BtnIcon>
              <SortIcon src={IconSort} />
            </BtnIcon>
          </HeaderDiv>
        ),
        accessor: "address",
        sortType: "basic",
        // maxWidth: 301,
        // minWidth: 299,
        width: '32%',
        Cell: (props) => {
          return (
            <CellDiv>
              <StaticText>{props.address}</StaticText>
            </CellDiv>
            )
        },
      },
      {
        Header: (
          <HeaderDiv>
            <Text>Portfolio</Text>
            <BtnIcon>
              <SortIcon src={IconSort} />
            </BtnIcon>
          </HeaderDiv>
        ),
        accessor: "portfolio",
        sortType: "basic",
        // maxWidth: 501,
        // minWidth: 299,
        width: '12%',
        Cell: (props) => {
          return (
            <CellDiv>
              <StaticText>{props.portfolio}</StaticText>
            </CellDiv>
            )
        },
      },
      {
        Header: (
          <HeaderDiv>
            <Text>Edit</Text>
          </HeaderDiv>
        ),
        accessor: "edit",
        // maxWidth: 301,
        // minWidth: 299,
        width: '6%',
        Cell: (props) => {
          return (
            <WingnutDiv>
                  <Link to={`/portal/notification/edit/${props.propertyId}`} style={{ textDecoration: "none", color: COLORS.White }}>
                    <BtnIcon>
                      <EditIcon src={IconActive} />
                    </BtnIcon>
                </Link>     
            </WingnutDiv>
          )
        },
      },
      {
        Header: (
          <HeaderDiv>
            <Text>On/Off</Text>
          </HeaderDiv>
        ),
        accessor: "onOff",
        width: '15%',
        Cell: (props) => {
          return (
              <CellStatusDiv>
                  <ToggleTriState disable={props.isAuth ? 1 : 0} toggle={props.toggle} updateToggle={(e) => updateTableCell(e, props.notificationId)} />
              </CellStatusDiv>
          )
        },
      },
    ],
    []
  )

  const hiddenColumns = (screenSize) => {

    var columnsToHide = []
    if (screenSize === ScreenSize.Medium) {
      columnsToHide = ["address", "portfolio"]
    }
    if (screenSize === ScreenSize.Small) {
      columnsToHide = ["address", "portfolio", "propertyID"]
    }
    return columnsToHide
  }

  return (
    <Container>
      <Styles>
        <MediaQuery minWidth={1171}>
          <NotificationTable
            columns={tableColumns}
            columnsToHide={hiddenColumns(ScreenSize.Large)}
            data={props.tableData}
            headerHeight={130}
            rowHeight={130}
          />
        </MediaQuery>

        <MediaQuery minWidth={744} maxWidth={1170}>
          <NotificationTable
            columns={tableColumns}
            columnsToHide={hiddenColumns(ScreenSize.Medium)}
            data={props.tableData}
            headerHeight={130}
            rowHeight={130}
          />
        </MediaQuery>

        <MediaQuery maxWidth={743}>
          <NotificationTable
            columns={tableColumns}
            columnsToHide={hiddenColumns(ScreenSize.Small)}
            data={props.tableData}
            headerHeight={130}
            rowHeight={130}
          />
        </MediaQuery>
      </Styles>
    </Container>
  )
}

export default NotificationTableStructure

const Container = styled.div`
  /* border: 1px solid red; */
  overflow: auto;
  height: 100%;
  width: 100%;
  margin-top: 0px;
  ${media.lessThan("743px")`
    align-self: center;
    margin-top: 0px;  
    padding-left: 16px;  
    padding-right: 16px;   
    `}
  ${media.between("744px", "large")`
      /* margin-top: 20px; */
      width: 98%;
    `}
    ${media.greaterThan("large")`
    `}
`

const Styles = styled.div`
  /* padding-top: 10px; */
  color: ${COLORS.White};
  overflow: none;

  table {
    border-spacing: 0;
    /* border: 1px solid blue; */

    thead {
      ${"" /* These styles are required for a scrollable body to align with the header properly */}
      overflow-y: auto;
      overflow-x: hidden;

      // Below create a fixed header - from here -> https://codesandbox.io/s/54z882qno4?file=/index.css:192-256
      position: sticky;
      top: 0;
      z-index: 2;
    }

    tbody {
      ${"" /* These styles are required for a scrollable table body */}
      overflow-y: scroll;
      overflow-x: hidden;
    }

    tr {
      :last-child {
        td {
          border-bottom: 1;
        }
      }
    }

    th,
    td {
      margin: 0;
      border-bottom: 1px solid ${COLORS.HighlightDark};

      :last-child {
        border-right: 0;
      }
    }
  }
`
const HeaderDiv = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
  height: 45px;
  background: ${COLORS.Secondary};
`

const CellDiv = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  bottom: 7px;
  /* height: 45px; */
`

const WingnutDiv = styled.div`
    /* border: 1px purple dashed; */
    /* height: 50px; */
    /* margin-top: 20px; */
    /* margin-bottom: 7px; */
`;

const ContentDiv = styled.div`
    /* border: 1px yellow dashed; */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-self: center;
`;

const CellStatusDiv = styled.div`
  /* border: 1px green dashed; */
  padding-top: 5px;
  padding-bottom: 5px;
  width: 110px;
  padding-left: 30px;
  ${media.lessThan("743px")`
        width: 70px;
        padding-left: 8px;
    `}
  ${media.between("744px", "large")`
        width: 80px;
        padding-left: 20px;
    `}

`

const BtnIcon = styled.button`
  background: transparent;
  /* outline: none; */
  border: none;
  /* border: 1px green dashed; */
`

const SortIcon = styled.img`
  /* border: 1px red dashed; */
  width: 18px;
  height: 18px;
  position: relative;
  /* align-self: center; */
  top: 1px;
  margin-right: 0px;
`

const EditIcon = styled.img`
  /* border: 1px red dashed; */
  width: 20px;
  height: 20px;
  position: relative;
  /* align-self: center; */
  /* top: 1px; */
  margin-left: 20px;
  margin-bottom: 6px;
  ${media.lessThan("743px")`
      margin-left: 3px;
    `}
  ${media.between("744px", "large")`
      margin-left: 10px;
    `}
`

const Text = styled.h5`
  /* border: 1px green dashed; */
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-self: center;
  /* align-items: center; */
  letter-spacing: -0.01em;
  color: ${COLORS.White};
`

const StaticText = styled(Text)`
  /* border: 1px green dashed; */
  padding-left: 5px;
  color: ${COLORS.HighlightLight};
  align-self: center;
`