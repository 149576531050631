import React, { Component } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Icon1 from "./../../images/ic_SaveMoney.svg"
import Icon2 from "./../../images/ic_PreventFailures.svg"
import Icon3 from "./../../images/ic_OptimizeSystems.svg"
import Aos from "aos"
import "aos/dist/aos.css"
import { COLORS } from "../../styles/colors"

const Grid = styled.div`
  /* border: 1px yellow dashed; */
  grid-area: 2 / 1 / 3 / 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: auto;
  margin-top: 80px;
  ${media.lessThan("743px")`
        flex-direction: column;
    `}
  ${media.between("744px", "large")`
        flex-wrap: wrap;
  `}
  ${media.greaterThan("large")`
  `}
`

const Card = styled.div`
  height: 350px;
  width: 393px;
  background-color: #0c182f;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  align-self: center;
  z-index: 1;

  ${media.lessThan("743px")`
        height: 233px;
        width: 308px;
        margin-bottom: 20px;
    `}
  ${media.between("744px", "large")`
        height: 233px;
        width: 308px;
        margin-bottom: 20px;
    `}
    ${media.greaterThan("large")`
  `}
`

const CardMiddle = styled(Card)`
  margin-right: 30px;
  margin-left: 30px;
`

const Icon = styled.img`
  height: 76px;
  width: 76px;
  margin-top: 38px;
  margin-left: 50px;
  ${media.lessThan("743px")`
        height: 56px;
        width: 56px;
        margin-top: 32px;
    `}
  ${media.between("744px", "large")`
        height: 56px;
        width: 56px;
        margin-top: 32px;
    `}
    ${media.greaterThan("large")`
  `}
`

const Title = styled.h4`
  font-family: "Blinker", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30.3918px;
  line-height: 31px;
  margin-left: 50px;
  margin-top: 32px;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
  ${media.lessThan("743px")`
        font-size: 23px;
        margin-top: 11px;
    `}
  ${media.between("744px", "large")`
        font-size: 23px;
        margin-top: 11px;
    `}
    ${media.greaterThan("large")`
  `}
`

const Blurb = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 20px;
  line-height: 129.5%;
  margin-left: 50px;
  margin-top: 10px;
  width: 260px;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
  opacity: 0.5;
  ${media.lessThan("743px")`
        font-size: 14px;
        width: 243px;
        margin-top: 11px;
    `}
  ${media.between("744px", "large")`
        font-size: 14px;
        width: 243px;
        margin-top: 11px;
    `}
    ${media.greaterThan("large")`
  `}
`

export class Features extends Component {
  componentDidMount() {
    Aos.init()
  }

  render() {
    return (
      <Grid>
        <Card
          data-aos='slide-up'
          data-aos-duration='1000'
          data-aos-delay='250'
          data-aos-easing='ease-in-sine'
          data-aos-once='true'
        >
          <Icon src={Icon1} />
          <Title>Save Money</Title>
          <Blurb>
            Reduce labour time ensuring regulatory compliance (boiler specific and pressure vessel specific)
          </Blurb>
        </Card>
        <CardMiddle
          data-aos='slide-up'
          data-aos-duration='1000'
          data-aos-delay='450'
          data-aos-easing='ease-in-sine'
          data-aos-once='true'
        >
          <Icon src={Icon2} />
          <Title>Prevent Failures</Title>
          <Blurb>Receive automated alarms for system anomalies before small problems become big ones.</Blurb>
        </CardMiddle>
        <Card
          data-aos='slide-up'
          data-aos-duration='1000'
          data-aos-delay='650'
          data-aos-easing='ease-in-sine'
          data-aos-once='true'
        >
          <Icon src={Icon3} />
          <Title>Optimize Systems</Title>
          <Blurb>Switch to a predictive maintenance strategy by analyzing historic system data.</Blurb>
        </Card>
      </Grid>
    )
  }
}

export default Features
