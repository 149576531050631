

const NotificationDefaults = [
              {
                  key: "outdoorTemperature",
                  label: "",
                  thresholdStatus: null,
                  minValue: 0, 
                  maxValue: 999, 
                  min: "0",
                  max: "999", 
                  userMinValue: null,
                  userMaxValue: null,
                  unit: null
              },
             {
                  key: "mechanicalRoomTemperature",
                  label: "",
                  thresholdStatus: null,
                  minValue: 50, 
                  maxValue: 115, 
                  min: "50 °F", 
                  max: "115 °F", 
                  userMinValue: null,
                  userMaxValue: null,
                  unit: "°F" 
              },
              {
                  key: "mechanicalRoomCombustionAirTemperature",
                  label: "",
                  thresholdStatus: null,
                  minValue: 0, 
                  maxValue: 999, 
                  min: "0",
                  max: "999", 
                  userMinValue: null,
                  userMaxValue: null,
                  unit: null 
              },
              {
                  key: "boilerSupplyHeaderTemperature",
                  label: "",
                  thresholdStatus: null,
                  minValue: 130, 
                  maxValue: 200, 
                  min: "130 °F", 
                  max: "200 °F", 
                  userMinValue: null,
                  userMaxValue: null,
                  unit: "°F" 
              },
              {
                  key: "boilerReturnHeaderTemperature",
                  label: "",
                  thresholdStatus: null,
                  minValue: 50, 
                  maxValue: 200, 
                  min: "50 °F", 
                  max: "200 °F",
                  userMinValue: null,
                  userMaxValue: null, 
                  unit: "°F" 
              },
              {
                  key: "boilerExhaustTemperature",
                  label: "",
                  thresholdStatus: null,
                  minValue: 50, 
                  maxValue: 400, 
                  min: "50 °F", 
                  max: "400 °F", 
                  userMinValue: null,
                  userMaxValue: null,
                  unit: "°F" 
              },           
             {
                  key: "boilerInletTemperature",
                  label: "",
                  thresholdStatus: null,
                  minValue: 50, 
                  maxValue: 200, 
                  min: "50 °F", 
                  max: "200 °F", 
                  userMinValue: null,
                  userMaxValue: null,
                  unit: "°F" 
              },   
              {
                  key: "boilerOutletTemperature",
                  label: "",
                  thresholdStatus: null,
                  minValue: 130, 
                  maxValue: 200, 
                  min: "130 °F", 
                  max: "200 °F", 
                  userMinValue: null,
                  userMaxValue: null,
                  unit: "°F" 
              },           
              {
                  key: "hotWaterTankSupplyHeaderTemperature",
                  label: "",
                  thresholdStatus: null,
                  minValue: 80, 
                  maxValue: 160, 
                  min: "80 °F", 
                  max: "160 °F", 
                  userMinValue: null,
                  userMaxValue: null,
                  unit: "°F" 
              },  
             {
                  key: "hotWaterTankReturnHeaderTemperature",
                  label: "",
                  thresholdStatus: null,
                  minValue: 50, 
                  maxValue: 200, 
                  min: "50 °F", 
                  max: "200 °F", 
                  userMinValue: null,
                  userMaxValue: null,
                  unit: "°F" 
              },           
       {
                  key: "hotWaterTankExhaustTemperature",
                  label: "",
                  thresholdStatus: null,
                  minValue: 50, 
                  maxValue: 400, 
                  min: "50 °F", 
                  max: "400 °F", 
                  userMinValue: null,
                  userMaxValue: null,
                  unit: "°F" 
              },   
     {
                  key: "hotWaterTankInletTemperature",
                  label: "",
                  thresholdStatus: null,
                  minValue: 50, 
                  maxValue: 200, 
                  min: "50 °F", 
                  max: "200 °F", 
                  userMinValue: null,
                  userMaxValue: null,
                  unit: "°F" 
              },           
       {
                  key: "hotWaterTankOutletTemperature",
                  label: "",
                  thresholdStatus: null,
                  minValue: 80, 
                  maxValue: 160, 
                  min: "80 °F", 
                  max: "160 °F", 
                  userMinValue: null,
                  userMaxValue: null,
                  unit: "°F" 
              },  
      {
                  key: "indirectTankInletTemperature",
                  label: "",
                  thresholdStatus: null,
                  minValue: 130, 
                  maxValue: 200, 
                  min: "130°F", 
                  max: "200°F", 
                  userMinValue: null,
                  userMaxValue: null,
                  unit: "°F" 
              },           
       {
                  key: "indirectTankOutletTemperature",
                  label: "",
                  thresholdStatus: null,
                  minValue: 100, 
                  maxValue: 200, 
                  min: "100 °F", 
                  max: "200 °F", 
                  userMinValue: null,
                  userMaxValue: null,
                  unit: "°F" 
              },   
   {
                  key: "roomTemperature",
                  label: "",
                  thresholdStatus: null,
                  minValue: 50, 
                  maxValue: 115, 
                  min: "50 °F", 
                  max: "115 °F", 
                  userMinValue: null,
                  userMaxValue: null,
                  unit: "°F" 
              },           
 {
                  key: "supplyLineTemperature",
                  label: "",
                  thresholdStatus: null,
                  minValue: 130, 
                  maxValue: 200, 
                  min: "130 °F", 
                  max: "200 °F", 
                  userMinValue: null,
                  userMaxValue: null,
                  unit: "°F" 
              },  
       {
                  key: "returnLineTemperature",
                  label: "",
                  thresholdStatus: null,
                  minValue: 50, 
                  maxValue: 200, 
                  min: "50 °F", 
                  max: "200 °F", 
                  userMinValue: null,
                  userMaxValue: null,
                  unit: "°F" 
              },           
       {
                  key: "storageTankInletTemperature",
                  label: "",
                  thresholdStatus: null,
                  minValue: 50, 
                  maxValue: 200, 
                  min: "50 °F", 
                  max: "200 °F", 
                  userMinValue: null,
                  userMaxValue: null,
                  unit: "°F" 
              },   
       {
                  key: "storageTankOutletTemperature",
                  label: "",
                  thresholdStatus: null,
                  minValue: 50, 
                  maxValue: 200, 
                  min: "50 °F", 
                  max: "200 °F", 
                  userMinValue: null,
                  userMaxValue: null,
                  unit: "°F" 
              },           
      {
                  key: "bypassLineTemperature",
                  label: "",
                  thresholdStatus: null,
                  minValue: 50, 
                  maxValue: 200, 
                  min: "50 °F", 
                  max: "200 °F", 
                  userMinValue: null,
                  userMaxValue: null,
                  unit: "°F" 
              },  
     {
                  key: "heatingCoilLineTemperature",
                  label: "",
                  thresholdStatus: null,
                  minValue: 50, 
                  maxValue: 200, 
                  min: "50 °F", 
                  max: "200 °F", 
                  userMinValue: null,
                  userMaxValue: null,
                  unit: "°F" 
              },  
      {
                  key: "sideStreamFilterTemperature",
                  label: "",
                  thresholdStatus: null,
                  minValue: 50, 
                  maxValue: 200, 
                  min: "50 °F", 
                  max: "200 °F", 
                  userMinValue: null,
                  userMaxValue: null,
                  unit: "°F" 
              },  
       {
                  key: "makeUpWaterTemperature",
                  label: "",
                  thresholdStatus: null,
                  minValue: 50, 
                  maxValue: 200, 
                  min: "50 °F", 
                  max: "200 °F", 
                  userMinValue: null,
                  userMaxValue: null,
                  unit: "°F" 
              },  
       {
                  key: "temperature",
                  label: "",
                  thresholdStatus: null,
                  minValue: 0, 
                  maxValue: 999, 
                  min: "0",
                  max: "999", 
                  userMinValue: null,
                  userMaxValue: null,
                  unit: null 
              },  
     {
                  key: "systemPumpCurrent",
                  label: "",
                  thresholdStatus: null,
                  minValue: 0, 
                  maxValue: 999, 
                  min: "0 A", 
                  max: "999 A", 
                  userMinValue: null,
                  userMaxValue: null,
                  unit: "Amps" 
              },  
       {
           key: "boilerPumpCurrent",
           label: "",
           thresholdStatus: null,
           minValue: 0, 
           maxValue: 999, 
           min: "0 A", 
           max: "999 A", 
           userMinValue: null,
           userMaxValue: null,
           unit: "Amps" 
              },  
      {
          key: "recirculationPumpCurrent",
          label: "",
          thresholdStatus: null,
          minValue: 0, 
          maxValue: 999, 
          min: "0 A", 
          max: "999 A", 
          userMinValue: null,
          userMaxValue: null,
          unit: "Amps" 
              },  
     {
         key: "indirectTankPumpCurrent",
         label: "",
         thresholdStatus: null,
         minValue: 0, 
         maxValue: 999, 
         min: "0 A", 
         max: "999 A", 
         userMinValue: null,
         userMaxValue: null,
         unit: "Amps" 
              },  
        {
            key: "storageTankPumpCurrent",
            label: "",
            thresholdStatus: null,
            minValue: 0, 
            maxValue: 999, 
            min: "0 A", 
            max: "999 A", 
            userMinValue: null,
            userMaxValue: null,
            unit: "Amps" 
              },  
        {
            key: "current",
            label: "",
            thresholdStatus: null,
            minValue: 0, 
            maxValue: 999, 
            min: "0",
            max: "999", 
            userMinValue: null,
            userMaxValue: null,
            unit: null 
              }, 
        {
            key: "boilerSystemPressure",
            label: "",
            thresholdStatus: null,
            minValue: 10, 
            maxValue: 80, 
            min: "10 PSI", 
            max: "80 PSI", 
            userMinValue: null,
            userMaxValue: null,
            unit: "PSI" 
              }, 
     {
         key: "door", 
         label: "",
         thresholdStatus: null,
         minValue: 0, 
         maxValue: 999, 
         min: "0",
         max: "999", 
         userMinValue: null,
         userMaxValue: null,
         unit: null 
              }, 
     {
         key: "window",
         label: "",
         thresholdStatus: null,
         minValue: 0, 
         maxValue: 999, 
         min: "0",
         max: "999", 
         userMinValue: null,
         userMaxValue: null,
         unit: null 
              }, 
    {
        key: "occupancy",
        label: "",
        thresholdStatus: null,
        minValue: 0, 
        maxValue: 999, 
        min: "0",
        max: "999", 
        userMinValue: null,
        userMaxValue: null,
        unit: null 
              }, 
       {
           key: "leak",
           label: "",
           thresholdStatus: null,
           minValue: 0, 
           maxValue: 999, 
           min: "0",
           max: "999", 
           userMinValue: null,
           userMaxValue: null,
           unit: null 
              }, 
      {
          key: "onOff",
          label: "",
          thresholdStatus: null,
          minValue: 0, 
          maxValue: 999, 
          min: "0",
          max: "999", 
          userMinValue: null,
          userMaxValue: null,
          unit: null 
              }, 
       {
           key: "systemAquastatState",
           label: "",
           thresholdStatus: null,
           minValue: 0, 
           maxValue: 999, 
           min: "0",
           max: "999", 
           userMinValue: null,
           userMaxValue: null,
           unit: null 
              }, 
        {
            key: "boilerAquastatState",
            label: "",
            thresholdStatus: null,
            minValue: 0, 
            maxValue: 999, 
            min: "0",
            max: "999", 
            userMinValue: null,
            userMaxValue: null,
            unit: null 
              }, 
       {
           key: "hotWaterTankAquastatState",
           label: "",
           thresholdStatus: null,
            minValue: 0, 
            maxValue: 999, 
            min: "0",
            max: "999", 
            userMinValue: null,
            userMaxValue: null,
            unit: null 
              }, 
       {
           key: "",
           label: "",
           thresholdStatus: null,
           minValue: 0, 
           maxValue: 999, 
           min: "0",
           max: "999", 
           userMinValue: null,
           userMaxValue: null,
           unit: null 
              }, 
   {
       key: "boilerLowLimitState",
       label: "",
       thresholdStatus: null,
       minValue: 0, 
       maxValue: 999, 
       min: "0",
       max: "999", 
       userMinValue: null,
       userMaxValue: null,
       unit: null 
              }, 
     {
         key: "hotWaterTankHighLimitState",
         label: "",
         thresholdStatus: null,
         minValue: 0, 
         maxValue: 999, 
         min: "0",
         max: "999", 
         userMinValue: null,
         userMaxValue: null,
         unit: null 
              }, 
      {
          key: "hotWaterTankLowLimitState",
          label: "",
          thresholdStatus: null,
          minValue: 0, 
          maxValue: 999, 
          min: "0",
          max: "999", 
          userMinValue: null,
          userMaxValue: null,
          unit: null 
              }, 
        {
            key: "?",
            label: "",
            thresholdStatus: null,
            minValue: 0, 
            maxValue: 999, 
            min: "0",
            max: "999", 
            userMinValue: null,
            userMaxValue: null,
            unit: null 
              }, 
       {
           key: "boilerControlRelayState",
           label: "",
           thresholdStatus: null,
           minValue: 0, 
           maxValue: 999, 
           min: "0",
           max: "999", 
           userMinValue: null,
           userMaxValue: null,
           unit: null 
              }, 
       {
           key: "hotWaterTankControlRelayState",
           label: "",
           thresholdStatus: null,
           minValue: 0, 
           maxValue: 999, 
           min: "0",
           max: "999", 
           userMinValue: null,
           userMaxValue: null,
           unit: null 
              }, 
       {
           key: "systemPumpcontrolRelayState",
           label: "",
           thresholdStatus: null,
           minValue: 0, 
           maxValue: 999, 
           min: "0",
           max: "999", 
           userMinValue: null,
           userMaxValue: null,
           unit: null 
              }, 
       {
           key: "boilerPumpControlRelayState",
           label: "",
           thresholdStatus: null,
           minValue: 0, 
           maxValue: 999, 
           min: "0",
           max: "999",
           userMinValue: null,
           userMaxValue: null, 
           unit: null 
        }, 
       {
           key: "recirculationPumpControlRelayState",
           label: "",
           thresholdStatus: null,
           minValue: 0, 
           maxValue: 999, 
           min: "0",
           max: "999", 
           userMinValue: null,
           userMaxValue: null,
           unit: null 
              }, 
        {
          key: "state",
          label: "",
          thresholdStatus: null,
          minValue: 0, 
          maxValue: 999, 
          min: "0",
          max: "999", 
          userMinValue: null,
          userMaxValue: null,
          unit: null 
              }
            ]

            export default NotificationDefaults