import React, { useEffect, useState, useContext } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import media from "styled-media-query"
import Image3 from "./../../images/ic_arrow_right_green.svg"
import ImgGreen from "./../../images/ic_status_green.svg"
import ImgPurple from "./../../images/ic_status_purple.svg"
import ImgRed from "./../../images/ic_status_red.svg"
import ImgYellow from "./../../images/ic_status_yellow.svg"
import ImgEye from "./../../images/eye_blue.svg"
import ImgWingnut from "./../../images/ic_wingnuts.svg"
import ImgProperty from "./../../images/ic_property.svg"
import { COLORS } from "../../styles/colors"

const HeaderContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  background-color: #0E1C36;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 15px;
  justify-content: space-between;
  ${media.lessThan("743px")`
    flex-direction: column;
  `}
  ${media.between("744px", "large")`
  `}
`

const TopContainer = styled.div`
  /* border: 1px blue dashed; */
  display: flex;
  flex-direction: row;
    margin-right: 10px;
`

const StatusIcon = styled.img`
  width: 12px;
  height: 12px;
  margin-right: 22px;
  align-self: center;
`

const HeaderText = styled.div`
  font-family: Blinker;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 110%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
`

const WingnutDetails = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  flex-direction: column;
  align-content: center;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
      margin-right: 5px;
    `}
`

const InfoText = styled.h2`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: ${COLORS.HighlightLight};
  margin-top: 5px;
  margin-right: 5px;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const InfoText2 = styled(InfoText)`
  max-width: 300px;
  font-size: 12px;
  margin-top: 15px;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  ${media.lessThan("743px")`
    align-self: flex-start;
    `}
`

const BtnBack = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 40px;
  border: 1px solid #63c085;
  border-radius: 30px;
  color: black;
  background: #63c085;
  cursor: pointer;
  text-align: center;
  :hover {
    opacity: 0.8;
  }
  ${media.lessThan("743px")`
    height: 55px;
    margin-top: 22px;
    margin-bottom: 30px;
  `}
  ${media.between("744px", "large")`
    height: 55px;   
    width: 200px;
    align-self: center;
  `}
  ${media.greaterThan("large")`
    align-self: center;
    `}
`

const ButtonText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transition-duration: 0.5s;
  :hover {
  }
`

export default function StatusDetailsUnconfigured(props) {

  const [isHovering, setIsHovering] = useState(false)

  return (
    <HeaderContainer>
      <TopContainer>
        <StatusIcon src={ImgYellow} />
        <WingnutDetails>
          <HeaderText>Unconfigured Wingnut</HeaderText>
          <InfoText>This Wingnut must be configured in order to collect data</InfoText>
        </WingnutDetails>
      </TopContainer>
        <ButtonContainer>
          <BtnBack onClick={props.setConfigOpen}>
            <ButtonText>CONFIGURE WINGNUT</ButtonText>
          </BtnBack>
        </ButtonContainer>

    </HeaderContainer>
  )
}
