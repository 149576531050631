import React, { useEffect, useState, useContext } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import { COLORS } from "../../../styles/colors"
import { Link } from "react-router-dom"
import CustomMonthDropdownUI from "./CustomMonthDropdownUI"
import CustomYearDropdownUI from "./CustomYearDropdownUI"

export default function PropertyCustomTimeUI(props) {

  const [selectedMonth, setSelectedMonth] = useState()
  const [selectedYear, setSelectedYear] = useState()
  const [dateSelected, setDateSelected] = useState(false)

  const applyCustomDate = () => {
    if(dateSelected){
      props.loadCustomMonth(selectedMonth, selectedYear)
      setDateSelected(false)
    }
  }

  const setMonth = (month) => {
    setSelectedMonth(month)
  }

  const setYear = (year) => {
    setSelectedYear(year)
    setSelectedMonth()
  }

  useEffect(()=>{
    if(selectedMonth !== undefined && selectedYear !== undefined){
      setDateSelected(true)
    } else {
      setDateSelected(false)
    }
  }, [selectedMonth, selectedYear])

  return (
    <OuterContainer>
      <DateContainer>
        <Dropdown>
            <DropdownTitle>Year</DropdownTitle>
            <CustomYearDropdownUI setData={setYear} {...props}/>
        </Dropdown>
        <Dropdown>
            <DropdownTitle>Month</DropdownTitle>
            <CustomMonthDropdownUI setData={setMonth} selectedYear={selectedYear} {...props}/>
        </Dropdown>
    </DateContainer>
  
        <BtnApply disabled={!dateSelected} style={{display: dateSelected ? 'block' : 'none', background: dateSelected == true ? COLORS.Green : COLORS.Primary}} onClick={applyCustomDate}>
            <ButtonText2 style={{ color: dateSelected == true ? COLORS.White : COLORS.HighlightLight}}>
                APPLY
            </ButtonText2>
        </BtnApply>
  </OuterContainer>
  )
}

const OuterContainer = styled.div`
/* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${media.lessThan("743px")`
  `}
`

const DateContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: row;
`;

const Dropdown = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`;

const DropdownTitle = styled.p`
    /* border: 1px yellow dashed; */
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 129.5%;
    letter-spacing: 0.03em;
    text-transform: capitalize;
    color: ${COLORS.HighlightLight};
`;

const BtnApply = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    /* padding: 14px 40px; */
    gap: 9px;
    background: ${COLORS.Green};
    width: 140px;
    height: 40px;
    border: 1px solid #63C085;
    border-radius: 30px;
    margin-right: 20px;
    margin-bottom: 10px;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
      margin-bottom: 0px;
    `}
`;

const ButtonText2 = styled.p`
  /* border: 1px red dashed; */
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${COLORS.Primary};
  align-self: center;
  transition-duration: 0.5s;
  ${media.lessThan("743px")`
        font-size: 15px;
        /* width: 170px; */
    `}
  ${media.between("744px", "large")`
        font-size: 15px;
    `}
    ${media.greaterThan("large")`
    `}
`;
