import React, { useEffect, useState, useContext } from "react"
import { API, Auth } from "aws-amplify"
import styled from "styled-components"
import media from "styled-media-query"
import SensorCell from './SensorCell'
import { AccountContext } from "../../auth/Account"
import { COLORS } from "../../styles/colors"


export default function NotificationConfiguration(props) {

  const [sensorDefaults, setSensorDefaults] = useState(props.sensorDefaults)
  const [errorArray, setErrorArray] = useState([])
  const {getSession} = useContext(AccountContext)
  const [isAuth, setIsAuth ] = useState(false)

  const [alertLabels, setAlertLabels] = useState([])
  
  useEffect(() => {
    getSession().then(({ isAuth }) => {
      setIsAuth(isAuth)
    })
}, [])

  useEffect(() => {
    if(props.saveAll === true){
      saveConfiguration()
    }
  }, [props.saveAll])

  const saveConfiguration = async () => {

        var newThresholds = {}
            sensorDefaults.forEach((sensor)=> {
                if(sensor.thresholdStatus !== null && sensor.userMinValue !== null && sensor.userMaxValue !== null){
                  newThresholds[`${sensor.label}`] = {
                          max: `${sensor.userMaxValue}`, 
                          thresholdStatus: sensor.thresholdStatus === 1 ? "enabled" : "disabled", 
                          min: `${sensor.userMinValue}`
                        }
                  } 
            })

            var newConfig =  {
              "entityStatus": ``,   // RIGHT HERE
              "config": {
                "thresholds": {},
                "recipients": {}
              }
          }

        newConfig['config'].recipients = props.recipients
        newConfig['entityStatus'] = props.disable === 1 || props.disable === 2 ? "enabled" : "disabled"
        newConfig['config'].thresholds = newThresholds

        if(props.notificationDetails[0]){
            const token = (await Auth.currentSession()).getIdToken()
            const jwt = token.getJwtToken()
            const init = {
              headers: { Authorization: `Bearer ${jwt}` },
              body: newConfig
            }

            const response = await API.post('FrontendAPI-notifications', `/${props.notificationDetails[0].id}`, init)
            if (response.success) {
              props.willRefreshAlerts()
              props.updateCurrentThresholds(newThresholds)
            }  
        }  
}
  
  const setError = (isError, label, minOrMax) => {
    const errorName = label + minOrMax

    if(isError === true){
      // add error
      if(errorArray.some(error => error === errorName)){
        // error already been set
      } else {
        setErrorArray([...errorArray, errorName])
      }

    } else {
      // remove error
    let localArray = errorArray

    if(localArray.length !== 0){
      let index = localArray.findIndex(error => {
        return error === errorName
      })
      localArray.splice(index, 1)
      setErrorArray(localArray)
    }
  }
  }

  const configChanged = (val) => {
    //  setDidConfigChange(val)
     props.isActiveChange(true)
  }

  const updateSensor = (label, min, max, thresholdStatus) => {
    var localToggles = []

    const updatedSensorDefaults = sensorDefaults.map(sensor => {
        if(sensor.label === label){
          return{...sensor,
            userMinValue: min? parseInt(min, 10) : min === 0 ? 0 : null,
            userMaxValue: max? parseInt(max, 10) : null,
            thresholdStatus: thresholdStatus
          }
        }                    
          return sensor     
      })

      updatedSensorDefaults.forEach((sensor)=> {
        localToggles.push(sensor.thresholdStatus !== null ? sensor.thresholdStatus : 0)
      })
    setSensorDefaults(updatedSensorDefaults)
  }


      // Convert alerts to an array for quick lookup for sensorcell
      useEffect(() => {
        if(props.alerts){
          var alertLabels = []
          Object.keys(props.alerts).map((key) => {
            alertLabels.push(key)
         })
          setAlertLabels(alertLabels)
        }
    }, [props.alerts])

  return (

    <OuterContainer>

       {sensorDefaults && sensorDefaults.length !== 0 && sensorDefaults.map((sensorDefault, index) => {
        var inAlert = false
        var breachCount = 0
        var alertValue = null

              if(alertLabels.length !== 0){
                if(alertLabels.indexOf(sensorDefault.label) > -1 ){
                  inAlert = true
                  alertValue = props.alerts[sensorDefault.label]
                }
              }

              if(Object.keys(props.breaches).length !== 0){
                Object.keys(props.breaches).forEach(key => {
                  if(key === sensorDefault.label){
                    breachCount = props.breaches[key]
                  }
                })
              }

              return <SensorCell 
                key={index}  
                isAuth={isAuth}
                inAlert={inAlert} 
                alertValue={alertValue} 
                configChanged={configChanged} 
                sensor={sensorDefault} 
                updateSensor={updateSensor} 
                disable={props.disable} 
                setError={setError} 
                alerts={props.alerts}
                breachCount={breachCount}
                />
            })}     

      </OuterContainer>
  )


}

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${COLORS.HighlightMedium};
  margin-top: 21px;
  margin-right: 30px;
  ${media.lessThan("743px")`
      align-self: center;
      /* width: 90% */
      margin-right: 0px;
    `}
`

const HeaderText = styled.h2`
  font-family: Blinker;
  font-style: normal;
  font-weight: normal;
  margin-top: 30px;
  font-size: 26px;
  line-height: 84.5%;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
  /* margin-bottom: 22px; */
`
const OuterContainer = styled.div`
  /* border: 1px red dashed;
  min-height: 1400px; */
`;

const Container = styled.div`
  /* border: 1px transparent dashed; */
`;

const SaveConfigContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row; 
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: -34px;
  z-index: 1;
`;

const SaveConfig = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 40px;
  border: 1px solid #63c085;
  border-radius: 30px;
  color: ${COLORS.White};
  background: #63c085;
  cursor: pointer;
  text-align: center;
  /* margin-right: 15px; */
  :hover {
    opacity: 0.8;

  }
  ${media.lessThan("743px")`
    /* margin-bottom: 50px; */
        display: none;
    `}
  ${media.between("744px", "large")`
    /* margin-bottom: 50px; */

    `}
    ${media.greaterThan("large")`
    `}
`

const SaveConfig2 = styled(SaveConfig)`
  ${media.lessThan("743px")`
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
    `}
  ${media.between("744px", "large")`
        display: none;
    `}
    ${media.greaterThan("large")`
        display: none;
    `}
`

const ButtonText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transition-duration: 0.5s;
  color: black;
`

const ButtonText2 = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: ${COLORS.White};
  text-align: center;
  align-self: center;
  margin-right: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transition-duration: 0.5s;
  cursor: pointer;
  :hover {
    color: #63C085; 
  }
    ${media.lessThan("743px")`
    display: none;
    `}
  ${media.between("744px", "large")`
    /* display: none; */
    `}
    ${media.greaterThan("large")`
    `}
  }
`

const ButtonText3 = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: ${COLORS.White};
  text-align: center;
  align-self: center;
  margin-right: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transition-duration: 0.5s;
  z-index: 1;
  cursor: pointer;
  :hover {
    color: #63C085;
  }
  ${media.lessThan("743px")`
    display: inline;
    /* color: green; */
    align-self: flex-start;
    width: 200px;
    `}
    ${media.between("744px", "large")`
    display: none;
    `}
    ${media.greaterThan("large")`
    display: none;
    `}
`;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: COLORS.Primary,
    borderRadius: "30px",
    border: "none",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.7)",
    zIndex: 10,
  },
}
