import React, { Component } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Image from "./../../images/LOGO_ALT.svg"
import Image2 from "./../../images/ic_close.svg"
import Image3 from "./../../images/ic_close_green.svg"
import LoginForm from "./../form/LoginForm"
import { Link } from "react-router-dom"
import { COLORS } from "../../styles/colors"

const Grid = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  width: 882px;
  height: auto;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  justify-self: center;
  z-index: 2;
  ${media.lessThan("743px")`
        width: 100%;
    `}
  ${media.between("744px", "large")`
        width: 600px;
    `}
    ${media.greaterThan("large")`
  `}
`

const Container = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  width: 384px;
  height: auto;
  flex-direction: column;
  align-self: center;
  ${media.lessThan("743px")`
        width: auto;
    `}
  ${media.between("744px", "large")`
        width: 450px;
    `}
    ${media.greaterThan("large")`
  `}
`

const Logo = styled.img`
  width: 159px;
  height: 47px;
  margin-top: 27px;
  align-self: center;
  transition-duration: 0.5s;
  :hover {
    transform: scale(1.2);
  }
  ${media.lessThan("743px")`
        width: 129px;
        height: 37px;
    `}
`

const Title = styled.h1`
  font-family: Blinker;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 84.5%;
  margin-top: 46px;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
  align-self: center;
  ${media.lessThan("743px")`
    margin-top: 42px;
    text-align: center;
    font-size: 25px;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
  `}
`

const BtnClose = styled.div`
  width: 100px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
  align-self: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  border-radius: 6px;
  ${media.lessThan("743px")`
        align-self: center;
        margin-top: 0px;
        margin-right: 0px;
    `}
  ${media.between("744px", "large")`
        align-self: flex-end;
        margin-top: 20px;
        /* margin-right: -50px; */
    `}
    ${media.greaterThan("large")`
        /* margin-right: -100px; */
  `}
`

const BtnForgotPassword = styled.button`
  background: transparent;
  outline: none;
  border: none;
  color: ${COLORS.White};
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 130%;
  align-items: center;
  cursor: pointer;
  text-align: center;
  text-decoration-line: underline;
  margin-top: 10px;
  margin-bottom: 70px;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
`

const ButtonText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 129.5%;
  text-align: center;
  letter-spacing: 0.05em;
  color: ${COLORS.White};
  align-self: center;
  transition-duration: 0.5s;
  :hover {
    color: ${COLORS.Base};
  }
`

const Icon = styled.img`
  width: 8px;
  height: 8px;
  margin-right: 10px;
  align-self: center;
  transition-duration: 0.5s;
  color: ${COLORS.White};
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
  `}
`

export default class LoginModal extends Component {
  state = {
    isHovering: false,
    // isOpen: true
  }

  // openModal = () => {
  //     this.setState({isOpen: true})
  // }

  // afterOpenModal = () => {

  // }

  // closeModal = () => {
  //     this.setState({isOpen: false})
  // }

  render() {
    return (
      <Grid>
        <BtnClose
          onClick={this.props.closeModal}
          onMouseEnter={(e) => {
            this.setState({ isHovering: true })
          }}
          onMouseLeave={(e) => {
            this.setState({ isHovering: false })
          }}
          style={{ backgroundColor: this.state.isHovering == true ? COLORS.White : "transparent" }}
        >
          <Icon src={this.state.isHovering == true ? Image3 : Image2} />
          <ButtonText
            style={{
              color: this.state.isHovering == true ? "#63C085" : COLORS.White,
            }}
          >
            {" "}
            CLOSE{" "}
          </ButtonText>
        </BtnClose>
        <Container>
          <Logo src={Image} />
          <Title>Login to see your Dashboard</Title>
          <LoginForm />
          <Link to='/resetpassword' style={{ textDecoration: "none", alignSelf: "center" }}>
            <BtnForgotPassword>Forgot Password?</BtnForgotPassword>
          </Link>
        </Container>
      </Grid>
    )
  }
}
