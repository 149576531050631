

  export const FormatLabel = (string) => {
    // add space before capitals
    var formattedString = string.replace(/([a-z])([A-Z])/g, '$1 $2');

    // capitalize first word
    var capitalFormattedString = formattedString.charAt(0).toUpperCase() + formattedString.slice(1);

    // add space before numbers
    return capitalFormattedString.replace(/.{1}$/,' $&');
  }