import React, { useEffect, useState, useContext } from "react"
import { AccountContext } from "./../../auth/Account"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import media from "styled-media-query"
import Image2 from "./../../images/eye.svg"
import Image from "./../../images/ic_success.svg"
import { DarkButtonSpinner } from "./../custom/Spinner"
import { HelpValidatorStrings } from "./../util/VaildatorStrings"
import {
  ErrorContainer,
  Icon,
  Error,
  EmptyError,
  SuccessContainer,
  SuccessIcon,
  Success,
  EmptySuccess,
} from "./../custom/ValidatorStyles"
import { COLORS } from "../../styles/colors"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  ${media.lessThan("743px")`
    `}
`

const Form = styled.form`
  /* border: 1px blue dashed; */
  width: 100%;
  max-width: 550px;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  ${media.lessThan("743px")`
    width: 100%;
    `}
  ${media.between("744px", "large")`
    width: 100%;
    max-width: 520px;
    `}
    ${media.greaterThan("large")`
    `}
`

const Label = styled.label`
  /* border: 1px blue dashed; */
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 129.5%;
  letter-spacing: 0.03em;
  color: ${COLORS.HighlightLight};
  margin-bottom: 2px;
`

const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  align-self: flex-end;
  width: 189px;
  height: 40px;
  border: none;
  background: #63c085;
  cursor: pointer;
  border-radius: 36px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.05em;
  margin-bottom: 10px;
  margin-top: 0px;
  transition-duration: 0.5s;
  :hover {
    opacity: 0.8;
  }
  ${media.lessThan("743px")`
      align-self: flex-start;
      height: 55px;
      font-size: 15px;
    `}
  ${media.between("744px", "large")`
      height: 55px;
      font-size: 15px;
    `}
    ${media.greaterThan("large")`
    `}
`

const ButtonText = styled.p`
  /* border: 1px red dashed; */
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: black;
  ${media.lessThan("743px")`
        font-size: 15px;
    `}
  ${media.between("744px", "large")`
        font-size: 15px;
    `}
    ${media.greaterThan("large")`
    `}
`

const Textarea = styled.textarea`
  resize: none;
  width: 100%;
  max-width: 550px;
  height: 219px;
  outline: none;
  border: none;
  color: ${COLORS.White};
  font-family: "Blinker", sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  background-color: ${COLORS.Secondary};
  border-radius: 8px;
  box-sizing: border-box;
  padding: 10px;
  ::placeholder {
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: normal;
    line-height: 129.5%;
    display: flex;
    align-items: center;
    color: ${COLORS.HighlightMedium};
  }
  :hover {
    border: 1px solid ${COLORS.HighlightMedium};
    padding-left: 9px;
    padding-top: 9px;
  }
  :focus {
    outline: ${COLORS.White};
    border: 1px solid ${COLORS.HighlightLight};
    padding-left: 9px;
    padding-top: 9px;
  }
  ${media.lessThan("743px")`
    width: 100%;
    `}
  ${media.between("744px", "large")`
    width: 100%;
    max-width: 520px;
    `}
    ${media.greaterThan("large")`
    `}
`

export default function HelpForm() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm({ reValidateMode: "onSubmit" })

  const { getSession } = useContext(AccountContext)
  const [showSpinner, setShowSpinner] = useState(false)
  const [success, setSuccess] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [validatorError, setValidatorError] = useState(null)

  const [state, setState] = useState({
    family_name: null,
    given_name: null,
    company: null,
    email: null,
  })

  useEffect(() => {
    getSession().then(({ family_name, given_name, company, email }) => {
      setState({ family_name, given_name, company, email })
    })
  }, [])

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess(false)
      }, 2000)
      return () => clearTimeout(timer)
    }
  }, [success])

  const onSubmit = async (data) => {
    setValidatorError(null)
    setSuccess(false)
    setDisabled(true)
    setShowSpinner(true)

    try {
      let result = await fetch(
        // "https://10ww8j15w1.execute-api.us-east-2.amazonaws.com/prod/sendcontactus", // info@spherescreative.com
        "https://3qvbz9e2j8.execute-api.us-west-2.amazonaws.com/prod/messages", // Wingnut
        {
          method: "post",
          mode: "no-cors",
          headers: {
            Accept: "application/json",
            "Content-type": "application/json",
          },
          body: JSON.stringify({
            fullname: state.given_name && state.family_name ? `${state.given_name} ${state.family_name}` : "Name Unknown",
            company: state.company ? state.company : "Company Unknown",
            email: state.email ? state.email : "Email Unknown",
            message: data.message,
          }),
        }
      )

      setShowSpinner(false)
      setSuccess(true)
      setDisabled(false)
      reset({ message: "" })
      setValidatorError(null)
    } catch (e) {
      console.log("Error", e)
      setShowSpinner(false)
      setSuccess(false)
      setDisabled(false)
      setValidatorError("Unable to send message. Please try again")
    }
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        {success ? (
          <SuccessContainer>
            <SuccessIcon src={Image} />
            <Success>Your message has been sent. We'll be in touch shortly.</Success>
          </SuccessContainer>
        ) : (
          <EmptySuccess></EmptySuccess>
        )}
        <Label>Message</Label>
        <Textarea
          disabled={disabled}
          type='textarea'
          rows={6}
          name='message'
          placeholder='Please describe your issue in as much detail as possible'
          {...register("message", { required: true, maxLength: 300 })}
        />
        {errors.message ? (
          <Error>{HelpValidatorStrings(errors.message?.type)}</Error>
        ) : validatorError ? (
          <ErrorContainer>
            <Icon src={Image} />
            <Error>{validatorError}</Error>
          </ErrorContainer>
        ) : (
          <EmptyError />
        )}

        <Button disabled={disabled} type='submit'>
            <ButtonText style={{marginLeft: disabled ? "22px" : null}}>SUBMIT</ButtonText>
            {showSpinner && <DarkButtonSpinner animation='border' />}
        </Button>
      </Form>
    </Container>
  )
}
