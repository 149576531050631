import React, { useEffect, useState, useContext } from "react"
import { AccountContext } from "../../auth/Account"
import styled from "styled-components"
import media from "styled-media-query"
import AccountDetailsForm from "../form/AccountDetailsForm"
import ChangeEmailForm from "../form/ChangeEmailForm"
import ChangePassword from "../form/ChangePassword"
import { COLORS } from "../../styles/colors"

const Container = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start
  margin-bottom: 50px;
  margin-top: -7px;
  padding: 20px;
  background-color: #0e1c36;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  ${media.lessThan("743px")`
    flex-direction: column;
      width: 100%;
      align-self: center;
      margin-right: 0px;
    `}
    ${media.between("744px", "large")`
      flex-direction: column;
    `}
`

const FormContainer = styled.div`
  /* border: 1px yellow dashed; */
  width: 40%;
  min-width: 379px;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  ${media.lessThan("743px")`
    flex-direction: column;
    min-width: 100%;
    `}
  ${media.between("744px", "large")`
    flex-direction: column;
    width: 100%;
    `}
`


export default function EditAccount() {
  const { getSession } = useContext(AccountContext)

  const [state, setState] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    formattedNumber: "",
  })

  useEffect(() => {
    updateForm()
  }, [])

  const updateForm = () => {
    getSession().then(({ email, given_name, family_name, phone_number }) => {
      // Format the phone number for the update account screen
      const trimNumber = phone_number.slice(2)
      const formattedNumber = `(${trimNumber.substring(0, 3)}) ${trimNumber.substring(3, 6)} ${trimNumber.substring(6,10)}`

      setState({
        email: email,
        firstName: given_name,
        lastName: family_name,
        phoneNumber: phone_number,
        formattedNumber: formattedNumber,
      })
    })
  }

  return (
    <Container>
      <FormContainer>
        <AccountDetailsForm
          firstName={state.firstName}
          lastName={state.lastName}
          phoneNumber={state.phoneNumber}
          formattedNumber={state.formattedNumber}
          updateForm={updateForm}
        />
        <ChangeEmailForm />
      </FormContainer>
      <FormContainer>
        <ChangePassword />
      </FormContainer>
    </Container>
  )
}
