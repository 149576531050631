import { Auth } from "aws-amplify"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import media from "styled-media-query"
import { COLORS } from "../../styles/colors"
import { formatPhoneNumber } from "../util/StringUtil"
import ErrorImage from "./../../images/ic_alert.svg"
import Image from "./../../images/ic_success.svg"
import { ButtonSpinner } from "./../custom/Spinner"
import {
  EmptyError,
  EmptySuccess,
  Error,
  ErrorContainer,
  Icon,
  Success,
  SuccessContainer,
  SuccessIcon2,
} from "./../custom/ValidatorStyles"
import { PhoneValidatorStrings, ServerStrings, ValidatorStrings } from "./../util/VaildatorStrings"

const Container = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: column;
  align-content: center;
  width: auto;
  margin-top: 20px;
  ${media.lessThan("743px")`
      align-self: center;
      width: 100%;
    `}
`

const Form = styled.form`
  /* border: 1px red dashed; */
  /* width: 500px; */
  display: flex;
  flex-direction: column;
  /* margin-top: 46px; */
  ${media.lessThan("743px")`
        align-self: center;
        width: 100%;
        margin-top: 0px;
    `}
  ${media.between("744px", "large")`
        /* width: 384px; */
    `}
`

const HeaderText = styled.h2`
  font-family: Blinker;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 84.5%;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
  margin-bottom: 20px;
`


const Label = styled.label`
  /* border: 1px blue dashed; */
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 129.5%;
  letter-spacing: 0.03em;
  color: ${COLORS.HighlightLight};
  margin-bottom: 2px;
  /* margin-top: 6px; */
`

const Input = styled.input`
  /* border: 1px blue dashed; */
  width: 100%;
  max-width: 378px;
  height: 45px;
  outline: none;
  border: none;
  color: ${COLORS.White};
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 129.5%;
  font-weight: normal;
  background-color: ${COLORS.Secondary};
  border-radius: 8px;
  box-sizing: border-box;
  padding-left: 10px;
  ::placeholder {
    color: ${COLORS.HighlightMedium};
  }
  :hover {
    border: 1px solid ${COLORS.HighlightMedium};
    padding-left: 9px;
  }
  :focus {
    border: 1px solid ${COLORS.HighlightLight};
    padding-left: 9px;
  }
  ${media.lessThan("743px")`
        width: 100%;
        height: 55px;
    `}
  ${media.between("744px", "large")`
        width: 100%;
        height: 55px;
    `}
    ${media.greaterThan("large")`
    `}
`

const ButtonContainer = styled.div`
  /* border: 1px blue dashed; */
  display: flex;
  flex-direction: column;
  ${media.lessThan("743px")`
    `}
`

const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  width: 133px;
  height: 40px;
  border: 1px #63c085 solid;
  background: transparent;
  cursor: pointer;
  border-radius: 36px;
  text-align: center;
  transition-duration: 0.5s;
  :hover {
    background: ${COLORS.Secondary};
  }
  ${media.lessThan("743px")`
        align-self: flex-start;
        margin-bottom: 18px;
        height: 55px;
    `}
  ${media.between("744px", "large")`
        height: 55px;
    `}
    ${media.greaterThan("large")`
    `}
`

const ButtonText = styled.p`
  /* border: 1px red dashed; */
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${COLORS.White};
  ${media.lessThan("743px")`
        font-size: 15px;
    `}
  ${media.between("744px", "large")`
        font-size: 15px;
    `}
    ${media.greaterThan("large")`
    `}
`

const IconSuccess = styled.img`
  width: 20;
  height: 20px;
  /* margin-left: 20px; */
  align-self: center;
  transition-duration: 0.5s;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const SectionContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
`

export default function AccountDetailsForm(props) {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, isSubmitSuccessful },
  } = useForm({ reValidateMode: "onSubmit" })

  const [showSpinner, setShowSpinner] = useState(false)
  const [serverError, setServerError] = useState(null)
  const [success, setSuccess] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const onSubmit = async (data) => {
    const { newFirstName, newLastName, newPhoneNumber } = data
    if (newFirstName || newLastName || newPhoneNumber) {
      setServerError(null)
      setSuccess(false)
      setDisabled(true)
      setShowSpinner(true)

      const formattedPhoneNumber = formatPhoneNumber(newPhoneNumber)
      await Auth.currentAuthenticatedUser()
        .then((user) => {
          const { firstName, lastName, phoneNumber } = props
          return Auth.updateUserAttributes(user, {
            given_name: newFirstName ? newFirstName : firstName,
            family_name: newLastName ? newLastName : lastName,
            phone_number: newPhoneNumber ? formattedPhoneNumber : phoneNumber,
          })
        })
        .then((response) => {
          setServerError(null)
          setShowSpinner(false)
          setSuccess(true)
          setDisabled(false)
          reset({ newFirstName: "", newLastName: "", newPhoneNumber: "" })
          props.updateForm()
        })
        .catch((err) => {
          setServerError(ServerStrings(err.name))
          setShowSpinner(false)
          setSuccess(false)
          setDisabled(false)
        })
    }
  }

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess(false)
      }, 2000)
      return () => clearTimeout(timer)
    }
  }, [success])

  return (
    <Container>
      <Form autoComplete='off' onSubmit={handleSubmit(onSubmit)} noValidate>
        <HeaderText>Account Details</HeaderText>
        <SectionContainer>
          <Label>First Name</Label>
          <Input
            type='text'
            name='newFirstName'
            placeholder={props.firstName}
            {...register("newFirstName", { maxLength: 45 })}
            style={{ border: errors.newFirstName ? "1px solid #D25353" : null, paddingLeft: errors.newFirstName ? '9px' : null }}
          />
          {errors.newFirstName ? <Error>{ValidatorStrings(errors.newFirstName?.type)}</Error> : <EmptyError />}

          <Label>Last Name</Label>
          <Input
            type='text'
            name='newLastName'
            placeholder={props.lastName}
            {...register("newLastName", { maxLength: 45 })}
            style={{ border: errors.newLastName ? "1px solid #D25353" : null, paddingLeft: errors.newLastName ? '9px' : null }}
          />
          {errors.newLastName ? <Error>{ValidatorStrings(errors.newLastName?.type)}</Error> : <EmptyError />}

          <Label>Phone Number</Label>
          <Input
            type='tel'
            name='newPhoneNumber'
            placeholder={props.formattedNumber}
            {...register("newPhoneNumber", { minLength: 10, maxLength: 20, pattern: /^[\d \-()]*$/ })}
            style={{ border: errors.newPhoneNumber ? "1px solid #D25353" : null, paddingLeft: errors.newPhoneNumber ? '9px' : null }}
          />
          {errors.newPhoneNumber ? (
            <Error>{PhoneValidatorStrings(errors.newPhoneNumber?.type)}</Error>
          ) : serverError ? (
            <ErrorContainer>
              <Icon src={ErrorImage} />
              <Error>{serverError}</Error>
            </ErrorContainer>
          ) : (
            <EmptyError />
          )}
        </SectionContainer>

        <ButtonContainer>
        <Button disabled={disabled} type='submit' >
            <ButtonText style={{marginLeft: disabled ? "22px" : null}}>SAVE</ButtonText>
            {showSpinner && <ButtonSpinner animation='border' />}
        </Button>
          {success ? (
            <SuccessContainer style={{ alignSelf: 'start', marginTop: 5 }}>
              <SuccessIcon2 src={Image} style={{ marginLeft: 0 }} />
              <Success>Your settings have been saved!</Success>
            </SuccessContainer>
          ) : (
            <EmptySuccess></EmptySuccess>
          )}
        </ButtonContainer>
      </Form>
    </Container>
  )
}

// https://codesandbox.io/s/showhide-password-on-toggle-in-react-hooks-95qcz?file=/src/App.js:1079-1084
// https://dev.to/detoner777/show-hide-password-on-toggle-in-react-hooks-1lph
