import React, { Component } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Image from "./../../images/Tech.svg"
import Image2 from "./../../images/IsoRender.svg"
import Image3 from "./../../images/ic_arrowRight.svg"
import Image4 from "./../../images/Ellipse_hardware.png"
import Image5 from "./../../images/IsoRender_medium.svg"
import Image6 from "./../../images/IsoRender_small.svg"
import { Link } from "react-scroll"
import Aos from "aos"
import "aos/dist/aos.css"
import { COLORS } from "../../styles/colors"

const Grid = styled.div`
  /* border: 1px blue dashed; */
  grid-area: 3 / 1 / 4 / 2;
  max-width: 1440px;
  display: flex;
  width: auto;
  flex-direction: row;
  justify-self: center;
  align-content: center;
  margin-bottom: 100px;
  /* z-index: -1; */
  margin-left: 30px;
  ${media.lessThan("743px")`
        flex-direction: column;
        margin-left: 0px;
    `}
  ${media.between("744px", "large")`
        margin-bottom: 106px;
        margin-left: 30px;
    `}
    ${media.greaterThan("large")`
    `}
`

const TextContainer = styled.div`
  /* border: 1px green dashed; */
  width: auto;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  ${media.lessThan("743px")`
        width: 90%;
        align-self: center;
    `}
  ${media.between("744px", "large")`
    width: 50%;
    `}

    ${media.greaterThan("large")`
    `}
`

const TechImg = styled.img`
  width: 66px;
  height: 420px;
  align-self: center;
  margin-top: -200px;
  ${media.lessThan("743px")`
        width: 43px;
        height: 271px;
        overflow: none
    `}
  ${media.between("744px", "large")`
        width: 43px;
        height: 271px;
    `}
    ${media.greaterThan("large")`
    `}
`

const HeaderText = styled.h1`
  /* border: 1px red dashed; */
  margin-top: 73px;
  font-family: "Blinker", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 52px;
  line-height: 57px;
  letter-spacing: -0.01em;
  /* z-index: 1; */
  color: ${COLORS.White};
  ${media.lessThan("743px")`
        width: 80%;
        font-family: 'Blinker', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 35px;
        line-height: 101.5%;
        letter-spacing: -0.01em;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const SpecSheetContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  height: 12px;
  flex-direction: row;
  align-content: center;
  margin-top: 28px;
`

const Icon = styled.img`
  width: 13px;
  height: 11.38px;
  margin-right: 20px;
  align-self: center;
`

const BtnSpecSheet = styled.div`
  background: none;
  cursor: pointer;
  outline: none;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 15px;
  letter-spacing: 0.05em;
  color: #63c085;
  align-self: center;
  ${media.lessThan("743px")`
    `}
`

const ImgContainer = styled.div`
  /* border: 1px yellow dashed; */
  width: auto;
  display: flex;
  flex-direction: column;
  align-content: center;
  padding-right: 20px;
  transition-duration: 2s;
  transition-timing-function: cubic-bezier(0.69, -0.33, 0.38, 1.76);
  :hover {
    transform: scale(1.1);
  }
  ${media.lessThan("743px")`
        align-self: center;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const HardwareImg = styled.img`
  /* border: 1px blue dashed; */
  width: 757.3px;
  height: 537px;
  margin-top: 120px;
  ${media.lessThan("743px")`
        overflow: none;
        width: auto;
        height: 244.64px;
        margin-top: 50px;
        margin-bottom: 50px;
    `}
  ${media.between("744px", "large")`
        width: auto;
        height: 339px;
    `}
    ${media.greaterThan("large")`
    `}
`

const HardwareImg2 = styled.div`
  background-image: url(${Image2});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* border: 1px blue dashed; */
  width: 757.3px;
  height: 537px;
  margin-top: 120px;
  ${media.lessThan("743px")`
        background-image: url(${Image6});
        overflow: none;
        width: 345px;
        height: 244.64px;
        margin-top: 50px;
        margin-bottom: 50px;
    `}
  ${media.between("744px", "large")`
        background-image: url(${Image5});
        width: 479px;
        height: 339px;
    `}
    ${media.greaterThan("large")`
    `}
`

const Ellipse = styled.img`
  /* border: 1px blue dashed; */
  position: absolute;
  background-size: cover;
  width: 700px;
  height: auto;
  margin-top: 240px;
  resize: contain;
  align-self: center;
  justify-self: center;
  z-index: -1;
  overflow: none;
  ${media.lessThan("743px")`
        width: 300px;
        height: auto;
        margin-top: 100px;
    `}
  ${media.between("744px", "large")`
        width: 450px;
        height: auto;
        margin-top: 200px;
    `}
    ${media.greaterThan("large")`
    `}
`

export class Hardware extends Component {
  componentDidMount() {
    Aos.init()
  }

  render() {
    return (
      <Grid>
        <TextContainer>
          <TechImg data-aos='fade-down' data-aos-duration='1000' src={Image} />
          <HeaderText>A turn-key solution that connects you to your mechanical system from anywhere.</HeaderText>
          <SpecSheetContainer>
            <Icon
              src={Image3}
              data-aos='fade-in'
              data-aos-duration='1000'
              data-aos-delay='500'
              data-aos-easing='ease-in-sine'
            />
            <Link activeClass='active' to='specs' spy={true} smooth={true} duration={1000}>
              <BtnSpecSheet data-aos='fade-right' data-aos-duration='1000' data-aos-easing='ease-in-sine'>
                SEE FULL SPEC SHEET
              </BtnSpecSheet>
            </Link>
          </SpecSheetContainer>
        </TextContainer>
        <ImgContainer>
          <HardwareImg2 data-aos='zoom-in' data-aos-duration='1000' data-aos-easing='ease-in-sine' />
          <Ellipse src={Image4} />
        </ImgContainer>
      </Grid>
    )
  }
}

export default Hardware
