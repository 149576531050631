import React, { Component } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import { COLORS } from "../../styles/colors"
import Image from "./../../images/Devices.svg"
import IconBullet1 from "./../../images/ic_dot_grey.svg"

const Grid = styled.div`
  /* border: 1px blue dashed; */
  grid-area: 6 / 1 / 7 / 2;
  display: flex;
  width: auto;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 100px;
  justify-self: center;
  ${media.lessThan("743px")`
        margin: 0px;
        width: 80%;
        justify-content: center;
    `}
  ${media.between("744px", "large")`
        justify-self: center;
        width: 95%;
        justify-content: start;
    `}
    ${media.greaterThan("large")`
    `}
`

const HeaderText = styled.h1`
  font-family: "Blinker", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 52px;
  line-height: 53px;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
  ${media.lessThan("743px")`
        margin-left: 0px;
        width: 80%;
        font-size: 35px;
    `}
  ${media.between("744px", "large")`
        justify-content: center;
        font-size: 52px;
    `}
    ${media.greaterThan("large")`
    `}
`

const Title = styled.h1`
  /* border: 1px red dashed; */
  font-family: "Blinker", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 25.3918px;
  line-height: 26px;
  margin-top: 50px;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
`

const Icon = styled.img`
  width: 192.42px;
  height: 45.08px;
  left: 100px;
  margin-top: 30px;
  opacity: 0.5;
`

const Line = styled.div`
  width: 90%;
  margin-top: 41px;
  opacity: 0.5;
  border: 1px solid ${COLORS.White};
`

const Line2 = styled.div`
  width: 90%;
  margin-top: 41px;
  opacity: 0.5;
  border: 1px solid ${COLORS.White};
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
        display: none;
    `}
    ${media.greaterThan("large")`
        display: none;
    `}
`

const SpecsContainer = styled.div`
  /* border: 1px red dashed; */
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: start;
  ${media.lessThan("743px")`
        flex-direction: column;
        justify-content: space-evenly;
    `}
  ${media.between("744px", "large")`
        justify-content: start;
  `}
`

const SpecsColumn = styled.div`
  /* border: 1px green dashed; */
  height: auto;
  width: 300px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 40px;
  ${media.lessThan("743px")`
        width: 80%;
        padding: 0px;
    `}
  ${media.between("744px", "large")`
        width: 300px;
        padding: 20px;
    `}
`

const BulletPointContainer = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`

const IconBullet = styled.img`
  height: 9px;
  width: 9px;
  align-self: center;
  margin-right: 20px;
`

const BulletText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 20px;
  line-height: 129.5%;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
  opacity: 0.5;
`

// https://hackernoon.com/how-to-make-a-silky-smooth-scroll-in-react-5dj3xdl

export class TechSpecs extends Component {
  render() {
    return (
      <Grid id='specs'>
        <HeaderText>Tech Specs</HeaderText>
        <Title>Device Support</Title>
        <Icon src={Image} />
        <Line />
        <SpecsContainer>
          <SpecsColumn>
            <Title>Features</Title>
            <BulletPointContainer>
              <IconBullet src={IconBullet1} />
              <BulletText>Hardware and software watchdog timers</BulletText>
            </BulletPointContainer>
            <BulletPointContainer>
              <IconBullet src={IconBullet1} />
              <BulletText>Power, connectivity, and state LEDs</BulletText>
            </BulletPointContainer>
            <BulletPointContainer>
              <IconBullet src={IconBullet1} />
              <BulletText>Self-recovery from power outages</BulletText>
            </BulletPointContainer>
            <BulletPointContainer>
              <IconBullet src={IconBullet1} />
              <BulletText>Local data storage during network outages</BulletText>
            </BulletPointContainer>
            <BulletPointContainer>
              <IconBullet src={IconBullet1} />
              <BulletText>Remote software updates</BulletText>
            </BulletPointContainer>
            <BulletPointContainer>
              <IconBullet src={IconBullet1} />
              <BulletText>In-field provisioning</BulletText>
            </BulletPointContainer>

            <Line />

            <Title>Dimensions</Title>
            <BulletPointContainer>
              <IconBullet src={IconBullet1} />
              <BulletText>32cm x 11cm x 5cm</BulletText>
            </BulletPointContainer>

            <Line />

            <Title>Weight</Title>
            <BulletPointContainer>
              <IconBullet src={IconBullet1} />
              <BulletText>565g</BulletText>
            </BulletPointContainer>

            <Line2 />
          </SpecsColumn>

          <SpecsColumn>
            <Title>Mounting</Title>
            <BulletPointContainer>
              <IconBullet src={IconBullet1} />
              <BulletText>Mounting holes for screws/bolts</BulletText>
            </BulletPointContainer>

            <Line />

            <Title>Power Requirements</Title>
            <BulletPointContainer>
              <IconBullet src={IconBullet1} />
              <BulletText>110V</BulletText>
            </BulletPointContainer>
            <BulletPointContainer>
              <IconBullet src={IconBullet1} />
              <BulletText>600mA</BulletText>
            </BulletPointContainer>
            <BulletPointContainer>
              <IconBullet src={IconBullet1} />
              <BulletText>60Hz</BulletText>
            </BulletPointContainer>

            <Line />

            <Title>Inputs</Title>
            <BulletPointContainer>
              <IconBullet src={IconBullet1} />
              <BulletText>16 x 5V 100kΩ thermistor</BulletText>
            </BulletPointContainer>
            <BulletPointContainer>
              <IconBullet src={IconBullet1} />
              <BulletText>16 x 24V 4-20mA current loop</BulletText>
            </BulletPointContainer>
            <BulletPointContainer>
              <IconBullet src={IconBullet1} />
              <BulletText>12 x High impedance relay detect</BulletText>
            </BulletPointContainer>
            <BulletPointContainer>
              <IconBullet src={IconBullet1} />
              <BulletText>4 x 5V digital input with 5V supply</BulletText>
            </BulletPointContainer>

            <Line2 />
          </SpecsColumn>

          <SpecsColumn>
            <Title>Connectivity</Title>
            <BulletPointContainer>
              <IconBullet src={IconBullet1} />
              <BulletText>Ethernet</BulletText>
            </BulletPointContainer>
            <BulletPointContainer>
              <IconBullet src={IconBullet1} />
              <BulletText>WiFi</BulletText>
            </BulletPointContainer>
            <BulletPointContainer>
              <IconBullet src={IconBullet1} />
              <BulletText>LTE gateway (additional cost)</BulletText>
            </BulletPointContainer>

            <Line />

            <Title>Features</Title>
            <BulletPointContainer>
              <IconBullet src={IconBullet1} />
              <BulletText>Thermistors</BulletText>
            </BulletPointContainer>
            <BulletPointContainer>
              <IconBullet src={IconBullet1} />
              <BulletText>Pressure Sensors</BulletText>
            </BulletPointContainer>
            <BulletPointContainer>
              <IconBullet src={IconBullet1} />
              <BulletText>Split-core Current Sensors</BulletText>
            </BulletPointContainer>
            <BulletPointContainer>
              <IconBullet src={IconBullet1} />
              <BulletText>Door sensor</BulletText>
            </BulletPointContainer>
            <BulletPointContainer>
              <IconBullet src={IconBullet1} />
              <BulletText>Window Sensor</BulletText>
            </BulletPointContainer>
            <BulletPointContainer>
              <IconBullet src={IconBullet1} />
              <BulletText>Leak detection sensor</BulletText>
            </BulletPointContainer>
            <BulletPointContainer>
              <IconBullet src={IconBullet1} />
              <BulletText>Occupancy Sensor</BulletText>
            </BulletPointContainer>
            <BulletPointContainer>
              <IconBullet src={IconBullet1} />
              <BulletText>Control relay state detection sensor</BulletText>
            </BulletPointContainer>
            <BulletPointContainer>
              <IconBullet src={IconBullet1} />
              <BulletText>Thermostat state detection sensor</BulletText>
            </BulletPointContainer>
            <BulletPointContainer>
              <IconBullet src={IconBullet1} />
              <BulletText>Aquastat state detection sensor</BulletText>
            </BulletPointContainer>
            <BulletPointContainer>
              <IconBullet src={IconBullet1} />
              <BulletText>CO sensor</BulletText>
            </BulletPointContainer>
          </SpecsColumn>
        </SpecsContainer>
      </Grid>
    )
  }
}

export default TechSpecs
