import React, { Component, useEffect, useState } from 'react';
import styled from "styled-components"
import { COLORS } from '../../styles/colors';

// Tri-state toggle switch used everywhere

const values = [0,2,1]

export default function ToggleTriState(props) {

  const [toggle, setToggle] = useState(props.toggle)

    const handleChange = () => {

        if(props.disable !== 0){
            switch(toggle){
                case 0:
                    setToggle(1)
                    props.updateToggle(1)
                    break;
                case 1:
                    setToggle(0)
                    props.updateToggle(0)
                    break;
                case 2:
                    setToggle(0)
                    props.updateToggle(0)
                    break;
            }
      }
    };

    useEffect(() => {
        setToggle(props.toggle)
    }, [props.toggle])
    
        return (
        <ToggleContainer>
            <ToggleWrapper>
                <Switch selected={toggle} style={{opacity: props.disable !== null && props.disable === 0 ? "0.5" : "1.0"}}>

                    <SwitchRadio type="radio" name="switch" checked={toggle === 0} onChange={e => {}}/>
                    <SwitchDiv onClick={() => handleChange()} selected={toggle} style={{cursor: props.disable === 0 ? 'default' : 'pointer'}}>0</SwitchDiv>

                    <SwitchRadio type="radio" name="switch" checked={toggle === 2} onChange={e => {}}/>
                    <SwitchDiv onClick={() => handleChange()} selected={toggle} style={{cursor: props.disable === 0 ? 'default' : 'pointer'}}>2</SwitchDiv>

                    <SwitchRadio type="radio" name="switch" checked={toggle === 1} onChange={e => {}}/>
                    <SwitchDiv onClick={() => handleChange()} style={{cursor: props.disable === 0 ? 'default' : 'pointer'}}>1</SwitchDiv>
                    
                    { toggle != null || toggle != undefined  ? 
                        <SwitchSelection style={{ left: `${values.indexOf(toggle) / 3 * 100}%`}} selected={toggle}/>
                        :
                         null
                    }
                </Switch>
            </ToggleWrapper>
            
        </ToggleContainer>
        );
    
    }

    const ToggleContainer = styled.div`
        /* border: 1px blue dashed; */
        display: flex;
        flex-direction: row;
    `;

    const ToggleWrapper = styled.div`
        /* border: 1px blue dashed; */
        /* display: flex; */
        /* flex-direction: row; */


    `;

    // The Switch Parent
    export const Switch = styled.div`
    position: relative;
    height: 27px;
    width: 51px;
    border-radius: 30px;
    /* box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);; */
    border: ${COLORS.HighlightMedium} solid 1px;
    outline: none;

   ${props => props.selected === 0 &&`
            background-color: ${COLORS.Secondary};;
        `}

    ${props => props.selected === 1 &&`
            background-color: #63C085;;
        `}

    ${props => props.selected === 2 &&`
            background-color: #FBAC21;
        `}
  `;
  
  // Hide the ugly html switch
  export const SwitchRadio = styled.input`
    display: none;
  `;
  
  // Inside Circle 
  export const SwitchSelection = styled.span`
    /* border: 1px blue solid; */
    display: block;
    position: absolute;
    z-index: 1;
    top: 5px;
    margin-left: 3px;
    width: 15px;
    height: 15px;
    background: ${COLORS.White};
    border-radius: 30px;
    transition: left 0.25s ease-out;

    ${props => props.selected === 0 &&`
            margin-left: 6px;

        `}
    ${props => props.selected === 2 &&`
            margin-left: 1px;

        `}
    ${props => props.selected === 1 &&`
            margin-left: -4px;
        `}
  `;
  

  // Handling the slide 
  export const SwitchDiv = styled.label`
    /* border: 1px red solid; */
    position: relative;
    z-index: 2;
    float: left;
    width: 16px;
    line-height: 26px;
    font-size: 11px;
    color: rgba(0, 0, 0, 0.0);
    text-align: center;
    cursor: pointer;
  
    ${SwitchRadio}:checked + &{
      transition: 0.15s ease-out;
    }
  `;

    const ToggleTitle = styled.p`
        font-family: "Roboto Condensed", sans-serif;
        letter-spacing: 0.03em;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 130%;
        color: ${COLORS.White};
        margin-left: 8px;
        display: flex;
        align-self: center;
    `
  
