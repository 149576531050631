import React, { useEffect, useState, useContext } from "react"
import { API, Auth } from "aws-amplify"
import styled from "styled-components"
import media from "styled-media-query"
import { COLORS } from "../../styles/colors"
import Icon from "./../../images/ic_alert.svg";
import ImgArrowDown from "./../../images/ic_arrow_down_white.svg"
import ImgArrowRightWhite from "./../../images/ic_arrow_right_white.svg"
import ImgArrowRight from "./../../images/ic_arrow_right_blue.svg"
import { SpinnerContainer, StyledRelativeSpinner } from "../custom/Spinner"
import WingnutDetailsUI from "./component/WingnutDetailsUI"
import WingnutStatus from "./../wingnuts/WingnutStatus.enum"
import { DateTime } from "luxon"
import ImgWingnut from "./../../images/ic_wingnuts.svg"

export default function WingnutDetailsModule(props) {

  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const [isHovering, setIsHovering] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if(data && isOpen){
      loadChartData()
    }
  }, [props.refresh])

  const toggleChart = () => {
    if(isOpen){
      setIsOpen(false)
    } else {
      loadChartData()
    }
}

  const loadChartData = async () => {
    if(props.wingnut){
      setIsLoading(true)
      const token = (await Auth.currentSession()).getIdToken()
      const jwt = token.getJwtToken()
      const init = {
        headers: { Authorization: `Bearer ${jwt}` },
      }

      const response = await API.get('FrontendAPI-analytics', `/wingnutspecs?wingnutId=${props.wingnut.externalId}`, init)
      if (response.success) {
        setData(response.data)
        setIsOpen(true)
        setIsLoading(false)
      }
    }
  }

  const wingnutStatus = (item) => {

    if (item.config.latestPost) {
      var date = DateTime.utc()
      var apiDateTime = item.config.latestPost ? DateTime.fromFormat(item.config.latestPost, "yyyy-MM-dd HH:mm:ss") : DateTime.utc()
      var difference = date.diff(apiDateTime, 'minutes')
     
      if (difference.minutes > 15) {
        return WingnutStatus.Disconnected.value
      } 

    } else {
      return WingnutStatus.Disconnected.value
    }

      if (item.config.propertyId === undefined) {
        return WingnutStatus.Unlinked.value
      }

      if (!(Object.keys(item.config.sensors).length > 0)) {
        return WingnutStatus.Unconfigured.value
      }

    return WingnutStatus.Live.value
  }


  
  return (
    <OuterContainer >
      <Card>
      <Container
              onClick={!isLoading ? toggleChart : null} 
              style={{ cursor: !isLoading ? 'pointer' : 'default', background: isOpen ? null : isHovering ? COLORS.Secondary : null}}
              onMouseEnter={(e) => {setIsHovering(true)}}
              onMouseLeave={(e) => {setIsHovering(false)}}>
          <LeftContainer>
              <HeaderContainer>

                  <StatusIcon  src={props.wingnut ?  wingnutStatus(props.wingnut) : ImgWingnut} />

                  <HeaderText onClick={!isLoading ? toggleChart : null} style={{ cursor: !isLoading ? 'pointer' : 'default' }}>{props.wingnut ? `Wingnut ${props.wingnut.externalId}` : 'Wingnut Details'}</HeaderText>
              </HeaderContainer>
              { isLoading
                ? <SpinnerContainer style={{justifyContent: "flex-start", marginLeft: 20,  position: 'relative'}} ><StyledRelativeSpinner  style={{ width: "26px", height: "26px" }}  animation='border' /></SpinnerContainer>
                : null }

          </LeftContainer>  

          <RightContainer>
              <ArrowIcon
                src={isOpen ? ImgArrowDown : ImgArrowRightWhite} />
          </RightContainer>
        </Container>
        {
          isOpen 
          ? <WingnutDetailsUI data={data} {...props}/> 
          : null
        }

      </Card>
    </OuterContainer>
  )
}

const OuterContainer = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  max-width: 1440px;
  ${media.lessThan("743px")`
    justify-content: center;
  `}
  `;

const Card = styled.div`
   /* border: 1px red dashed; */
   display: flex;
   width: 100%;
   max-width: 1440px;
   flex-direction: row;
   justify-content: space-between;
   margin-right: 20px;
   margin-top: 20px;
   background: #0e1c36;
   border-radius: 10px;
   min-height: 68px;
   /* padding: 20px; */
   flex-wrap: wrap;
   ${media.lessThan("743px")`
      width: 90%;
      align-self: center;
      margin-right: 0px;
    `}
   ${media.between("744px", "large")`
     `}
     ${media.greaterThan("large")`
     `}
 `

const Container = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  `;

const HeaderText = styled.h2`
  /* border: 1px red dashed; */
  font-family: Blinker;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  /* line-height: 84.5%; */
  letter-spacing: -0.01em;
  color: ${COLORS.White};
`

const LeftContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: row;
  /* height: 100px; */
`;

const RightContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: row;
  /* height: 100px; */
`;

const ArrowIcon = styled.img`
  width: 24px;
  height: 24px;
  align-self: center;
`

const HeaderContainer = styled.div`
    /* border: 1px blue dashed; */
    display: flex;
    flex-direction: row;
    align-items: center;
    ${media.lessThan("743px")`
        align-self: center;
        margin-right: 0px;
  `}
`

const StatusIcon = styled.img`
    /* border: 1px red dashed; */
    width: 12px;
    height: 12px;
    margin-right: 22px;
`
