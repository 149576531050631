import { API } from "aws-amplify"
import React, { Component } from "react"
import { Link, withRouter } from "react-router-dom"
import styled from "styled-components"
import media from "styled-media-query"
import { COLORS } from "../../styles/colors"
import Image2 from "./../../images/ic_check_circle.svg"
import Image from "./../../images/LOGO_ALT.svg"
import CreateAccountForm from "./../form/CreateAccountForm"

const Grid = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  height: 100%;
  flex-direction: column;
  align-content: center;
  justify-self: center;
  padding-top: 70px;
  padding-bottom: 60px;
  overflow: none;
  ${media.lessThan("743px")`
      width: 100%;
      height: auto;
    `}
  ${media.between("744px", "large")`
        width: 100%;
      height: auto;
    `}
    ${media.greaterThan("large")`
  `}
`

const Container = styled.div`
  /* border: 2px green dashed; */
  display: flex;
  width: 456px;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  align-content: center;
  ${media.lessThan("743px")`
      width: 95%;

    `}
`

const SubContainer = styled.div`
  /* border: 2px green dashed; */
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  align-content: center;
  ${media.lessThan("743px")`
      width: 95%;

    `}
`

const Logo = styled.img`
  width: 202px;
  height: 57px;
  align-self: center;
  transition-duration: 0.5s;
  :hover {
    transform: scale(1.2);
  }
`

const Icon = styled.img`
  width: 35px;
  height: 35px;
  align-self: center;
  transition-duration: 0.5s;
  color: ${COLORS.White};
  margin-top: 46px;
  margin-bottom: 8px;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
  `}
`

const EmptyIcon = styled.div`
  width: 35px;
  height: 35px;
  align-self: center;
  transition-duration: 0.5s;
  color: ${COLORS.White};
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
  `}
`

const Title = styled.h1`
  font-family: Blinker;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 84.5%;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
  align-self: center;
  ${media.lessThan("743px")`
        font-size: 25px;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
  `}
`

const Blurb = styled(Title)`
  font-family: "Blinker", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 84.5%;
  text-align: center;
  letter-spacing: -0.01em;
  margin-top: 15px;
  ${media.lessThan("743px")`
        font-size: 18px;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
  `}
`

const BlurbHighlight = styled(Blurb)`
  font-weight: bold;
`

const MiniBlurb = styled(Blurb)`
  font-size: 18px;
`

const BtnHaveAccount = styled.button`
  background: transparent;
  outline: none;
  border: none;
  color: ${COLORS.White};
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 130%;
  cursor: pointer;
  text-align: center;
  text-decoration-line: underline;
  opacity: 0.5;
  margin-top: 10px;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
`

const BtnLogin = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  align-self: center;
  width: 259px;
  height: 50px;
  border: none;
  background: #63c085;
  cursor: pointer;
  border-radius: 36px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 13px;
  line-height: 129.5%;
  text-align: center;
  letter-spacing: 0.05em;
  margin-bottom: 10px;
  margin-top: 65px;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

class CreateAccount extends Component {
  state = {
    isLoading: true,
    isSuccess: false,
    id: "",
    email: "",
    company: "",
    companyId: "",
  }

  componentDidMount = () => {
    this.getSearchParamsToken()
      .then((uuid) => {
        return API.get("FrontendAPI-users", `/${uuid}`)
      })
      .then((response) => {
        const { uuid, email, company, companyId } = response
        this.setState({
          isLoading: false,
          id: uuid,
          email,
          company,
          companyId,
        })
      })
      .catch((error) => {
        console.log("Error", error)
        this.props.history.push("/")
      })
  }

  getSearchParamsToken = () => {
    return new Promise((resolve, reject) => {
      const params = new URLSearchParams(this.props.location.search)
      const uuid = params.get("token")
      if (uuid) {
        resolve(uuid)
      } else {
        reject("Token not valid")
      }
    })
  }

  updateSuccess = () => {
    this.setState({ isSuccess: true })
  }

  render() {
    return (
      <Grid>
        <Container>
          <Logo src={Image} />

          {this.state.isSuccess ? <Icon src={Image2} /> : <EmptyIcon />}

          <Title>{this.state.isSuccess ? "Success!" : this.state.company}</Title>

          {this.state.isSuccess ? (
            <SubContainer>
              <MiniBlurb>Account created for</MiniBlurb>
              <Blurb>{this.state.email}</Blurb>

              <Link to='/login' style={{ textDecoration: "none" }}>
                <BtnLogin>LOG IN</BtnLogin>
              </Link>
            </SubContainer>
          ) : this.state.isLoading ? (
            <SubContainer />
          ) : (
            <SubContainer>
              <Blurb>Account Creation for {this.state.email}</Blurb>
              <CreateAccountForm
                updateSuccess={this.updateSuccess}
                id={this.state.id}
                email={this.state.email}
                company={this.state.company}
                companyId={this.state.companyId}
              />
              <Link to='/login' style={{ textDecoration: "none", alignSelf: "center" }}>
                <BtnHaveAccount>Already have an account? Log in</BtnHaveAccount>
              </Link>
            </SubContainer>
          )}
        </Container>
      </Grid>
    )
  }
}

export default withRouter(CreateAccount)
