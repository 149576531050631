import React, { useEffect, useState, useContext, useRef } from "react"
import { useLocation } from 'react-router-dom'
import { API, Auth } from "aws-amplify"
import { Link } from "react-router-dom"
import styled from "styled-components"
import media from "styled-media-query"
import Image2 from "./../../images/ic_launch_green.svg"
import ImgArrowRightWhite from "./../../images/ic_arrow_right_white.svg"
import ImgArrowDown from "./../../images/ic_arrow_down_white.svg"
import ImgArrowRight from "./../../images/ic_arrow_right_blue.svg"
import ImgWingnut from "./../../images/ic_wingnuts.svg"
import ImgProperty from "./../../images/ic_property.svg"
import StatusDetailsDisconnected from "./../wingnuts/StatusDetailsDisconnected"
import StatusDetailsUnconfigured from "./../wingnuts/StatusDetailsUnconfigured"
import StatusDetailsUnlinked from "./../wingnuts/StatusDetailsUnlinked"
import StatusDetailsLive from "./../wingnuts/StatusDetailsLive"
import WingnutConfiguration from "./../wingnuts/WingnutConfiguration"
import Modal from "react-modal"
import PopupContainer from "./../popup/PopupContainer"
import WingnutStatus from "./WingnutStatus.enum"
import { useMediaQuery } from 'react-responsive'
import DiscardChangesPopup from "../popup/DiscardConfigPopup"
import { DateTime } from "luxon"
import { AccountContext } from "../../auth/Account"
import { COLORS } from "../../styles/colors"

export default function Wingnut(props) {
  const { params } = props.match
  const [isHovering, setIsHovering] = useState(false)
  const [isConfigOpen, setIsConfigOpen] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isTabOpen, setIsTabOpen] = useState(false)
  const [didConfigChange, setDidConfigChange] = useState(false)
  const [wingnutDetails, setWingnutDetails] = useState()
  const [schemaConfig, setSchemaConfig] = useState()
  const [refresh, setRefresh] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [alertArray, setAlertArray] = useState([])
  const [alertSignal, setAlertSignal] = useState(false)
  const [childKey, setChildKey] = useState(1);
  const [currentTab, setCurrentTab] = useState(0)
  const [popupNumber, setPopupNumber] = useState(4)
  const [disabled, setDisabled] = useState(true)
  const [showSpinner, setShowSpinner] = useState(false)
  const [saveDisabled, setSaveDisabled] = useState(false)

  const isDesktopSize = useMediaQuery({ query: '(min-width: 744px)' })
  const isJustDesktopSize = useMediaQuery({ query: '(min-width: 1170px)' })

  const location = useLocation()

  const { getSession } = useContext(AccountContext)
  const [isAuth, setIsAuth ] = useState(false)

  // ----------------------------------------------------
  const myRef = useRef();
	
  const handleClickOutside = e => {
    if(didConfigChange == true && isOpen == false && isTabOpen == false){
        if (!myRef.current.contains(e.target)) {
          showPopup(7)
        }
      };
    }

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    });
    // ----------------------------------------------------

    useEffect(() => {
      getSession().then(({ isAuth }) => {
        setIsAuth(isAuth)
      })
  }, [])

  const trackCurrentTab = (tab) => {
    setCurrentTab(tab)
  }

  const updateSensor = (sensor) => {
    // This is ultimately what is saving the state of the cell
    const {key, type, label} = sensor

    if (wingnutDetails) {

      if (type == null && label == null){
        // If these are null, need to remove this key
        delete wingnutDetails.config.sensors[key]
      } else {
        wingnutDetails.config.sensors[key] = {type, label}
      }

    }
  }

  const runSensorValidator = () => {
        if (alertArray && alertArray.length){
            setAlertSignal(!alertSignal)
            showPopup(6)
        } else {
          showPopup(4)
        }
      }

  const setConfigured = async () => {
          // Only save if there are no alerts
          if (alertArray && alertArray.length){
            setAlertSignal(!alertSignal)
          } else {
            setSaveDisabled(true)
            setShowSpinner(true)
              const token = (await Auth.currentSession()).getIdToken()
              const jwt = token.getJwtToken()
              const init = {
                headers: { Authorization: `Bearer ${jwt}` },
                body: wingnutDetails.config
              }
 
              const response = await API.post('FrontendAPI-wingnuts', `/${params.id}`, init)
              if (response.success) {
                setSaveDisabled(false)
                setShowSpinner(false)
                setDidConfigChange(false)
                toggleConfig()
              }
          }     
  }

  const wingnutStatus = (status) => {
    if (wingnutDetails && status === WingnutStatus.Disconnected) {
      var disconnected = true
      if (wingnutDetails.config.latestPost) {
        var date = DateTime.utc()
        var apiDateTime = wingnutDetails.config.latestPost ? DateTime.fromFormat(wingnutDetails.config.latestPost, "yyyy-MM-dd HH:mm:ss") : DateTime.utc()
        var difference = date.diff(apiDateTime, 'minutes')
        disconnected = difference.minutes > 15
      }
      
      return disconnected
    }
    if (wingnutDetails && status === WingnutStatus.Unlinked) {
      return (wingnutDetails.config.propertyId === undefined)
    }
    if (wingnutDetails && status === WingnutStatus.Unconfigured) {
      return !(Object.keys(wingnutDetails.config.sensors).length > 0)
    }
  }

  const closePopup = () => {
      setIsOpen(false)
    if(popupNumber != 6){
      setRefresh(!refresh)
    }
  }

  const discardChanges = () => {
    setSaveDisabled(true)
    setShowSpinner(true)
    setAlertArray([])
    setDidConfigChange(false)
    setRefresh(!refresh)

    // Set timer to avoid race condition
    setTimeout(() => {
      setChildKey(prev => prev + 1);
      }, 200)
  }


  useEffect(() => {
    let cancelled = false

    async function getWingnutDetails() {
      const token = (await Auth.currentSession()).getIdToken()
      const jwt = token.getJwtToken()

      const init = {
        headers: { Authorization: `Bearer ${jwt}` },
      }

      const response = await API.get('FrontendAPI-wingnuts', `/${params.id}`, init)

      if (response.success && !cancelled) {
        setSaveDisabled(false)
        setShowSpinner(false)
        setDisabled(false)
        setWingnutDetails(response.data)
        setIsLoading(false)

        if(location.state && location.state.openConfig == true){
          toggleConfig()
         }
      }
    }

    async function getSchema() {

      const response = await API.get('FrontendAPI-schema', ``)
      var config = {
        totalConfigurations: 0,
        thermistorInputs:{
          typeList: [],
          labelList: [],
          types: [],
          labels: []
        },
        transducerInputs:{
          typeList: [],
          labelList: [],
          types: [],
          labels: []
        },
        generalPurposeInputs: {
          typeList: [],
          labelList: [],
          types: [],
          labels: []
        },
        relaySenseInputs: {
          typeList: [],
          labelList: [],
          types: [],
          labels: []
        }
      }
      if (response.success && !cancelled) {
        // THERMISTOR
        response.data.thermistorInputs.sensors.forEach(sensors => {
          sensors.types.forEach(type => {
            const result = type.replace(/(\d+)/g, " $1")
            const finalResult = result.charAt(0).toUpperCase() + result.slice(1)
            if (config.thermistorInputs.typeList.indexOf(finalResult) === -1) {
              config.thermistorInputs.typeList.push(finalResult)
              config.thermistorInputs.types.push({
                label: finalResult,
                value: type
              })
            }
          })

          sensors.labels.forEach(label => {
            var trimmed = label.slice(0, -1)
            const result = trimmed.replace(/([A-Z])/g, " $1")
            const finalResult = result.charAt(0).toUpperCase() + result.slice(1)
            if (config.thermistorInputs.labelList.indexOf(finalResult) === -1) {
              config.totalConfigurations += 1
              config.thermistorInputs.labelList.push(finalResult)
              config.thermistorInputs.labels.push({
                label: finalResult,
                value: label.slice(0, -1)
              })
            }
          })
        })

        // TRANSUDCER
        response.data.currentLoopInputs.sensors.forEach(sensors => {
          sensors.types.forEach(type => {
            const result = type.replace(/(\d+)/g, " $1")
            const words = result.split(" ")[0]
            const numbers = result.split(" ")[1]
            const parsedWords = words.replace(/([A-Z])/g, " $1")
            const finalResult = parsedWords.charAt(0).toUpperCase() + parsedWords.slice(1) + ` ${numbers}`
            if (config.transducerInputs.typeList.indexOf(finalResult) === -1) {
              config.transducerInputs.typeList.push(finalResult)
              config.transducerInputs.types.push({
                label: finalResult,
                value: type
              })
            }
          })

          sensors.labels.forEach(label => {
            var trimmed = label.slice(0, -1)
            const result = trimmed.replace(/([A-Z])/g, " $1")
            const finalResult = result.charAt(0).toUpperCase() + result.slice(1)
            if (config.transducerInputs.labelList.indexOf(finalResult) === -1) {
              config.totalConfigurations += 1
              config.transducerInputs.labelList.push(finalResult)
              config.transducerInputs.labels.push({
                label: finalResult,
                value: label.slice(0, -1)
              })
            }
          })
        })

        // GENERAL PURPOSE INPUTS
        response.data.gpioInputs.sensors.forEach(sensors => {
          sensors.types.forEach(type => {
            const result = type.replace(/([A-Z])/g, " $1")
            const finalResult = result.charAt(0).toUpperCase() + result.slice(1)
            if (config.generalPurposeInputs.typeList.indexOf(finalResult) === -1) {
              config.generalPurposeInputs.typeList.push(finalResult)
              config.generalPurposeInputs.types.push({
                label: finalResult,
                value: type
              })
            }
          })

          sensors.labels.forEach(label => {
            var trimmed = label.slice(0, -1)
            const result = trimmed.replace(/([A-Z])/g, " $1")
            const finalResult = result.charAt(0).toUpperCase() + result.slice(1)
            if (config.generalPurposeInputs.labelList.indexOf(finalResult) === -1) {
              config.totalConfigurations += 1
              config.generalPurposeInputs.labelList.push(finalResult)
              config.generalPurposeInputs.labels.push({
                label: finalResult,
                value: label.slice(0, -1)
              })
            }
          })
        })
        setSchemaConfig(config)
      }
    }
    
    getSchema()
    getWingnutDetails()
    return () => {
      cancelled = true
    }
  }, [refresh])

  const checkAlert = (checkId) => {
    const index = alertArray.findIndex(id => id === checkId);
    if (index > -1) { 
      return true
    } else {
      return false
    }
  }

  const addAlert = (addId) => {
    const index = alertArray.findIndex(id => id === addId);
    if (index < 0) { 
      setAlertArray(prevAlertArray => [...prevAlertArray, addId])
     } 
  }

  const removeAlert = (removeId) => {
    // Remove the cell id from the array of alerts as soon as all three are clicked
    // Sanitize array for duplicate entries from race conditions
    const dataSet = new Set(alertArray)
    const uniqueArray = Array.from(dataSet)
    const index = uniqueArray.findIndex(id => id === removeId);
    if (index > -1) { 
      let localArray = uniqueArray
      localArray.splice(index, 1)
      setAlertArray(localArray);
     } 
  }

  const configChange = (change) => {
    setDidConfigChange(change)
  }

  const toggleConfig = () => {
    if(didConfigChange == false){

      if(isConfigOpen){
        const element = isDesktopSize ? document.getElementById('top') : document.getElementById('cardTop');
        element.scrollIntoView({ behavior: 'smooth' });
        setTimeout(() => {
          setIsConfigOpen(false)
        }, 400)
      } else {
        setIsConfigOpen(true)
        setTimeout(() => {
        const element = document.getElementById('edit');
        element.scrollIntoView({ behavior: 'smooth' });
        }, 100)
      }
   }
  }

  const showPopup = (number) => {
    setPopupNumber(number)
    setIsOpen(true)
  }

  const setupIsTabOpen = (bool) => {
    setIsTabOpen(bool)
  }

  return (
    <OuterContainer id='top'>
      <Modal isOpen={isOpen} onRequestClose={closePopup} style={customStyles} contentLabel=''>
        <PopupContainer uuid={wingnutDetails && wingnutDetails.id} closePopup={closePopup} popupNumber={popupNumber} saveAll={setConfigured} discardAll={discardChanges} alertArray={alertArray} showSpinner={showSpinner} saveDisabled={saveDisabled}/>
      </Modal>

    <Container>
      <StatusCard>
        <HeaderContainer>
          {wingnutStatus(WingnutStatus.Disconnected) && (<StatusDetailsDisconnected />)}
          {wingnutStatus(WingnutStatus.Unconfigured) && (<StatusDetailsUnconfigured setConfigOpen={toggleConfig} />)}
          {wingnutStatus(WingnutStatus.Unlinked) && (<StatusDetailsUnlinked wingnutDetails={wingnutDetails} />)}

          { !wingnutStatus(WingnutStatus.Disconnected) && !wingnutStatus(WingnutStatus.Unconfigured) && !wingnutStatus(WingnutStatus.Unlinked) ?
            <StatusDetailsLive wingnutDetails={wingnutDetails} disabled={disabled} />
          :
            null
          }

        </HeaderContainer>

        <DetailsContainer id="cardTop">
          <WingnutDetails>
            <WingnutIcon src={ImgWingnut} />
            <HeaderText>Wingnut Details</HeaderText>
            <Title>Serial Number</Title>
            <InfoText>{wingnutDetails ? wingnutDetails.externalId : "-"}</InfoText>
            <Title>UUID Number</Title>
            <InfoText>{wingnutDetails ? wingnutDetails.id : "-"}</InfoText>
            <Title>Date Added</Title>
            <InfoText>{wingnutDetails && wingnutDetails.createdAt ? wingnutDetails.createdAt : "-"}</InfoText>
          </WingnutDetails>
          <WingnutDetailsRight id="config">
            <WingnutIcon src={ImgProperty} />
            <HeaderText>Property Details</HeaderText>
            <Title>Property Name</Title>
            <InfoText>{wingnutDetails && wingnutDetails.property ? wingnutDetails.property.config.name: "-"}</InfoText>
            <Title>Property ID</Title>
            <InfoText>{wingnutDetails && wingnutDetails.property ? wingnutDetails.property.externalId : "-"}</InfoText>
            <Title>Address</Title>
            <InfoText>{wingnutDetails && wingnutDetails.property ? `${wingnutDetails.property.config.address.streetAddress} ${wingnutDetails.property.config.address.city} ${wingnutDetails.property.config.address.provinceState} ${wingnutDetails.property.config.address.country} ${wingnutDetails.property.config.address.postalZip}` : "-"}</InfoText>
            <LinkContainer>
              {wingnutDetails && wingnutDetails.property && (
                  <Link to={`/portal/property/detail/${wingnutDetails && wingnutDetails.property && wingnutDetails.property.id}`} style={{ textDecoration: "none"}}>
                    <ButtonContainer>
                      <Button>Go to Property</Button>
                    </ButtonContainer>
                  </Link>
              )}
              {wingnutDetails && wingnutDetails.property && (
                <ButtonContainer
                 style={{display: isAuth ? "flex" : "none"}}
                 onClick={() => {
                  if (wingnutDetails) {
                    showPopup(1)
                  }
                }}>
                  <Button>Unlink Property</Button>
                </ButtonContainer>
              )}
                <Link to={`/portal/wingnut/link/${wingnutDetails && wingnutDetails.id}`} style={{ textDecoration: "none"}}>
              {wingnutDetails && wingnutDetails.property && (
                  // TODO: hiding this for now
                    <ButtonContainer style={{minWidth: 180, display: 'none'}}> 
                      <Button>Change Linked Property</Button >
                    </ButtonContainer>
                 )}
                </Link>


            </LinkContainer>
          </WingnutDetailsRight>
        </DetailsContainer>

      <ConfigParent ref={myRef}>
        <ConfigHeaderContainer  style={disabled ? {pointerEvents: "none", opacity: "0.8"} : {}}>
        <ConfigContainer
                onClick={!isLoading ? toggleConfig : null} 
                style={{ cursor: !isLoading && didConfigChange == false ? 'pointer' : 'default', background: isConfigOpen ? null : isHovering ? COLORS.Secondary : null}}
                onMouseEnter={(e) => {setIsHovering(true)}}
                onMouseLeave={(e) => {setIsHovering(false)}}>
            <HeaderText

              style={{color: isConfigOpen ? COLORS.White : isHovering ? COLORS.White : null, marginTop: isConfigOpen ? "-15px" : null}}
            >Wingnut Configuration</HeaderText>

            <div id='edit' />
            <ConfigRightContainer>
              {isConfigOpen ? 
                <SaveConfigContainer>
                  <ButtonText2 onClick={() => showPopup(5)} style={{ visibility: isJustDesktopSize ?  didConfigChange ? "visible" : 'hidden' : 'hidden'}}>DISCARD CHANGES</ButtonText2>
                  <SaveConfig disabled={!didConfigChange} onClick={runSensorValidator} style={{backgroundColor: didConfigChange ? null : '#B6B6B6', border: didConfigChange ? null : '#B6B6B6'}}>
                      <ButtonText style={{color: didConfigChange ? null : COLORS.Base, opacity: didConfigChange ? null : '0.5' }}>SAVE CONFIGURATION</ButtonText>
                  </SaveConfig>
                </SaveConfigContainer>
                :
                null
              }
              <ArrowIcon
                src={isConfigOpen? ImgArrowDown : ImgArrowRightWhite}/>
            </ConfigRightContainer>
            </ConfigContainer>
        </ConfigHeaderContainer>

          <SaveConfigMobileContainer style={{display: isConfigOpen ? 'flex' : 'none'}}>
            <SaveConfig2 disabled={!didConfigChange} onClick={runSensorValidator} style={{backgroundColor: didConfigChange ? null : '#B6B6B6', border: didConfigChange ? null : '#B6B6B6'}}>
              <ButtonText style={{color: didConfigChange ? null : COLORS.Base, opacity: didConfigChange ? null : '0.5' }}>SAVE CONFIGURATION</ButtonText>
            </SaveConfig2>
            <ButtonText2Mobile onClick={() => showPopup(5)} style={{ visibility: didConfigChange ? "visible" : 'hidden'}}>DISCARD CHANGES</ButtonText2Mobile>
          </SaveConfigMobileContainer>

        {isConfigOpen ?
           <WingnutConfiguration 
           ref={myRef} 
           key={childKey}
           isTabOpen={isTabOpen} 
           setupIsTabOpen={setupIsTabOpen}
           saveAll={setConfigured} 
           didConfigChange={didConfigChange} 
           wingnutDetails={wingnutDetails} 
           schemaConfig={schemaConfig} 
           updateSensor={(sensor) => updateSensor(sensor)} 
           configChange={configChange} 
           addAlert={addAlert} 
           removeAlert={removeAlert} 
           checkAlert={checkAlert} 
           alertSignal={alertSignal} 
           alertArray={alertArray} 
           discardChanges={discardChanges} 
           trackCurrentTab={trackCurrentTab} 
           currentTab={currentTab}/>
          :
           null
        }
     </ConfigParent>

      </StatusCard>
    </Container>
    </OuterContainer>
  )
}

const OuterContainer = styled.div`
  /* border: 1px red dashed; */
`;

const Container = styled.div`
  /* border: 1px yellow dashed; */
  max-width: 1440px;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  padding-top: 20px;
  ${media.lessThan("743px")`
      justify-content: center;
  `}
  ${media.between("744px", "large")`
  `}
  ${media.greaterThan("large")`
  `}
`

const StatusCard = styled.div`
  /* border: 1px green dashed; */
  width: 98%;
  height: auto;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin-bottom: 20px;
  ${media.lessThan("743px")`
    padding: 20px 20px;
    width: 95%;
    margin-right: 0px;
    align-self: center;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const HeaderContainer = styled.div`
  /* border: 1px blue dashed; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 10px;
  ${media.lessThan("743px")`
  `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const StatusIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 15px;
`

const WingnutIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-bottom: 20px;
`

const HeaderText = styled.div`
  font-family: Blinker;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 110%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
`

const Line = styled.div`
  width: auto;
  height: 0.69px;
  background: ${COLORS.HighlightMedium};
  margin-bottom: 35px;
  `

const Line2 = styled(Line)`
  margin-top: 35px;
`;

const DetailsContainer = styled.div`
  /* border: 1px white dashed;; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  ${media.lessThan("743px")`
  flex-direction: column;
  `}
  ${media.between("744px", "large")`
  /* padding-bottom: 27px; */
    `}
`

const WingnutDetails = styled.div`
  /* border: 1px red dashed; */
  background-color: #0E1C36;
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 20px;
  border-radius: 10px;
  margin-right: 8px;
  ${media.lessThan("743px")`
        padding-bottom: 27px;
        width: 100%;
        padding-right: 100px;
        margin-right: 0px;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const WingnutDetailsRight = styled.div`
  /* border: 1px white dashed; */
  background-color: #0E1C36;
  display: flex;
  width: 50%;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  margin-left: 8px;
  ${media.lessThan("743px")`
    width: 100%;
    margin-left: 0px;
    margin-top: 16px;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const Title = styled.h3`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 129.5%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color: ${COLORS.HighlightMedium};
  margin-top: 10px;
`

const InfoText = styled.h2`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: ${COLORS.HighlightLight};
  margin-top: 2px;
`

const LinkContainer = styled.div`
  /* border: 1px blue dashed; */
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  flex-wrap: wrap;
  ${media.lessThan("743px")`
  `}
  ${media.between("744px", "large")`
    justify-content: flex-start;
  `}
`

const ButtonContainer = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  flex-direction: row;
  margin-right: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
  ${media.lessThan("743px")`
  `}
  ${media.between("744px", "large")`
  `}
`

const LaunchIcon = styled.img`
  width: 9px;
  height: 9px;
  align-self: center;
  margin-right: 7.5px;
`

const Button = styled.button`
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  border: none;
  padding: 0px;
  background: transparent;
  cursor: pointer;
  color: ${COLORS.White};

  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 129.5%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${COLORS.Green};
  /* margin-right: 32px; */

  :hover {
    color: #63C085;
  }
`

const ConfigParent = styled.div`
  /* border: 1px green dashed; */
`;

const ConfigHeaderContainer = styled.div`
  /* border: 1px red dashed; */
  background-color: #0E1C36;
  /* padding: 20px; */
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  margin-top: 15px;
`

const ConfigContainer = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
`;

const ConfigRightContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
`

const ArrowIcon = styled.img`
  width: 24px;
  height: 24px;
  align-self: center;
`

const SaveConfigContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
`;

const SaveConfig = styled.button`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 40px;
  border: 1px solid #63c085;
  border-radius: 30px;
  color: ${COLORS.White};
  background-color: #0E1C36;
  cursor: pointer;
  text-align: center;
  margin-right: 15px;
  :hover {
    opacity: 0.8;

  }
  ${media.lessThan("743px")`
    display: none;
    `}
  ${media.between("744px", "large")`
    display: none;

    `}
    ${media.greaterThan("large")`
    `}
`

const SaveConfigMobileContainer = styled.div`
  /* border: 1px red dashed; */
  ${media.lessThan("743px")`
    display: flex;
    flex-direction: row;
    background-color: #0E1C36;
    margin-top: -20px;
    margin-bottom: -40px;
    padding-left: 20px;
    border-radius: 10px;
    `}
  ${media.between("744px", "large")`
        /* display: none; */
        display: flex;
        flex-direction: row;
        background-color: #0E1C36;
        margin-top: -20px;
        margin-bottom: -10px;
        padding-left: 20px;
        border-radius: 10px;
    `}
    ${media.greaterThan("large")`
        display: none;
    `}
`;

const SaveConfig2 = styled(SaveConfig)`
  ${media.lessThan("743px")`
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        background-color: #0E1C36;
    `}
  ${media.between("744px", "large")`
        /* display: none; */
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        background-color: #0E1C36;
    `}
    ${media.greaterThan("large")`
        display: none;
    `}
`

const ButtonText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transition-duration: 0.5s;
  color: ${COLORS.White};
  ${media.lessThan("743px")`
    /* display: none; */
    `}
  ${media.between("744px", "large")`
  `}
  ${media.greaterThan("large")`
    /* display: none; */
    `}
`



const ButtonText2 = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: ${COLORS.White};
  text-align: center;
  align-self: center;
  margin-right: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transition-duration: 0.5s;
  cursor: pointer;
  :hover {
    color: #63C085; 
  }
    ${media.lessThan("743px")`
    display: none;
    `}
  ${media.between("744px", "large")`
    display: none;
    `}
    ${media.greaterThan("large")`
    `}
  `;

const ButtonText2Mobile = styled(ButtonText2)`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: ${COLORS.White};
  text-align: center;
  align-self: center;
  margin-right: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transition-duration: 0.5s;
  cursor: pointer;
  :hover {
    color: #63C085; 
  }
  ${media.lessThan("743px")`
    display: flex;
  `}
  ${media.between("744px", "large")`
    display: flex;
  `}
  ${media.greaterThan("large")`
    display: none;
  `}
`;

const ButtonText3 = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: ${COLORS.White};
  text-align: center;
  align-self: center;
  margin-right: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transition-duration: 0.5s;
  cursor: pointer;
  :hover {
    color: #63C085;
  }
  ${media.lessThan("743px")`
    display: inline;
    /* color: green; */
    align-self: flex-start;
    margin-bottom: 30px;
    width: 200px;
    `}
    ${media.between("744px", "large")`
    display: none;
    `}
    ${media.greaterThan("large")`
    display: none;
    `}  
`;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: COLORS.Primary,
    borderRadius: "30px",
    border: "none",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.7)",
    zIndex: 10,
  },
}
