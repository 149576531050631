import React, { useEffect, useState } from 'react';
import styled from "styled-components"
import media from "styled-media-query"
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { COLORS } from "./../../../styles/colors"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { Switch } from 'react-router-dom';


var groupIntervals = [
  { timeUnit: "millisecond", count: 1 },
  { timeUnit: "millisecond", count: 10 },
  { timeUnit: "millisecond", count: 100 },
  { timeUnit: "second", count: 1 },
  { timeUnit: "second", count: 10 },

  { timeUnit: "minute", count: 1 },  
  { timeUnit: "minute", count: 5 },  
  { timeUnit: "minute", count: 10 },
  { timeUnit: "minute", count: 15 }, 

  { timeUnit: "hour", count: 1 },
  { timeUnit: "day", count: 1 },
  { timeUnit: "week", count: 1 },
  { timeUnit: "month", count: 1 },
  { timeUnit: "year", count: 1 }
]



const oldChartLineColors = [
  "#63C085", // green light
  "#4AB2FB", // blue light
  "#7D5EA8", // purple light
  "#FBAC21", // yellow light
  "#F15C5D", // red light
  "#3F7754", // green med
  "#32678C", // blue med
  "#5D477B", // purple med
  "#B87F1A", // yellow med
  "#862424", // red med
  "#2C4736", // green dark
  "#1D3140", // blue dark
  "#3498db", // dodger blue
  "#9b59b6", // medium purple
  "#34495e", // dark slate gray
  "#f1c40f", // highlighter yellow
  "#e74c3c", // watermelon red
  "#95a5a6", // storm cloud gray
  "#ecf0f1", // platinum gray
  "#16a085"  // dark cyan green
];

const chartLineColors = [
  am5.color("#63C085"), // green light
  am5.color("#4AB2FB"), // blue light
  am5.color("#7D5EA8"), // purple light
  am5.color("#FBAC21"), // yellow light
  am5.color("#F15C5D"), // red light
  am5.color("#3F7754"), // green med
  am5.color("#32678C"), // blue med
  am5.color("#5D477B"), // purple med
  am5.color("#B87F1A"), // yellow med
  am5.color("#862424"), // red med
  am5.color("#2C4736"), // green dark
  am5.color("#1D3140"), // blue dark
  am5.color("#3498db"), // dodger blue 
  am5.color("#9b59b6"), // medium purple
  am5.color("#34495e"), // dark slate gray
  am5.color("#f1c40f"), // highlighter yellow
  am5.color("#e74c3c"), // watermelon red
  am5.color("#95a5a6"), // storm cloud gray
  am5.color("#ecf0f1"), // platinum gray
  am5.color("#16a085"),  // dark cyan green
]

export default function TimeSeriesAMChart(props) {

  
    const [chart1, setChart1] = useState()
    const [root1, setRoot1] = useState()
    const [XAxis1, setXAxis1] = useState()
    const [YAxis1, setYAxis1] = useState()
    const [rightYAxis1, setRightYAxis1] = useState()
    const [state1, setState1] = useState({scrollbar: null, sbxAxis: null, sbyAxis: null})
    
    const [chart2, setChart2] = useState()
    const [root2, setRoot2] = useState()
    const [XAxis2, setXAxis2] = useState()
    const [YAxis2, setYAxis2] = useState()
    const [rightYAxis2, setRightYAxis2] = useState()
    const [state2, setState2] = useState({scrollbar: null, sbxAxis: null, sbyAxis: null})
  
    const [chart3, setChart3] = useState()
    const [root3, setRoot3] = useState()
    const [XAxis3, setXAxis3] = useState()
    const [YAxis3, setYAxis3] = useState()
    const [rightYAxis3, setRightYAxis3] = useState()
    const [state3, setState3] = useState({scrollbar: null, sbxAxis: null, sbyAxis: null})

    const fps = 100  // Frames Per Second ->  The smaller the number, the less resources charts will use, at the expense of smoothness of animations

    useEffect(() => {
      // setRoot1()
      // setRoot2()
      // setRoot3()

      function createChart(id) {
        // console.log("START CREATE CHART ", id)
        var root = am5.Root.new(`chart${props.id}${id}`);    

        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([am5themes_Animated.new(root)]);
       
       
        // root.fps = fps;       // SET FRAMES PER SECOND

        // // https://www.amcharts.com/docs/v5/charts/stock-chart/#Instantiating_the_chart
        var chart = root.container.children.push(
          am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            // wheelX: "panX",;
            // wheelY: "zoomXY",         // zoomXY at same time
            maxTooltipDistanceBy: "y",
            pinchZoomX:true,
            arrangeTooltips: true
          })
        );

        var title = ''

            if(id == 1){
              title = `${props.label} Temperatures`
            } else if(id == 2){
              title = `${props.label} Pumps`
            } else if(id == 3){
              title = `${props.label} States`
            } 

            chart.children.unshift(am5.Label.new(root, {
                text: title,
                fontSize: 28,
                fill: `${COLORS.White}`,
                fontWeight: "400",
                textAlign: "left",
                fontFamily: 'Blinker',
                fontStyle: 'normal',
                x: am5.percent(0),
                centerX: am5.percent(0),
                // y: am5.percent(50),
                centerY: am5.percent(70),
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: -10
              }));

          const axisTheme = am5.Theme.new(root);
              axisTheme.rule("AxisLabel").setAll({
                fill: am5.color(COLORS.HighlightLight),
                fontSize: "10px",
                fontFamily: "Roboto Condensed",
                fontWeight: "400",
              });
              root.setThemes([
                axisTheme
              ]);

          // Added for advanced zoom functionality 
          let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: "zoomX"
          }));
          cursor.lineX.set("visible", true);
          cursor.lineY.set("visible", true);
    
        // // https://www.amcharts.com/docs/v5/concepts/formatters/formatting-numbers/
        root.numberFormatter.set("numberFormat", "#,###.00");

                // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
              let scrollbar = am5xy.XYChartScrollbar.new(root, {
                  orientation: "horizontal",
                  height: 40,
                  marginTop: 20,
                });

                chart.set("scrollbarX", scrollbar);
                scrollbar.startGrip.set("scale", 0.8);
                scrollbar.endGrip.set("scale", 0.8);

                scrollbar.get("background").setAll({
                  // fill: am5.color(0x000000),
                  fillOpacity: 0.1,
                  cornerRadiusTR: 5,
                  cornerRadiusBR: 5,
                  cornerRadiusTL: 5,
                  cornerRadiusBL: 5
                });

                //----------------------Aggregating for snapshot needed??
                let sbxAxis = scrollbar.chart.xAxes.push(
                  am5xy.DateAxis.new(root, {
                    groupData: true,
                    groupCount: 2500,
                    baseInterval: { timeUnit: "second", count: 1 },
                    groupIntervals: groupIntervals,
                    renderer: am5xy.AxisRendererX.new(root, {
                      opposite: false,
                      strokeOpacity: 0
                    })
                  })
                );

                let sbyAxis = scrollbar.chart.yAxes.push(
                  am5xy.ValueAxis.new(root, {
                    renderer: am5xy.AxisRendererY.new(root, {})
                  })
                );


                //--------Aggregating by library wired up here
          var xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
            // maxDeviation: 0.2,
            // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/date-axis/#Dynamic_data_item_grouping   // Aggregation
            groupData: true,
            groupCount: 2500,
            baseInterval: { timeUnit: "second", count: 1 },
            groupIntervals: groupIntervals,
            strokeOpacity: 0.0,
            strokeWidth: 2,
            renderer: am5xy.AxisRendererX.new(root, {}),
            tooltip: am5.Tooltip.new(root, {
              // fill: am5.color(COLORS.HighlightLight),
              fontSize: "0.938",
              // fontFamily: "Roboto Condensed",
              // fontWeight: "400",
            })
          }));
          
          var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {}),
          }));

          yAxis.set('width', 35)

          // Create the right Y-axis for the second data type
          let rightYAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, { opposite: true }) // Set to true to draw the axis on the opposite side
          }));

          // rightYAxis.set('width', 160)


          if(id !== 2){
            rightYAxis.get("renderer").labels.template.adapters.add("html", function(html, target) {
              return "<div style=\"color: #15223F; font-weight: 400; font-size: 10px; font-family: Roboto Condensed;\">20</div>";
          });
          }

          // Make a single empty line for setup config -- figure out how to not need to do this
            var series = chart.series.push(
                am5xy.LineSeries.new(root, {
                    name: "Series",
                    xAxis: xAxis,
                    yAxis: yAxis,
                    valueYField: "value",
                    valueXField: "date",
                    minDistance: 0
                }));

            series.data.setAll([]);

        // Animation
        series.appear(1000);
        chart.appear(1000, 100);

        // scrollbar.disabled = true   // hide scrollbar? Not working

         // --------------------------- Set up syncing
         xAxis.on("start", function() {
          syncAxes(chart);
         });
         xAxis.on("end", function() {
          syncAxes(chart);
         });

        if(id == 1){
          setState1({scrollbar: scrollbar, sbxAxis: sbxAxis, sbyAxis: sbyAxis})
          setXAxis1(xAxis)
          setYAxis1(yAxis)
          setRightYAxis1(rightYAxis)
          setRoot1(root) 
          setChart1(chart) 
        } else if(id == 2){
          setState2({scrollbar: scrollbar, sbxAxis: sbxAxis, sbyAxis: sbyAxis})
          setXAxis2(xAxis)
          setYAxis2(yAxis)
          setRightYAxis2(rightYAxis)
          setRoot2(root) 
          setChart2(chart) 
        } else if (id == 3){
          setState3({scrollbar: scrollbar, sbxAxis: sbxAxis, sbyAxis: sbyAxis})
          setXAxis3(xAxis)
          setYAxis3(yAxis)
          setRightYAxis3(rightYAxis)
          setRoot3(root) 
          setChart3(chart) 
        } 
        // console.log("END CREATE CHART ", id)
        return chart

      } // End method

      am5.addLicense(process.env.REACT_APP_AMCHARTS5_KEY);

      var chartsToSync = [];
      chartsToSync.push(createChart(1));
      chartsToSync.push(createChart(2));
      chartsToSync.push(createChart(3));

      am5.array.each(chartsToSync, function(sourceChart) {
        var syncCursors = [];
        am5.array.each(chartsToSync, function(targetChart) {
          if (targetChart !== sourceChart) {
            syncCursors.push(targetChart.get("cursor"));
          }
        });
        sourceChart.get("cursor").set("syncWith", syncCursors);
      });
      
           // LINKED ZOOM
           function syncAxes(targetChart) {
            var targetAxis = targetChart.xAxes.getIndex(0);
            if (targetAxis._skipSync != true) {
              var start = targetAxis.get("start");
              var end = targetAxis.get("end");
              am5.array.each(chartsToSync, function(chart) {
                if (chart != targetChart) {
                  var axis = chart.xAxes.getIndex(0);
                  axis._skipSync = true;
                  axis.setAll({
                    start: start,
                    end: end
                  })
                  axis._skipSync = false;
                }
              });
            }
          }
      }, []);

      useEffect(() => {  
        return () => {
          if(root1 !== undefined){
            // console.log("ROOT 1 DISPOSED")
            root1.dispose()
            chart1.dispose()
            setState1({scrollbar: null, sbxAxis: null, sbyAxis: null})
            setChart1()
            setRoot1()
            setXAxis1()
            setYAxis1()
            setRightYAxis1()
          }
        };
          }, [root1]);

      useEffect(() => {  
         return () => {
          if(root2 !== undefined){
            // console.log("ROOT 2 DISPOSED")
            root2.dispose()
            chart2.dispose()
             setState2({scrollbar: null, sbxAxis: null, sbyAxis: null})
             setChart2()
             setRoot2()
             setXAxis2()
             setYAxis2()
             setRightYAxis2()
            }
          };
           }, [root2]);

       useEffect(() => {  
          return () => {
            if(root3 !== undefined){  
              // console.log("ROOT 3 DISPOSED")
                setState3({scrollbar: null, sbxAxis: null, sbyAxis: null})
                root3.dispose()
                chart3.dispose()
                setChart3()
                setRoot3()
                setXAxis3()
                setYAxis3()
                setRightYAxis3()
              }
            };
               }, [root3]);
      // Reference Docs for linking Tooltip and Zoom
      // https://www.amcharts.com/docs/v5/tutorials/syncing-axis-zooms-across-multiple-charts/
      // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/


      //------------------------------------------DATA LOAD

      useEffect(() => {
        if(props.temperatureData != undefined && props.temperatureData != null && chart1 != undefined && chart1 != null){
            if(props.temperatureData.length > 0){
              // printDates(props.temperatureData, "Temperature Data")
              // console.log("Temperature Data----->", props.temperatureData)
              loadChart(1, props.temperatureData, root1, chart1, XAxis1, YAxis1, state1.scrollbar, state1.sbxAxis, state1.sbyAxis, 'Temperature (°F)')
              // chart1.xAxes.getIndex(0).zoom({ start: 0, end: 1});
              
            }
        } 
      }, [props.temperatureData])

      useEffect(() => {
        if(props.currentPressureData != undefined && props.currentPressureData != null && chart2 != undefined && chart2 != null){
          if(props.currentPressureData.length > 0){
            // printDates(props.currentPressureData, "Current Pressure Data")
            loadChart(2, props.currentPressureData, root2, chart2, XAxis2, YAxis2, state2.scrollbar, state2.sbxAxis, state2.sbyAxis, 'Pressure (PSI)')
          }
        } 
      }, [props.currentPressureData])

      useEffect(() => {
        if(props.stateData != undefined && props.stateData != null && chart3 != undefined && chart3 != null){
          if(props.stateData.length > 0){
            // printDates(props.stateData, "State Data")
            loadChart(3, props.stateData, root3, chart3, XAxis3, YAxis3, state3.scrollbar, state3.sbxAxis, state3.sbyAxis, 'State (ON/OFF)')
          }
        } 
      }, [props.stateData])


    const  loadChart = (id, data, root, chart, XAxis, YAxis, scrollbar, sbxAxis, sbyAxis, yLabel) => {

      if(chart && data){
        // console.log("START LOAD CHART ", id)
          chart.zoomOut()
          const oldDataSets = chart.series.values

          if(oldDataSets.length !== 0){
            do {
              chart.series.removeIndex(0).dispose();
            }
            while (oldDataSets.length !== 0); 
          }

          //--------------------------------------------------------Toggle timeZone
          // root.timezone = am5.Timezone.new(props.timeZone);      // Adds way to much latency
          // root.utc = true

          YAxis.children.push(am5.Label.new(root, {
                  text: yLabel,
                  rotation: -90, // Rotate the label by -90 degrees
                  x: am5.percent(-20),
                  y: am5.percent(50),
                  centerX: am5.percent(50),
                  centerY: am5.percent(50),
                  fontWeight: "300",
                  fontSize: 12,
                  fontFamily: 'Roboto Condensed',
                  fill: am5.color(COLORS.HighlightLight) 
                }));

          if(id == 1){
            rightYAxis1.children.push(am5.Label.new(root, {
               text: yLabel,
               rotation: -90, // Rotate the label by -90 degrees
               x: am5.percent(-20),
               y: am5.percent(50),       // Adjust right label here?
               centerX: am5.percent(50),
               centerY: am5.percent(50),
               fontWeight: "300",
               fontSize: 12,
               fontFamily: 'Roboto Condensed',
               fill: am5.color(COLORS.TileBlue) 
               }));
              }

          if(id == 2){
            rightYAxis2.children.push(am5.Label.new(root, {
              text: "Current (Amps)",
              rotation: -90, // Rotate the label by -90 degrees
              x: am5.percent(-20),
              y: am5.percent(50),       // Adjust right label here?
              centerX: am5.percent(50),
              centerY: am5.percent(50),
              fontWeight: "300",
              fontSize: 12,
              fontFamily: 'Roboto Condensed',
              fill: am5.color(COLORS.HighlightLight) 
            }));
          }

          if(id == 3){
            rightYAxis3.children.push(am5.Label.new(root, {
              text: yLabel,
              rotation: -90, // Rotate the label by -90 degrees
              x: am5.percent(-20),
              y: am5.percent(50),       // Adjust right label here?
              centerX: am5.percent(50),
              centerY: am5.percent(50),
              fontWeight: "300",
              fontSize: 12,
              fontFamily: 'Roboto Condensed',
              fill: am5.color(COLORS.TileBlue) 
            }));
          }

          // console.log("chartData", data)

          if(id === 1){
            //temp
            let yAxis = chart.yAxes.getIndex(0);
            let yAxis2 = chart.yAxes.getIndex(1);

            let temperatureMin = 50
            let temperatureMax = 210
            let minMaxTemperature = findMinMax(data, 'Temperature')

            if(minMaxTemperature.min !== Infinity){
              if(minMaxTemperature.min < temperatureMin){
              temperatureMin = minMaxTemperature.min
              }
            }
            if(minMaxTemperature.max !== Infinity && minMaxTemperature.max !== -Infinity && minMaxTemperature.min !== minMaxTemperature.max ){
              if(minMaxTemperature.max > temperatureMax){
                temperatureMax = minMaxTemperature.max
              }
            }

            // console.log("TempMinMax", temperatureMin, temperatureMax)
            yAxis.set("extraMin", 0);
            yAxis.set("extraMax", 0);
            yAxis.set("min", temperatureMin);
            yAxis.set("max", temperatureMax);
            yAxis.set("strictMinMax", true)

            yAxis2.set("min", 0);
            yAxis2.set("max", 20);
            yAxis2.set("fill", am5.color(COLORS.TileBlue))
          } else if(id === 2){
            let yAxis = chart.yAxes.getIndex(0);
            let yAxis2 = chart.yAxes.getIndex(1);

             //------------------------------------

            let pressureMin = 0
            let pressureMax = 60
            let minMaxPressure = findMinMax(data, 'Pressure')

            if(minMaxPressure.min !== Infinity){
              if(minMaxPressure.min < pressureMin){
              pressureMin = minMaxPressure.min
              }
            }
            if(minMaxPressure.max !== Infinity && minMaxPressure.max !== -Infinity && minMaxPressure.min !== minMaxPressure.max ){
              if(minMaxPressure.max > pressureMax){
              pressureMax = minMaxPressure.max
              }
            }

            // console.log("PressureMinMax", pressureMin, pressureMax)
            yAxis.set("extraMin", 0);
            yAxis.set("extraMax", 0);
            yAxis.set("min", pressureMin);
            yAxis.set("max", pressureMax);
            yAxis.set("strictMinMax", true)

            //-----------------------------------
            let currentMin = 0
            let currentMax = 20
            let minMaxCurrent = findMinMax(data, 'Current')

            if(minMaxCurrent.min !== Infinity){
              if(minMaxCurrent.min < currentMin){
              currentMin = minMaxCurrent.min
              }
            }
            if(minMaxCurrent.max !== Infinity && minMaxCurrent.max !== -Infinity && minMaxCurrent.min !== minMaxCurrent.max ){
              if(minMaxCurrent.max > currentMax){
              currentMax = minMaxCurrent.max
              }
            }

            // console.log("CurrentMinMax", currentMin, currentMax)
            yAxis2.set("extraMin", 0);
            yAxis2.set("extraMax", 0);
            yAxis2.set("min", currentMin);
            yAxis2.set("max", currentMax);      
            yAxis.set("strictMinMax", true)
            //------------------------------------
            
          } else if (id === 3){
            let yAxis = chart.yAxes.getIndex(0);
            let yAxis2 = chart.yAxes.getIndex(1);
               // Changes State Chart Y-Axis to OFF/ON
               yAxis.get("renderer").labels.template.adapters.add("text", function(text, target) {
                if (target.dataItem) {
                  // Use exact comparison for 0 and 1 to avoid rounding issues
                  let value = target.dataItem.get("value");
                  if (value === 1) {
                    return "ON";
                  } else if (value === 0) {
                    return "OFF";
                  }
                }
                // Return the original text for other values
                return "";
              });

            yAxis.set("min", 0);
            yAxis.set("max", 1);    
            yAxis2.set("min", 0);
            yAxis2.set("max", 20);
            yAxis.set("strictMinMax", true);
          } 

          const scrollbarSeries = scrollbar.chart.series.values;

          for (var i = scrollbarSeries.length - 1; i >= 0; i--) {
              if (i !== 0) { // Assuming you want to keep the first series
                  scrollbarSeries[i].dispose(); // Dispose the series
              }
          }

          const chartLabels = chart.bottomAxesContainer.children

            for (var i = 0; i < chartLabels.length; i++) {
              if(i != 0){
                chart.bottomAxesContainer.children.removeIndex(i).dispose();
              }
            }

            // To get consistent colors
            const colors = chartLineColors.slice(0, data.length); 
            chart.get("colors").set("colors", colors);

            const getUnits = () => {

              var label = ''

              if(id == 1){
                label = "\u00b0F"
              } else if (id == 2){
                label = "Amps"
              } else if (id == 3){
                label = ""
              } else {
                label = ''
              }
              return label  
            }

            var dataSeries = []

            for (var i = 0; i < data.length; i++) {

              var series = null

              if(id === 3){

                series = chart.series.push(

                  am5xy.StepLineSeries.new(root, {
                        name: data[i].name,
                        xAxis: XAxis,
                        yAxis: id == 2 && data[i].name.includes('Current') ? rightYAxis2 : YAxis,
                        valueYField: "value",
                        valueXField: "date",
                        minDistance: 1,
                        // fill: am5.color(0xabcdef),
                        // locationX: 0.75,
                        // legendValueText: `{valueY} \u2103`,
                        tooltip: am5.Tooltip.new(root, {
                          labelText: `{valueY} ${getUnits()}`,
                          pointerOrientation: "horizontal",
                          showTooltipOn: "click"
                          // fontSize: 12,
                          // fontWeight: "400",
                          // fill: am5.color(COLORS.HighlightLight),
                          // fontFamily: "Roboto Condensed",
                          // labelText: "[bold]{name}[/]\n{valueX.formatDate()}: {valueY}"
                        })
                    }));

                    series.fills.template.setAll({
                      fill: series.get("stroke"),
                      fillOpacity: 1.0, 
                      visible: true
                    })

              } else {
                series = chart.series.push(

                  am5xy.LineSeries.new(root, {
                        name: data[i].name,
                        xAxis: XAxis,
                        yAxis: id == 2 && data[i].name.includes('Current') ? rightYAxis2 : YAxis,
                        valueYField: "value",
                        valueXField: "date",
                        minDistance: 1,
                        // legendValueText: `{valueY} \u2103`,
                        tooltip: am5.Tooltip.new(root, {
                          labelText: `{valueY} ${getUnits()}`,
                          pointerOrientation: "horizontal",
                          showTooltipOn: "click"
                          // fontSize: 12,
                          // fontWeight: "400",
                          // fill: am5.color(COLORS.HighlightLight),
                          // fontFamily: "Roboto Condensed",
                          // labelText: "[bold]{name}[/]\n{valueX.formatDate()}: {valueY}"
                        })
                    }));
              }

                  dataSeries.push(series)
                  // console.log("dataSeries count", dataSeries.length)

                  // Custom tooltip detailing
                  if(id == 2){
                    if(data[i].name.includes('Pressure')){
                      series.get("tooltip").label.adapters.add("text", function(text, target) {
                        if (target.dataItem) {
                          let value = target.dataItem.get("valueY");
                          return `${value} PSI`;
                        }
                        return text;
                      });
                    } else {
                      series.get("tooltip").label.adapters.add("text", function(text, target) {
                        if (target.dataItem) {
                          let value = target.dataItem.get("valueY");
                          return `${value} Amps`;
                        }
                        return text;
                      });
                    }
                  } else if(id === 3){    
                    series.get("tooltip").label.adapters.add("text", function(text, target, key) {
                      if (target.dataItem && target.dataItem.dataContext) {
                        let value = target.dataItem.dataContext.value;
                        let newText = value === 1 ? "ON" : "OFF";
                        text = text.replace("{valueY}", newText);
                      }
                      return text;
                    });
                  } 

              // chart.get("colors").set("background", [
              //   am5.color("#63C085"), // green light
              // ]);
              
              var localData = data[i].data       // full 
              var updatedData = {}

      
              updatedData = transformData(localData);        
              series.data.setAll(updatedData);

                //-------------------------------------------Load Scrollbar with data
                let scrollBarData = scrollbar.chart.series.push(
                  am5xy.LineSeries.new(root, {
                    xAxis: sbxAxis,
                    yAxis: sbyAxis,
                    valueYField: "value",
                    valueXField: "date",
                  })
                );

                scrollBarData.data.setAll(updatedData);;
            }

            // Zoom chart all the way out
            chart.zoomOut()
            
            // Tooltip to jump between points
            chart.get("cursor").set("xAxis", XAxis);
            chart.get("cursor").set("snapToSeriesBy", "x");

            if(dataSeries.length != 0){
              chart.get("cursor").set("snapToSeries", [dataSeries[0]]);
            }

              if(id != 1){
                scrollbar.set('visible', false)
              } 


              //----------

              let legend = chart.bottomAxesContainer.children.push(am5.Legend.new(root, {
                visible: true,
                paddingTop: 0,
                paddingTop: 10,
                useDefaultMarker: true,
                x: am5.percent(0),
                centerX: am5.percent(0),
                centerY: am5.percent(70),
              }));
              legend.data.setAll(chart.series.values);
              legend.valueLabels.template.set("forceHidden", true);

              // Some legend styling
              legend.labels.template.setAll({
                fontSize: 15,
                fill: am5.color(COLORS.White),
                fontFamily: "Roboto Condensed",
                fontWeight: "500",
              });

              // console.log("END LOAD CHART ", id)

              props.chartLoaded(id)
          } 
      } 

        // TODO: Round up to nearest decimal
        function transformData(data) {
          return data.map(([date, value]) => ({
             date,
            value
          }));
        }

        function findMinMax(data, label){

          let lowestMin = Number.POSITIVE_INFINITY; // Start with the highest possible number
          let highestMax = Number.NEGATIVE_INFINITY; // Start with the lowest possible number

          data.forEach(item => {
            if(item.name.includes(label)){
                if (item.min < lowestMin) {
                  lowestMin = item.min; // Update lowestMin if the current item's min is lower
                }
                if (item.max > highestMax) {
                  highestMax = item.max; // Update highestMax if the current item's max is higher
                }
              }
            });

              lowestMin = Math.floor(lowestMin / 10) * 10;
              highestMax = Math.ceil(highestMax / 10) * 10;

          return {
            min: lowestMin,
            max: highestMax
          }
        }
    
      return (
        <div>
          {
            props.temperatureData ?
            <Card id="chartContainer" style={{display: props.temperatureData && props.temperatureData.length > 0 ? 'block' : 'none'}}>
               <div id={`chart${props.id}1`} style={{ width: "100%", height: '400px'}}></div>
            </Card>
            : null
          }

         {
          props.currentPressureData && props.temperatureData ?
            <Card id="chartContainer" style={{display: props.temperatureData && props.currentPressureData && props.currentPressureData.length > 0 ? 'block' : 'none'}}>
              <div id={`chart${props.id}2`} style={{ width: "100%", height: `200px`}}></div>
            </Card>
          : null
         }

        {
          props.stateData ? 
            <Card id="chartContainer" style={{display: props.temperatureData && props.stateData && props.stateData.length > 0 ? 'block' : 'none'}}>
               <div id={`chart${props.id}3`} style={{ width: "100%", height: `200px`}}></div>
            </Card>
            : null
        }


        </div>
      );
}

const Card = styled.div`
/* border: 1px red dashed; */
display: flex;
flex-direction: column;
background: #17253E;
border-radius: 4px;
padding: 20px;
margin-bottom: 20px;
padding-bottom: 10px;
margin-right: 20px;
/* margin-top: 33px; */
`;
