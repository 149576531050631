import React, { useEffect, useState } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import ImgProperty from "./../../images/ic_property.svg"
import ImgAdd from "./../../images/ic_add.svg"
import Select from "react-dropdown-select"
import Fuse from "fuse.js"
import { COLORS } from "../../styles/colors"

const Container = styled.div`
  /* border: 1px dashed yellow; */
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  width: 377px;
  ${media.lessThan("743px")`
        width: 280px;
    `}
`

const WingnutDetails = styled.div`
  border: 1px solid ${COLORS.HighlightMedium};
    /* border: 1px solid red; */
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: none;
  ${media.lessThan("743px")`

    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`



const StyledSelect = styled(Select)`
  height: 45px;
  color: ${COLORS.White};
  background: ${COLORS.Secondary};
  /* margin-top: 15px;  */

  ${media.lessThan("743px")`
        /* width: 90%; */
        /* height: 55px; */
    `}
  ${media.between("744px", "large")`
        width: 100%;
        /* height: 55px; */
    `}
    ${media.greaterThan("large")`
    `}

    .react-dropdown-select-clear,
    .react-dropdown-select-dropdown-handle {
    color: ${COLORS.White};
    height: 22px;
  }
  .react-dropdown-select-option {
  }
  .react-dropdown-select-item {
  }
  .react-dropdown-select-input {
    color: ${COLORS.White};             // cursor select
  }
  .react-dropdown-select-dropdown {
    background: ${COLORS.Secondary};            // card background
    border-radius: 8px;
    border: 1px solid ${COLORS.HighlightMedium};
  }
  .react-dropdown-select-item {
    color: ${COLORS.White};                     // list item
    border-bottom: none;
    padding-top: 12px;
    padding-bottom: 12px;
    /* height: 60px; */
    font-size: 16px;
    :hover {
      background: ${COLORS.HighlightMedium};
    }
  }
  .react-dropdown-select-item.react-dropdown-select-item-selected{
    color: ${COLORS.White};                   // selected item
    background: ${COLORS.HighlightMedium};
    border-bottom: none;
  }
  .react-dropdown-select-item.react-dropdown-select-item-active {
    //background: #111;
    /* border-bottom: 1px solid #333; */

    /* font-weight: bold; */

  }
  .react-dropdown-select-item.react-dropdown-select-item-disabled {
    /* color: #ccc; */
  }
  ::placeholder {

  }
  :focus {
    outline: ${COLORS.White};
    border: 1px solid ${COLORS.White};
  }
`

const Input = styled.input`
background: transparent;
border: none;
/* border: 1px solid red; */
outline: none;
width: 100%;
height: 45px;
/* height: 100%; */
color: ${COLORS.White};
/* font-family: "Roboto Condensed", sans-serif; */
`;


const fuzzyOptions = {
  threshold: 0.1,
  keys: ["name"],
}

export default function NotificationEmployeeSelect(props) {
  const { data } = props
  const [isSelected, setIsSelected] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  const [input, setInput] = useState("")
  const [searchOptions, setSearchOptions] = useState([])
  const fuseSearch = new Fuse(data, fuzzyOptions)

  useEffect(() => {
    setSearchOptions(mapRawData(data))
  }, [props])

  const mapRawData = (data) => {
    // Get all the Id's of Employees in view, don't show any in dropdown
    const viewEmployeeIds = []
    const viewEmployees  = props.viewEmployees

    viewEmployees.forEach((item) => {
      viewEmployeeIds.push(item.id)
    })

    const filteredData = data.filter((item) => !viewEmployeeIds.includes(item.id))

    const options = filteredData.map((item) => (
      { key: item.id,
        label: item.name,
        value: item.id,}
     ) 
    )

    return options
  }

  const filterOptions = (filter) => {
    if (filter) {
      setSearchOptions(mapRawData(fuseSearch.search(filter).map((s) => s.item)))
      setInput(filter)
    } else {
      setSearchOptions(mapRawData(fuseSearch.getIndex().docs))
      setInput(input.slice(0, -1))
    }
  }

  const onSelected = (item) => {
    if (item[0]) {
      // setSelectedProperty(item[0])
      // setIsSelected(true)
      props.addEmployee(item[0].value)
      setInput("")
    } else {
      // setSelectedProperty(null)
      // setIsSelected(false)
    }
  }


  return (
    <Container>
      <WingnutDetails>
        <div      
            onMouseEnter={(e) => {
            setIsHovering(true)
            }}
            onMouseLeave={(e) => {
            setIsHovering(false)
          }}>
        <StyledSelect
          disabled={props.disabled}
          dropdownGap={0}
          keepOpen={false}
          placeholder={"Search Employee"}
          onChange={(value) => onSelected(value)}
          options={searchOptions}
          values={[]}
          inputRenderer={
            ({ props, state, methods, inputRef }) => ( 
               <Input 
                id="inputID" 
                autoComplete="off"
                onChange={(event) => filterOptions(event.target.value)}
                value={input}
                hidden={false} 
                placeholder={"Search Employee"}
                ref={inputRef} 
                style={{ cursor: 'pointer', color: COLORS.White, fontSize: "18px", fontWeight: "300"}} 
              /> 
            )
          }
          searchable={true}
          style={{
            border: isHovering ? "1px solid #d5d5d5" : isSelected ? "1px solid #d5d5d5" : "1px solid transparent",
            borderRadius: 8,
            fontWeight: "300",
            paddingLeft: "12px",
            fontFamily: "Roboto Condensed",
            fontSize: "0px"
          }}
          />
          </div>
      </WingnutDetails>
    </Container>
  )

}
