import React, { useState, useContext } from "react"
import { API, Auth } from "aws-amplify"
import { useHistory } from "react-router-dom"
import { AccountContext } from "./../../auth/Account"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import media from "styled-media-query"
import Image3 from "./../../images/ic_arrow_right_green.svg"
import { DarkButtonSpinner } from "./../custom/Spinner"
import { ValidatorStrings, ServerStrings, WingnutValidatorStrings } from "./../util/VaildatorStrings"
import { ErrorContainer, Icon, Error, EmptyError } from "./../custom/ValidatorStyles"
import { COLORS } from "../../styles/colors"

const Container = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: column;
  align-content: center;
  width: auto;
  ${media.lessThan("743px")`
        align-self: center;
        width: 100%;
        `}
`

const Form = styled.form`
  /* border: 1px blue dashed; */
  width: 439px;
  display: flex;
  flex-direction: column;
  margin-top: 27px;
  margin-right: 83px;
  ${media.lessThan("743px")`
        align-self: center;
        width: 100%;
        margin-right: 0px;
        margin-top: 30px;
    `}
  ${media.between("744px", "large")`
        width: 384px;
    `}
`

const HeaderText = styled.h1`
  font-family: Blinker;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 84.5%;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
  margin-bottom: 22px;
  ${media.lessThan("743px")`
        /* align-self: center; */
    `}
`

const Label = styled.label`
  /* border: 1px blue dashed; */
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 129.5%;
  letter-spacing: 0.03em;
  color: ${COLORS.HighlightLight};
  margin-bottom: 2px;
  margin-top: 6px;
`

const Input = styled.input`
  /* border: 1px blue dashed; */
  width: 100%;
  height: 45px;
  outline: none;
  border: none;
  color: ${COLORS.White};

  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 129.5%;
  font-weight: 400;
  background-color: #1d283e;
  border-radius: 8px;
  box-sizing: border-box;
  padding-left: 10px;
  ::placeholder {
    color: ${COLORS.HighlightMedium};
  }
  :hover {
    border: 1px solid ${COLORS.HighlightMedium};
    padding-left: 9px;
  }
  :focus {
    outline: ${COLORS.White};
    border: 1px solid ${COLORS.HighlightLight};
    padding-left: 9px;
  }
  ${media.lessThan("743px")`
        width: 90%;
        height: 55px;
    `}
  ${media.between("744px", "large")`
        width: 100%;
        height: 55px;
    `}
    ${media.greaterThan("large")`
    `}
`

const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* padding: 10px 20px; */
  align-self: flex-end;
  width: 170px;
  height: 45px;
  border: none;
  border-radius: 30px;
  background: #63c085;
  color: black;
  cursor: pointer;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 13px;
  text-align: center;
  letter-spacing: 0.05em;
  margin-bottom: 10px;
  margin-top: 10px;
  transition-duration: 0.5s;
  :hover {
    opacity: 0.8;
  }
  ${media.lessThan("743px")`
        align-self: flex-start;
        margin-top: 28px;
        height: 55px;
    `}
  ${media.between("744px", "large")`
        height: 55px;
    `}
    ${media.greaterThan("large")`
    `}
`

const ButtonText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: black;
`

const IconArrow = styled.img`
  width: 6;
  height: 10px;
  /* margin-left: 20px; */
  align-self: center;
  transition-duration: 0.5s;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

String.prototype.splice = function(idx, rem, str) {
  return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
};


export default function AddWingnutForm() {
  const history = useHistory()

  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors, isSubmitSuccessful },
  } = useForm({ reValidateMode: "onSubmit" })

  const { getSession, authenticateChangeEmail } = useContext(AccountContext)
  const [showSpinner, setShowSpinner] = useState(false)
  const [passwordShown, setPasswordShown] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [serverError, setServerError] = useState(null)
  const [isHovering, setIsHovering] = useState(false)

  const onSubmit = async (data) => {
    setServerError(null)
    setShowSpinner(true)
    setDisabled(true)

    const token = (await Auth.currentSession()).getIdToken()
    const jwt = token.getJwtToken()
    const init = {
      headers: { Authorization: `Bearer ${jwt}` },
      body: {
        wingnutId: data.uuid.toLowerCase()
      }
    }
    try {
      const response = await API.post('FrontendAPI-wingnuts', ``, init)
      if (response.success) {
        getSession().then(() => {
          history.push(`add/success/${data.uuid}`)
        })
      } else {
        setShowSpinner(false)
        setDisabled(false)
        setServerError(ServerStrings("InvalidWingnut"))
      }
    } catch (error) {
      setShowSpinner(false)
      setDisabled(false)
      setServerError(ServerStrings("InvalidWingnut"))
    }
  }

  const isUUID = (uuid) => {
    const regex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi
    return regex.test(uuid)
  }

  const onUUIDChange = (e) => {
    e.preventDefault()
    var value = e.target.value

    var shouldDelete = true
    // if value starts with - it means another character was deleted
    if (value.substr(value.length - 1) == "-") {
      shouldDelete = false
    }

    // only want Hexidecimal Values
    value = value.replace(/[^a-fA-F0-9]+/g,'')

    if (value.length > 32) {
      value = value.slice(0, 32)
    }

    if (value.length > 19) {
      value = value.splice(20, 0, "-")
    }
    if (value.length > 15) {
      value = value.splice(16, 0, "-")
    }
    if (value.length > 11) {
      value = value.splice(12, 0, "-")
    } 
    if (value.length > 7) {
      value = value.splice(8, 0, "-")
    }


    // Character deleted, remove dash and number behind dash
    if (!e.nativeEvent.data && value.substr(value.length - 1) == "-" && shouldDelete) {
      value = value.slice(0, -2)
    }

    setValue("uuid", value.toLowerCase())
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Label>UUID</Label>
        <Input
          type='text'
          name='uuid'
          placeholder='00000000-0000-0000-0000-000000000000'
          {...register("uuid", { 
            required: true,
            maxLength: 40,
            validate: isUUID,
            onChange: (e) => {onUUIDChange(e)}
          })}
          style={{ border: errors.uuid || serverError ? "1px solid #D25353" : null, paddingLeft: errors.uuid || serverError ? '9px' : null }}
        />
        {errors.uuid ? <Error>{WingnutValidatorStrings(errors.uuid?.type)}</Error> : serverError ? <Error>{serverError}</Error> : <EmptyError />}

        <Button disabled={disabled} type='submit'>
            <ButtonText style={{marginLeft: disabled ? "22px" : null}}>CONTINUE</ButtonText>
            {showSpinner && <DarkButtonSpinner animation='border' />}
        </Button>
      </Form>
    </Container>
  )
}
