
export default function GetTimezoneFromLatLng(lat, lng) {
  const timestamp = Math.floor(Date.now() / 1000); // current timestamp in seconds
  const apiUrl = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${timestamp}&key=AIzaSyD7hBW1S5Xum-sZn_Z7JrHCgJP5suaMozU`;

  return fetch(apiUrl)
    .then((response) => response.json())
    .then((data) => {
      const timezoneLabel = data.timeZoneId;
      const utcOffset = data.rawOffset + data.dstOffset;

      const offsetSeconds = data.rawOffset + data.dstOffset;
      const offsetHours = offsetSeconds / 3600;
      const offsetSign = offsetHours > 0 ? '+' : '-';
      const offsetAbs = Math.abs(offsetHours);
      const offsetString = `Property Time - (GMT${offsetSign}${offsetAbs}) `;
      const offset = `(GMT${offsetSign}${offsetAbs}) `;
      const finalLabel = offsetString + timezoneLabel

      return { label: finalLabel, timezoneLabel: timezoneLabel, offset: offset};
    })
    .catch((error) => console.error('Error:', error));
}