import React, { useState, useContext, useEffect } from "react"
import { API, Auth } from "aws-amplify"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import media from "styled-media-query"
import Image from "./../../images/ic_check_circle.svg"
import { DarkButtonSpinner } from "./../custom/Spinner"
import ErrorImage from "./../../images/ic_alert.svg"
import { SpinnerContainer, StyledSpinner } from "../custom/Spinner"
import { useMediaQuery } from 'react-responsive'
import {
  ErrorContainer,
  Icon,
  Error,
  EmptyError,
} from "../custom/ValidatorStyles"
import { PhoneValidatorStrings, ValidatorStrings, ServerStrings } from "../util/VaildatorStrings"
import AddressSearch from "./AddressSearch"
import { COLORS } from "../../styles/colors"

const Container = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  flex-direction: row;
  /* width: 100%; */
  max-width: 1440px;
  height: auto;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  align-content: center;
  margin-top: -10px;
  margin-right: 20px;
  padding-left: 20px;
  padding-top: 20px;
  background: #0e1c36;
  ${media.lessThan("743px")`
      align-self: center;
      width: 100%;
      margin-right: 0px;
    `}
`

const FormContainer = styled.div`
  /* border: 1px yellow dashed; */
  width: 50%;
  min-width: 379px;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  ${media.lessThan("743px")`
    flex-direction: column;
    min-width: 100%;
    margin-right: 0px;
    `}
  ${media.between("744px", "large")`
    flex-direction: column;
    width: 100%;
    margin-right: 0px;
    `}
`

const Form = styled.form`
  /* border: 1px red dashed; */
  width: 100%;
  max-width: 439px;
  display: flex;
  flex-direction: column;
  /* margin-top: 46px; */
  ${media.lessThan("743px")`
        align-self: center;
        width: 100%;
        margin-top: 0px;
        flex-direction: column;
    `}
  ${media.between("744px", "large")`
        /* width: 384px; */
        flex-direction: column;
    `}
`

const HeaderText = styled.h2`
  font-family: Blinker;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 84.5%;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
  margin-bottom: 11px;
  margin-top: 20px;
`

const Label = styled.label`
  /* border: 1px blue dashed; */
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 129.5%;
  letter-spacing: 0.03em;
  color: ${COLORS.HighlightLight};
  /* margin-top: 2px; */
  margin-bottom: 2px;
`

const Input = styled.input`
  /* border: 1px blue dashed; */
  width: 100%;
  height: 45px;
  outline: none;
  border: none;
  color: ${COLORS.White};
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 129.5%;
  font-weight: normal;
  background-color: ${COLORS.Secondary};
  border-radius: 8px;
  box-sizing: border-box;
  padding-left: 10px;
  ::placeholder {
    color: ${COLORS.HighlightMedium};
  }
  :hover {
    border: 1px solid ${COLORS.HighlightMedium};
    padding-left: 9px;
  }
  :focus {
    border: 1px solid ${COLORS.HighlightLight};
    padding-left: 9px;
  }
  ${media.lessThan("743px")`
        width: 100%;
        height: 55px;
    `}
  ${media.between("744px", "large")`
        width: 100%;
        height: 55px;
    `}
    ${media.greaterThan("large")`
    `}
`

const InputDisabled = styled(Input)`
  color: ${COLORS.HighlightLight};

  :hover {
    border: none;
    padding-left: 10px;
  }
  :focus {
    border: none;
    padding-left: 10px;
  }
`

const Input2 = styled(Input)`
  width: 96%;
  ${media.lessThan("743px")`
        width: 100%;
        height: 55px;
    `}
    ${media.greaterThan("large")`
      margin-right: 10px;
    `}
`

const Input3 = styled(Input)`
  width: 100%;
  ${media.lessThan("743px")`
        width: 100%;
        height: 55px;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const ButtonContainer = styled.div`
  /* border: 1px blue dashed; */
  display: flex;
  flex-direction: row;
  width: auto;
  margin-top: 28px;
  ${media.lessThan("743px")`
    align-self: flex-start;
    flex-direction: column;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`;

const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  align-self: flex-start;
  width: 180px;
  height: 40px;
  border: none;
  background: #63c085;
  cursor: pointer;
  border-radius: 36px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 13px;
  text-align: center;
  letter-spacing: 0.05em;
  margin-right: 10px;
  :hover {
    opacity: 0.8;
  }
  ${media.lessThan("743px")`
        align-self: flex-start;
        height: 55px;
        /* width: 200px; */
    `}
  ${media.between("744px", "large")`
        height: 55px;
        /* width: 200px; */
    `}
    ${media.greaterThan("large")`
    `}
`

const ButtonCancel = styled(Button)`
  background: transparent;
  border: 1px solid #63c085;
  color: ${COLORS.White};
  :hover {
    background: ${COLORS.Secondary};
    opacity: 1;
  }
  ${media.lessThan("743px")`
    margin-top: 10px;
        align-self: flex-start;
        height: 55px;
        /* width: 200px; */
    `}
`;

const ButtonText = styled.p`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #0c182f;
  :hover {
    color: ${COLORS.Base};
  }
`

const ButtonText2 = styled(ButtonText)`
      color: ${COLORS.White};
`;

const SectionContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: column;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
`

const StatusIcon = styled.img`
  width: 23px;
  height: 23px;
  /* margin-bottom: 20px; */
  margin-top: 20px;
`

const RowContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  ${media.lessThan("743px")`
        flex-direction: column;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const ColumnContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const SuccessContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  align-content: center;
  height: 40px;
  /* margin-top: 5px; */
  ${media.lessThan("743px")`
        align-self: flex-start;
        `}
`

export const SuccessIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 13px;
  transition-duration: 0.5s;
  color: ${COLORS.White};
  align-self: center;
  ${media.lessThan("743px")`
        `}
  ${media.between("744px", "large")`
        `}
        ${media.greaterThan("large")`
    `}
`

export const Success = styled.p`
  /* border: 1px blue dashed; */
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: ${COLORS.White};
  align-self: center;
  ${media.lessThan("743px")`
            width: 100%;
        `}
  ${media.between("744px", "large")`
        `}
`

export const EmptySuccess = styled(Success)`
  /* border: 1px blue dashed; */
  /* margin-bottom: 50px; */
  height: 36px;
`

export default function EditPropertyDetails(props) {
  const { propertyDetails, editOpen, updatePropertyDetails } = props
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { errors, isSubmitSuccessful },
  } = useForm({ reValidateMode: "onSubmit" })

  const [success, setSuccess] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const onSubmit = async (data) => {
    const { name, externalId, portfolio, street, city, provinceState, postalZipCode, country } = data
    setDisabled(true)

    const property = {
      externalId: externalId,
      config: {
        name: name,
        address: {
          city: city,
          country: country,
          postalZip: postalZipCode,
          provinceState: provinceState,
          streetAddress: street
        },
        assets: {}, // Unused for now
        portfolio: portfolio
      }
    }

    async function editProperty() {
      const token = (await Auth.currentSession()).getIdToken()
      const jwt = token.getJwtToken()
      const init = {
        headers: { Authorization: `Bearer ${jwt}` },
        body: property
      }
      const response = await API.post('FrontendAPI-properties', `/${propertyDetails.id}`, init)
      if (response.success) {
        setSuccess(true)
      }
      setDisabled(false)
    }

    editProperty()
  }

  const selectAddress = (newAddress) => {
    setValue("street", newAddress.streetAddress)
    setValue("city", newAddress.city)
    setValue("provinceState", newAddress.provinceState)
    setValue("postalZipCode", newAddress.postalZip)
    setValue("country", newAddress.country)
  }

  const propertyIdValidation = (e) => {
    var value = e.target.value
    value = value.replace(/\D/gm,'')
    setValue("externalId", value)
  }

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess(false)
      }, 2000)
      return () => {
        clearTimeout(timer)
        editOpen(false)
        updatePropertyDetails()
      }
    }
  }, [success])

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
      <FormContainer>
        <SectionContainer>
          <HeaderText>General Information</HeaderText>
            <Label>Property Name</Label>
            <InputDisabled 
              disabled={true}
              type='text' 
              name='name' 
              placeholder={"Name used to identify the property"}
              {...register("name", { required: true, maxLength: 45, value: propertyDetails.config.name })}
              style={{ border: errors.name ? "1px solid #D25353" : null, paddingLeft: errors.name ? '9px' : null }}
            />
            {errors.name ? <Error>{ValidatorStrings(errors.name?.type)}</Error> : <EmptyError />}
            <Label>Property ID</Label>
            <InputDisabled 
               disabled={true}
              type='text' 
              name='externalId' 
              placeholder={"ID used to identify the property"}
              {...register("externalId", { 
                required: true, maxLength: 45, 
                value: propertyDetails.externalId,
                onChange: (e) => {propertyIdValidation(e)}
              })}
              style={{ border: errors.externalId ? "1px solid #D25353" : null, paddingLeft: errors.externalId ? '9px' : null }}
            />
            {errors.externalId ? <Error>{ValidatorStrings(errors.externalId?.type)}</Error> : <EmptyError />}
            <Label>Portfolio</Label>
            <Input 
              type='text'
              name='portfolio' 
              placeholder={"Portfolio Name"} 
              {...register("portfolio", { required: false, maxLength: 45, value: propertyDetails.config.portfolio })}
              style={{ border: errors.portfolio ? "1px solid #D25353" : null, paddingLeft: errors.portfolio ? '9px' : null }}
            />
            {errors.protfolio ? <Error>{ValidatorStrings(errors.portfolio?.type)}</Error> : <EmptyError />}
          </SectionContainer>
        </FormContainer>
        <FormContainer>
        <SectionContainer>
        <HeaderText>Address</HeaderText>
        <Label>Street Address</Label>
          <AddressSearch selectAddress={selectAddress} defaultValue={propertyDetails.config.address.streetAddress} errors={errors} />
          {errors.street ? <Error>{ValidatorStrings(errors.street?.type)}</Error> : <EmptyError />}
          <Label>City</Label>
          <Input 
            type='text' 
            name='city' 
            placeholder='City' 
            {...register("city", { required: true, maxLength: 45, value: propertyDetails.config.address.city })}
            style={{ border: errors.city ? "1px solid #D25353" : null, paddingLeft: errors.city ? '9px' : null }}
          />
          {errors.city ? <Error>{ValidatorStrings(errors.city?.type)}</Error> : <EmptyError />}
          <Label>Country</Label>
          <Input 
            type='text'
            name='country'
            placeholder='Country'
            {...register("country", { required: true, maxLength: 45, value: propertyDetails.config.address.country })}
            style={{ border: errors.country ? "1px solid #D25353" : null, paddingLeft: errors.city ? '9px' : null }}
          />
          {errors.city ? <Error>{ValidatorStrings(errors.city?.type)}</Error> : <EmptyError />}
          <RowContainer>
            <ColumnContainer>
              <Label>Province / State</Label>
              <Input2
                type='text' 
                name='provinceState' 
                placeholder='Province / State'
                {...register("provinceState", { required: true, maxLength: 20, value: propertyDetails.config.address.provinceState })}
                style={{ border: errors.provinceState ? "1px solid #D25353" : null, paddingLeft: errors.provinceState ? '9px' : null }}
              />
              {errors.provinceState ? <Error>{ValidatorStrings(errors.provinceState?.type)}</Error> : <EmptyError />}
            </ColumnContainer>
            <ColumnContainer>
              <Label>Postal / ZIP Code</Label>
              <Input3 
                type='text' 
                name='postalZipCode' 
                placeholder='Postal / ZIP Code' 
                {...register("postalZipCode", { required: true, maxLength: 20, value: propertyDetails.config.address.postalZip })}
                style={{ border: errors.postalZipCode ? "1px solid #D25353" : null, paddingLeft: errors.postalZipCode ? '9px' : null }}
              />
              {errors.postalZipCode ? <Error>{ValidatorStrings(errors.postalZipCode?.type)}</Error> : <EmptyError />}
            </ColumnContainer>
          </RowContainer>
        </SectionContainer>

          <ButtonContainer>   
          
          <Button disabled={disabled} type='submit' >
            <ButtonText style={{marginLeft: disabled ? "22px" : null}}>SAVE CHANGES</ButtonText>
            {disabled && <DarkButtonSpinner animation='border' />}
          </Button>


            <ButtonCancel onClick={(e) => {
            e.preventDefault()
            setDisabled(false)
            editOpen(false)
          }}
          type='text'>
                <ButtonText2>CANCEL</ButtonText2>
            </ButtonCancel>
        </ButtonContainer>

        {success ? (
        <SuccessContainer>
          <SuccessIcon src={Image} />
          <Success>Success. Your changes have been saved</Success>
        </SuccessContainer>
        ) : (
          <EmptySuccess></EmptySuccess>
        )}
        </FormContainer>

      </Form>
    </Container>
  )
}
