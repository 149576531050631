import React, { useState, useEffect, useContext } from "react"
import styled from "styled-components"
import { useTable, useSortBy } from "react-table"
import { AccountContext } from "../../auth/Account"
import { COLORS } from "../../styles/colors"

// Implements the table designed in NotificationTableStructure

function Table({ columns, data, columnsToHide }) {

  const { getSession } = useContext(AccountContext)
  const [isAuth, setIsAuth ] = useState(false)

  useEffect(() => {
    getSession().then(({ isAuth }) => {
      setIsAuth(isAuth)
    })
  }, [])


  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: columnsToHide,
      },
    },
    useSortBy
  )

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps([
                  {
                    style: { minWidth: column.minWidth, width: column.width, backgroundColor: COLORS.Primary },
                  },
                  column.getSortByToggleProps(),
                ])}
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()}>
                    {cell.render("Cell", {
                      propertyName: row.original.propertyName,
                      propertyId: row.original.propertyId,
                      externalId: row.original.externalId,
                      address: row.original.address,
                      portfolio: row.original.portfolio,
                      toggle: row.original.toggle,
                      notificationId: row.original.notificationId,
                      isAuth: isAuth
                    })}
                  </td>
                ) // YES
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default Table
