import React, {useState} from "react"
import { BrowserRouter as Router, Switch, Route, useLocation, useHistory } from "react-router-dom"
import "./App.scss"
import MediaQuery from "react-responsive"
import ResetPassword from "./ui/layout/ResetPassword"
import CreateAccount from "./ui/layout/CreateAccount"
import SideNav from "./ui/navbar/SideNav"
import NavBar from "./ui/navbar/NavBar"
import Header from "./ui/navbar/Header"
import styled from "styled-components"
import AccountSettings from "./ui/layout/Account"
import WingnutOverview from "./ui/wingnuts/WingnutOverview"
import WingnutDetail from "./ui/wingnuts/WingnutDetails"
import AddWingnut from "./ui/wingnuts/AddWingnut"
import LinkWingnutToProperty from "./ui/wingnuts/LinkWingnutToProperty"
import AddWingnutSuccess from "./ui/wingnuts/AddWingnutSuccess"
import PropertyOverview from "./ui/properties/PropertyOverview"
import PropertyDetail from "./ui/properties/PropertyDetail"
import AddPropertyContainer from "./ui/properties/AddPropertyContainer"
import PropertyLink from "./ui/wingnuts/LinkPropertyToWingnut"
import Dashboard from "./ui/layout/Dashboard"
import HomeLayout from "./ui/home/HomeLayout"
import Help from "./ui/layout/Help"
import PrivacyPolicy from "./ui/form/PrivacyPolicy"
import TermsOfService from "./ui/form/TermsOfService"
import LoginStatic from "./ui/layout/LoginStatic"
import ProtectedRoute from "./auth/ProtectedRoute"
import Notification from "./ui/notification/NotificationOverview"
import NotificationDetails from "./ui/notification/NotificationDetails"
import { COLORS } from "./styles/colors"

// https://stackoverflow.com/questions/40612663/how-to-add-scroll-to-child-of-flex-item

const PageContainer = styled.div`
  display: flex;
  /* flex-flow: row wrap; */
  /* height: 200px; */
`

const RightPanel = styled.div`
  flex: 1;
  display: flex; /*Added this*/
  flex-direction: column; /*Added this*/
  height: 100vh;
  background-color: ${COLORS.Base};;
  padding-left: 30px;
`

const ContentPanel = styled.div`
  display: block;
  overflow: auto;
  flex: 1; /*Added this*/
  height: 100vh;
`

const NavBarContainer = styled.div`
  display: flex;
  flex-direction: column;
`

function AppRouter(props) {

  return (
    <div>
      <MediaQuery maxWidth={743}>
        <Switch>
          <Route path='/resetpassword' component={ResetPassword} />
          <Route path='/createaccount' component={CreateAccount} location={props.location} />
          <Route path='/privacy' component={PrivacyPolicy} />
          <Route path='/terms' component={TermsOfService} />
          <Route path='/login' component={LoginStatic} />
          <ProtectedRoute path='/portal' auth={props.isAuth} component={() => {
              return (
                <NavBarContainer>
                  <NavBar />
                  <Header />
                      {/* <ProtectedRoute exact path='/portal/dashboard' component={Dashboard} auth={props.isAuth} /> */}
                      <ProtectedRoute path='/portal/dashboard/:id' component={Dashboard} auth={props.isAuth} />
                      <ProtectedRoute path='/portal/account' component={AccountSettings} auth={props.isAuth} />
                      <ProtectedRoute exact path='/portal/wingnut' component={WingnutOverview} auth={props.isAuth} />
                      <ProtectedRoute exact path='/portal/wingnut/detail/:id' component={WingnutDetail} auth={props.isAuth}/>
                      <ProtectedRoute exact path='/portal/wingnut/add' component={AddWingnut} auth={props.isAuth} />
                      <ProtectedRoute exact path='/portal/wingnut/link/:id' component={LinkWingnutToProperty} auth={props.isAuth}/>
                      <ProtectedRoute exact path='/portal/wingnut/add/success/:id' component={AddWingnutSuccess} auth={props.isAuth}/>
                      <ProtectedRoute exact path='/portal/property' component={PropertyOverview} auth={props.isAuth} />
                      <ProtectedRoute exact path='/portal/property/detail/:id' component={PropertyDetail} auth={props.isAuth}/>
                      <ProtectedRoute exact path='/portal/property/add' component={AddPropertyContainer} auth={props.isAuth} />
                      <ProtectedRoute exact path='/portal/property/link/:id' component={PropertyLink} auth={props.isAuth} />         
                      <ProtectedRoute exact path='/portal/notification' component={Notification} auth={props.isAuth} />                       
                      <ProtectedRoute path='/portal/help' component={Help} auth={props.isAuth} />
                </NavBarContainer>
              )
            }}
          ></ProtectedRoute>
          <Route exact path='/'>
            <HomeLayout />
          </Route>
        </Switch>
      </MediaQuery>

      <MediaQuery minWidth={744}>
        <Switch>
          <Route path='/resetpassword' component={ResetPassword} />
          <Route path='/createaccount' component={CreateAccount} />
          <Route path='/privacy' component={PrivacyPolicy} />
          <Route path='/terms' component={TermsOfService} />
          <Route path='/login' component={LoginStatic} />
          <ProtectedRoute path='/portal' auth={props.isAuth} component={() => {
              return (
                <PageContainer>
                  <SideNav/>
                  <RightPanel>
                    <Header />
                    <ContentPanel>
                      {/* <ProtectedRoute exact path='/portal/dashboard' component={Dashboard} auth={props.isAuth} /> */}
                      <ProtectedRoute path='/portal/dashboard/:id' component={Dashboard} auth={props.isAuth} />
                      <ProtectedRoute path='/portal/account' component={AccountSettings} auth={props.isAuth} />
                      <ProtectedRoute exact path='/portal/wingnut' component={WingnutOverview} auth={props.isAuth} />
                      <ProtectedRoute exact path='/portal/wingnut/detail/:id' component={WingnutDetail} auth={props.isAuth}/>
                      <ProtectedRoute exact path='/portal/wingnut/add' component={AddWingnut} auth={props.isAuth} />
                      <ProtectedRoute exact path='/portal/wingnut/link/:id' component={LinkWingnutToProperty} auth={props.isAuth}/>
                      <ProtectedRoute exact path='/portal/wingnut/add/success/:id' component={AddWingnutSuccess} auth={props.isAuth}/>
                      <ProtectedRoute exact path='/portal/property' component={PropertyOverview} auth={props.isAuth} />
                      <ProtectedRoute exact path='/portal/property/detail/:id' component={PropertyDetail} auth={props.isAuth}/>
                      <ProtectedRoute exact path='/portal/property/add' component={AddPropertyContainer} auth={props.isAuth} />
                      <ProtectedRoute exact path='/portal/property/link/:id' component={PropertyLink} auth={props.isAuth} />      
                      <ProtectedRoute exact path='/portal/notification' component={Notification} auth={props.isAuth} />           
                      <ProtectedRoute path='/portal/help' component={Help} auth={props.isAuth} />
                    </ContentPanel>
                  </RightPanel>
                </PageContainer>
              )
            }}
          ></ProtectedRoute>
          <Route path='/' component={HomeLayout} />
        </Switch>
      </MediaQuery>
    </div>
  )
}

export default AppRouter

  // Refactor to a single source of truth for routes once bugs can be worked out
  // const ProtectedRoutes = [
  //   <div>
  //     <ProtectedRoute path='/portal/dashboard' component={Dashboard} auth={props.isAuth} />
  //     <ProtectedRoute path='/portal/account' component={AccountSettings} auth={props.isAuth} />
  //     <ProtectedRoute path='/portal/wingnut' component={WingnutOverview} auth={props.isAuth} />
  //     <ProtectedRoute path='/portal/property' component={PropertyOverview} auth={props.isAuth} />
  //     <ProtectedRoute path='/portal/help' component={Help} auth={props.isAuth} />
  //   </div>
  // ]

  // const UnprotectedRoutes = [
  //    <div>
  //         <Route path='/resetpassword' component={ResetPassword} />
  //         <Route path='/createaccount' component={CreateAccount} />
  //         <Route path='/resetnewpassword' component={ResetNewPassword} />
  //         <Route path='/privacy' component={PrivacyPolicy} />
  //         <Route path='/terms' component={TermsOfService} />
  //         <Route path='/login' component={LoginStatic} />
  //   </div>
  // ]