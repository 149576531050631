import React, { useEffect, useState, useContext } from "react"
import Select from "react-dropdown-select"
import styled from "styled-components"
import media from "styled-media-query"
import { COLORS } from "../../styles/colors"

const StyledSelect = styled(Select)`
  height: 45px;
  color: ${COLORS.White};
  background: ${COLORS.Secondary};
  margin-top: 15px; 

  ${media.lessThan("744px")`
        /* width: 90%; */
        height: 55px;
    `}
  ${media.between("medium", "large")`
        width: 100%;
        height: 55px;
    `}
    ${media.greaterThan("large")`
    `}

    .react-dropdown-select-clear,
    .react-dropdown-select-dropdown-handle {
    color: ${COLORS.White};
    height: 22px;
  }
  .react-dropdown-select-option {
  }
  .react-dropdown-select-item {

  }
  .react-dropdown-select-input {
    color: ${COLORS.White};             // cursor select
  }
  .react-dropdown-select-dropdown {
    background: ${COLORS.Secondary};            // card background
    border-radius: 8px;
    border: 1px solid ${COLORS.HighlightMedium};
  }
  .react-dropdown-select-item {
    color: ${COLORS.White};                     // list item
    border-bottom: none;
    padding-top: 12px;
    padding-bottom: 12px;
    :hover {
      background: ${COLORS.HighlightMedium};
    }
  }
  .react-dropdown-select-item.react-dropdown-select-item-selected{
    color: ${COLORS.White};                   // selected item
    background: ${COLORS.HighlightMedium};
    border-bottom: none;
  }
  .react-dropdown-select-item.react-dropdown-select-item-active {
    //background: #111;
    /* border-bottom: 1px solid #333; */

    /* font-weight: bold; */

  }
  .react-dropdown-select-item.react-dropdown-select-item-disabled {
    /* color: #ccc; */
  }
  ::placeholder {
    color: ${COLORS.White};

  }
  :focus {
    outline: ${COLORS.White};
    border: 1px solid ${COLORS.White};
  }

`

export default function TimeRangeDropdownUI(props) {
  
  const [selected, setSelected] = useState(null)
  const [isSelected, setIsSelected] = useState(false)
  const [isHovering, setIsHovering] = useState(false)

  const customContentRenderer = () => (
    <div style={{ cursor: 'pointer', color: selected ? COLORS.White : COLORS.HighlightMedium}}>
      {selected? selected : props.placeholder}
    </div>
  );
  
  const onSelected = (item) => {
    if (item[0]) {
      setSelected(item[0].label)
      props.onSelectedValue(item[0])
      setIsSelected(true)
    } else {
      setSelected(null)
      setIsSelected(false)
    }
  }

  useEffect(() => {
      setSelected(null)
      setIsSelected(false)
  }, [props.clearInput])

  useEffect(() => {
    if (props.selectedValue.length > 0) {
      onSelected(props.selectedValue)
    }
  }, [])

  return (
    <div style={{ width: props.width}}                 
    onMouseEnter={(e) => {
      setIsHovering(true)
    }}
    onMouseLeave={(e) => {
      setIsHovering(false)
    }}
    >
      <StyledSelect
          dropdownGap={0}
          color={COLORS.White}
          keepOpen={false}
          contentRenderer={customContentRenderer}
          onChange={(value) => onSelected(value)}
          options={props.data}
          values={[]}
          style={{
              border: props.localAlert && selected === null ? "1px solid transparent" : isHovering ? `1px solid ${COLORS.HighlightMedium}` : isSelected ? "1px solid transparent" : `1px solid ${COLORS.HighlightMedium}`,
              borderRadius: 8,
              color: COLORS.White,
              fontWeight: "100",
              paddingLeft: "12px",
              fontFamily: "Roboto Condensed",
              fontSize: "16px",
          }}
      />
    </div>
  )
}
