import React, { useEffect, useState, useContext } from "react"
import media from "styled-media-query"
import styled from "styled-components"
import PropertyTable from "./PropertyTable"
import IconLaunch from "./../../images/ic_launch_green.svg"
import IconActive from "./../../images/ic_status_green.svg"
import IconAlert from "./../../images/ic_alert.svg"
import IconAlertBlue from "./../../images/ic_alert_blue_light.svg"
import IconNotification from "./../../images/ic_notification_blue.svg"
import IconUnconfigured from "./../../images/ic_status_purple.svg"
import { API, Auth } from "aws-amplify"
import IconSort from "./../../images/ic_arrows_sort.svg"
import { useHistory } from "react-router-dom"
import { Link } from "react-router-dom"
import ScreenSize from "../wingnuts/ScreenSize.enum"
import MediaQuery from "react-responsive"
import WingnutStatus from "../wingnuts/WingnutStatus.enum"
import { DateTime } from "luxon"
import { COLORS } from "../../styles/colors"
import ToggleTriState from './../notification/ToggleTriState'

// Handle the highlevel table construction
function PropertyTableStructure(props) {
  const history = useHistory()

  const [isHovering, setIsHovering] = useState(false)

  const updateToggle = async (toggle, notificationId) => {
    
    if(toggle !== null && notificationId !== null){
      var newConfig =  {
        "entityStatus": "",
     }
  
     newConfig['entityStatus'] = toggle === 1 ? "enabled" : "disabled"

            const token = (await Auth.currentSession()).getIdToken()
            const jwt = token.getJwtToken()
            const init = {
              headers: { Authorization: `Bearer ${jwt}` },
              body: newConfig
            }
            const response = await API.post('FrontendAPI-notifications', `/${notificationId}`, init)
            if (response.success) {
              // success save
            }  
    }
  }

  //----------------------------------------Custom Sort Functions
  const basicSort = React.useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
        const a = rowA.original[columnId]
        const b = rowB.original[columnId]
        if(a > b) return 1
        if (b > a) return -1
        return 0
    }
}, [])

const addressSort = React.useMemo(() => {
  return (rowA, rowB, columnId, desc) => {
      const a = rowA.original[columnId].streetAddress
      const b = rowB.original[columnId].streetAddress
      if (a > b) return 1
      if (b > a) return -1
      return 0
  }
}, [])

const portfolioSort = React.useMemo(() => {
  return (rowA, rowB, columnId, desc) => {
      const a = rowA.original[columnId]
      const b = rowB.original[columnId]
      const aId = rowA.original.propertyId
      const bId = rowB.original.propertyId
      if (a > b) return 1
      if (b > a) return -1
      if (aId > bId) return 1
      if (bId > aId) return -1
      return 0
  }
}, [])

const linkedWingnutsSort = React.useMemo(() => {
  return (rowA, rowB, columnId, desc) => {
      const a = rowA.original.linkedWingnuts.length
      const b = rowB.original.linkedWingnuts.length
      const aId = rowA.original.propertyId
      const bId = rowB.original.propertyId
      if (a > b) return 1
      if (b > a) return -1
      if (aId > bId) return 1
      if (bId > aId) return -1
      return 0
  }
}, [])

const alarmStateSort = React.useMemo(() => {
  return (rowA, rowB, columnId, desc) => {
      const a = rowA.original.alarmState
      const b = rowB.original.alarmState
      const aId = rowA.original.propertyId
      const bId = rowB.original.propertyId
      if (a > b) return 1
      if (b > a) return -1
      if (aId > bId) return 1
      if (bId > aId) return -1
      return 0
  }
}, [])

const notificationEnableSort = React.useMemo(() => {
  return (rowA, rowB, columnId, desc) => {
      const a = rowA.original.toggleStatus
      const b = rowB.original.toggleStatus
      const aId = rowA.original.propertyId
      const bId = rowB.original.propertyId
      if (a > b) return 1
      if (b > a) return -1
      if (aId > bId) return -1
      if (bId > aId) return 1
      return 0
  }
}, [])

const statusSort = React.useMemo(() => {
  return (rowA, rowB, columnId, desc) => {
    const a = rowA.original.linkedWingnuts.length > 0
    const b = rowB.original.linkedWingnuts.length > 0
      const aId = rowA.original.propertyId
      const bId = rowB.original.propertyId
      if (a > b) return 1
      if (b > a) return -1
      if (aId > bId) return -1
      if (bId > aId) return 1
      return 0
  }
}, [])

  const tableColumns = React.useMemo(
    () => [
      {
        // Turned int function to get access to column props { props.column.isSorted ? props.column.isSortedDesc ? ' 🔽' : ' 🔼' : ''}
        Header: (props) => {
          return (
            <HeaderDiv>
              <Text>Property ID</Text>
              <BtnIcon>
                <SortIcon src={IconSort} />
              </BtnIcon>
            </HeaderDiv>
          )
        },
        accessor: "propertyId",
        sortType: basicSort,
        // maxWidth: 301,
        // minWidth: 299,
        width: '10%',
        Cell: (props) => {
          return (
            <Link to={`/portal/property/detail/${props.propertyUUID}`} style={{ textDecoration: "none", color: COLORS.White }}>
              <CellDiv>
                <ContentDiv>
                  <BtnIcon>
                    <Icon src={IconLaunch} />
                  </BtnIcon>
                  <Text>{props.row.original.propertyIdDisplay}</Text>
                </ContentDiv>
              </CellDiv>
            </Link>
          )
        },
        // Cell: props => <Link to="/portal/property" style={{ textDecoration: 'none', color: 'white'  }}>{props.test}</Link>  // this works use everywhere
      },
      {
        Header: (
          <HeaderDiv>
            <Text>Property Name</Text>
            <BtnIcon>
              <SortIcon src={IconSort} />
            </BtnIcon>
          </HeaderDiv>
        ),
        accessor: "propertyName",
        sortType: basicSort,
        // maxWidth: 301,
        // minWidth: 299,
        width: '20%',
        Cell: (props) => {
          return (
            <Link to={`/portal/property/detail/${props.propertyUUID}`} style={{ textDecoration: "none", color: COLORS.White }}>
              <CellDiv>
                <ContentDiv>
                  <BtnIcon>
                    <Icon src={IconLaunch} />
                  </BtnIcon>
                  <Text>{props.propertyName}</Text>
                </ContentDiv>
              </CellDiv>
            </Link>
          )
        },
      },
      {
        Header: (
          <HeaderDiv>
            <Text>Address</Text>
            <BtnIcon>
              <SortIcon src={IconSort} />
            </BtnIcon>
          </HeaderDiv>
        ),
        accessor: "address",
        sortType: addressSort,
        // maxWidth: 301,
        // minWidth: 299,
        width: '30%',
        Cell: (props) => {
          return (
            <CellDiv>
              <StaticText>{props.address && `${props.address.streetAddress} ${props.address.city} ${props.address.provinceState} ${props.address.country} ${props.address.postalZip}`}</StaticText>
            </CellDiv>
            )
        },
      },
      {
        Header: (
          <HeaderDiv>
            <Text>Portfolio</Text>
            <BtnIcon>
              <SortIcon src={IconSort} />
            </BtnIcon>
          </HeaderDiv>
        ),
        accessor: "portfolio",
        sortType: portfolioSort,
        // maxWidth: 301,
        // minWidth: 299,
        width: '10%',
        Cell: (props) => {
          return (
            <CellDiv>
              <StaticText>{props.portfolio}</StaticText>
            </CellDiv>
            )
        },
      },
      {
        Header: (
          <HeaderDiv>
            <Text>Linked Wingnuts</Text>
            <BtnIcon>
              <SortIcon src={IconSort} />
            </BtnIcon>
          </HeaderDiv>
        ),
        accessor: "linkedWingnuts",
        sortType: linkedWingnutsSort,
        // maxWidth: 301,
        // minWidth: 299,
        width: '15%',
        Cell: (props) => {
          return (
            <WingnutDiv>
              {props.value.map((item) => {
                return (
                <div style={{ marginRight: 5 }}>
                  <Link to={`/portal/wingnut/detail/${item.id}`} style={{ textDecoration: "none", color: COLORS.White }}>
                      <LinkedWingnut>
                        <IconWingnut src={wingnutStatus(item)} />
                        {(item.externalId).substr(0,4)}
                      </LinkedWingnut>
                  </Link>                 
                </div>
                )
              })}
            </WingnutDiv>
          )
        },
      },
      {
        Header: (
          <HeaderDiv>
            <HiddenText>.</HiddenText>
            <HeaderAlertIcon src={IconAlertBlue} />
            <BtnIcon>
              <SortIcon src={IconSort} />
            </BtnIcon>
          </HeaderDiv>
        ),
        accessor: "alert",
        sortType: alarmStateSort,
        width: '5%',
        Cell: (props) => {
          return (
                <CellAlertDiv>
                  {props.alarmState === "ALARM" 
                  ?  <AlertIcon src={IconAlert} />
                  :  <AlertIcon src={IconAlertBlue} />            
                } 
                </CellAlertDiv>
          )
        },
      },
      {
        Header: (
          <HeaderDiv>
            <HiddenText>.</HiddenText>
            <HeaderNotificationIcon src={IconNotification} />
            <BtnIcon>
              <SortIcon src={IconSort} />
            </BtnIcon>
          </HeaderDiv>
        ),
        accessor: "toggle",
        sortType: notificationEnableSort,
        width: '5%',
        Cell: (props) => {
          return (
                <CellToggleChildDiv>
                  <ToggleTriState disable={props.isAuth ? 1 : 0} toggle={props.toggleStatus} updateToggle={(e) => updateToggle(e, props.notificationId)} />
                </CellToggleChildDiv>
          )
        },
      },
      {
        Header: (
          <HeaderDiv>
            <Text>Status</Text>
            <BtnIcon>
              <SortIcon src={IconSort} />
            </BtnIcon>
          </HeaderDiv>
        ),
        accessor: "status",
        sortType: statusSort,
        width: '10%',
        Cell: (props) => {
          return (
              <CellStatusDiv>
                <StatusIcon src={props.linkedWingnuts.length > 0 ? IconActive : IconUnconfigured} />
              </CellStatusDiv>
          )
        },
      },
    ],
    []
  )

  const hiddenColumns = (screenSize) => {
    // propertyId, propertyName, address, portfolio, linkedWingnuts, status
    var columnsToHide = []
    if (screenSize === ScreenSize.Medium) {
      // propertyId, propertyName, portfolio, linkedWingnuts, status
      columnsToHide = ["address", "portfolio"]
    }
    if (screenSize === ScreenSize.Small) {
      // propertyId, propertyName, status
      columnsToHide = ["address", "portfolio", "linkedWingnuts"]
    }
    return columnsToHide
  }

  const wingnutStatus = (item) => {
    if (item.config.latestPost) {
      var date = DateTime.utc()
      var apiDateTime = item.config.latestPost ? DateTime.fromFormat(item.config.latestPost, "yyyy-MM-dd HH:mm:ss") : DateTime.utc()
      var difference = date.diff(apiDateTime, 'minutes')
      if (difference.minutes > 15) {
        return WingnutStatus.Disconnected.value
      }
    } else {
      return WingnutStatus.Disconnected.value
    }

    if (item.config.propertyId === undefined) {
      return WingnutStatus.Unlinked.value
    }

    if (!(Object.keys(item.config.sensors).length > 0)) {
      return WingnutStatus.Unconfigured.value
    }

    return WingnutStatus.Live.value
  }

  return (
    <Container>
      <Styles>
        <MediaQuery minWidth={1171}>
          <PropertyTable
            columns={tableColumns}
            columnsToHide={hiddenColumns(ScreenSize.Large)}
            data={props.tableData}
            headerHeight={130}
            rowHeight={130}
          />
        </MediaQuery>

        <MediaQuery minWidth={744} maxWidth={1170}>
          <PropertyTable
            columns={tableColumns}
            columnsToHide={hiddenColumns(ScreenSize.Medium)}
            data={props.tableData}
            headerHeight={130}
            rowHeight={130}
          />
        </MediaQuery>

        <MediaQuery maxWidth={743}>
          <PropertyTable
            columns={tableColumns}
            columnsToHide={hiddenColumns(ScreenSize.Small)}
            data={props.tableData}
            headerHeight={130}
            rowHeight={130}
          />
        </MediaQuery>
      </Styles>
    </Container>
  )
}

export default PropertyTableStructure


const Container = styled.div`
  /* border: 1px solid red; */
  overflow: auto;
  height: 100%;
  width: 100%;
  margin-top: 0px;
  ${media.lessThan("743px")`
    align-self: center;
    margin-top: 0px;  
    padding-left: 16px;  
    padding-right: 16px;   
    `}
  ${media.between("744px", "large")`
      margin-top: 20px;
      width: 98%;
    `}
    ${media.greaterThan("large")`
    `}
`

const Styles = styled.div`
  /* padding-top: 10px; */
  color: ${COLORS.White};
  overflow: none;

  table {
    border-spacing: 0;
    /* border: 1px solid blue; */

    thead {
      ${"" /* These styles are required for a scrollable body to align with the header properly */}
      overflow-y: auto;
      overflow-x: hidden;

      // Below create a fixed header - from here -> https://codesandbox.io/s/54z882qno4?file=/index.css:192-256
      position: sticky;
      top: 0;
      z-index: 2;
    }

    tbody {
      ${"" /* These styles are required for a scrollable table body */}
      overflow-y: scroll;
      overflow-x: hidden;
    }

    tr {
      :last-child {
        td {
          border-bottom: 1;
        }
      }
    }

    th,
    td {
      margin: 0;
      border-bottom: 1px solid ${COLORS.HighlightDark};

      :last-child {
        border-right: 0;
      }
    }
  }
`
const HeaderDiv = styled.div`
  /* border: 1px red dashed;; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
  height: 45px;
`

const CellDiv = styled.div`
  /* border: 1px green dashed;; */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  bottom: 1px;
  height: 45px;
`

const WingnutDiv = styled.div`
    /* border: 1px purple dashed;; */
    /* height: 50px; */
    display: flex;
    flex-direction: row;
`;

const ContentDiv = styled.div`
    /* border: 1px yellow dashed; */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-self: center;
`;

const BtnIcon = styled.button`
  /* border: 1px green dashed; */
  background: transparent;
  /* outline: none; */
  border: none;
`

const LinkedWingnut = styled.button`
  /* border: 1px green dashed;; */
  display: flex;
  width: 80px;
  background: ${COLORS.Primary};
  height: 24px;
  padding: 5px 15px;
  border-radius: 30px;
  border: none;
  /* margin-left: 1px; */
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  z-index: 1;
  /* identical to box height, or 14px */

  letter-spacing: -0.01em;

  /* White */
  color: ${COLORS.White};
  :hover {
    color: ${COLORS.Primary};
    background: ${COLORS.White};
  }
`

const IconArrow = styled.img`
  width: 6;
  height: 10px;
  /* margin-left: 20px; */
  align-self: center;
  transition-duration: 0.5s;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const HeaderAlertIcon = styled.img`
  height: 18px;
  width: 18px;
  margin-right: 17px;
  ${media.lessThan("743px")`
      margin-left: 3px;
    `}
  ${media.between("744px", "large")`
      margin-left: 10px;
    `} */
`

const HeaderNotificationIcon = styled.img`
  height: 25px;
  width: 25px;
  margin-right: 17px;
  ${media.lessThan("743px")`
      margin-left: 3px;
    `}
  ${media.between("744px", "large")`
      /* margin-left: 10px; */
    `} */
`

const IconWingnut = styled(IconArrow)`
  margin-right: 8px;
  width: 12px;
  height: 12px;
`

const SortIcon = styled.img`
  /* border: 1px red dashed; */
  width: 18px;
  height: 18px;
  position: relative;
  /* align-self: center; */
  top: 1px;
  margin-right: 10px;
`

const Icon = styled.img`
  /* border: 1px red dashed; */
  width: 10px;
  height: 10px;
  position: relative;
  top: 1px;
`

const ToolIcon = styled.img`
  /* border: 1px red dashed; */
  width: 18px;
  height: 18px;
  position: relative;
  top: 5px;
`

const Text = styled.h5`
  /* border: 1px green dashed; */
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-self: center;
  /* align-items: center; */
  letter-spacing: -0.01em;
  color: ${COLORS.White};
`


const HiddenText = styled(Text)`
  color: ${COLORS.Primary};
`

const StaticText = styled(Text)`
  /* border: 1px green dashed; */
  padding-left: 5px;
  color: ${COLORS.HighlightLight};
  align-self: center;
`

const StatusIcon = styled.img`
  /* border: 1px red dashed; */
  width: 10px; 
  height: 10px;
  /* position: relative; */
`

const CellStatusDiv = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
`

const AlertIcon = styled.img`
  /* border: 1px red dashed;; */
  width: 20px; 
  height: 20px;
  `

const CellAlertDiv = styled.div`
  /* border: 1px blue dashed; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  /* position: relative; */
`

const CellToggleChildDiv = styled.div`
  /* border: 1px red dashed; */
  height: 19px;
  display: flex;
  justify-content: center;
  `






// render different table column combinations based on screen size

// React-table
// https://retool.com/blog/building-a-react-table-component/
// https://codesandbox.io/s/github/tannerlinsley/react-table/tree/master/examples/basic?file=/src/App.js
// https://codesandbox.io/s/cocky-poincare-b24mt?file=/src/App.js:123-157
// https://stackoverflow.com/questions/65161059/how-to-load-images-in-react-table-from-json-file

// Header search
// https://codesandbox.io/s/react-table-select-uh3i0?file=/src/HeaderButton.js:66-219

// Fuzzy search
// https://codesandbox.io/s/dq9ou?file=/src/index.js
// https://medium.com/analytics-vidhya/how-to-create-a-fuzzy-search-in-react-js-using-fuse-js-859f80345657
// https://fusejs.io/demo.html
// https://www.youtube.com/watch?v=GZl-yEz4_qw&t=214s&ab_channel=ColbyFayock

// {
//   Header: 'Serial',
//   accessor: 'serial',

// Cell: props => <PlaceholderImage width={60} textColor="#fff" text="Image" />
// Cell: ({ cell: { value } }) => (
//   <img
//     src={value}
//     width={60}
//   />
// )
// },
