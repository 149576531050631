import React from 'react'
import styled from "styled-components"
import media from "styled-media-query"
import { Link } from "react-router-dom"
import WingnutStatus from "../wingnuts/WingnutStatus.enum"
import { DateTime } from "luxon"
import { COLORS } from '../../styles/colors'

// Notification error page?



export default function NotificationNoConfiguration(props) {

  const wingnutStatus = (item) => {
    if (item.config.latestPost) {
      var date = DateTime.utc()
      var apiDateTime = item.config.latestPost ? DateTime.fromFormat(item.config.latestPost, "yyyy-MM-dd HH:mm:ss") : DateTime.utc()
      var difference = date.diff(apiDateTime, 'minutes')
      if (difference.minutes > 15) {
        return WingnutStatus.Disconnected.value
      }
    } else {
      return WingnutStatus.Disconnected.value
    }

    if (item.config.propertyId === undefined) {
      return WingnutStatus.Unlinked.value
    }

    if (!(Object.keys(item.config.sensors).length > 0)) {
      return WingnutStatus.Unconfigured.value
    }

    return WingnutStatus.Live.value
  }

  return (
    <div>
        {props.isLinked ? 
          <Container>
            <InstructionText>This property has no configured wingnuts</InstructionText>
            <WingnutContainer>
            {props.propertyDetails && props.propertyDetails.linkedWingnuts.map((item, index) => {
                  return (
                    <Link key={`linked-wingnut-${item.id}`} to={`/portal/wingnut/detail/${item.id}`} style={{ textDecoration: "none" }}>
                      <BtnWingnut key={`linked-wingnut-${index}`}>
                        <IconWingnut src={wingnutStatus(item)} />
                          <BtnText2>{(item.externalId).substr(0,4)}</BtnText2>
                      </BtnWingnut>
                    </Link>
                  )
                })}
            </WingnutContainer>
          </Container>
      :
        <Container>
          <InstructionText>This property has no linked wingnuts</InstructionText>
          <Link to={{ pathname: `/portal/property/link/${props.propertyId}`}} style={{ textDecoration: "none" }}>   
            <BtnLink>
              <ButtonText>
                LINK A NEW WINGNUT
              </ButtonText>
            </BtnLink>
          </Link>
        </Container>
      }
    </div>
  )
}

const Container = styled.div`
  /* border: 1px blue dashed; */
  display: flex;
  flex-direction: column;
  margin-bottom: 19px;
  width: 95%;
  /* height: 100vh; */
  max-width: 1047px;
  background-color: #0e1c36;
  border-radius: 20px;
  /* padding: 34px; */
  /* margin-right: 30px; */
  /* margin-top: 20px; */
  ${media.lessThan("743px")`
      width: 90%;
      align-self: center;
      margin-right: 0px;
    `}
`
const WingnutContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 7px;
`

const BtnText2 = styled.p`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
`

const BtnWingnut = styled.button`
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  padding: 5px 15px;
  border: none;
  width: 80px;
  height: 28px;
  background: ${COLORS.Secondary};
  border-radius: 30px;
  /* margin-right: 8px; */
  ${media.lessThan("743px")`
  margin-bottom: 10px;
    `}
  ${media.between("744px", "large")`
  margin-bottom: 10px;

    `}
    ${media.greaterThan("large")`
    `}
  &:hover {
    background: ${COLORS.White};
    ${BtnText2} {
      color: ${COLORS.Secondary};
    }
  }
`

const IconArrow = styled.img`
  width: 6;
  height: 10px;
  align-self: center;
  transition-duration: 0.5s;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const IconWingnut = styled(IconArrow)`
  margin-right: 8px;
  width: 12px;
  height: 12px;
`

const InstructionText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
  margin-bottom: 7px;
  font-size: 18px;
  padding-top: 19px;
  ${media.lessThan("743px")`
  `}
  ${media.between("744px", "large")`
  `}
  ${media.greaterThan("large")`
  `}
`

const BtnConfigure = styled.button`
  width: 160px;
  border-radius: 30px;
  background: #63c085;
  border: none;
  color: black;
  margin-right: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin-top: 10px;
  cursor: pointer;
  text-align: center;
  :hover {
    opacity: 0.8;
    background: #63c085;
  }
  ${media.lessThan("743px")`
        /* align-self: flex-start; */
        height: 55px;
        margin-right: 0px;
        width: 180px;

    `}
  ${media.between("744px", "large")`
        height: 55px;
        width: 180px;
    `}
    ${media.greaterThan("large")`
    `}
`

const BtnLink = styled(BtnConfigure)`
  width: 204px;
`

const ButtonText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transition-duration: 0.5s;
  :hover {
  }
  ${media.lessThan("743px")`
        font-size: 15px;
    `}
  ${media.between("744px", "large")`
        font-size: 15px;
    `}
    ${media.greaterThan("large")`
    `}
`