
import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts'
import styled from "styled-components"


export default class TimeSeriesZoom extends React.Component {
            constructor(props) {
            super(props);

            this.state = {
          
                series: [{
                    name: 'Total Views',
                    data: generateDayWiseTimeSeries(0, 18)
                  }, {
                    name: 'Unique Views',
                    data: generateDayWiseTimeSeries(1, 18)
                  }],
                  options: {
                    chart: {
                      type: "area",
                      height: 300,
                      foreColor: "#999",
                      stacked: true,
                      dropShadow: {
                        enabled: true,
                        enabledSeries: [0],
                        top: -2,
                        left: 2,
                        blur: 5,
                        opacity: 0.06
                      }
                    },
                    colors: ['#00E396', '#0090FF'],
                    stroke: {
                      curve: "smooth",
                      width: 3
                    },
                    dataLabels: {
                      enabled: false
                    },
     
                    markers: {
                      size: 0,
                      strokeColor: "#fff",
                      strokeWidth: 3,
                      strokeOpacity: 1,
                      fillOpacity: 1,
                      hover: {
                        size: 6
                      }
                    },
                    xaxis: {
                      type: "datetime",
                      axisBorder: {
                        show: false
                      },
                      axisTicks: {
                        show: false
                      }
                    },
                    yaxis: {
                      labels: {
                        offsetX: 14,
                        offsetY: -5
                      },
                      tooltip: {
                        enabled: true
                      }
                    },
                    grid: {
                      padding: {
                        left: -5,
                        right: 5
                      }
                    },
                    tooltip: {
                      x: {
                        format: "dd MMM yyyy"
                      },
                    },
                    legend: {
                      position: 'top',
                      horizontalAlign: 'left'
                    },
                    fill: {
                      type: "solid",
                      fillOpacity: 0.7
                    }
                }
            }    
        }
        

            render() {
            return (
                
                <Container>
                    <ReactApexChart options={this.state.options} series={this.state.series} type="line" height={350} />
                </Container>

            );
            }
        }
        // chatGPT
        function generateDayWiseTimeSeries(s, count) {
            var values = [    [4,3,10,9,29,19,25,9,12,7,19,5,13,9,17,2,7,5],
              [2,3,8,7,22,16,23,7,11,5,12,5,10,4,15,2,6,2]
            ];
            var series = [];
            var x = new Date("2023-01-01").getTime();
            var dayInMs = 86400000;
            for (var i = 0; i < count; i++) {
              series.push([x, values[s][i]]);
              x += dayInMs;
            }
            return series;
          }

        // function generateDayWiseTimeSeries(s, count) {
        //     var values = [[
        //       4,3,10,9,29,19,25,9,12,7,19,5,13,9,17,2,7,5
        //     ], [
        //       2,3,8,7,22,16,23,7,11,5,12,5,10,4,15,2,6,2
        //     ]];
        //     var i = 0;
        //     var series = [];
        //     var x = new Date("11 Nov 2012").getTime();
        //     while (i < count) {
        //       series.push([x, values[s][i]]);
        //       x += 86400000;
        //       i++;
        //     }
        //     return series;
        //   }

        const Container = styled.div`
            display: flex;
            flex-direction: column;
        `;



  