import React, { useState, useEffect } from "react"
import { BrowserRouter as Router,  useLocation, useHistory } from "react-router-dom"
import { Amplify } from 'aws-amplify';
import { Reset } from "styled-reset"
import { Auth } from "aws-amplify"
import { ApiAuth, ApiEndpoints } from "./api"
import "./App.scss"
import AppRouter from "./AppRouter"
import { Account } from "./auth/Account"

Amplify.configure({
  Auth: ApiAuth,
  API: {
    endpoints: ApiEndpoints,
  },
})

function App() {

  const history = useHistory()
  // const location = useLocation()

  // State for authentication status
  const [isAuth, setIsAuth] = useState(false)

  // State to track if session check is complete
   const [isLoading, setIsLoading] = useState(false);

  const loginAuth = () => {
    setIsAuth(true)
    setIsLoading(false)
  }

  const logoutAuth = () => {
    setIsAuth(false)
    setIsLoading(false)
  }



  return (
    <Router>
      <Reset />
        <Account loginAuth={loginAuth} logoutAuth={logoutAuth}>
          <AppRouter isAuth={isAuth} />
        </Account> 
    </Router>
  )
}


export default App
