import React, { Component } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Image from "./../../images/LOGO_ALT.svg"
import Image2 from "./../../images/ic_back.svg"
import { Link, withRouter } from "react-router-dom"
import {Interweave} from 'interweave';
import { SpinnerContainer, StyledSpinner } from "./../custom/Spinner"
import { COLORS } from "../../styles/colors"

const Grid = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  /* width: 882px; */
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  justify-self: center;
  background: ${COLORS.Primary};
`

const Container = styled.div`
  /* border: 1px green dashed; */
  max-width: 990px;
  min-height: 1000px;
  display: flex;
  width: 70%;
  height: auto;
  background: ${COLORS.Primary};
  flex-direction: column;
  align-self: center;
  ${media.lessThan("743px")`
        width: 90%; 
    `}
  ${media.between("744px", "large")`
        width: 80%;
    `}
    ${media.greaterThan("large")`
  `}
`

const Logo = styled.img`
  width: 202px;
  height: 57px;
  margin-top: 27px;
  /* align-self: center; */
  transition-duration: 0.5s;
  :hover {
    transform: scale(1.2);
  }
`

const Title = styled.h1`
  font-family: "Blinker", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 31px;
  margin-top: 46px;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
  /* align-self: center; */
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
  `}
`

const BlurbText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 23.4px;
  color: ${COLORS.White};
  margin-top: 5px;
  ${media.lessThan("743px")`
    font-size: 16px;
    `}
  ${media.between("744px", "large")`
    font-size: 18px;
    `}
`

const Button = styled.div`
  width: 255.55px;
  height: 69.62px;
  margin-top: 44px;
  margin-bottom: 44px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  background-color: transparent;
  border: 2px solid ${COLORS.White};
  box-sizing: border-box;
  border-radius: 36px;
  /* start of Hover code */
  transition-duration: 0.5s;
  :hover {
    background-color: ${COLORS.White};
  }
  /* end of hover code */
  ${media.lessThan("743px")`
      align-self: center;
    `}
  ${media.between("744px", "large")`
      align-self: center;
    `}
`

const BtnBack = styled.div`
  /* border: 1px red dashed; */
  width: 100px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 0px;
  margin-top: 30px;
  cursor: pointer;
  align-self: flex-end;
  background-color: transparent;
  box-sizing: border-box;
  border-radius: 6px;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
`

const ButtonText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  /* line-height: 129.5%; */
  text-align: center;
  letter-spacing: 0.05em;
  color: ${COLORS.White};
  align-self: center;
  justify-self: center;
  transition-duration: 0.5s;
  :hover {
    color: ${COLORS.Base};
  }
`

const Icon = styled.img`
  width: 6px;
  height: 11px;
  margin-right: 10px;
  align-self: center;
  transition-duration: 0.5s;
  color: ${COLORS.White};
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
  `}
`

class TermsOfService extends Component {

state = {
    data : "",
    isLoading: true,
    isHovering: false,
    showSpinner: true
  };


componentWillMount(){

  setTimeout(() => {
    fetch('https://www.iubenda.com/api/terms-and-conditions/60609012')
      .then(response => response.json())
      .then(data => this.setState({data: data, isLoading: false, showSpinner: false }));
   }, 1000);
}

  render() {
    
    return (
      <Grid>
        <Container>
          <BtnBack
            onClick={() => this.props.history.goBack()}
            onMouseEnter={(e) => {
              this.setState({ isHovering: true })
            }}
            onMouseLeave={(e) => {
              this.setState({ isHovering: false })
            }}
            style={{ backgroundColor: this.state.isHovering == true ? COLORS.White : "transparent" }}
          >
          
            <Icon
              style={{
                color: this.state.isHovering == true ? "#63C085" : COLORS.White,
              }}
              src={Image2}
            />
            <ButtonText
              style={{
                color: this.state.isHovering == true ? "#63C085" : COLORS.White,
              }}
            >
              {" "}
              BACK{" "}
            </ButtonText>
          </BtnBack>

          <Logo src={Image} />
          <Title>Terms Of Service</Title>
          <SpinnerContainer>{this.state.showSpinner && <StyledSpinner animation='border' />}</SpinnerContainer>
            {this.state.isLoading ? 
              null
            : 
            <BlurbText>
              <Interweave content={this.state.data.content} />
            </BlurbText>} 

              {this.state.isLoading ?
                null
              : 
              <Button
              onMouseEnter={(e) => {
                this.setState({ isHovering: true })
              }}
              onMouseLeave={(e) => {
                this.setState({ isHovering: false })
              }}
              style={{ backgroundColor: this.state.isHovering == true ? COLORS.White : COLORS.Base }}
              onClick={() => this.props.history.goBack()}
            >
              <ButtonText
                style={{
                  color: this.state.isHovering == true ? "#63C085" : COLORS.White,
                }}
              >
                CLOSE
              </ButtonText>
            </Button>
            }
 
          
        </Container>
      </Grid>
    )
  }
}

export default withRouter(TermsOfService)
