import { API, Auth } from "aws-amplify"
import React, { Component } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Fuse from "fuse.js"
import WingnutController from "./WingnutController"
import WingnutTableStructure from "./WingnutTableStructure"
import WingnutStatus from "./WingnutStatus.enum"
import { SpinnerContainer, StyledSpinner } from "../custom/Spinner"
import { DateTime } from "luxon";
import { COLORS } from "../../styles/colors"

const Container = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-right: 30px;
  background-color: ${COLORS.Base};
  ${media.lessThan("743px")`
    margin-right: 0px;
    padding-bottom: 80px;
    `}
`

const fuzzyOptions = {
  // isCaseSensitive: false,
  // includeScore: false,
  // shouldSort: true,
  // includeMatches: false,
  // findAllMatches: false,
  // minMatchCharLength: 3,
  // location: 0,
  threshold: 0.1,
  // distance: 100,
  // useExtendedSearch: false,
  // ignoreLocation: false,
  // ignoreFieldNorm: false,
  // fieldNormWeight: 1,
  keys: ["serial", "uuid", "propertyId", "propertyName", "portfolio", "address.streetAddress", "address.city", "address.postalZip", "address.provinceState", "address.country"],
}

export default class WingnutOverview extends Component {
  constructor() {
    super()
    this.fuseSearch = null
    this.showData = true
    this.state = {
      data: [],
      search: "",
      isLoading: true,
      status: {
        Live: false,
        Disconnected: false,
        Unlinked: false,
        Unconfigured: false,
      },
    }
  }

  componentDidMount() {
    this.getWingnuts()
  }

  componentWillUnmount() {
    this.showData = false
  }

  componentDidUpdate(prevProps, prevState){
    if(prevState.isLoading === true && this.state.isLoading === false){
      this.filterData(this.state.search, this.state.status)
    }
  }

  searchForWingnut(event) {
    if(this.state.isLoading === false){
      this.filterData(event.target.value, this.state.status)
    } else {
      this.setState({
        search: event.target.value,
      })
    }
  }

  filterData(fuzzySearch, wingnutState) {
    var data = []
    if (fuzzySearch) {
      data = this.fuseSearch.search(fuzzySearch).map((s) => s.item)
    } else {
      if(this.fuseSearch){
        data = this.fuseSearch.getIndex().docs
      }
    }

    data = data.filter((item) => {
      if (wingnutState.Live && item.status.live) {
        return item
      }
      if (wingnutState.Disconnected && item.status.disconnected) {
        return item
      }
      if (wingnutState.Unlinked && item.status.unlinked) {
        return item
      }
      if (wingnutState.Unconfigured && item.status.unconfigured) {
        return item
      }
      // no toggles set, return all items
      if (!wingnutState.Live && !wingnutState.Disconnected && !wingnutState.Unlinked && !wingnutState.Unconfigured) {
        return item
      }
    })
    this.setState({
      search: fuzzySearch,
      status: wingnutState,
      data: data,
    })
  }

  toggleStatus(status) {
    var wingnutStatus = this.state.status

    if (status === WingnutStatus.Live) {
      wingnutStatus.Live = !wingnutStatus.Live
    }
    if (status === WingnutStatus.Disconnected) {
      wingnutStatus.Disconnected = !wingnutStatus.Disconnected
    }
    if (status === WingnutStatus.Unlinked) {
      wingnutStatus.Unlinked = !wingnutStatus.Unlinked
    }
    if (status === WingnutStatus.Unconfigured) {
      wingnutStatus.Unconfigured = !wingnutStatus.Unconfigured
    }

    this.filterData(this.state.search, wingnutStatus)
  }

  setWingnutStatus(item) {
    var disconnected = true
    if (item.config.latestPost) {
      var date = DateTime.utc()
      var apiDateTime = item.config.latestPost ? DateTime.fromFormat(item.config.latestPost, "yyyy-MM-dd HH:mm:ss") : DateTime.utc()
      var difference = date.diff(apiDateTime, 'minutes')
      disconnected = difference.minutes > 15
    }

    var unlinked = (item.config.propertyId === undefined)
    var unconfigured = !(Object.keys(item.config.sensors).length > 0)
    return {
      live: (!disconnected && !unlinked && !unconfigured) ? true : false,
      disconnected,
      unlinked,
      unconfigured
    }
  }

  setupTable(data) {
    this.fuseSearch = new Fuse(data, fuzzyOptions)
    this.setState({
      data: data
    })
  }

  async getWingnuts() {
    const token = (await Auth.currentSession()).getIdToken()
    const jwt = token.getJwtToken()
    const init = {
      headers: { Authorization: `Bearer ${jwt}` },
    }

    API.get("FrontendAPI-wingnuts", "", init)
      .then((response) => {
        if (response.success) {
          const mappedData = response.data.map((item) => {

            return {
              serial: parseInt(item.externalId),
              propertyId: item.property?.externalId,
              propertyName: item.propertyName,
              propertyUUID: item.property?.id,
              address: item.property?.config.address,
              portfolio: item.property?.config.portfolio,
              sensors: item.config.sensors,
              uuid: item.id,
              status: this.setWingnutStatus(item)
            }
          })
          if (this.showData) {
            this.setupTable(mappedData)
          }
        }
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => this.setState({ isLoading: false }))
  }

  render() {
    return (
      <Container>
        <WingnutController
          tableData={this.state.data}
          toggleStatus={(s) => this.toggleStatus(s)}
          onInputChange={(e) => this.searchForWingnut(e)}
          wingnutState={this.state.status}
        />
        { this.state.isLoading
        ? (<SpinnerContainer><StyledSpinner animation='border' /></SpinnerContainer>)
        : (<WingnutTableStructure tableData={this.state.data} />)}
      </Container>
    )
  }
}
