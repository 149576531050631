import React, { createContext, useEffect } from "react"
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js"
import Pool from "./UserPool"
import { useHistory, useLocation } from "react-router-dom"
import { Auth } from "aws-amplify"

const AccountContext = createContext()

const Account = (props) => {
  const history = useHistory()
  const location = useLocation()

  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser()
      if (user) {
        user.getSession(async (err, session) => {
          if (err) {
            reject()
          } else {
            const attributes = await new Promise((resolve, reject) => {
              user.getUserAttributes((err, attributes) => {
                if (err) {
                  reject(err)
                } else {
                  const results = {}

                  for (let attribute of attributes) {
                    const { Name, Value } = attribute
     
                    // parse this custom json field
                    if(Name === "custom:company"){
                      results["company"] = Value
                    }
        
                    if(Name === "custom:role"){
                      if (Value === "admin"){
                        results["isAuth"] = true
                        results["isAdmin"] = true
                      } else if (Value === "write"){
                        results["isAuth"] = true
                        results["isAdmin"] = false
                      } else {
                        results["isAuth"] = false
                        results["isAdmin"] = false
                      }
                    }

                    results[Name] = Value
                  }
                  resolve(results)
                }
              })
            })
            resolve({ user, ...session, ...attributes })
          }
        })
      } else {
        reject()
      }
    })
  }

  const authenticate = async (username, password, setRemember) => {
    return Auth.signIn(username, password)
      .then((user) => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          props.logoutAuth()
          return user
        }
        props.loginAuth()
        return user
      })
      .catch((err) => {
        console.error("onFailure ", err)
        props.logoutAuth()
        throw err
      })
  }

  const authenticateChangeEmail = async (Username, Password) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool })

      const authDetails = new AuthenticationDetails({
        Username,
        Password,
      })

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          resolve(data)
        },
        onFailure: (err) => {
          console.error("onFailure ", err)
          reject(err)
        },
        newPasswordRequired: (data) => {
          resolve(data)
        },
      })
    })
  }

    useEffect(() => {
    const checkSession = async () => {
      try {
        const session = await Auth.currentSession();
        if(session){
          props.loginAuth()
          history.push(location)
        } else {
          props.logoutAuth()
        }
      } catch (error) {
        props.logoutAuth()
      }
    };

    checkSession()
  }, []);

  const logout = () => {
    const user = Pool.getCurrentUser()
    if (user) {
      props.logoutAuth()
      user.signOut()
      localStorage.clear();
      history.push("/")
    }
  }

  return (
    <AccountContext.Provider value={{ authenticate, getSession, logout, authenticateChangeEmail }}>
      {props.children}
    </AccountContext.Provider>
  )
}

export { Account, AccountContext }
