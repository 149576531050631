import React, { Component } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import { COLORS } from "../../styles/colors"
import AddWingnutForm from "../form/AddWingnutForm"
import HelpForm from "../form/HelpForm"

const Container = styled.div`
  /* border: 1px blue dashed; */
  display: flex;
  flex-direction: column;
  ${media.lessThan("744px")`
      margin-left: 30px;
      margin-bottom: 30px;
    `}
`

const HeaderText = styled.h2`
  font-family: Blinker;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 84.5%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
`

const DateText = styled.p`
  width: 430px;
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: ${COLORS.White};
  margin-top: 15px;
`

export default class AddWingnut extends Component {
  render() {
    return (
      <Container>
        <DateText>You can find the serial number and UUID on your Wingnut device</DateText>
        <AddWingnutForm />
      </Container>
    )
  }
}
