import React, { Component, useEffect, useState } from "react"
import { API, Auth } from "aws-amplify"
import styled from "styled-components"
import media from "styled-media-query"
import Connected from "./../../images/ic_link.svg"
import NotConnected from "./../../images/ic_link_off.svg"
import WingnutDetailsCard from "./WingnutDetailsCard"
import PropertyDetailsCard from "./PropertyDetailsCard"
import AddPropertyContainer from "../properties/AddPropertyContainer"
import LinkWingnutSelectProperty from "./LinkWingnutSelectProperty"
import LinkWingnutHeader from "./LinkWingnutHeader"
import MakeData from "./MakeData"
import { SpinnerContainer, StyledSpinner } from "./../custom/Spinner"
import { useMediaQuery } from 'react-responsive'
import { COLORS } from "../../styles/colors"


export default function LinkWingnutToProperty(props) {
  const {params} = props.match
  const [properties, setProperties] = useState([])
  const [wingnutDetails, setWingnutDetails] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const isDesktopSize = useMediaQuery({ query: '(min-width: 744px)' })

  useEffect(() => {
    let cancelled = false

    async function getWingnutDetails() {
      const token = (await Auth.currentSession()).getIdToken()
      const jwt = token.getJwtToken()
      const init = {
        headers: { Authorization: `Bearer ${jwt}` },
      }
      const response = await API.get('FrontendAPI-wingnuts', `/${params.id}`, init)
      if (response.success && !cancelled) {
        setWingnutDetails(response.data)
      }
    }

    async function getProperties() {
      const token = (await Auth.currentSession()).getIdToken()
      const jwt = token.getJwtToken()
      const init = {
        headers: { Authorization: `Bearer ${jwt}` },
      }
      const response = await API.get('FrontendAPI-properties', ``, init)
      if (response.success && !cancelled) {
        setProperties(response.data)
        setIsLoading(false)
      }
    }

    getProperties()
    getWingnutDetails()
    return () => {
      cancelled = true
    }
  }, [])

  const hasProperty = () => {
    var hasProp = false

    if (wingnutDetails) {
      hasProp = wingnutDetails.config.hasOwnProperty('propertyId')
    }

    return hasProp
  }

  const linkProperty = async (id) => {
    setDisabled(true)
    const token = (await Auth.currentSession()).getIdToken()
    const jwt = token.getJwtToken()
    const init = {
      headers: { Authorization: `Bearer ${jwt}` },
      body: { propertyId: id }
    }

    const response = await API.post('FrontendAPI-wingnuts', `/${wingnutDetails.id}/link`, init)
    if (response.success) {
      setDisabled(false)
      var selectedProperty = properties.find(item => item.id == id)
      response.data.property = selectedProperty
      setWingnutDetails(response.data)
    }
  }

  const addProperty = (data) => {
    properties.push(data)
    setProperties(properties)
    toggleAddProperty()
  }

  const unlinkProperty = (data) => {
  }

  const toggleAddProperty = () => {
    if(isOpen){
      const element = isDesktopSize ? document.getElementById('top') : document.getElementById('navbar');
      element.scrollIntoView({ behavior: 'smooth' });
      setTimeout(() => {
        setIsOpen(false)
      }, 400)
    } else {
      setIsOpen(true)
      setTimeout(() => {
      const element = document.getElementById('add');
      element.scrollIntoView({ behavior: 'smooth' });
      }, 100)
    }
  }

  return (
    <OuterContainer >
      <Container id='top'>
        <StatusCard>
          <HeaderContainer>

          {hasProperty() 
          ? <EmptyContainer/>
          : !isLoading && <LinkWingnutHeader updateSuccess={hasProperty()} done={(e) => unlinkProperty(e)} />
           }

          </HeaderContainer>
          <Line />
          <DetailsContainer>
            <WingnutDetails >
              {wingnutDetails ? (<WingnutDetailsCard wingnutDetails={wingnutDetails} onLeftSide={true} hasProperty={hasProperty} />) : (<SpinnerContainer><StyledSpinner animation='border' /></SpinnerContainer>) }
            </WingnutDetails>
            {hasProperty() ? <WingnutIcon src={Connected} /> : <WingnutIcon src={NotConnected} />}
            <WingnutDetails>
              {hasProperty() ? (
                <PropertyDetailsCard propertyDetails={wingnutDetails.property} onLeftSide={false}/>
              ) : (
                <LinkWingnutSelectProperty disabled={disabled} linkProperty={(id) => linkProperty(id)} data={properties} toggleAddProperty={() => toggleAddProperty()}/>
              )}
            </WingnutDetails>
          </DetailsContainer>
        </StatusCard>
       <div id='add'>
        {isOpen && <AddPropertyContainer linkView={isDesktopSize ? true : false} done={(data) => addProperty(data)}/>}
       </div>

      </Container>
    </OuterContainer>
  )
}


const OuterContainer = styled.div`
  /* border: 1px transparent dashed; */
  display: flex;
  ${media.lessThan("743px")`
      justify-content: center;
    `}
`;

const Container = styled.div`
  /* max-width: 1260px; */
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  /* padding-bottom: 50px; */
  padding-top: 18px;
  background-color: ${COLORS.Base};
  width: 90%;
  max-width: 1047px;
  ${media.lessThan("743px")`
        justify-content: center;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const StatusCard = styled.div`
  /* border: 1px yellow dashed; */
  width: auto;
  max-width: 1048px;
  min-height: 570px;
  height: auto;
  display: flex;
  flex-direction: column;
  background: #0e1c36;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 35px 35px;
  margin-right: 30px;
  ${media.lessThan("743px")`
        padding: 20px 20px;
        width: 95%;
        margin-right: 0px;
        align-self: center;
    `}
  ${media.between("744px", "large")`
        width: 95%;
        padding: 25px 25px;
    `}
    ${media.greaterThan("large")`
    `}
`

const HeaderContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${media.lessThan("743px")`
        flex-direction: column;
    `}
  ${media.between("744px", "large")`
        /* flex-direction: column; */
        flex-wrap: wrap;
        justify-content: flex-start;
    `}
    ${media.greaterThan("large")`
    `}
`

const DetailsContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  ${media.lessThan("743px")`
        flex-direction: column;
    `}
  ${media.between("744px", "large")`
        flex-direction: column;
        /* width: 350px; */
    `}
`

const WingnutDetails = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: column;
  width: 340px;
  padding-bottom: 5px;
  ${media.lessThan("743px")`
    width: 320px;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const WingnutIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 30px;
  margin-left: 30px;
  margin-top: 110px;
  ${media.lessThan("743px")`
        margin-top: 20px;
        margin-bottom: 20px;
        margin-right: 0px;
        margin-left: 155px;
    `}
  ${media.between("744px", "large")`
        margin-top: 20px;
        margin-bottom: 20px;
        margin-right: 0px;
        margin-left: 155px;
        /* align-self: center; */
    `}
    ${media.greaterThan("large")`
    `}
`

const EmptyContainer = styled.div`
  height: 53px;
`

const Line = styled.div`
  /* width: 97.75%; */
  height: 1px;
  background: ${COLORS.HighlightMedium};
  margin-top: 14px;
  /* margin-right: 30px; */
  margin-bottom: 26px;
  ${media.lessThan("743px")`
        align-self: center;
        margin-right: 0px;
    `}
`