import React, { Component } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Icon1 from "./../../images/ic_tool.svg"
import Icon2 from "./../../images/ic_shield.svg"
import Icon3 from "./../../images/ic_bulb.svg"
import Icon4 from "./../../images/ic_lock.svg"
import IconBullet1 from "./../../images/ic_dot_red.svg"
import IconBullet2 from "./../../images/ic_dot_yellow.svg"
import IconBullet3 from "./../../images/ic_dot_purple.svg"
import IconBullet4 from "./../../images/ic_dot_green.svg"
import Image4 from "./../../images/Tech.svg"
import ImgWingnutInterface from "./../../images/Wingnut_interface.svg"
import Aos from "aos"
import "aos/dist/aos.css"
import { COLORS } from "../../styles/colors"

const Grid = styled.div`
  /* border: 1px blue dashed; */
  grid-area: 5 / 1 / 6 / 2;
  width: 99%;

  justify-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  margin-bottom: 20px;
  margin-top: -140px;
  /* z-index: 0; */
  ${media.lessThan("743px")`
        flex-direction: column;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const Card = styled.div`
  /* border: 1px green dashed; */
  max-width: 1440px;
  height: auto;
  width: 90%;
  border-radius: 50px;
  background-color: #0c182f;
  display: flex;
  flex-direction: column;
  align-self: center;
  ${media.lessThan("743px")`
        margin-top: 100px;
        width: 90%;
        align-self: center;
    `}
  ${media.between("744px", "large")`
        margin-top: 100px;
        width: 90%;
    `}
    ${media.greaterThan("large")`
  `}
`

const HeaderText = styled.h1`
  /* border: 1px red dashed; */
  width: 65%;
  margin-top: 50px;
  margin-left: 75px;
  font-family: "Blinker", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 52.3918px;
  line-height: 53px;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
  ${media.lessThan("743px")`
        margin-left: 0px;
        width: 70%;
        font-family: 'Blinker', sans-serif;
        font-size: 35px;
        line-height: 101.5%;
        align-self: center
    `}
  ${media.between("744px", "large")`
        justify-content: center;
        font-size: 52px;
        width: 80%;
    `}
    ${media.greaterThan("large")`
    `}
`

const BottomContainer = styled.div`
  /* border: 1px blue dashed; */
  display: flex;
  max-width: 1200px;
  width: 95%;
  flex-direction: row;
  justify-content: space-evenly;
  padding-left: 40px;
  margin-bottom: 114px;
  margin-top: 130px;
  flex-wrap: wrap;
  ${media.lessThan("743px")`
        width: 80%;
        flex-direction: column;
        align-content: center;
        align-self: center;
        justify-content: center;
        margin-top: 54px;
        margin-bottom: 53px;
        padding-left: 0px;
    `}
  ${media.between("744px", "large")`
        justify-content: start;
        flex-direction: column;
        margin-top: 60px;
        margin-bottom: 94px;
  `}
`

const DetailsContainer = styled.div`
  /* border: 1px green dashed; */
  width: auto;
  max-width: 750px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: flex-end;
  ${media.lessThan("743px")`
        width: auto;
        margin-left: 0px;
        margin-right: 0px;
        justify-self: center;
        flex-direction: column;
        align-self: center;
    `}
  ${media.between("744px", "large")`
        margin-left: 50px;
        align-self: flex-start;
    `}
`

const DetailRow = styled.div`
  display: flex;
  flex-direction: row;
  ${media.lessThan("743px")`
        flex-direction: column;
    `}
`

const SingleDetailContainer = styled.div`
  /* border: 1px yellow dashed; */
  width: 275px;
  display: flex;
  flex-direction: column;
  margin-right: 3px;
  margin-bottom: 51px;
  ${media.lessThan("743px")`
        margin-right: 0px;
        align-self: center;
    `}
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: center;
  ${media.lessThan("743px")`
        flex-direction: column;
    `}
  ${media.between("744px", "large")`
        flex-direction: column;
    `}
`

const Title = styled.h1`
  /* border: 1px red dashed; */
  font-family: "Blinker", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 101.5%;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
        align-self: center;
    `}
`

const Icon = styled.img`
  /* border: 1px green dashed; */
  height: 60px;
  width: 60px;
  margin-right: 30px;
  align-self: flex-start;
  ${media.lessThan("743px")`
        margin-right: 0px;
        margin-left: -10px;
        `}
  ${media.between("744px", "large")`
        margin-right: 0px;
        margin-left: -10px;
    `}
`

const BulletPointContainer = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`

const IconBullet = styled.img`
  height: 9px;
  width: 9px;
  align-self: center;
  margin-right: 20px;
`

const BulletText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 23.6391px;
  line-height: 101.5%;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
  opacity: 0.5;
`

const ImgWingnut = styled.img`
  /* border: 1px yellow dashed; */
  width: 429.47px;
  height: 479.23px;
  margin-bottom: 20px;
  ${media.lessThan("743px")`
        width: 280px;
        height: auto;
        margin-top: 40px;
        margin-right: 0px;
        justify-self: center;
    `}
  ${media.between("744px", "large")`
        margin-top: 80px;
        align-self: center;
        margin-right: 0px;
    `}
    ${media.greaterThan("large")`
  `}
`

const TechImg = styled.img`
  width: 66px;
  height: 420px;
  align-self: center;
  margin-left: 800px;
  margin-bottom: -140px;
  /* z-index: 1; */
  ${media.lessThan("743px")`
        width: 43px;
        height: 271px;
        overflow: none;
        margin-top: -140px;
        margin-right: 0px;
        align-self: center;
        `}
  ${media.between("744px", "large")`
        width: 43px;
        height: 271px;
        margin-top: -140px;
        margin-right: 200px;
    `}
`

export class Details extends Component {
  render() {
    return (
      <Grid>
        <Card>
          <HeaderText>
            Wingnut interfaces with industry standard sensing equipment to provide universal system connectivity.
          </HeaderText>
          <BottomContainer>
            <DetailsContainer
              data-aos='slide-right'
              data-aos-duration='1000'
              data-aos-easing='ease-in-sine'
              data-aos-once='true'
            >
              <DetailRow>
                <SingleDetailContainer>
                  <TitleContainer>
                    <Icon src={Icon1} />
                    <Title>Mechanical</Title>
                  </TitleContainer>
                  <BulletPointContainer>
                    <IconBullet src={IconBullet1} />
                    <BulletText>Hydronic Heating</BulletText>
                  </BulletPointContainer>
                  <BulletPointContainer>
                    <IconBullet src={IconBullet1} />
                    <BulletText>HVAC Equipment</BulletText>
                  </BulletPointContainer>
                  <BulletPointContainer>
                    <IconBullet src={IconBullet1} />
                    <BulletText>Domestic Hot Water</BulletText>
                  </BulletPointContainer>
                  <BulletPointContainer>
                    <IconBullet src={IconBullet1} />
                    <BulletText>Sprinkler Systems</BulletText>
                  </BulletPointContainer>
                </SingleDetailContainer>

                <SingleDetailContainer>
                  <TitleContainer>
                    <Icon src={Icon2} />
                    <Title>Safety</Title>
                  </TitleContainer>
                  <BulletPointContainer>
                    <IconBullet src={IconBullet2} />
                    <BulletText>Overflow Detection</BulletText>
                  </BulletPointContainer>
                  <BulletPointContainer>
                    <IconBullet src={IconBullet2} />
                    <BulletText>Flood Detection</BulletText>
                  </BulletPointContainer>
                  <BulletPointContainer>
                    <IconBullet src={IconBullet2} />
                    <BulletText>CO Detection</BulletText>
                  </BulletPointContainer>
                  <BulletPointContainer>
                    <IconBullet src={IconBullet2} />
                    <BulletText>Leak Detection</BulletText>
                  </BulletPointContainer>
                </SingleDetailContainer>
              </DetailRow>

              <DetailRow>
                <SingleDetailContainer>
                  <TitleContainer>
                    <Icon src={Icon3} />
                    <Title>Energy</Title>
                  </TitleContainer>
                  <BulletPointContainer>
                    <IconBullet src={IconBullet3} />
                    <BulletText>Electricity Consumption</BulletText>
                  </BulletPointContainer>
                  <BulletPointContainer>
                    <IconBullet src={IconBullet3} />
                    <BulletText>Natural Gas Consumption</BulletText>
                  </BulletPointContainer>
                </SingleDetailContainer>

                <SingleDetailContainer>
                  <TitleContainer>
                    <Icon src={Icon4} />
                    <Title>Access</Title>
                  </TitleContainer>
                  <BulletPointContainer>
                    <IconBullet src={IconBullet4} />
                    <BulletText>Entry and Exit</BulletText>
                  </BulletPointContainer>
                  <BulletPointContainer>
                    <IconBullet src={IconBullet4} />
                    <BulletText>Room occupancy</BulletText>
                  </BulletPointContainer>
                </SingleDetailContainer>
              </DetailRow>
            </DetailsContainer>
            <ImgWingnut
              src={ImgWingnutInterface}
              data-aos='slide-left'
              data-aos-duration='1000'
              data-aos-easing='ease-in-sine'
              data-aos-once='true'
            />
          </BottomContainer>
        </Card>
      </Grid>
    )
  }
}

export default Details
