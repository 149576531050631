import { Auth } from "aws-amplify"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import "react-phone-number-input/style.css"
import { Link, useHistory } from "react-router-dom"
import styled from "styled-components"
import media from "styled-media-query"
import { COLORS } from "../../styles/colors"
import { formatPhoneNumber } from "../util/StringUtil"
import Image2 from "./../../images/eye.svg"
import ImageBlueEye from "./../../images/eye_blue.svg"
import Image from "./../../images/ic_alert.svg"
import Image3 from "./../../images/ic_arrow_right.svg"
import { SpinnerContainer, StyledSpinner } from "./../custom/Spinner"
import { EmptyError, Error, ErrorContainer, Icon } from "./../custom/ValidatorStyles"
import { PhoneValidatorStrings, ServerStrings, ValidatorStrings } from "./../util/VaildatorStrings"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: auto;
  align-self: center;
  margin-top: 36px;
  ${media.lessThan("743px")`
    width: 90%;
  `}
`

const Form = styled.form`
  width: 384px;
  display: flex;
  flex-direction: column;
  ${media.lessThan("743px")`
    width: 90%;
    align-self: center;
  `}
  ${media.between("744px", "large")`
    width: 384px;
  `}
`

const Label = styled.label`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 129.5%;
  letter-spacing: 0.03em;
  color: ${COLORS.HighlightLight};
  margin-bottom: 2px;
`

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-top: 56px;
  align-self: center;
`

const LblCheckbox = styled.label`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 129.5%;
  align-self: center;
  text-align: center;
  color: ${COLORS.White};
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const EyeIcon = styled.img`
  align-self: center;
  transition-duration: 0.5s;
  color: ${COLORS.White};
  margin-left: -50px;
`

const Input = styled.input`
  width: 100%;
  height: 45px;
  outline: none;
  border: none;
  color: ${COLORS.White};

  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 129.5%;
  font-weight: 400;
  background-color: #1d283e;
  border-radius: 8px;
  box-sizing: border-box;
  padding-left: 10px;
  ::placeholder {
    color: ${COLORS.HighlightMedium};
  }
  :hover {
    border: 1px solid ${COLORS.HighlightMedium};
    padding-left: 9px;
  }
  :focus {
    outline: ${COLORS.White};
    border: 1px solid ${COLORS.HighlightLight};
    padding-left: 9px;
  }

  ${media.lessThan("743px")`
    width: 100%;
    height: 45px;
  `}
  ${media.between("744px", "large")`
    width: 100%;
    height: 45px;
  `}
`

const Checkbox = styled.input`
  border: 1px solid ${COLORS.White};
  box-sizing: border-box;
  border-radius: 3px;
  margin-right: 14px;
  width: 20px;
  height: 20px;
  background: transparent;
`

const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  align-self: center;
  width: 233px;
  height: 45px;
  border: none;
  background: #63c085;
  cursor: pointer;
  border-radius: 36px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 13px;
  line-height: 129.5%;
  text-align: center;
  letter-spacing: 0.05em;
  margin-bottom: 10px;
  margin-top: 30px;
  ${media.lessThan("743px")`
    height: 55px;
  `}
  ${media.between("744px", "large")`
    height: 55px;
  `}
`

const ButtonText = styled.p`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 129.5%;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #0c182f;
  transition-duration: 0.5s;
  :hover {
    color: ${COLORS.Base};
  }
`

const CheckboxError = styled(Error)

const IconArrow = styled.img`
  width: 6;
  height: 10px;
  align-self: center;
  transition-duration: 0.5s;
`

const Conditions = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 17px;
  line-height: 129.5%;
  align-self: center;
  text-align: center;
  color: ${COLORS.White};
  text-decoration-line: underline;
`

export default function CreateAccountForm(props) {
  const history = useHistory()

  const {
    register,
    handleSubmit,
    getValues,
    onChange,
    formState: { errors },
  } = useForm({ reValidateMode: "onSubmit" })

  const [showSpinner, setShowSpinner] = useState(false)
  const [passwordShown, setPasswordShown] = useState(false)
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false)
  const [serverError, setServerError] = useState(null)
  const [disabled, setDisabled] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  const [checked, setChecked] = useState(false)

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true)
  }

  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(confirmPasswordShown ? false : true)
  }

  useEffect(() => {
    if (onChange) {
      onChange(checked)
    }
  }, [checked])

  const onSubmit = async (data) => {
    setServerError(null)
    setShowSpinner(true)
    setShowSpinner(true)
    setDisabled(true)

    const formattedPhoneNumber = formatPhoneNumber(data.phoneNumber)
    Auth.signUp({
      username: props.email,
      password: data.password,
      attributes: {
        given_name: data.firstName,
        family_name: data.lastName,
        phone_number: formattedPhoneNumber,
        "custom:uuid": props.id,
        "custom:company": props.company,
        "custom:companyId": props.companyId,
      },
    })
      .then((user) => {
        setServerError(null)
        props.updateSuccess()
        setTimeout(function () {
          history.push("/login")
        }, 3000)
      })
      .catch((err) => {
        console.error(err)
        setServerError(ServerStrings(err.name))
      })
      .finally(() => {
        setShowSpinner(false)
        setDisabled(false)
      })
  }

  return (
    <Container>
      <SpinnerContainer>{showSpinner && <StyledSpinner animation='border' />}</SpinnerContainer>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Label>Email</Label>
        <Input
          readOnly
          type='text'
          name='email'
          placeholder='Email'
          value={props.email}
          style={{ border: errors.email ? "1px solid #D25353" : null, paddingLeft: errors.email ? "9px" : null }}
        />
        {errors.email ? <Error>{ValidatorStrings(errors.email?.type)}</Error> : <EmptyError />}

        <Label>First Name</Label>
        <Input
          type='text'
          name='firstName'
          placeholder='First Name'
          {...register("firstName", { required: true, maxLength: 45 })}
          style={{
            border: errors.firstName ? "1px solid #D25353" : null,
            paddingLeft: errors.firstName ? "9px" : null,
          }}
        />
        {errors.firstName ? <Error>{ValidatorStrings(errors.firstName?.type)}</Error> : <EmptyError />}

        <Label>Last Name</Label>
        <Input
          type='text'
          name='lastName'
          placeholder='Last Name'
          {...register("lastName", { required: true, maxLength: 45 })}
          style={{ border: errors.lastName ? "1px solid #D25353" : null, paddingLeft: errors.lastName ? "9px" : null }}
        />
        {errors.lastName ? <Error>{ValidatorStrings(errors.lastName?.type)}</Error> : <EmptyError />}

        <Label>Phone Number</Label>
        <Input
          type='tel'
          name='phoneNumber'
          placeholder='(123) 123 1234'
          {...register("phoneNumber", { required: true, minLength: 10, maxLength: 20, pattern: /^[\d \-()]*$/ })}
          style={{
            border: errors.phoneNumber ? "1px solid #D25353" : null,
            paddingLeft: errors.phoneNumber ? "9px" : null,
          }}
        />
        {errors.phoneNumber ? <Error>{PhoneValidatorStrings(errors.phoneNumber?.type)}</Error> : <EmptyError />}

        <Label>Password</Label>
        <div style={{ display: "flex" }}>
          {" "}
          <InputContainer>
            <Input
              type={passwordShown ? "text" : "password"}
              name='password'
              placeholder='••••••••'
              {...register("password", {
                required: true,
                maxLength: 45,
                validate: { emailEqual: (value) => value === getValues().confirmPassword },
              })}
              style={{
                border: errors.password ? "1px solid #D25353" : null,
                paddingLeft: errors.password ? "9px" : null,
              }}
            />
            <EyeIcon onClick={togglePasswordVisiblity} src={passwordShown ? Image2 : ImageBlueEye} />
          </InputContainer>{" "}
        </div>
        {errors.password ? <Error>{ValidatorStrings(errors.password?.type)}</Error> : <EmptyError />}

        <Label>Confirm Password</Label>
        <div style={{ display: "flex" }}>
          {" "}
          <InputContainer>
            <Input
              type={confirmPasswordShown ? "text" : "password"}
              name='confirmPassword'
              placeholder='••••••••'
              {...register("confirmPassword", {
                required: true,
                maxLength: 45,
                validate: { emailEqual: (value) => value === getValues().password },
              })}
              style={{
                border: errors.confirmPassword ? "1px solid #D25353" : null,
                paddingLeft: errors.confirmPassword ? "9px" : null,
              }}
            />
            <EyeIcon onClick={toggleConfirmPasswordVisiblity} src={confirmPasswordShown ? Image2 : ImageBlueEye} />
          </InputContainer>{" "}
        </div>
        {errors.confirmPassword ? (
          <Error>{ValidatorStrings(errors.confirmPassword?.type)}</Error>
        ) : serverError ? (
          <ErrorContainer>
            <Icon src={Image} />
            <Error>{serverError}</Error>
          </ErrorContainer>
        ) : (
          <EmptyError />
        )}

        <CheckboxContainer>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Checkbox
              style={{ cursor: "pointer", background: "red" }}
              checked={checked}
              onClick={() => setChecked(!checked)}
              type='checkbox'
              name='agreeToTerms'
              {...register("agreeToTerms", { required: true })}
            />
            <LblCheckbox>I agree with the</LblCheckbox>
            <Link to='/terms' style={{ textDecoration: "none", alignSelf: "center" }}>
              <Conditions>Terms</Conditions>
            </Link>
            <LblCheckbox> and </LblCheckbox>
            <Link to='/privacy' style={{ textDecoration: "none", alignSelf: "center" }}>
              <Conditions>Privacy</Conditions>
            </Link>
          </div>
          {errors.agreeToTerms ? (
            <CheckboxError>{ValidatorStrings(errors.agreeToTerms?.type)}</CheckboxError>
          ) : (
            <EmptyError />
          )}
        </CheckboxContainer>

        <Button
          disabled={disabled}
          type='submit'
          onMouseEnter={(e) => {
            setIsHovering(true)
          }}
          onMouseLeave={(e) => {
            setIsHovering(false)
          }}
        >
          <ButtonText style={{ marginRight: isHovering === true ? "20px" : "0px" }}>CREATE ACCOUNT</ButtonText>
          <IconArrow style={{ opacity: isHovering === true ? "100" : "0" }} src={Image3} />
        </Button>
      </Form>
    </Container>
  )
}
