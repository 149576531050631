import React, { useState, useEffect, forwardRef } from 'react';
import media from "styled-media-query"
import DatePicker from 'react-datepicker'
import IconPencil from './../../../images/ic_pencil_blue.svg'
import styled from 'styled-components';
import "react-datepicker/dist/react-datepicker.css"
import "./TimeRangePickerUI.css";
import { COLORS } from '../../../styles/colors';
import { useHistory } from 'react-router-dom'


export default function TimeRangePickerUI(props) {

  // https://codesandbox.io/s/react-datepicker-custominput-sample-forked-fizf4j?file=/src/App.tsx;

  const [isSelected, setIsSelected] = useState(false)
  const history = useHistory();
  
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (

      <CustomDateButton style={{border: isSelected ? '1px solid #d5d5d5' : null, padding: isSelected ? '11px' : null}} className="example-custom-input" onClick={onClick} ref={ref}>
        <ButtonText style={{color: isSelected ? COLORS.White : null}}>{value}</ButtonText>
        <Icon src={IconPencil} />
      </CustomDateButton>
    ));

    const checkIfDateIsToday = (dateToCheck) => {

      const today = new Date(); 
      
      if (dateToCheck.getFullYear() === today.getFullYear() && 
          dateToCheck.getMonth() === today.getMonth() && 
          dateToCheck.getDate() === today.getDate()) {
        return true
      } else {
        return false
      }

    }

    const loadDate = (date) => {

      if(checkIfDateIsToday(date)){
        props.lockTo24H(false)
        var freshDate = new Date()
        props.loadCustomDate(freshDate)
        setIsSelected(true)
        history.push(`/portal/dashboard/${freshDate.getTime()}`)
      } else {
        props.lockTo24H(true)
        props.loadZero24HourDate(date)
        setIsSelected(true)
      }
    }

  return (
    <Container>
      <DatePicker 
      wrapperClassName='datepicker'
      key={0}
      selected={props.endTime} 
      onChange={(date) => loadDate(date)} 
      timeInputLabel="Time:"
      maxDate={new Date()}
      disabled={false} 
      placeholderText="Click to select a date"
      // minTime={new Date().setHours(0, 0, 0, 0)}
      // maxTime={new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHour(), now.getMinute(), now.getSecond())}
      // showTimeSelect
      withPortal
      disabledKeyboardNavigation
      timeFormat="HH:mm"
      timeIntervals={60}
      // dateFormat="MM/dd/yyyy h:mm aa"
      dateFormat="MM/dd/yyyy"
      scrollableMonthYearDropdown
      customInput={<ExampleCustomInput />}
    />
    

 


      {/* -------------START DATEPICKER */}
      {/* <DatePicker 
        key={0}
        selected={props.startTime} 
        onChange={(date) => props.loadStartTime(date)} 
        onChangeRaw={(e) => handleChangeRaw(e)}
        timeInputLabel="Time:"
        maxDate={new Date()}
        disabled={true} 
        // minTime={new Date().setHours(0, 0, 0, 0)}
        // maxTime={new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHour(), now.getMinute(), now.getSecond())}
        showTimeSelect
        disabledKeyboardNavigation
        timeFormat="HH:mm"
        timeIntervals={60}
        dateFormat="MM/dd/yyyy h:mm aa"
        showMonthDropdown
        showYearDropdown
        scrollableMonthYearDropdown
      /> */}


      {/* -------------END DATEPICKER */}
      {/* <DatePicker 
        key={1}
        selected={props.endTime} 
        onChange={(date) => props.loadEndTime(date)} 
        timeInputLabel="Time:"
        maxDate={new Date()}
        disabled={true} 
        // minTime={new Date().setHours(0, 0, 0, 0)}
        // maxTime={new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHour(), now.getMinute(), now.getSecond())}
        showTimeSelect
        disabledKeyboardNavigation
        timeFormat="HH:mm"
        timeIntervals={60}
        dateFormat="MM/dd/yyyy h:mm aa"
        showMonthDropdown
        showYearDropdown
        scrollableMonthYearDropdown
      /> */}



    </Container>
  );
}



const Container = styled.div`
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  flex-direction: row;
  /* justify-items: space-between; */

`

const CustomDateButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 360px;
  height: 45px;
  background: #1A2B4A;
  border-radius: 8px;
  border: none;
  padding: 12px;
  :hover{
    padding: 11px;
  }
  ${media.lessThan("743px")`
    height: 55px;
    max-width: 300px;
    min-width: 270px;
    `}
  ${media.between("744px", "large")`
    height: 55px; 
    `}
`;

const ButtonText = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 100;
  font-size: 16px;
  line-height: 129.5%;
  display: flex;
  color: #4C628C;
  :hover{
    padding: 0px;
    border: none;
  }
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
  :hover{
    padding: 0px;
    border: none;
  }
  /* margin-right: 5px; */
`;