import React, { useEffect, useState, useContext } from "react"
import { AccountContext } from "../../auth/Account"
import styled from "styled-components"
import media from "styled-media-query"
import ImgArrowRight from "./../../images/ic_arrow_right_blue.svg"
import ImgArrowRightWhite from "./../../images/ic_arrow_right_white.svg"
import ImgArrowDown from "./../../images/ic_arrow_down_white.svg"
import AccountDetailsForm from "../form/AccountDetailsForm"
import ChangeEmailForm from "../form/ChangeEmailForm"
import ChangePassword from "../form/ChangePassword"
import EditAccount from "./EditAccount"
import { useMediaQuery } from 'react-responsive'
import { COLORS } from "../../styles/colors"
import NotificationSaveDiscard from "../notification/NotificationSaveDiscard"


export default function Account() {

  const [isHovering, setIsHovering] = useState(false)
  const [isDisconnected, setIsDisconnected] = useState(false)
  const [isUnlinked, setIsUnlinked] = useState(false)
  const [isUnconfigured, setIsUnconfigured] = useState(true)
  const [isConfigOpen, setIsConfigOpen] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const setConfigOpen = () => {
    setIsConfigOpen(true)
  }

  const setConfigured = () => {
    setIsUnconfigured(false)
    setIsConfigOpen(false)
  }

  const closePopup = () => {
    setIsOpen(false)
  }

  const { getSession } = useContext(AccountContext)
  const isDesktopSize = useMediaQuery({ query: '(min-width: 744px)' })

  const [state, setState] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    formattedNumber: "",
  })

  useEffect(() => {
    updateForm()
  }, [])


  const toggleConfig = () => {
    if(isConfigOpen){
      const element = isDesktopSize ? document.getElementById('top') : document.getElementById('navbar');
      element.scrollIntoView({ behavior: 'smooth' });
      setTimeout(() => {
        setIsConfigOpen(false)
      }, 400)
    } else {
      setIsConfigOpen(true)
      setTimeout(() => {
      const element = document.getElementById('edit');
      element.scrollIntoView({ behavior: 'smooth' });
      }, 100)
    }
  }

  const updateForm = () => {
    getSession().then(({ email, given_name, family_name, phone_number }) => {
      // Format the phone number for the update account screen
      const trimNumber = phone_number.slice(2)
      const formattedNumber = `(${trimNumber.substring(0, 3)}) ${trimNumber.substring(3, 6)} ${trimNumber.substring(
        6,
        10
      )}`

      setState({
        email: email,
        firstName: given_name,
        lastName: family_name,
        phoneNumber: phone_number,
        formattedNumber: formattedNumber,
      })
    })
  }

  return (
    <OuterContainer id='top'>
      <Container>
        <TopContainer>
          <HeaderText style={{marginBottom: 22}}>{state.email}</HeaderText>
        {/* <Line /> */}
          <Title>First Name</Title>
          <InfoText>{state.firstName}</InfoText>
          <Title>Last Name</Title>
          <InfoText>{state.lastName}</InfoText>
          <Title>Email</Title>
          <InfoText>{state.email}</InfoText>
          <Title>Phone Number</Title>
          <InfoText style={{marginBottom: 29}}>{state.formattedNumber}</InfoText>
          {/* <Line /> */}
        </TopContainer>

          <ConfigHeaderContainer id='edit'>
            <ConfigContainer
                onClick={!isLoading ? toggleConfig : null} 
                style={{ cursor: !isLoading ? 'pointer' : 'default', background: isConfigOpen ? null : isHovering ? COLORS.Secondary : null}}
                onMouseEnter={(e) => {setIsHovering(true)}}
                onMouseLeave={(e) => {setIsHovering(false)}}>
                <HeaderText2
                  >Edit Account Details </HeaderText2>
                
                <ConfigRightContainer>
                    <NotificationSaveDiscard />
                  <ArrowIcon
                    src={isConfigOpen? ImgArrowDown : ImgArrowRightWhite} />
                </ConfigRightContainer>
              </ConfigContainer>
          </ConfigHeaderContainer>
 
            {/*----SAVE MOBILE----*/}
            <SaveConfigContainerMobile style={{display: isConfigOpen ? 'flex' : 'none'}}>
                <SaveConfigMobile >
                    <BtnTextMobile>SAVE CONFIGURATION</BtnTextMobile>
                </SaveConfigMobile>
                <ButtonText2Mobile >DISCARD CHANGES</ButtonText2Mobile>
            </SaveConfigContainerMobile>

          {isConfigOpen ?
            <EditAccount />
            :
            null
          }

        </Container>
      </OuterContainer>
      )
    }


  const OuterContainer = styled.div`
  /* border: 1px transparent dashed; */
  display: flex;
  ${media.lessThan("743px")`
      justify-content: center;
      margin-top: 30px;
    `}
  `

  const Container = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  margin-right: 30px;
  /* margin-top: 20px; */
  margin-bottom: 50px;
  ${media.lessThan("743px")`
      width: 90%;
      align-self: center;
      margin-right: 0px;
    `}
  `

  const TopContainer = styled.div`
  /* border: 1px yellow dashed; */
  /* width: 439px; */
  background-color: #0e1c36;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 20px;
  /* width: 100%; */
  ${media.lessThan("743px")`
      width: 100%;
      align-self: center;
    `}
  ${media.between("744px", "large")`
        /* width: 384px; */
    `}
  `

  const Label = styled.label`
  /* border: 1px blue dashed; */
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 129.5%;
  letter-spacing: 0.03em;
  color: ${COLORS.HighlightMedium};
  margin-bottom: 2px;
  margin-top: 6px;
  `

  const HeaderText = styled.h2`
  font-family: Blinker;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 84.5%;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
  `

  const HeaderText2 = styled(HeaderText)`
  color: ${COLORS.White};
  align-self: center;
  `;

  const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${COLORS.HighlightMedium};
  margin-top: 21px;
  margin-right: 30px;
  ${media.lessThan("743px")`
      align-self: center;
      /* width: 90% */
      margin-right: 0px;
    `}
  `

  const Title = styled.h3`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 129.5%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color: ${COLORS.HighlightMedium};
  margin-top: 9px;
  `

  const InfoText = styled.h2`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: ${COLORS.White};
  margin-top: 1px;
  `

  const ChangeContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${media.lessThan("743px")`
      align-self: center;
      width: 90%;
    `}
  `


  const ConfigHeaderContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  /* padding: 20px; */
  background-color: #0e1c36;
  border-radius: 10px;
  min-height: 68px;
  `

const ConfigContainer = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
`;

  const ConfigRightContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  `

  const ArrowIcon = styled.img`
  width: 24px;
  height: 24px;
  align-self: center;
  `

  const BtnBack = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 40px;
  border: 1px solid #63c085;
  border-radius: 30px;
  color: ${COLORS.White};
  background: transparent;
  cursor: pointer;
  text-align: center;
  margin-right: 15px;
  :hover {
    background: ${COLORS.Secondary};
  }
  ${media.lessThan("743px")`
    /* margin-bottom: 50px; */
        display: none;
    `}
  ${media.between("744px", "large")`
    /* margin-bottom: 50px; */
    `}
    ${media.greaterThan("large")`
    `}
  `

  const ButtonText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transition-duration: 0.5s;
  `

  const SaveConfigContainerMobile = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  /* margin-right: 20px; */
  background: #0e1c36;
  height: 60px;
  margin-top: -10px;
  padding-left: 20px;
  ${media.lessThan("743px")`
    display: flex;
    flex-wrap: wrap;
    min-height: 120px;
    padding-top: 20px;
    width: 100%;
    `}
  ${media.between("744px", "large")`
    display: flex;
  `}
  ${media.greaterThan("large")`
    display: none;
      height: 10px;
    `}
`;

const SaveConfigMobile = styled.button`
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 40px;
  gap: 9px;
  width: 241px;
  height: 40px;
  border: 1px solid #63C085;
  border-radius: 30px;
  background: none;
  align-self: center;
  transition-duration: 0.5s;
  margin-right: 20px;
  :hover {
    opacity: 0.8; 
  }
    ${media.lessThan("743px")`
      display: flex;
    `}
    ${media.between("744px", "large")`
      display: flex;
    `}
    ${media.greaterThan("large")`
      display: none;
    `}
`;
    
const BtnTextMobile = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transition-duration: 0.5s;
  color: ${COLORS.White};
    ${media.lessThan("743px")`
      display: flex;
    `}
    ${media.between("744px", "large")`
      display: flex;
    `}
    ${media.greaterThan("large")`
      display: none;
    `}
`;

  const ButtonText2Mobile = styled.p`
    font-family: "Roboto Condensed", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: ${COLORS.White};
    text-align: center;
    align-self: center;
    margin-right: 15px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    transition-duration: 0.5s;
    cursor: pointer;
    :hover {
      color: #63C085; 
    }
  ${media.lessThan("743px")`
    display: flex;
  `}
  ${media.between("744px", "large")`
    display: flex;
  `}
  ${media.greaterThan("large")`
    display: none;
  `}
  `;
    // <EditAccount />