export const COLORS = {
    Base : "#041129",
    Primary : "#0A1934",
    Secondary : "#1A2B4A", 
    HighlightDark : "#1A2740",
    HighlightMedium : "#4C628C",
    HighlightLight : "#A1ADC2",
    White: "#FFFFFF",
    ErrorOrange: "#F15C5D",
    GreyUnavailable: "#B6B6B6",
    Green: "#63C085",
    Red: "#FF0000",
    TileBlue: "#15223F"
}