import React, { useEffect, useState, useContext } from "react"
import Select from "react-dropdown-select"
import styled from "styled-components"
import media from "styled-media-query"
import { COLORS } from "./../../../styles/colors"

// Next steps: Format time range date, query data based on times

export default function CustomMonthDropdownUI(props) {

  const [isSelected, setIsSelected] = useState(false)
  const [selectedTimeRange, setSelectedTimeRange] = useState(null)
  const [isHovering, setIsHovering] = useState(false)
  // const [keepOpen, setKeepOpen] = useState(false)


  const customContentRenderer = () => (
    <div style={{ cursor: 'pointer', color: selectedTimeRange ? COLORS.White : COLORS.HighlightMedium}}>
      {selectedTimeRange ? selectedTimeRange : "Select month"}
    </div>
  );

    const setupOptions = () => {
      const options = [
        {key: 0, value: 0, label: "January" },
        {key: 1, value: 1, label: "February" },
        {key: 2, value: 2, label: "March" },
        {key: 3, value: 3, label: "April" },
        {key: 4, value: 4, label: "May" },
        {key: 5, value: 5, label: "June" },
        {key: 6, value: 6, label: "July" },
        {key: 7, value: 7, label: "August" },
        {key: 8, value: 8, label: "September" },
        {key: 9, value: 9, label: "October" },
        {key: 10, value: 10, label: "November" },
        {key: 11, value: 11, label: "December" },
      ];

      if(props.firstMonth && props.firstYear && props.selectedYear ){

          if(props.selectedYear === props.firstYear){ // If user selects first year of data all months may not be available

            const currentMonth = new Date().getMonth() + 1     // +1 so we always get a one month spread

            if(currentMonth > props.firstMonth){
              return options.slice(props.firstMonth, currentMonth).reverse() // If current month bigger then start month, just show range between
            } else {
              return options.slice(props.firstMonth, 12).reverse() // If current month smaller then start month, want range from first month -> end of year  
            }

          } else if(props.selectedYear === new Date().getFullYear()){  // If user selects current year not all months may not be available
            // If selected year 
            return options.slice(0, new Date().getMonth() + 1).reverse()  
          } else {
            // All other cases
            return options.slice(0, 12).reverse() //Otherwise all months should be available
          }

        } else {
          return options.slice(0, 0) 
        }
   
    }

    useEffect(()=>{
      setSelectedTimeRange()   
    }, [props.selectedYear])
  
  const onSelected = (item) => {
    if (item[0]) {
        const month = item[0].value
        props.setData(month)
        setSelectedTimeRange(`${item[0].label}`)   
        setIsSelected(true)
    } else {
      setSelectedTimeRange(null)
      setIsSelected(false)
    }
  }

  return (
    <Container>
      <TimeRangeDetails>
        <div                 
          onMouseEnter={(e) => {
            setIsHovering(true)
          }}
          onMouseLeave={(e) => {
            setIsHovering(false)
          }}
        >
          <StyledSelect
              dropdownGap={0}
              color={COLORS.White}
              keepOpen={false}
              placeholder={"Select or search for a date"}
              contentRenderer={customContentRenderer}
              onChange={(value) => onSelected(value)}
              options={setupOptions()}
              values={[]}
              dropdownHeight="500px"
              dropdownPosition="bottom"
              style={{
                border: isHovering ? "1px solid #d5d5d5" : isSelected ? "1px solid #d5d5d5" : "1px solid transparent",
                  borderRadius: 8,
                  color: COLORS.White,
                  fontWeight: "100",
                  paddingLeft: "12px",
                  fontFamily: "Roboto Condensed",
                  fontSize: "16px"
              }}
          />
        </div>
      </TimeRangeDetails>
    </Container>
  )
}

const Container = styled.div`
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
`

const CustomText = styled.p`
  color: ${COLORS.White};
  font-weight: 100,
  padding-left: 12px,
  font-family: "Roboto Condensed",
  font-size: 16px;
`;

            {/* <div style={{height: 45, width: 180, border: '1px white solid'}} /> */}

const TimeRangeDetails = styled.div`
  border-radius: 10px;
  /* box-sizing: border-box; */
  display: flex;
  flex-direction: column;
  flex-wrap: none;
  width: 180px;
  /* border: 1px white solid; */
  ${media.lessThan("743px")`
        /* padding-bottom: 27px; */
        /* padding-right: 0px; */
        /* width: 100%; */
      max-width: 180px;
      min-width: 150px;
        /* height: 350px; */
    `}
  ${media.between("744px", "large")`
  /* height: 350px; */
    `}
    ${media.greaterThan("large")`
    `}
`

const StyledSelect = styled(Select)`
  height: 45px;
  width: 384px;
  color: ${COLORS.White};
  background: ${COLORS.Secondary};
  /* margin-top: 15px;  */

  ${media.lessThan("744px")`
        /* width: 90%; */
        height: 55px;
        margin-bottom: 10px;
    `}
  ${media.between("medium", "large")`

        height: 55px;
    `}
    ${media.greaterThan("large")`
    `}

    .react-dropdown-select-clear{
      
    }
    .react-dropdown-select-dropdown-handle {
    color: ${COLORS.White};
    height: 22px;
  }
  .react-dropdown-select-option {
  }
  .react-dropdown-select-item {

  }
  .react-dropdown-select-input {
    color: ${COLORS.White};             // cursor select
  }
  .react-dropdown-select-dropdown {
    background: ${COLORS.Secondary};            // card background
    border-radius: 8px;
    border: 1px solid ${COLORS.HighlightMedium};
  }
  .react-dropdown-select-item {
    color: ${COLORS.White};                     // list item
    border-bottom: none;
    padding-top: 12px;
    padding-bottom: 12px;
    :hover {
      background: ${COLORS.HighlightMedium};
    }
  }
  .react-dropdown-select-item.react-dropdown-select-item-selected{
    color: ${COLORS.White};                   // selected item
    background: ${COLORS.HighlightMedium};
    border-bottom: none;
  }
  .react-dropdown-select-item.react-dropdown-select-item-active {
    //background: #111;
    /* border-bottom: 1px solid #333; */

    /* font-weight: bold; */

  }
  .react-dropdown-select-item.react-dropdown-select-item-disabled {
    /* color: #ccc; */
  }
  ::placeholder {
    color: ${COLORS.White};

  }
  :focus {
    outline: ${COLORS.White};
    border: 1px solid ${COLORS.White};
  }

`

const CalendarContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* height: 500px; */
  /* left: 454px; */
  /* top: 390px; */
  /* background: #1A2B4A; */
  /* border: 1px solid #A1ADC2; */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
`;