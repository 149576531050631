import React, {useState} from 'react'
import { API, Auth } from "aws-amplify"
import AddProperty from "./../properties/AddProperty"
import AddPropertyConfirm from "./../properties/AddPropertyConfirm"
import AddPropertySuccess from "./../properties/AddPropertySuccess"

export default function AddPropertyContainer(props) {

    const [showConfirm, setShowConfirm] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [propertyUUID, setPropertyUUID] = useState()
    const [showSpinner, setShowSpinner] = useState(false)
    const [disabled, setDisabled] = useState(false)

    const [propertyData, setPropertyData] = useState({
        propertyName: '',
        externalId: '',
        portfolio: '',
        street: '',
        city: '',
        provinceState: '',
        postalZipCode: ''
    })

    const loadFormData = (data) => {
        setPropertyData({
            propertyName: data.propertyName,
            externalId: data.externalId,
            portfolio: data.portfolio,
            street: data.street,
            city: data.city,
            provinceState: data.provinceState,
            postalZipCode: data.postalZipCode,
            country: data.country
        })
        setShowConfirm(true)
    }

    const submitData = async () => {
        setDisabled(true)
        setShowSpinner(true)
        const payload = {
          externalId: propertyData.externalId,
          config: {
            name: propertyData.propertyName,
            address: {
                city: propertyData.city,
                country: propertyData.country, 
                postalZip: propertyData.postalZipCode,
                provinceState: propertyData.provinceState,
                streetAddress: propertyData.street
            },
            assests: {},
            portfolio: propertyData.portfolio
          }
        }
        const token = (await Auth.currentSession()).getIdToken()
        const jwt = token.getJwtToken()
        const init = {
            headers: { 
                Authorization: `Bearer ${jwt}` 
            },
            body: payload
        }

        try {
            const response = await API.post('FrontendAPI-properties', ``, init)
            if (response.success) {
                setDisabled(false)
                setShowSpinner(false)
                if(props.linkView == null){
                    setShowSuccess(true)
                }
                setPropertyUUID(response.data.id)

                if (props.done) {
                    props.done(response.data)
                }
            } else {
                setDisabled(false)
                setShowSpinner(false)
            }
        } catch (error) {
            setDisabled(false)
            setShowSpinner(false)
        }
    }

    const backToEdit = () => {
        setShowConfirm(false)
        setShowSuccess(false)
    }

    return (
        <div>
            {showConfirm ?
                    showSuccess ?
                        props.linkView == null ?
                            <AddPropertySuccess propertyUUID={propertyUUID} />
                            :
                            null
                        :
                        <AddPropertyConfirm linkView={props.linkView}  confirm={submitData} form={propertyData} backToEdit={backToEdit} showSpinner={showSpinner} disabled={disabled}/>
                :
                <AddProperty linkView={props.linkView} loadFormData={loadFormData} propertyData={propertyData}/>
            }          
        </div>
    )
}
