import React, { useEffect, useState, useContext } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import { COLORS } from "../../styles/colors"

const Container = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  padding-top: 38px;
  margin-right: 40px;
  margin-bottom: 46px;
  ${media.lessThan("743px")`
        flex-direction: column;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const TabContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  width: 0.5;
  padding: 10px;
  /* opacity: 50%; */
  ${media.lessThan("743px")`
        width: 100%;
    `}
  ${media.between("744px", "large")`
        flex-wrap: wrap;
        justify-content: flex-start;
    `}
    ${media.greaterThan("large")`
        width: 25%;
        padding: 5px;
    `}
`

const StatusIcon = styled.img`
  width: 11px;
  height: 11px;
  margin-right: 15px;
  align-self: center;
`

const CardTextContainer = styled.div`
  /* border: 1px red dashed; */
  /* width: 144px; */
  display: flex;
  flex-direction: column;
  min-width: 200px;
`

const CardNumber = styled.h1`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  color: ${COLORS.HighlightDark};
  margin-top: 3px;
`

const HeaderText = styled.h3`
  font-family: Blinker;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 110%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: ${COLORS.HighlightMedium};
`

export default function WingnutConfigTabHeaderItem(props) {

    const [isHovering, setIsHovering] = useState(false)
    const [isSelected, setIsSelected] = useState(false)

    const setSelected = () => {
        props.onHandleClick()
        setIsSelected(true)
    }

    useEffect(() => {
        setIsSelected(props.selected)
    })

  return (
    <TabContainer 
    style={{ cursor: isHovering ? 'pointer' : null}}
    onClick={setSelected}
    onMouseEnter={(e) => {
        setIsHovering(true)
      }}
      onMouseLeave={(e) => {
        setIsHovering(false)
      }}
    >
    <StatusIcon src={props.img} />
    <CardTextContainer>
      <HeaderText style={{color: isSelected ? COLORS.White : isHovering? COLORS.HighlightLight : null}}>{props.inputText}</HeaderText>
      <CardNumber style={{color: isSelected ? COLORS.HighlightLight : isHovering? COLORS.HighlightLight : COLORS.HighlightMedium}} >{props.sensorText}</CardNumber>
    </CardTextContainer>
  </TabContainer>
  )
}
