import React, { useEffect, useState, useContext } from "react"
import styled from "styled-components"
import { COLORS } from "../../styles/colors"
import {FormatLabel} from "./../util/FormatLabel"

export default function AlertCard(props) {

    var number = Number(props.value)
    var rounded = number.toFixed(1)

    const formatUnit = () => {
        if(props.label){
           if (props.label.includes("Current")){
                return `${rounded} A`
           } else if (props.label.includes("Pressure")){
                return `${rounded} PSI`
           } else {
            return `${rounded} \u00B0 F`
           }
        }
    }

  return (
    <Card >
        <TextContainer>
            <TitleText>{FormatLabel(props.label)}</TitleText>
            <TempText>{formatUnit()} </TempText>
        </TextContainer>
        <BtnOpen onClick={()=>{props.toggleNotificationDetails(false, props.label)}}>Open Threshold</BtnOpen>
    </Card>

  )
}


const Card = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 105px;
    width: 266px;
    background: ${COLORS.Secondary};
    padding: 11.5px;
    border-radius: 5px;
    /* flex-grow: 1; */
    margin: 5px;
    margin-left: 0px;
    margin-right: 10px;
`;

const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const TitleText = styled.p`
    /* border: 1px blue dashed; */
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 96%;
    display: flex;
    align-items: center;
    color: ${COLORS.White};
    /* width: 150px; */
`;

const TempText = styled.p`
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 129.5%;
    display: flex;
    align-items: center;
    color: ${COLORS.ErrorOrange};
    margin-top: 3px;
`;

const BtnOpen = styled.button`
    /* border: 1px blue dashed; */
    outline: none;
    border: none;
    background: transparent;
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 129.5%;
    display: flex;
    align-items: flex-start;
    text-decoration-line: underline;
    color: ${COLORS.White};
    padding: 0px;
`;