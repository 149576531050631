import React, { useEffect, useState, useContext } from "react"
import { Link } from "react-router-dom"
import { API, Auth } from "aws-amplify"
import styled from "styled-components"
import media from "styled-media-query"
import { COLORS } from "./../../styles/colors"

export default function PropertyHeader(props) {

  const {address} = props.propertyDetails.config
  const [isGoToPropertyHovering, setIsGoToPropertyHovering] = useState(false)

  return (
    <HeaderContainer>
        <HeaderTextContainer>
            <HeaderText>{props.propertyDetails.config.name}</HeaderText>
            <Subheader>{address && `${address.streetAddress} ${address.city} ${address.provinceState} ${address.country} ${address.postalZip}`}</Subheader>
        </HeaderTextContainer>

        <Link to={`/portal/property/detail/${props.propertyDetails ? props.propertyDetails.id : null}`} style={{ marginBottom: 8, height: 30, textDecoration: "none", pointerEvents: props.propertyDetails && props.isLoading === false  ? '' : 'none'}}>
            <BtnLinkWingnut
              onMouseEnter={(e) => {
                  if(props.propertyDetails){
                    setIsGoToPropertyHovering(true)
                    }
                   }}
                   onMouseLeave={(e) => {
                    setIsGoToPropertyHovering(false)
                   }}>
                <ButtonText2 style={{opacity: props.propertyDetails && props.isLoading === false ? 1.0 : 0.5, color: isGoToPropertyHovering ? '#63C085' : '#FFFFFF', cursor: props.propertyDetails ? 'pointer' : null}}>GO TO PROPERTY</ButtonText2> 
            </BtnLinkWingnut>
          </Link>


    </HeaderContainer>
  )
}

const HeaderContainer = styled.div`
    /* border: 1px blue dashed; */
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-bottom: 44px;
    align-items: flex-end;
    ${media.lessThan("743px")`
        flex-direction: column;
        margin-bottom: 15px;
        margin-top: 15px;
        align-items: center;
  `}
`

const HeaderTextContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${media.lessThan("743px")`
    align-self: center;
  `}
`;


const HeaderText = styled.h2`
    /* border: 1px red dashed; */
    font-family: Blinker;
    font-size: 31px;
    font-style: normal;
    font-weight: 600;
    line-height: 90%; /* 27.9px */
    letter-spacing: -0.31px;
    color: ${COLORS.White};
    ${media.lessThan("743px")`
      text-align: center;
  `}
`

const Subheader = styled.h4`
  font-family: "Roboto Condensed", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    margin-top: 6px;
    color: ${COLORS.HighlightLight};
    ${media.lessThan("743px")`
      text-align: center;
  `}
`;

const BtnLinkWingnut = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    background: transparent;
    margin-left: 45px;
    /* width: 134px; */
    /* height: 34px; */
    border: 1px solid #63C085;
    border-radius: 26px;
    /* margin-bottom: 5px; */
    ${media.lessThan("743px")`
      margin-left: 0px;
      margin-top: 15px;
    `}
`;

const ButtonText2 = styled.p`
  /* border: 1px red dashed; */
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${COLORS.White};
  align-self: center;
  transition-duration: 0.5s;
  ${media.lessThan("743px")`
        font-size: 15px;
        /* width: 170px; */
    `}
  ${media.between("744px", "large")`
        font-size: 15px;
    `}
    ${media.greaterThan("large")`
    `}
`;
