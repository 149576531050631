import React, { useState, useContext, useEffect, useMemo } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import PlacesAutocomplete, {geocodeByPlaceId} from 'react-places-autocomplete';
import { COLORS } from "../../styles/colors";

const google = window.google

const Container = styled.div`
    /* border: 1px yellow dashed; */
    width: 100%;
` 

const Input = styled.input`
  /* border: 1px blue dashed; */
  width: 100%;
  height: 45px;
  outline: none;
  border: none;
  color: ${COLORS.White};
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 129.5%;
  font-weight: normal;
  background-color: ${COLORS.Secondary};
  border-radius: 8px;
  box-sizing: border-box;
  padding-left: 10px;
  ::placeholder {
    color: ${COLORS.HighlightMedium};
  }
  :hover {
    border: 1px solid ${COLORS.HighlightMedium};
    padding-left: 9px;
  }
  :focus {
    border: 1px solid ${COLORS.HighlightLight};
    padding-left: 9px;
  }
  ${media.lessThan("743px")`
        width: 100%;
        height: 55px;
    `}
  ${media.between("744px", "large")`
        width: 100%;
        height: 55px;
    `}
    ${media.greaterThan("large")`
    `}
`

export default function AddressSearch(props) {
  const { selectAddress, errors, defaultValue } = props
  const [address, setAddress] = useState(defaultValue)

  const handleChange = (value) => {
    setAddress(value)
  }

  const handleSelect = async (address, placeId, suggestion) => {
    setAddress(suggestion.formattedSuggestion.mainText)

    const [place] = await geocodeByPlaceId(placeId);
    const streetNumber = place.address_components.find(c => c.types.includes('street_number')) || undefined;
    const street = place.address_components.find(c => c.types.includes('route')) || undefined;
    const city = place.address_components.find(c => c.types.includes('locality')) || undefined;
    const country = place.address_components.find(c => c.types.includes('country')) || undefined;
    const province = place.address_components.find(c => c.types.includes('administrative_area_level_1')) || undefined;
    const postalCode = place.address_components.find(c => c.types.includes('postal_code')) || undefined;

    const newAddress = {
      city: city ? city.long_name : "",
      country: country ? country.long_name : "",
      postalZip: postalCode ? postalCode.long_name : "",
      provinceState: province ? province.short_name : "",
      streetAddress: (`${streetNumber ? streetNumber.long_name : ""} ${street ? street.short_name : ""}`).trim()
    }
    selectAddress(newAddress)
  }

  return (
    <Container>
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <div>
              <Input 
                {...getInputProps({ name: "street", type: "text", placeholder: 'Address Search',})} 
                style={{ border: errors.street ? "1px solid #D25353" : null, paddingLeft: errors.street ? '9px' : null }}
              />

            <div className="autocomplete-dropdown-container">

              {loading && <div>Loading...</div>}
              
              {suggestions.map(suggestion => {
                const className = suggestion.active ?'suggestion-item--active': 'suggestion-item';

                return (
                    <div
                      style={{
                        backgroundColor: suggestion.active ? COLORS.HighlightMedium : COLORS.Secondary,
                        cursor: 'pointer',
                        width: '100%',
                        maxWidth: '378px', 
                        color: `${COLORS.White}`,
                        fontFamily: '"Roboto Condensed", sans-serif',
                        padding: 10,
                        borderTopLeftRadius: suggestions.indexOf(suggestion) === 0 ? '10px' : null,
                        borderTopRightRadius: suggestions.indexOf(suggestion) === 0 ? '10px' : null,
                        borderBottomLeftRadius: suggestions.indexOf(suggestion) === suggestions.length -1 ? '10px' : null,
                        borderBottomRightRadius: suggestions.indexOf(suggestion) === suggestions.length -1 ? '10px' : null,
                        borderLeft: `1px solid ${COLORS.HighlightLight}`,
                        borderRight:   `1px solid ${COLORS.HighlightLight}`,
                        borderBottom: suggestions.indexOf(suggestion) === suggestions.length -1 ? `1px solid ${COLORS.HighlightLight}` : null,
                      }}
                      {...getSuggestionItemProps(suggestion, {
                        className,                     
                      })}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </Container>
  )
}