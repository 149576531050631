import React, { useEffect, useState, useContext } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import IconLaunch from "./../../../images/ic_launch_green.svg"
import ImgGreen from "./../../../images/ic_status_green.svg"
import ImgWingnut from "./../../../images/ic_wingnuts.svg"
import { Link } from "react-router-dom"
import { COLORS } from "./../../../styles/colors"
import { DateTime } from "luxon"
import WingnutStatus from "./../../wingnuts/WingnutStatus.enum"


export default function WingnutDetailsUI(props) {

  const formatDate = (date) => {
    const dateObj = new Date(date);
    const dateWithoutMicroseconds = dateObj.toISOString().substring(0, 19) + "Z";
    const localDate = new Date(dateWithoutMicroseconds).toLocaleString();
    return dateWithoutMicroseconds
  }

  const wingnutStatus = (item) => {
    if (item.config.latestPost) {
      var date = DateTime.utc()
      var apiDateTime = item.config.latestPost ? DateTime.fromFormat(item.config.latestPost, "yyyy-MM-dd HH:mm:ss") : DateTime.utc()
      var difference = date.diff(apiDateTime, 'minutes')
      if (difference.minutes > 15) {
        return WingnutStatus.Disconnected.value
      }
    } else {
      return WingnutStatus.Disconnected.value
    }

      if (item.config.propertyId === undefined) {
        return WingnutStatus.Unlinked.value
      }

      if (!(Object.keys(item.config.sensors).length > 0)) {
        return WingnutStatus.Unconfigured.value
      }

    return WingnutStatus.Live.value
  }

  return (
    <OuterContainer>
        <TopContainer>


            {
              props.wingnut 
              ? <Link to={`/portal/wingnut/detail/${props.wingnut ? props.wingnut.id : null}`} style={{ textDecoration: "none"}}>
                  <Button>
                      <Icon src={IconLaunch} />
                      <ButtonText>Go to Wingnut</ButtonText>
                  </Button>
                </Link>
              : null
            }


        </TopContainer>
         
        <Container>
            <ColumnContainer>
                <TextContainer>
                    <Title>Serial Number</Title>
                    <InfoText>{props.wingnut ? props.wingnut.externalId : '--'}</InfoText>
                </TextContainer>
                <TextContainer>
                    <Title>Wingnut Model</Title>
                    <InfoText>{props.data && props.data[0] ? props.data[0].wingnutModel : '--'}</InfoText>
                </TextContainer>
            </ColumnContainer>
            <ColumnContainer>
                <TextContainer>
                    <Title>Wingnut Software Version</Title>
                    <InfoText>{props.data && props.data[0] ? props.data[0].wingnutSoftwareVersion : '--'}</InfoText>
                </TextContainer>
                <TextContainer>
                    <Title>Wingnut Hardware Version</Title>
                    <InfoText>{props.data && props.data[0] ? props.data[0].wingnutHardwareVersion  : '--'}</InfoText>
                </TextContainer>
            </ColumnContainer>
            <ColumnContainer>
                <TextContainer>
                    <Title>Wingnut Latest Timestamp</Title>
                    <InfoText>{props.data && props.data[0] ? formatDate(props.data[0].time)  : '--'}</InfoText>
                </TextContainer>
                <TextContainer>
                    <Title>Wingnut Last Boot</Title>
                    <InfoText>{props.data && props.data[0] ? formatDate(props.data[0].lastBoot)  : '--'}</InfoText>
                </TextContainer>
            </ColumnContainer>
        </Container>
      </OuterContainer>
  )
}

// latest boot 2023-01-18 17:46:00 
// hardware v1.4.0
// software v5.0.1
// model WN48-WIFI


  const OuterContainer = styled.div`
  /* border: 1px red dashed; */
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    padding-top: 0px;
  `

const TopContainer = styled.div`
    /* border: 1px green dashed; */
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    justify-content: flex-end;
    /* margin-top: 5px; */
`

const HeaderContainer = styled.div`
    /* border: 1px blue dashed; */
    display: flex;
    flex-direction: row;
    margin-bottom: 21px;
    align-items: center;
    ${media.lessThan("743px")`
        align-self: center;
        margin-right: 0px;
  `}
`

const StatusIcon = styled.img`
    /* border: 1px red dashed; */
    width: 12px;
    height: 12px;
    margin-right: 22px;
`


const HeaderText = styled.h2`
    /* border: 1px red dashed; */
    font-family: Blinker;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 84.5%;
    letter-spacing: -0.01em;
    color: ${COLORS.White};
`

const Container = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1440px;
  margin-right: 30px;
  margin-bottom: 10px;
  ${media.lessThan("743px")`
      align-self: center;
      margin-right: 0px;
    `}
  `

const TextContainer = styled.div`
    /* border: 1px yellow dashed; */
    display: flex;
    flex-direction: column;
    margin-right: 120px;
    ${media.lessThan("743px")`
    `}
`

const ColumnContainer = styled.div`
    /* border: 1px yellow dashed; */
    display: flex;
    margin-bottom: 10px;
    flex-direction: column;
    ${media.lessThan("743px")`
    `}
`

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  width: 130px;
  height: 20px;
  border: none;
  padding: 0px;
  background: transparent;
  cursor: pointer;
  color: ${COLORS.White};
  :hover {
    color: #63C085;
  }
`

const Icon = styled.img`
  /* border: 1px red dashed; */
  width: 10px;
  height: 10px;
  margin-right: 6px;
  position: relative;
`

const ButtonText = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 129.5%;
  letter-spacing: 0.05em;
  color: ${COLORS.White};
  :hover {
    color: #63C085;
  }
`

  const Title = styled.h3`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 129.5%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color: ${COLORS.HighlightMedium};
  margin-top: 9px;
  `

  const InfoText = styled.h2`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: ${COLORS.White};
  margin-top: 1px;
  `
