import React, { useEffect, useState, useContext } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import media from "styled-media-query"
import Image3 from "./../../images/ic_arrow_right_green.svg"
import ImgGreen from "./../../images/ic_status_green.svg"
import ImgPurple from "./../../images/ic_status_purple.svg"
import ImgRed from "./../../images/ic_status_red.svg"
import ImgYellow from "./../../images/ic_status_yellow.svg"
import ImgEye from "./../../images/eye_blue.svg"
import ImgWingnut from "./../../images/ic_wingnuts.svg"
import ImgProperty from "./../../images/ic_property.svg"
import { COLORS } from "../../styles/colors"

const HeaderContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: auto;
  min-width: 300px;
  margin-right: 20px;
  ${media.lessThan("743px")`
    margin-right: 0px;
    `}
  ${media.between("744px", "large")`
    margin-right: 0px;
    /* width: 250px; */
    `}
    ${media.greaterThan("large")`
    `}
`

const TopContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  align-content: flex-start;
`

const StatusIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 15px;
  margin-top: 3px;
  /* align-self: center; */
`

const HeaderText = styled.div`
  font-family: Blinker;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 110%;;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
`

const WingnutDetails = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  flex-direction: column;
  align-content: center;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const InfoText = styled.h2`
  /* width: 370px; */
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: ${COLORS.HighlightLight};
  margin-top: 6px;
  margin-right: 5px;
  ${media.lessThan("743px")`
        /* width: 300px; */
    `}
  ${media.between("744px", "large")`
        /* width: 300px; */

    `}
    ${media.greaterThan("large")`
    `}
`

const BtnBack = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 40px;
  border: 1px solid #63c085;
  border-radius: 30px;
  color: black;
  background: #63c085;
  cursor: pointer;
  text-align: center;
  /* margin-top: 22px; */
  ${media.lessThan("743px")`
    margin-bottom: 50px;
    `}
  ${media.between("744px", "large")`
    margin-bottom: 50px;
    `}
    ${media.greaterThan("large")`
    `}
`

const ButtonText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transition-duration: 0.5s;
  :hover {
  }
`

export default function LinkWingnutHeader(props) {
  const [isHovering, setIsHovering] = useState(false)

  return (
    <HeaderContainer>

      {props.updateSuccess ?
             <TopContainer>
             <StatusIcon src={ImgGreen} />
             <WingnutDetails>
               <HeaderText>Success</HeaderText>
               <InfoText>The Wingnut and property have been linked</InfoText>
             </WingnutDetails>
           </TopContainer>
       :
        <TopContainer>
          <StatusIcon src={ImgPurple} />
            <WingnutDetails>
            <HeaderText>Wingnut {props.wingnutId}</HeaderText>
            <InfoText>Select which property you'd like to link to this Wingnut</InfoText>
          </WingnutDetails>
        </TopContainer>
       }




    </HeaderContainer>
  )
}
