import React, { useEffect, useState, useContext } from "react"
import { AccountContext } from "../../auth/Account"
import styled from "styled-components"
import media from "styled-media-query"
import ImgProperty from "./../../images/ic_property.svg"
import ImgAdd from "./../../images/ic_add.svg"
import Select from "react-dropdown-select"
import Fuse from "fuse.js"
import { DarkButtonSpinner } from "./../custom/Spinner"
import { COLORS } from "../../styles/colors"

const fuzzyOptions = {
  threshold: 0.1,
  keys: ["config.name"],
}

export default function LinkWingnutSelectProperty(props) {
  const { data } = props
  const [isSelected, setIsSelected] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  const [selectedProperty, setSelectedProperty] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const [searchOptions, setSearchOptions] = useState([])
  const fuseSearch = new Fuse(data, fuzzyOptions)

  const { getSession } = useContext(AccountContext)
  const [isAuth, setIsAuth ] = useState(false)

  useEffect(() => {
    getSession().then(({ isAuth }) => {
      setIsAuth(isAuth)
    })
}, [])

  useEffect(() => {
    setSearchOptions(mapRawData(data))
  }, [data])

  const mapRawData = (data) => {
    const options = data.map((item) => ({
      key: item.id,
      label: item.config.name,
      value: item.id,
    }))
    options.unshift({label: (
          <BtnAddProperty>
            <AddIcon src={ImgAdd} />
            <ButtonText2>
              Add New Property
            </ButtonText2>
          </BtnAddProperty>
    ), value: "AddProperty"})
    return options
  }

  const filterOptions = (filter) => {
    if (filter) {
      setSearchOptions(mapRawData(fuseSearch.search(filter).map((s) => s.item)))
    } else {
      setSearchOptions(mapRawData(fuseSearch.getIndex().docs))
    }
  }

  const onSelected = (item) => {
    if (item[0]) {

      if(item[0].value === "AddProperty"){
        props.toggleAddProperty()
      }
      setSelectedProperty(item[0])
      setIsSelected(true)
    } else {
      setSelectedProperty(null)
      setIsSelected(false)
    }
  }

  const pressLinkProperty = () => {
    if (selectedProperty) {
      props.linkProperty(selectedProperty.value)
    }
  }

  return (
    <Container>
      <WingnutDetails>
        <WingnutIcon src={ImgProperty} />
        <HeaderText>Select a Property</HeaderText>
        <div     
            onMouseEnter={(e) => {
            setIsHovering(true)
            }}
            onMouseLeave={(e) => {
            setIsHovering(false)
          }}>
        <StyledSelect
          disabled={isAuth ? false : true}
          dropdownGap={0}
          keepOpen={false}
          placeholder={"Property Name"}
          onChange={(value) => onSelected(value)}
          options={searchOptions}
          values={[]}
          inputRenderer={
            ({ props, state, methods, inputRef }) => ( 
               <Input 
                id="inputID" 
                autoComplete="off"
                onChange={(event) => filterOptions(event.target.value)}
                hidden={selectedProperty ? true: false} 
                placeholder="Property Name" 
                ref={inputRef} 
                style={{ cursor: 'pointer', color: selectedProperty ? "green" : COLORS.White, fontSize: "18px", fontWeight: "300"}} 
              /> 
            )
          }
          searchable={true}
          style={{
            border: isHovering ? "1px solid #d5d5d5" : isSelected ? "1px solid #d5d5d5" : "1px solid transparent",
            borderRadius: 8,
            fontWeight: "300",
            paddingLeft: "12px",
            fontFamily: "Roboto Condensed",
            fontSize: "18px",
          }}
          />
          </div>

        <BtnBack disabled={props.disabled} type='submit' onClick={pressLinkProperty} style={{background : isSelected ? null  : '#B6B6B6', border : isSelected ? null  : 'none'}}>
            <ButtonText style={{marginLeft: props.disabled ? "22px" : null}}>LINK PROPERTY</ButtonText>
            {props.disabled && <DarkButtonSpinner animation='border' />}
        </BtnBack>

      </WingnutDetails>
    </Container>
  )

}


const Container = styled.div`
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  background: ${COLORS.Secondary};
`

//  default color

const WingnutDetails = styled.div`
  background: ${COLORS.Primary};
  border: 1px solid ${COLORS.HighlightMedium};
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: none;
  padding: 15px;
  height: 340px;
  ${media.lessThan("743px")`
        padding-bottom: 27px;
        /* padding-right: 0px; */
        width: 100%;
        height: 350px;
    `}
  ${media.between("744px", "large")`
  height: 350px;
    `}
    ${media.greaterThan("large")`
    `}
`

const WingnutIcon = styled.img`
  width: 23px;
  height: auto;
  /* margin-top: 30px; */
  margin-bottom: 8px;
`

const HeaderText = styled.div`
  font-family: Blinker;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 110%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
`

const StyledSelect = styled(Select)`
  height: 45px;
  color: ${COLORS.White};
  background: ${COLORS.Secondary};
  margin-top: 15px; 

  ${media.lessThan("743px")`
        /* width: 90%; */
        height: 55px;
    `}
  ${media.between("744px", "large")`
        width: 100%;
        height: 55px;
    `}
    ${media.greaterThan("large")`
    `}

    .react-dropdown-select-clear,
    .react-dropdown-select-dropdown-handle {
    color: ${COLORS.White};
    height: 22px;
  }
  .react-dropdown-select-option {
  }
  .react-dropdown-select-item {
  }
  .react-dropdown-select-input {
    color: ${COLORS.White};             // cursor select
  }
  .react-dropdown-select-dropdown {
    background: ${COLORS.Secondary};            // card background
    border-radius: 8px;
    border: 1px solid ${COLORS.HighlightMedium};
  }
  .react-dropdown-select-item {
    color: ${COLORS.White};                     // list item
    border-bottom: none;
    padding-top: 12px;
    padding-bottom: 12px;
    /* height: 60px; */
    font-size: 16px;
    :hover {
      background: ${COLORS.HighlightMedium};
    }
  }
  .react-dropdown-select-item.react-dropdown-select-item-selected{
    color: ${COLORS.White};                   // selected item
    background: ${COLORS.HighlightMedium};
    border-bottom: none;
  }
  .react-dropdown-select-item.react-dropdown-select-item-active {
    //background: #111;
    /* border-bottom: 1px solid #333; */

    /* font-weight: bold; */

  }
  .react-dropdown-select-item.react-dropdown-select-item-disabled {
    /* color: #ccc; */
  }
  ::placeholder {
    color: red;
  }
  :focus {
    outline: ${COLORS.White};
    border: 1px solid ${COLORS.White};
  }
`

const BtnBack = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 95%;
  height: 40px;
  border: 1px solid #63c085;
  border-radius: 30px;
  color: ${COLORS.Base};
  background: #63c085;
  cursor: pointer;
  text-align: center;
  margin-top: 17px;
  /* align-self: center; */
  transition-duration: 0.5s;
  :hover {
    opacity: 0.8;
  }
  ${media.lessThan("743px")`
    width: 100%;
    height: 55px;
    `}
  ${media.between("744px", "large")`
  height: 55px;
    `}
    ${media.greaterThan("large")`
    `}
`

const ButtonText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  :hover {
  }
`

const BtnAddProperty = styled.div`
  display: flex;
  flex-direction: row;
  border: none;
  background: transparent;
  padding-top: 5px;
`;

const AddIcon = styled.img`
  width: 14px; 
  height: 14px;
  align-self: center;
  margin-right: 8px;
`;

const ButtonText2 = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: #375D5A;
`;

const Input = styled.input`
background: transparent;
border: none;
outline: none;
width: 100%;
/* height: 100%; */
color: ${COLORS.White};
/* font-family: "Roboto Condensed", sans-serif; */
`;
