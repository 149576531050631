import React, { useEffect, useState, useContext } from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import media from "styled-media-query"
import ImgGreen from "./../../images/ic_status_green.svg"
import { COLORS } from "../../styles/colors"


const HeaderContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  background-color: #0E1C36;
  padding: 20px;
  border-radius: 10px;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 15px;
`

const StatusIcon = styled.img`
  width: 12px;
  height: 12px;
  margin-right: 22px;
  align-self: center;
`

const HeaderText = styled.div`
  font-family: Blinker;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 110%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
`

const WingnutDetails = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  flex-direction: row;
  align-content: center;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 177px;
  height: 40px;
  border: 1px solid #63c085;
  border-radius: 30px;
  background: transparent;
  color: ${COLORS.White};
  cursor: pointer;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.05em;
  ${media.lessThan("743px")`
        align-self: flex-start;
        margin-top: 28px;
        height: 55px;
    `}
  ${media.between("744px", "large")`
        height: 55px;
    `}
    ${media.greaterThan("large")`
    `}
`

export default function StatusDetailsLive(props) {
  const [isHovering, setIsHovering] = useState(false)

  return (
    <HeaderContainer>
      <WingnutDetails>
        <StatusIcon src={ImgGreen} />
        <HeaderText>Live</HeaderText>
      </WingnutDetails>

{/* This was the Go-To-Property button in the WINGNUT detail page on the STATUS LIVE header component - this button has been removed?? */}
      {/* <div style={props.disabled ? {pointerEvents: "none", opacity: "0.4"} : {}}>
        <Link to={`/portal/property/detail/${props.wingnutDetails && props.wingnutDetails.property && props.wingnutDetails.property.id}`} style={{ textDecoration: "none" }}>
          <Button>GO TO PROPERTY</Button>
        </Link>
      </div> */}

    </HeaderContainer>
  )
}
