import Enum from "enum"
import IconStatusLive from "./../../images/ic_status_green.svg"
import IconStatusDisconnected from "./../../images/ic_status_red.svg"
import IconStatusUnlinked from "./../../images/ic_status_yellow.svg"
import IconStatusUnconfigured from "./../../images/ic_status_purple.svg"

//large - 1171 - 1800
//medium - 768 - 1170
//small - 0 - 767

const ScreenSize = new Enum({
  Large: [1171, 1800],
  Medium: [768, 1170],
  Small: [0, 767],
})

export default ScreenSize
