import React, { Component } from 'react';
import Chart from 'react-apexcharts'
import styled from "styled-components"
import media from "styled-media-query"
import { COLORS } from "../../../styles/colors"

export default class OneGauge extends React.Component {
            constructor(props) {
            super(props);

            var lastValue = null
            var percent = 0
            var roundedPercent = 0

            if(this.props.data.lastValue && this.props.data.lastValue !== "none" ){
                lastValue = Number(this.props.data.lastValue)
            } else {
                lastValue = null
            }

            percent = lastValue / this.props.upperLimit * 100
            roundedPercent = percent.toFixed(1)
                
            this.state = {
                lastValue: lastValue,
                series: [roundedPercent],
                options: {
                chart: {
                    type: 'radialBar',
                    offsetY: -20,
                    sparkline: {
                    enabled: true
                    },
                    foreColor: `red`,
                    fontFamily: '"Roboto Condensed", sans-serif'
                },
                states: {
                    normal: {
                        filter: {
                            type: 'none',
                            value: 0,
                        }
                    },
                    hover: {
                        filter: {
                            type: 'none',
                            value: 0,
                        }
                    },
                    active: {
                        allowMultipleDataPointsSelection: false,
                        filter: {
                            type: 'none',
                            value: 0,
                        }
                    },
                },
                plotOptions: {
                    radialBar: {
                    startAngle: -90,
                    endAngle: 90,
                    hollow: {
                        // margin: 0,
                        size: '55%',
                        // background: '#fff',
                    },
                    colors: ['#645CAA'],
                    track: {
                        background: COLORS.Primary,
                        strokeWidth: '97%',
                        margin: 5, // margin is in pixels
         
                    },
                    dataLabels: {
                        show: false,
                        name: {
                        show: false
                        },
                        value: {
                        offsetY: -2,
                        fontSize: '22px'
                        }
                    }
                    }
                },
                fill: {
                    type: 'solid',
                    colors: [COLORS.Green],
                },
                grid: {
                    padding: {
                    top: -10
                    }
                },
     
                labels: ['Live'],
                },
            };
            }

            updateData = () => {

            }

            componentWillReceiveProps(nextProps) {
                if (this.props.data !== nextProps.data) {
                    if(nextProps.data !== null && nextProps.data!== undefined ){

                        var lastValue = null
                        var percent = 0
                        var roundedPercent = 0

                        if(nextProps.data.lastValue && nextProps.data.lastValue !== "none" ){
                            lastValue = Number(nextProps.data.lastValue)
                        }

                        percent = lastValue / this.props.upperLimit * 100
                        roundedPercent = percent.toFixed(1)

                        this.setState({
                            lastValue: lastValue,
                            series: [roundedPercent]
                        })
                    } 
                }   
              }

            render() {

            return (
                
                <Container>
                    <GaugeContainer id="chart">
                        <Chart options={this.state.options} series={this.state.series} type="radialBar" height='370' width='300' />
                    </GaugeContainer>
                        <LabelContainer>
                            <HeaderText>{0}</HeaderText>
                            <HeaderText>{`${this.props.upperLimit}`}</HeaderText>
                        </LabelContainer>
                       
                        { this.props.data && this.props.data.lastValue && this.props.data.lastValue !== "none" && this.state.lastValue !== null
                        ? <HeaderText2>{`${this.state.lastValue} ${this.props.unit}`}</HeaderText2>
                        : <NoWingnutText>No Data</NoWingnutText>
                    }
                </Container>
              )
            }
        }

        const Container = styled.div`
            /* border: 1px blue dashed; */
            display: flex;
            flex-direction: column;
        `;



        const LabelContainer = styled.div`
            /* border: 1px blue dashed; */
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 248px;
            height: 50px;
            margin-left: 30px;
            margin-top: -60px;
            ${media.lessThan("743px")`
                margin-top: -40px;
            `}
        `;

        const LabelContainer2 = styled.div`
            /* border: 1px blue dashed; */
            display: flex;
            flex-direction: row;
            justify-content: center;
            height: 50px;
            width: 235px;
            margin-left: 25px;
            margin-top: -20px;
        `;


        const HeaderText = styled.h2`
        /* border: 1px red dashed; */
            font-family: 'Roboto Condensed';
            font-style: normal;
            font-weight: 400;
            font-size: 15.9022px;
            line-height: 129.5%;
            display: flex;
            align-items: center;
            text-align: center;
            color: ${COLORS.HighlightMedium};
        `

        const HeaderText2 = styled.h2`
        /* border: 1px red dashed; */
            font-family: 'Blinker';
            font-style: normal;
            font-weight: 600;
            font-size: 36.3478px;
            line-height: 110%;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: -0.01em;
            color: ${COLORS.White};
            align-self: center;
            /* margin-left: 113px; */
            margin-top: -70px;
        `

        const GaugeContainer = styled.div`
            /* border: 1px blue dashed; */
            display: flex;
            flex-direction: row;
            margin-right: 50px;
            margin-bottom: 50px;
            ${media.lessThan("743px")`
                margin-bottom: 40px;
                margin-right: 0px;
            `}
            ${media.between("744px", "large")`
                margin-right: 20px;
                margin-bottom: 50px;
            `}
        `;

        const NoWingnutText = styled.p`
            font-family: 'Roboto Condensed';
            font-style: italic;
            font-weight: 300;
            font-size: 16px;
            line-height: 130%;
            color: ${COLORS.HighlightLight};
            margin-left: 115px;
            margin-top: -65px;
            margin-bottom: 15px;
            `;

