import React, { Component } from 'react'
import { API, Auth } from "aws-amplify"
import styled from "styled-components"
import media from "styled-media-query"
import Fuse from "fuse.js"
import { SpinnerContainer, StyledSpinner } from "../custom/Spinner"
import NotificationController from "./NotificationController"
import NotificationTableStructure from "./NotificationTableStructure"

import WingnutStatus from "./../wingnuts/WingnutStatus.enum"


// Parent container of notification search functions and table

const fuzzyOptions = {
  // isCaseSensitive: false,
  // includeScore: false,
  // shouldSort: true,
  // includeMatches: false,
  // findAllMatches: false,
  // minMatchCharLength: 3,
  // location: 0,
  threshold: 0.1,
  // distance: 100,
  // useExtendedSearch: false,
  // ignoreLocation: false,
  // ignoreFieldNorm: false,
  // fieldNormWeight: 1,
  // keys: ["propertyId", "propertyName", "portfolio", "linkedWingnuts", "address.streetAddress", "address.city", "address.postalZip", "address.provinceState", "address.country"],
  keys: ["propertyId", "propertyName", "portfolio", "linkedWingnuts", "address"],
}

export default class NotificationOverview extends Component {

  constructor() {
    super()
    this.fuseSearch = null
    this.showData = true
    this.state = {
      data: [],
      search: "",
      isLoading: true,
      status: {
        Unconfigured: false,
      },
    }
  }

  componentDidMount() {
    this.getNotifications()
  }

  componentWillUnmount() {
    this.showData = false
  }

  componentDidUpdate(prevProps, prevState){
    if(prevState.isLoading === true && this.state.isLoading === false){
      this.filterData(this.state.search, this.state.status)
    }
  }

  searchForNotification(event) {
    if(this.state.isLoading === false){
      this.filterData(event.target.value, this.state.status)
    } else {
      this.setState({
        search: event.target.value,
      })
    }
  }

  filterData(fuzzySearch, wingnutState) {
    var data = []
    if (fuzzySearch) {
      data = this.fuseSearch.search(fuzzySearch).map((s) => s.item)
    } else {
      if(this.fuseSearch){
        data = this.fuseSearch.getIndex().docs
      }
    }

    if (wingnutState.Unconfigured) {
      data = data.filter((item) => item.linkedWingnuts.length == 0)
    }

    this.setState({
      search: fuzzySearch,
      status: wingnutState,
      data: data,
    })
  }

  
  toggleStatus(status) {
    var wingnutStatus = this.state.status

    if (status === WingnutStatus.Unlinked) {
      wingnutStatus.Unconfigured = !wingnutStatus.Unconfigured
    }

    this.filterData(this.state.search, wingnutStatus)
  }

  setupTable(data) {
    this.fuseSearch = new Fuse(data, fuzzyOptions)
    this.setState({
      data: data,
    })
  }

  translateToggle(status) {
    if(status === "enabled"){
      return 1
    } else {
      return 0
    }
  }

  async getNotifications() {
    const token = (await Auth.currentSession()).getIdToken()
    const jwt = token.getJwtToken()
    const init = {
      headers: { Authorization: `Bearer ${jwt}` },
    }

    API.get("FrontendAPI-notifications", "", init)
      .then((response) => {
        if (response.success) {
          const mappedData = response.data.map((item) => {
            return {
              propertyId: item.property.id,
              externalId: item.property.externalId,
              propertyName: item.propertyName,
              address: `${item.property.config.address.streetAddress} ${item.property.config.address.city} ${item.property.config.address.country} ${item.property.config.address.postalZip}`,
              portfolio: item.property.config.portfolio,
              notificationId: item.id,
              toggle: this.translateToggle(item.entityStatus),
            }
          })
          if (this.showData) {
            this.setupTable(mappedData)
          }
        }
      })
      .catch((error) => {
        console.error("THERE WAS ERROR", error)
      })
      .finally(() => this.setState({ isLoading: false }))
  }

  render() {
    return (
         <Container>
         <NotificationController
                   tableData={this.state.data}
                   onInputChange={(e) => this.searchForNotification(e)}
         />
         { this.state.isLoading 
         ? (<SpinnerContainer><StyledSpinner animation='border' /></SpinnerContainer>) 
         : (<NotificationTableStructure tableData={this.state.data} />)}
       </Container>
    )
  }
}

const Container = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  width: 90%;
  height: 100%;
  overflow: auto;
  max-width: 1129px;
  background-color: #0e1c36;
  border-radius: 20px;
  padding: 34px;
  /* margin-right: 30px; */
  margin-top: 20px;
  ${media.lessThan("743px")`
      width: 90%;
      align-self: center;
      margin-right: 0px;
    `}
`
