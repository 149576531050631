import React, { Component } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Image from "./../../images/LOGO_ALT.svg"
import Image2 from "./../../images/ic_check_circle.svg"
import ImageBg2 from "./../../images/bg_bluePipes.png"
import ResetPasswordForm from "./../form/ResetPasswordForm"
import ResetPasswordCodeForm from "./../form/ResetPasswordCodeForm"
import { Link } from "react-router-dom"
import RecoveryCodeForm from "../form/RecoveryCodeForm"
import { COLORS } from "../../styles/colors"

export default class ResetPassword extends Component {
  state = {
    step: 0,
    code: "",
    email: ""
  }

  setEmail = (val) => {
    this.setState({ email: val })
  }

  setCode = (val) => {
    this.setState({ code: val })
  }

  goToStepNumber = (val) => {
    this.setState({ step: val })
  }

  render() {
    return (
      <Grid>
        <Container>
          <Logo src={Image} />

          {this.state.step === 3 ? <Icon src={Image2} /> : <EmptyIcon />}

          {(() => {
              switch (this.state.step) {
            case 0:
              return (
                <CopyContainer >
                  <Title>Reset Password</Title>
                  <Blurb>Enter your account email and we'll send you a recovery link</Blurb>
                </CopyContainer>
              )
            case 1:
              return(
                <CopyContainer>
                  <Title>Enter Recovery Code</Title>
                  <Blurb>Check your email for a recovery code</Blurb>
                </CopyContainer>
                )
            case 2:
              return(
                <CopyContainer>
                  <Title>Reset your Password</Title>
                </CopyContainer>
              )
            case 3:
              return(
               <CopyContainer>
                 <Title style={{ marginBottom: 25 }}>Success!</Title>
                 <Blurb>Your password has been successfully changed</Blurb>
               </CopyContainer>
              )
            default:
              return null;
          }
        })()}


          {(() => {
              switch (this.state.step) {
                case 0:
                  return (
                    <FormContainer>
                      <ResetPasswordForm goToStepNumber={this.goToStepNumber} setEmail={this.setEmail} />
                        <Link to='/login' style={{ textDecoration: "none", alignSelf: "center" }}>
                          <BtnBack type='submit'>BACK</BtnBack>
                        </Link>
                    </FormContainer>
                  );
                case 1:
                  return (
                    <FormContainer>
                      <RecoveryCodeForm goToStepNumber={this.goToStepNumber} setCode={this.setCode} />
                    </FormContainer>
                  );
                case 2:
                  return (
                    <FormContainer>
                      <ResetPasswordCodeForm goToStepNumber={this.goToStepNumber} code={this.state.code} email={this.state.email}/>
                          <BtnBack type='submit' onClick={() => (this.goToStepNumber(1))}>BACK</BtnBack>
                    </FormContainer>
                  );
                case 3:
                  return (
                    <SuccessContainer>
                      <Link to='/login' style={{ marginTop: 43, textDecoration: "none", alignSelf: "center" }}>
                         <BtnBackToLogin type='submit'>LOG IN</BtnBackToLogin>
                      </Link>
                    </SuccessContainer>
                  );
                default:
                  return null;
              }
         })()}

        </Container>
      </Grid>
    )
  }
}

const Grid = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  height: 100%;
  flex-direction: column;
  padding-top: 230px;
  padding-bottom: 100px;
  align-content: center;
  justify-self: center;

  ${media.lessThan("743px")`
      width: 100%;
      padding-top: 66px;
    `}
`

const Container = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  width: 456px;
  flex-direction: column;
  align-self: center;
  align-content: center;
  ${media.lessThan("743px")`
      width: 95%;
      align-self: center;
    `}
  ${media.between("744px", "large")`
    width: 456px;
    `}
`

const CopyContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: column;
  align-self: center;
  align-content: center;
  ${media.lessThan("743px")`
      width: 95%;
      align-self: center;
    `}
  ${media.between("744px", "large")`
    width: 456px;
    `}
`

const Logo = styled.img`
  width: 202px;
  height: 57px;
  align-self: center;
  margin-bottom: 15px;
  transition-duration: 0.5s;
  :hover {
    transform: scale(1.2);
  }
  `

const Icon = styled.img`
/* border: 1px red dashed; */
  width: 25px;
  height: 25px;
  align-self: center;
  transition-duration: 0.5s;
  color: ${COLORS.White};
  margin-top: 46px;
  margin-bottom: 20px;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
  `}
`

const EmptyIcon = styled.div`
  width: 35px;
  height: 35px;
  align-self: center;
  transition-duration: 0.5s;
  color: ${COLORS.White};
  margin-bottom: 8px;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
  `}
`

const Title = styled.h1`
  font-family: Blinker;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 25.2%;
  letter-spacing: -1%;
  margin-bottom: 19px;
  color: ${COLORS.White};
  align-self: center;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
  `}
`

const Blurb = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 130%;
  text-align: center;
  color: ${COLORS.White};
  ${media.lessThan("743px")`
        width: 300px;
        align-self: center;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
  `}
`

const BtnBack = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  align-self: center;
  width: 206px;
  height: 40px;
  border: 1px solid #63c085;
  border-radius: 30px;
  background: transparent;
  cursor: pointer;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 14px;
  color: ${COLORS.White};
  /* line-height: 129.5%; */
  text-align: center;
  letter-spacing: 0.05em;
  margin-bottom: 10px;
  ${media.lessThan("743px")`
  height: 55px;
        `}
  ${media.between("744px", "large")`
  height: 55px;
        `}
        ${media.greaterThan("large")`
        `}
`

const BtnBackToLogin = styled(BtnBack)`
  background: #63c085;
  width: 218px;
  color: ${COLORS.Base};
`

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const FormContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: column;
`