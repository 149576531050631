import React, { Component } from "react"
import { Link } from "react-scroll"
import styled from "styled-components"
import media from "styled-media-query"
import Logo from "./../../images/Logo_symbol.svg"
import Image from "./../../images/ic_arrowUp.svg"
import { COLORS } from "../../styles/colors"

const Grid = styled.div`
  /* border: 1px yellow dashed; */
  grid-area: 8 / 1 / 9 / 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding-top: 80px;
  padding-bottom: 40px;
  height: auto;
`

const LogoSymbol = styled.img`
  width: 157px;
  height: 45px;
  align-self: center;
  margin-bottom: 20px;
`

const Button = styled.div`
  width: 255.55px;
  height: 69.62px;
  margin-top: 44px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  background-color: transparent;
  border: 2px solid ${COLORS.White};
  box-sizing: border-box;
  border-radius: 36px;
  /* start of Hover code */
  transition-duration: 0.5s;
  :hover {
    background-color: ${COLORS.White};
  }
  /* end of hover code */
  ${media.lessThan("743px")`
      align-self: center;
    `}
  ${media.between("744px", "large")`
      align-self: center;
    `}
`

const ButtonText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 129.5%;
  text-align: center;
  letter-spacing: 0.05em;
  color: ${COLORS.White};
  align-self: center;
  transition-duration: 0.5s;
  :hover {
    color: ${COLORS.Base};
  }
`

const Icon = styled.img`
  width: 15px;
  height: 15px;
  /* margin-left: 20px; */
  align-self: center;
  transition-duration: 0.5s;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
  `}
`

const Blurb = styled.p`
  font-family: "Blinker", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 21px;
  line-height: 101.5%;
  text-align: center;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
`

export class Footer extends Component {
  state = {
    isHovering: false,
  }

  render() {
    return (
      <Grid>
        <LogoSymbol src={Logo} />
        <Blurb>© Wingnut IoT Inc.</Blurb>
        <Link
          style={{ alignSelf: "center" }}
          activeClass='active'
          to='landing'
          spy={true}
          smooth={true}
          duration={2000}
        >
          <Button
            onMouseEnter={(e) => {
              this.setState({ isHovering: true })
            }}
            onMouseLeave={(e) => {
              this.setState({ isHovering: false })
            }}
            style={{ backgroundColor: this.state.isHovering == true ? COLORS.White : COLORS.Base }}
          >
            <ButtonText
              style={{
                marginRight: this.state.isHovering == true ? "20px" : "0px",
                color: this.state.isHovering == true ? COLORS.Base : COLORS.White,
              }}
            >
              BACK TO TOP
            </ButtonText>
            <Icon style={{ opacity: this.state.isHovering == true ? "100" : "0" }} src={Image} />
          </Button>
        </Link>
      </Grid>
    )
  }
}

export default Footer
