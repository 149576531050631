import React, { Component } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Image from "./../../images/LOGO_ALT.svg"
import ImageBg2 from "./../../images/bg_bluePipes.png"
import LoginForm from "./../form/LoginForm"
import { Link } from "react-router-dom"
import { COLORS } from "../../styles/colors"

export default class LoginStatic extends Component {
  state = {
    isHovering: false,
  }

  render() {
    return (
      <Grid>
        <Container>
          <Logo src={Image} />
          <Title>Login to see your Dashboard</Title>
          <LoginForm />
          <Link to='/resetpassword' style={{ textDecoration: "none", alignSelf: "center" }}>
            <BtnForgotPassword>Forgot Password?</BtnForgotPassword>
          </Link>
        </Container>
      </Grid>
    )
  }
}

const Grid = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  height: 100vh;
  flex-direction: column;
  padding-top: 230px;
  align-content: center;
  justify-self: center;
  align-self: center;
  ${media.lessThan("743px")`
        width: 100%;
        padding-top: 66px;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
  `}
`

const Container = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  width: 384px;
  height: auto;
  flex-direction: column;
  align-self: center;
  background: transparent;
  ${media.lessThan("743px")`
        width: 95%;
    `}
  ${media.between("744px", "large")`
        width: 450px;
    `}
    ${media.greaterThan("large")`
  `}
`

const Logo = styled.img`
  width: 202px;
  height: 57px;
  /* margin-top: 27px; */
  align-self: center;
  transition-duration: 0.5s;
  :hover {
    transform: scale(1.2);
  }
  ${media.lessThan("743px")`
    `}
`

const Title = styled.h1`
  font-family: Blinker;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 84.5%;
  margin-top: 46px;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
  align-self: center;
  ${media.lessThan("743px")`
    margin-top: 42px;
    text-align: center;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
  `}
`

const BtnForgotPassword = styled.button`
  background: transparent;
  outline: none;
  border: none;
  color: ${COLORS.White};
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 130%;
  align-items: center;
  cursor: pointer;
  text-align: center;
  text-decoration-line: underline;
  opacity: 0.5;
  margin-top: 10px;
  margin-bottom: 70px;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
`

