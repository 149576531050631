

  export const cleanUUIDFromPath = (pathname) => {
    var path = pathname.substring(8).split("/")
    const uuid = path[path.length - 1]

    // UUID check
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    if (regexExp.test(uuid)) {
      path.splice(-1)
    }
    return path.join("/")
  }

  export const getUUIDFromPath = (pathname) => {
    var path = pathname.substring(8).split("/")
    const uuid = path[path.length - 1]

    // UUID check
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    if (regexExp.test(uuid)) {
      return uuid
    } else {

      if(uuid.length > 1){
        return uuid
      } else {
        return 0
      }
    }

  }

  export const checkIfUUID = (uuid) => {
    // UUID check
    const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    if (regexExp.test(uuid)) {
      return true
    } else {
        return false
    }
  }