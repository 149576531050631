import React, { useEffect, useState, useContext } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import { COLORS } from "./../../../styles/colors"
import PropertyDropdownUI from "./PropertyDropdownUI"
import CustomTimeZoneDropdownUI from "./../component/CustomTimeZoneDropdownUI"
import { useMediaQuery } from 'react-responsive'
import TimeRangePickerUI from "./TimeRangePickerUI"

export default function PropertyTimeUI(props) {

  return (
    <OuterContainer>
        <Container>
            <Dropdown>
                 <CalendarContainer>
                    <DropdownTitle style={{ marginBottom: 3}}>Custom Date</DropdownTitle>
                    <TimeRangePickerUI key={0} {...props}/>
                </CalendarContainer>
            </Dropdown>

             <Dropdown>
                <DropdownTitle style={{marginTop: 13}}>Custom TimeZone</DropdownTitle>
                <CustomTimeZoneDropdownUI {...props}  />
            </Dropdown> 
        </Container>
      </OuterContainer>
  )
}


  const OuterContainer = styled.div`
  /* border: 1px red dashed; */
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 20px;
    /* height: 500px; */
  `

const TopContainer = styled.div`
    /* border: 1px green dashed; */
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    ${media.lessThan("743px")`
      flex-direction: column;
    `}
`

const HeaderContainer = styled.div`
    /* border: 1px blue dashed; */
    display: flex;
    flex-direction: row;
    margin-bottom: 21px;
    ${media.lessThan("743px")`
        /* align-self: center; */
        margin-right: 0px;
        margin-bottom: 15px;
        justify-content: space-between;
  `}
`

const HeaderTextContainer = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const UtilityContainer = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  flex-direction: row;
  /* height: 30px; */
  /* justify-content: center; */
`;

const HeaderText = styled.h2`
    /* border: 1px red dashed; */
    font-family: Blinker;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 84.5%;
    letter-spacing: -0.01em;
    color: ${COLORS.White};
`

const Subheader = styled.h4`
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 130%;
    color: ${COLORS.White};
    margin-top: 6px;
`;

const Container = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1440px;
  margin-right: 30px;
  margin-bottom: 20px;
  ${media.lessThan("743px")`
      align-self: center;
      margin-right: 0px;
        flex-direction: column;
    `}
  `

const Dropdown = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: column;
  margin-right: 20px;
`;

const DropdownTitle = styled.p`
    /* border: 1px yellow dashed; */
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 129.5%;
    letter-spacing: 0.03em;
    text-transform: capitalize;
    color: ${COLORS.HighlightLight};
`;

const DropdownPlaceholder = styled.div`
    background: #1A2B4A;
    width: 384px;
    height: 45px;
    border: 1px solid #A1ADC2;
    border-radius: 8px;
    ${media.lessThan("743px")`
    `}
`

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  width: 130px;
  height: 20px;
  border: none;
  padding: 0px;
  background: transparent;
  margin-top: 7px;
  cursor: pointer;
  color: ${COLORS.White};
  :hover {
    color: #63C085;
  }
  ${media.lessThan("743px")`
    margin-bottom: 15px;
    margin-left: -10px;
  `}
`

const Icon = styled.img`
  /* border: 1px red dashed;; */
  width: 10px;
  height: 10px;
  margin-right: 6px;
  position: relative;
`

const ButtonText = styled.p`
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 129.5%;
  letter-spacing: 0.05em;
  color: ${COLORS.White};
  :hover {
    color: #63C085;
  }
`

const NoWingnutContainer = styled.div`
    /* border: 1px red dashed; */
    display: flex;
    flex-direction: column;
`;

const NoWingnutText = styled.p`
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 130%;
  color: ${COLORS.HighlightLight};
`;

const BtnLinkWingnut = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 40px;
    gap: 9px;
    background: ${COLORS.Green};
    width: 193px;
    height: 40px;
    border: 1px solid #63C085;
    border-radius: 30px;
    margin-right: 20px;
    margin-top: 16px;
    :hover {
  }
`;

const BtnSet = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: transparent;
    height: 40px;
    border: none;
    border-radius: 30px;
    :hover {
  }
`;

const ButtonText2 = styled.p`
  /* border: 1px red dashed; */
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${COLORS.Primary};
  align-self: center;
  transition-duration: 0.5s;
  ${media.lessThan("743px")`
        font-size: 15px;
        /* width: 170px; */
    `}
  ${media.between("744px", "large")`
        font-size: 15px;
    `}
    ${media.greaterThan("large")`
    `}
`;

const ButtonSetText = styled.p`
  /* border: 1px red dashed; */
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${COLORS.Green};
  align-self: center;
  transition-duration: 0.5s;
  ${media.lessThan("743px")`
        font-size: 15px;
        /* width: 170px; */
    `}
  ${media.between("744px", "large")`
        font-size: 15px;
    `}
    ${media.greaterThan("large")`
    `}
`;


const CalendarContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-top: 10px;
  width: 100%;
`;

const MiniSpinnerContainer = styled.div`
    /* border: 1px yellow dashed; */
  display: flex;
  width: 35px;
  height: 35px;
  justify-content: center;
  align-self: center;
  margin-bottom: 15px;
`