import React, { useEffect, useState, useContext } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Image3 from "./../../images/ic_arrow_right_green.svg"
import ImgGreen from "./../../images/ic_status_green.svg"
import ImgPurple from "./../../images/ic_status_purple.svg"
import ImgRed from "./../../images/ic_status_red.svg"
import ImgYellow from "./../../images/ic_status_yellow.svg"
import WingnutConfigTabHeaderItem from "./WingnutConfigTabHeaderItem"


const Container = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 38px;
  margin-right: 40px;
  margin-bottom: 46px;
  ${media.lessThan("743px")`
        flex-direction: column;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const TabContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  width: 0.5;
  padding: 10px;
  ${media.lessThan("743px")`
        width: 100%;
    `}
  ${media.between("744px", "large")`
        flex-wrap: wrap;
        justify-content: flex-start;
    `}
    ${media.greaterThan("large")`
        width: 25%;
        padding: 5px;
    `}
`

export default function WingnutConfigTabHeader(props) {

  return (
    <Container>
      <WingnutConfigTabHeaderItem selected={props.state.thermistor ? true : false} onHandleClick={() => props.setInputTab(0)}inputText="Thermistor Inputs" sensorText="(a1 - a16)" img={ImgRed}/>
      <WingnutConfigTabHeaderItem selected={props.state.transducer ? true : false}  onHandleClick={() => props.setInputTab(1)} inputText="Transducer Inputs" sensorText="(a17 - a32)" img={ImgPurple}/>
      <WingnutConfigTabHeaderItem selected={props.state.general ? true : false}  onHandleClick={() => props.setInputTab(2)} inputText="General Purpose Inputs" sensorText="(d01 - d04)" img={ImgYellow}/>
      <WingnutConfigTabHeaderItem selected={props.state.relay ? true : false}  onHandleClick={() => props.setInputTab(3)} inputText="Relay Sense Inputs" sensorText="(d05 - d16)" img={ImgGreen}/>     
    </Container>
  )
}
