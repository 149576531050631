import React, { useState, useContext, useEffect } from "react"
import { AccountContext } from "./../../auth/Account"
import { Link, useLocation } from "react-router-dom"
import styled from "styled-components"
import media from "styled-media-query"
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar"
import "./SideNav.scss"
import Image from "./../../images/LOGO_ALT.svg"
import Image6 from "./../../images/ic_arrow_right_white.svg"
import ImgWingnut from "./../../images/IsoRender_menu.png"
import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon as FAIcon } from "@fortawesome/react-fontawesome"
import Modal from "react-modal"
import LogoutPopup from "./../popup/LogoutPopup"
import { COLORS } from "../../styles/colors"
import { getUUIDFromPath } from "../util/CleanUUID"

const SideNavContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  opacity: 0.9;         // this allows the sidenav to dim when modal
`

const Container = styled.div`
  /* border: 1px green dashed; */
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const SubContainer = styled.div`
  /* border: 1px yellow dashed; */
`

const LinkContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 200px;
`

const Header = styled.div`
  /* border: 1px yellow dashed; */
  height: auto;
  margin-top: 10px;
  /* margin-bottom: 30px; */
`

const Body = styled.div`
  /* border: 1px blue dashed; */
`

const Footer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  height: auto;
  align-content: flex-end;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`

const Logo = styled.img`
  width: 94px;
  height: 27px;
  align-self: center;
  /* transition-duration: 0.5s;
    :hover{
        transform: scale(1.2);
    } */
`

const FaviconLogo = styled.img`
  width: 45px;
  height: 45px;
  /* transition-duration: 0.5s;
    :hover{
        transform: scale(1.2);
    } */
`

const MenuItemContainer = styled.div`
  /* border: 1px red dashed; */
  height: 40px;
  display: flex;
  flex-direction: row;
  align-content: center;
`

const Icon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 16px;
  align-self: center;
  color: ${COLORS.White};
`

const IconBurger = styled(Icon)`
  width: 18px;
  height: 12px;
`

const MenuText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 129.5%;
  align-self: center;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-top: 6px;
`

const Line = styled.div`
  width: 80%;
  height: 1px;
  background: ${COLORS.HighlightMedium};
  margin-top: 14px;
  margin-left: 20px;
`

const Box = styled.div`
  background: #4ab2fb;
  border-radius: 11px;
  width: 176px;
  height: 158.21px;
  align-self: center;
  align-content: center;
`

const Conditions = styled.p`
  margin-top: 20px;
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 130%;
  text-decoration-line: underline;
  color: ${COLORS.White};
  align-self: center;
`

const Copyright = styled.p`
  text-align: center;
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 130%;
  color: ${COLORS.HighlightMedium};
  margin-top: 7px;
`

const ImgWingut = styled.img`
  width: 136.79px;
  height: 97px;
  margin-top: -30px;
  align-self: center;
  margin-left: 20px;
`

const LookingText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: ${COLORS.Base};
  margin-left: 17px;
  margin-top: 5px;
`

const Button = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;
  height: auto;
  border: none;
  background: transparent;
  color: ${COLORS.White};
  cursor: pointer;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 13px;
  line-height: 129.5%;
  /* text-align: center; */
  letter-spacing: 0.05em;
  margin-left: 17px;
  margin-top: 8px;
  ${media.lessThan("medium")`
    align-self: flex-start;
    `}
  ${media.between("medium", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const ButtonText = styled.p`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 129.5%;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${COLORS.White};
  transition-duration: 0.5s;
  margin-right: 20px;
  :hover {
  }
`

const IconArrow = styled.img`
  width: 6;
  height: 10px;
  /* margin-left: 20px; */
  align-self: center;
  transition-duration: 0.5s;
  ${media.lessThan("medium")`
    `}
  ${media.between("medium", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: COLORS.Primary,
    borderRadius: "30px",
    border: "none",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.7)",
    zIndex: 10,
  },
}

export default function SideNav() {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [isHovering, setIsHovering] = useState(false)

  const [hoverDashboard, setHoverDashboard] = useState(false)
  const [hoverAccount, setHoverAccount] = useState(false)
  const [hoverWingnut, setHoverWingnut] = useState(false)
  const [hoverProperty, setHoverProperty] = useState(false)
  const [hoverNotification, setHoverNotification] = useState(false)
  const [hoverLogout, setHoverLogout] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [state, setState] = useState({
    selectDashboard: true,
    selectAccount: false,
    selectWingnut: false,
    selectProperty: false,
    selectNotification: false,
    selectLogout: false,
  })

  var location = useLocation()
  const { logout } = useContext(AccountContext)

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed)
  }

  useEffect(() => {
    var pathname = location.pathname.substring(8)

    switch (pathname) {
      case "dashboard/0":
        return setState({
          selectDashboard: true,
          selectAccount: false,
          selectLogin: false,
          selectWingnut: false,
          selectProperty: false,
          selectNotification: false,
        })
      case "account":
        return setState({
          selectDashboard: false,
          selectAccount: true,
          selectLogin: false,
          selectWingnut: false,
          selectProperty: false,
          selectNotification: false,
        })
      case "wingnut":
        return setState({
          selectDashboard: false,
          selectAccount: false,
          selectLogin: false,
          selectWingnut: true,
          selectProperty: false,
          selectNotification: false,
        })
        case `wingnut/detail/${getUUIDFromPath(location.pathname)}`:
        return setState({
          selectDashboard: false,
          selectAccount: false,
          selectLogin: false,
          selectWingnut: true,
          selectProperty: false,
          selectNotification: false,
        })
        case "notification":
          return setState({
            selectDashboard: false,
            selectAccount: false,
            selectLogin: false,
            selectWingnut: false,
            selectProperty: false,
            selectNotification: true,
          })
        case "property":
          return setState({
            selectDashboard: false,
            selectAccount: false,
            selectLogin: false,
            selectWingnut: false,
            selectProperty: true,
            selectNotification: false,
          })
        case `property/detail/${getUUIDFromPath(location.pathname)}`:
          return setState({
            selectDashboard: false,
            selectAccount: false,
            selectLogin: false,
            selectWingnut: false,
            selectProperty: true,
            selectNotification: false,
          })
    }
  }, [location])

  useEffect(() => {
    const width = window.innerWidth
    if (window.innerWidth > 1170) {
      setIsCollapsed(false)
    }
  }, [])

  const closePopup = () => {
    setIsModalOpen(false)
  }

  return (
    <div>
      <Modal isOpen={isModalOpen} onRequestClose={closePopup} style={customStyles} contentLabel='Wingnut Login'>
        <LogoutPopup closePopup={closePopup} setLogout={logout}/>
      </Modal>

    <SideNavContainer style={{ display: "flex", flexDirection: "row" }}>
      <ProSidebar collapsed={isCollapsed ? true : false}>
        <Container>
          <SubContainer>
            <Header>
              <Menu iconShape='square'>
                <MenuItem
                  onClick={toggleSidebar}
                  icon={<FAIcon color='#63C085' size='1x' rotation={0} icon='burger' />}
                >
                  <MenuItemContainer>
                    <Logo src={Image} />
                  </MenuItemContainer>
                </MenuItem>
              </Menu>
            </Header>
            <Body>
              <Menu iconShape='square'>
                <MenuItem
                  onMouseEnter={(e) => {
                    setHoverDashboard(true)
                  }}
                  onMouseLeave={(e) => {
                    setHoverDashboard(false)
                  }}
                  icon={
                    <FAIcon
                      color={state.selectDashboard ? "#63C085" : hoverDashboard ? COLORS.White : COLORS.HighlightMedium}
                      size='1x'
                      rotation={0}
                      icon='dashboard'
                    />
                  }
                >
                  <MenuItemContainer
                    onClick={() => {
                      setState({
                        selectDashboard: true,
                        selectAccount: false,
                        selectLogin: false,
                        selectWingnut: false,
                        selectProperty: false,
                        selectNotification: false,
                      })
                    }}
                  >
                    <MenuText style={{ color: state.selectDashboard ? COLORS.White : hoverDashboard ? COLORS.White : COLORS.HighlightMedium }}>
                      DASHBOARD
                    </MenuText>
                    <Link to='/portal/dashboard/0' />
                  </MenuItemContainer>
                </MenuItem>

                <MenuItem
                  onMouseEnter={(e) => {
                    setHoverAccount(true)
                  }}
                  onMouseLeave={(e) => {
                    setHoverAccount(false)
                  }}
                  icon={
                    <FAIcon
                      color={state.selectAccount ? "#63C085" : hoverAccount ? COLORS.White : COLORS.HighlightMedium}
                      size='1x'
                      rotation={0}
                      icon='account'
                    />
                  }
                >
                  <MenuItemContainer
                    onClick={() => {
                      setState({
                        selectDashboard: false,
                        selectAccount: true,
                        selectLogin: false,
                        selectWingnut: false,
                        selectProperty: false,
                        selectNotification: false,
                      })
                    }}
                  >
                    <MenuText style={{ color: state.selectAccount ? COLORS.White : hoverAccount ? COLORS.White : COLORS.HighlightMedium }}>
                      ACCOUNT
                    </MenuText>
                    <Link to='/portal/account' />
                  </MenuItemContainer>
                </MenuItem>

                <MenuItem
                  onMouseEnter={(e) => {
                    setHoverWingnut(true)
                  }}
                  onMouseLeave={(e) => {
                    setHoverWingnut(false)
                  }}
                  icon={
                    <FAIcon
                      style={{ marginTop: 7 }}
                      color={state.selectWingnut ? "#63C085" : hoverWingnut ? COLORS.White : COLORS.HighlightMedium}
                      size='1x'
                      rotation={0}
                      icon='wingnuts'
                    />
                  }
                >
                  <MenuItemContainer
                    onClick={() => {
                      setState({
                        selectDashboard: false,
                        selectAccount: false,
                        selectLogin: false,
                        selectWingnut: true,
                        selectProperty: false,
                        selectNotification: false,
                      })
                    }}
                  >
                    <MenuText style={{ color: state.selectWingnut ? COLORS.White : hoverWingnut ? COLORS.White : COLORS.HighlightMedium }}>
                      WINGNUTS
                    </MenuText>
                    <Link to='/portal/wingnut' />
                  </MenuItemContainer>
                </MenuItem>

                <MenuItem
                  onMouseEnter={(e) => {
                    setHoverProperty(true)
                  }}
                  onMouseLeave={(e) => {
                    setHoverProperty(false)
                  }}
                  icon={
                    <FAIcon
                      style={{ marginTop: 0 }}
                      color={state.selectProperty ? "#63C085" : hoverProperty ? COLORS.White : COLORS.HighlightMedium}
                      size='1x'
                      rotation={0}
                      icon='property'
                    />
                  }
                >
                  <MenuItemContainer
                    onClick={() => {
                      setState({
                        selectDashboard: false,
                        selectAccount: false,
                        selectLogin: false,
                        selectWingnut: false,
                        selectProperty: true,
                        selectNotification: false,
                      })
                    }}
                  >
                    <MenuText style={{ color: state.selectProperty ? COLORS.White : hoverProperty ? COLORS.White : COLORS.HighlightMedium }}>
                      PROPERTIES
                    </MenuText>
                    <Link to='/portal/property' />
                  </MenuItemContainer>
                </MenuItem>
                <Line />

                <MenuItem
                  onClick={() => setIsModalOpen(true)}
                  onMouseEnter={(e) => {
                    setHoverLogout(true)
                  }}
                  onMouseLeave={(e) => {
                    setHoverLogout(false)
                  }}
                  icon={
                    <FAIcon
                      color={state.selectLogout ? "#63C085" : hoverLogout ? COLORS.White : COLORS.HighlightMedium}
                      size='1x'
                      rotation={0}
                      icon='logout'
                    />
                  }
                >
                  <MenuItemContainer
                    onClick={() => {
                      setState({ selectDashboard: false, selectAccount: false, selectLogin: true })
                    }}
                  >
                    <MenuText style={{ color: state.selectLogout ? COLORS.White : hoverLogout ? COLORS.White : COLORS.HighlightMedium }}>
                      LOG OUT
                    </MenuText>
                  </MenuItemContainer>
                </MenuItem>
              </Menu>
            </Body>
          </SubContainer>

          {isCollapsed ? null : (
            <Footer>
              <Box>
                <ImgWingut src={ImgWingnut} />
                <LookingText>Looking for help with your Wingnut ?</LookingText>
                <Link to='/portal/help' style={{ textDecoration: "none" }}>
                  <Button
                    type='submit'
                    onMouseEnter={(e) => {
                      setIsHovering(true)
                    }}
                    onMouseLeave={(e) => {
                      setIsHovering(false)
                    }}
                  >
                    <IconArrow
                      style={{
                        opacity: isHovering == true ? "100" : "0",
                        display: isHovering == true ? "inline" : "none",
                      }}
                      src={Image6}
                    />
                    <ButtonText style={{ marginLeft: isHovering == true ? "10px" : "0px"}}>
                      CONTACT US
                    </ButtonText>
                  </Button>
                </Link>
              </Box>
              <LinkContainer>
                <Link to='/terms' style={{ textDecoration: "none", alignSelf: "center" }}>
                  <Conditions>Terms & Conditions</Conditions>
                </Link>
                <Conditions style={{ textDecoration: "none", marginLeft: "3px", marginRight: "3px" }}> & </Conditions>
                <Link to='/privacy' style={{ textDecoration: "none", alignSelf: "center" }}>
                  <Conditions>Privacy Policy</Conditions>
                </Link>
              </LinkContainer>
              <Copyright>Wingnut IoT Inc.</Copyright>
            </Footer>
          )}
        </Container>
      </ProSidebar>
    </SideNavContainer>
    </div>
  )
}

// Below code takes .svg's and adds them to FontAwesome library to use them in the Pro-Nav-Bar


var faBurger = {
  prefix: "fas",
  iconName: "burger",
  icon: [
    12,
    11,
    [],
    "f0000",
    "M1 10.5C0.45 10.5 0 10.95 0 11.5C0 12.05 0.45 12.5 1 12.5L17 12.5C17.55 12.5 18 12.05 18 11.5C18 10.95 17.55 10.5 17 10.5H1ZM1 5.5L17 5.5C17.55 5.5 18 5.95 18 6.5C18 7.05 17.55 7.5 17 7.5L1 7.5C0.45 7.5 0 7.05 0 6.5C0 5.95 0.45 5.5 1 5.5ZM1 0.5L17 0.5C17.55 0.5 18 0.95 18 1.5C18 2.05 17.55 2.5 17 2.5L1 2.5C0.45 2.5 0 2.05 0 1.5C0 0.95 0.45 0.5 1 0.5Z",
  ],
}
library.add(faBurger)

var faDashBoard = {
  prefix: "fas",
  iconName: "dashboard",
  icon: [
    19,
    18,
    [],
    "f0000",
    "M10.5 20C11.875 20 13 18.875 13 17.5V2.5C13 1.125 11.875 0 10.5 0C9.125 0 8 1.125 8 2.5V17.5C8 18.875 9.125 20 10.5 20ZM3 20C4.375 20 5.5 18.875 5.5 17.5V12.5C5.5 11.125 4.375 10 3 10C1.625 10 0.5 11.125 0.5 12.5V17.5C0.5 18.875 1.625 20 3 20ZM15.5 17.5V8.75C15.5 7.375 16.625 6.25 18 6.25C19.375 6.25 20.5 7.375 20.5 8.75V17.5C20.5 18.875 19.375 20 18 20C16.625 20 15.5 18.875 15.5 17.5Z",
  ],
}
library.add(faDashBoard)

var faAccount = {
  prefix: "fas",
  iconName: "account",
  icon: [
    16,
    15,
    [],
    "f0000",
    "M12 4C12 6.21 10.21 8 8 8C5.79 8 4 6.21 4 4C4 1.79 5.79 0 8 0C10.21 0 12 1.79 12 4ZM0 14C0 11.34 5.33 10 8 10C10.67 10 16 11.34 16 14V15C16 15.55 15.55 16 15 16H1C0.45 16 0 15.55 0 15V14Z",
  ],
}
library.add(faAccount)

var faWingnuts = {
  prefix: "fas",
  iconName: "wingnuts",
  icon: [
    19,
    15,
    [],
    "f0000",
    "M8.02535 6.223C7.22151 5.92094 5.54147 0.706306 4.93858 0.722204C2.01258 0.801696 0.830932 2.39153 0.62997 3.1626C0.52547 3.59185 2.08492 6.21504 3.78104 7.99565C4.66725 8.9769 5.7859 9.72529 7.03664 10.1737C7.71476 10.2457 8.39639 10.2802 9.07839 10.2771H12.8243C13.5385 10.2846 14.2525 10.2501 14.9626 10.1737C16.2148 9.72379 17.3357 8.97573 18.2262 7.99565C19.9223 6.21504 21.4818 3.59185 21.3692 3.1626C21.1683 2.36768 19.9866 0.777848 17.0686 0.722204C16.4577 0.722204 14.7616 5.92094 13.9819 6.223H8.02535Z",
  ],
}
library.add(faWingnuts)

var faProperty = {
  prefix: "fas",
  iconName: "property",
  icon: [
    13,
    14,
    [],
    "f0000",
    "M6.33146 13.1064V8.93971H9.6648V13.1064C9.6648 13.5647 10.0398 13.9397 10.4981 13.9397H12.9981C13.4565 13.9397 13.8315 13.5647 13.8315 13.1064V7.27305H15.2481C15.6315 7.27305 15.8148 6.79805 15.5231 6.54805L8.55646 0.273047C8.2398 -0.0102865 7.75646 -0.0102865 7.4398 0.273047L0.473129 6.54805C0.189795 6.79805 0.364795 7.27305 0.748129 7.27305H2.1648V13.1064C2.1648 13.5647 2.5398 13.9397 2.99813 13.9397H5.49813C5.95646 13.9397 6.33146 13.5647 6.33146 13.1064Z",
  ],
}
library.add(faProperty)

var faNotification = {
  prefix: "fas",
  iconName: "notification",
  icon: [
    14,
    12,
    [],
    "f0000",
    "M15.001 9.45833V13.625L16.076 14.7C16.601 15.225 16.226 16.125 15.4844 16.125H4.50935C3.76769 16.125 3.40102 15.225 3.92602 14.7L5.00102 13.625V9.45833C5.00102 6.89167 6.35935 4.75833 8.75102 4.19167V3.625C8.75102 2.93333 9.30935 2.375 10.001 2.375C10.6927 2.375 11.251 2.93333 11.251 3.625V4.19167C13.6344 4.75833 15.001 6.9 15.001 9.45833ZM11.6677 16.9583C11.6677 17.875 10.9177 18.625 10.001 18.625C9.07602 18.625 8.33435 17.875 8.33435 16.9583H11.6677Z",
  ],
}
library.add(faNotification)

var faLogout = {
  prefix: "fas",
  iconName: "logout",
  icon: [
    19,
    18,
    [],
    "f0000",
    "M5 3H19C20.1 3 21 3.9 21 5V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V16C3 15.45 3.45 15 4 15C4.55 15 5 15.45 5 16V18C5 18.55 5.45 19 6 19H18C18.55 19 19 18.55 19 18V6C19 5.45 18.55 5 18 5H6C5.45 5 5 5.45 5 6V8C5 8.55 4.55 9 4 9C3.45 9 3 8.55 3 8V5C3 3.9 3.89 3 5 3ZM15.79 12.7L12.2 16.29C11.81 16.68 11.18 16.68 10.79 16.29C10.41 15.91 10.4 15.27 10.79 14.88L12.67 13H4C3.45 13 3 12.55 3 12C3 11.45 3.45 11 4 11H12.67L10.79 9.11C10.4 8.72 10.4 8.09 10.79 7.7C10.9768 7.51275 11.2305 7.40751 11.495 7.40751C11.7595 7.40751 12.0132 7.51275 12.2 7.7L15.79 11.29C16.18 11.68 16.18 12.31 15.79 12.7Z",
  ],
}
library.add(faLogout)
