import React from 'react'
import { COLORS } from "./../../../styles/colors"
import styled from "styled-components"
import media from "styled-media-query"

export default function OneGaugeValues(props) {

  return (
    <Container>
        <TextContainer>
            <Title>Minimum</Title>
            { props.data && props.data.min !== "none" && props.data.min !== undefined && props.data.min !== NaN  
              ? <InfoText>{`${props.data.min} ${props.unit}`}</InfoText>
              : <NoWingnutText>No Data</NoWingnutText>
            }
        </TextContainer>
        <TextContainer>
            <Title>Average</Title>
            { props.data && props.data.average !== "none" && props.data.average !== undefined && props.data.average !== NaN 
              ? <InfoText>{`${props.data.average} ${props.unit}`}</InfoText>
              : <NoWingnutText>No Data</NoWingnutText>
            }
        </TextContainer>
        <TextContainer>
            <Title>Maximum</Title>
            { props.data && props.data.max !== "none" && props.data.max !== undefined && props.data.max !== NaN  
              ? <InfoText>{`${props.data.max} ${props.unit}`}</InfoText>
              : <NoWingnutText>No Data</NoWingnutText>
            }
        </TextContainer>


    </Container>
  )
}

const Container = styled.div`
    /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 10px;
  padding-left: 10px;
  margin-top: 30px;
  max-width: 300px;
`;

const TextContainer = styled.div`
    /* border: 1px yellow dashed; */
    display: flex;
    flex-direction: column;
    ${media.lessThan("743px")`
    `}
`

  const Title = styled.h3`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 129.5%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color: ${COLORS.HighlightMedium};
  margin-top: 9px;
  `

  const InfoText = styled.h2`
  font-family: 'Blinker';
  font-style: normal;
  font-weight: 400;
  font-size: 23.8533px;
  line-height: 110%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
  `

const NoWingnutText = styled.p`
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 130%;
  color: ${COLORS.HighlightLight};
`;