import React, { Component } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Image4 from "./../../images/Tech.svg"
import Aos from "aos"
import "aos/dist/aos.css"
import MarqueeSlider from "./MarqueeSlider"
import { COLORS } from "../../styles/colors"

const Grid = styled.div`
  /* border: 1px blue dashed; */
  grid-area: 4 / 1 / 5 / 2;
  display: flex;
  width: 100vw;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 20px;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
        justify-content: center;
    `}
`

const TickerContainer = styled.div`
  height: 300px;
  z-index: 2;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
        justify-content: center;
    `}
`

const HeaderText = styled.h1`
  /* border: 1px red dashed; */
  font-family: "Blinker", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 67.4322px;
  line-height: 53px;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
  align-self: center;
  ${media.lessThan("743px")`
        width: 80%;
        text-align: center;
        font-size: 45px;
    `}
  ${media.between("744px", "large")`
        justify-content: center;
        font-size: 52px;
    `}
`

const BlurbText = styled.p`
  margin-top: 20px;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  line-height: 129.5%;
  align-self: center;
  margin-bottom: 40px;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
  opacity: 0.5;
  ${media.lessThan("743px")`
        width: 65%;
        text-align: center;
    `}
  ${media.between("744px", "large")`
        justify-content: center;
    `}
`

const TechImg = styled.img`
  width: 66px;
  height: 420px;
  align-self: center;
  margin-left: 800px;
  margin-top: -60px;
  z-index: 1;
  ${media.lessThan("743px")`
        width: 43px;
        height: 271px;
        overflow: none;
        margin-top: -140px;
        margin-right: 0px;
        align-self: center;
        `}
  ${media.between("744px", "large")`
        width: 43px;
        height: 271px;
        margin-top: -140px;
        margin-right: 200px;
    `}
`

export class Sliders extends Component {
  state = {
    move: false,
  }

  componentDidMount() {
    Aos.init()

    setTimeout(
      function () {
        var resizeEvent = new Event("resize")
        window.dispatchEvent(resizeEvent)
      }.bind(this),
      1000
    )
  }

  render() {
    return (
      <Grid>
        <HeaderText>Data at your fingertips</HeaderText>
        <BlurbText>Wingnut supports an endless variety of sensors and data types.</BlurbText>

        <TickerContainer>
          <MarqueeSlider />
        </TickerContainer>

        <TechImg data-aos='fade-down' data-aos-duration='1000' data-aos-once='false' src={Image4} />
      </Grid>
    )
  }
}

export default Sliders
