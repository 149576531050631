import React, { useEffect, useState, useContext } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import ImgSearch from "./../../images/ic_search.svg"
import ImgProperty from "./../../images/ic_property.svg"
import ImgPortfolio from "./../../images/ic_portfolio.svg"
import ImgCities from "./../../images/ic_cities.svg"
import WingnutStatus from "./../wingnuts/WingnutStatus.enum"
import WingnutStatusCard from "./../wingnuts/WingnutStatusCard"
import PropertyStatusCard from "./PropertyStatusCard"
import { COLORS } from "../../styles/colors"

const Container = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: row;
  background-color: ${COLORS.Base};
  height: auto;
  margin-bottom: 10px;
  justify-content: space-between;
  ${media.lessThan("743px")`
    max-height: 840px;
        align-items: center;
        padding-top: 30px;
        margin-right: 0px;
        justify-content: center;
        flex-direction: column-reverse;
    `}
  ${media.between("744px", "large")`
    flex-direction: column-reverse;
        height: auto;
    `}
    ${media.greaterThan("large")`
    `}
`

const SearchContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: auto;
  max-width: 1500px;
  ${media.lessThan("743px")`
        /* width: 80%; */
    `}
  ${media.between("744px", "large")`
        flex-direction: row;
        width: 90%;
        justify-content: flex-start;
    `}
    ${media.greaterThan("large")`
    `}
`

const SearchContainerTop = styled.div`
  /* border: 1px blue dashed; */
  display: flex;
  flex-direction: column;
  width: 400px;
  /* max-width: 400px; */
  /* max-width: 400px; */
  ${media.lessThan("743px")`
    margin-top: 10px;
    align-content: center;
    max-width: 400px;
  width: 100%;
    `}
  ${media.between("744px", "large")`
        margin-top: 10px;
        width: 90%;
        max-width: 400px;
    `}
    ${media.greaterThan("large")`
    `}
`;

// wraps search bar/icon and instruction
const SearchBarContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  align-self: center;
  width: 100%;
`
// wraps search bar and search icon

const SearchBar = styled.input`
  /* border: 1px blue dashed; */
  width: 100%;
  height: 50px;
  /* max-width: 600px; */
  outline: none;
  border: 1px solid ${COLORS.White};
  color: ${COLORS.White};
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 129.5%;
  font-weight: normal;
  background-color: ${COLORS.Primary};
  border-radius: 8px;
  box-sizing: border-box;
  padding-left: 10px;
  ::placeholder {
    color: ${COLORS.HighlightMedium};
  }
  :focus {
    outline: ${COLORS.White};
    border: 2px solid ${COLORS.White};
  }
  ${media.lessThan("743px")`
        width: 100%;
    `}
  ${media.between("744px", "large")`
        width: 100%;
    `}
    ${media.greaterThan("large")`
    `}
`

const SearchIcon = styled.img`
  /* border: 1px red dashed; */
  height: 20px;
  width: 20px;
  transition-duration: 0.5s;
  color: ${COLORS.White};
  margin-left: -40px;
  margin-top: 15px;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const StatusContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  max-width: 800px;
  margin-bottom: 10px;
  ${media.lessThan("743px")`
        flex-direction: column;
        width: auto;
        min-width: 360px;
        margin-bottom: 0px;
    `}
  ${media.between("744px", "large")`
  width: 90%;
    `}
    ${media.greaterThan("large")`
    `}
`

const StatusPair = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  flex-direction: column;
  align-content: flex-end;
  ${media.lessThan("743px")`
        flex-direction: column;
        width: auto;
        align-content: center;
    `}
  ${media.between("744px", "large")`
  margin-right: 8.5px;
  flex-direction: column;
    `}
    ${media.greaterThan("large")`
    margin-right: 8.5px;
    `}
`

const StatusPairRight = styled(StatusPair)`
  ${media.between("744px", "large")`
  margin-left: 8.5px;
    `}
    ${media.greaterThan("large")`
    margin-left: 8.5px;
    `}

`;

const StatusCard = styled.div`
  width: 287px;
  height: 70px;
  /* min-width: 340px; */
  /* max-width: 380px; */
  /* height: 40%; */
  /* min-height: 85px; */
  /* max-height: 90px; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: flex-end;
  background: #0e1c36;
  border-radius: 10px;
  margin-bottom: 17px;
  ${media.lessThan("743px")`
  align-self: center;
  width: 90%;
    `}
  ${media.between("744px", "large")`
  min-width: 300px;
  max-width: 300px;
    `}
    ${media.greaterThan("large")`
    `}
`

const LeftContainer = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StatusIcon = styled.img`
  width: 24px;
  height: auto;
  margin-left: 20px;
  margin-right: 20px;
  align-self: center;
`

const CardTextContainer = styled.div`
  /* border: 1px red dashed; */
  width: 144px;
  display: flex;
  flex-direction: column;
`

const CardHeader = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 129.5%;
  letter-spacing: 0.03em;
  color: ${COLORS.HighlightMedium};
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
  font-size: 12pt;
    `}
    ${media.greaterThan("large")`
    `}
`

const CardNumber = styled.h1`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 129.5%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  text-transform: capitalize;
  color: ${COLORS.White};
`

const ResultContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: column;
  width: auto;
  ${media.lessThan("743px")`
      margin-top: 16px;
        margin-bottom: 16px;
  `}
  ${media.between("744px", "large")`
      margin-top: 10px;
      margin-left: 20px;
  `}
  ${media.greaterThan("large")`
  `}
`

const SearchText = styled(CardHeader)`
  font-size: 14px;
  padding-top: 10px;
  ${media.lessThan("743px")`
    font-size: 12px;
  `}
  ${media.between("744px", "large")`
    font-size: 12px;
  `}
  ${media.greaterThan("large")`
  `}
`

const ResultHeader = styled(CardHeader)`
  font-size: 14px;
  ${media.lessThan("743px")`
    align-self: center;
  `}
  ${media.between("744px", "large")`
  `}
  ${media.greaterThan("large")`
  `}
`

const ResultNumber = styled(CardHeader)`
  font-family: Blinker;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 110%;
  letter-spacing: -0.01em;
  color: ${COLORS.White} ;
  ${media.lessThan("743px")`
            align-self: center;
        `}
  ${media.between("744px", "large")`
        `}
        ${media.greaterThan("large")`
        `}
`

const EyeButton = styled.button`
  width: 42px;
  height: 42px;
  border: none;
  box-sizing: border-box;
  border-radius: 5px;
  background: #0e1c36;
  justify-self: end;
  pointer-events: none;
  :hover {
    background: ${COLORS.Secondary};
  }
`

const EyeIcon = styled.img`
  width: 24px;
  height: 24px;
`
export default function PropertyOverview(props) {
  const countPortfolio = () => {
    var portfolios = []
    props.tableData.forEach((item) => {
      if (item.portfolio) {
        const port = item.portfolio.trim() 
        if (port !== "") {
          if (portfolios.indexOf(port) === -1) {
            portfolios.push(port)
          }
        }
      }
    })
    return portfolios.length
  }
  const countPropertyStatus = (hasWingnuts = true) => {
    const data = props.tableData.filter((item) => item.linkedWingnuts.length > 0 === hasWingnuts)
    return data.length
  }
  const countCities = () => {
    var cities = []
    props.tableData.forEach((item) => {
      if (item.address) {
        if (cities.indexOf(item.address.city) === -1) {
          cities.push(item.address.city)
        }
      }
    })
    return cities.length
  }

  return (
    <Container>
      <SearchContainer>
        <SearchContainerTop>
          <SearchBarContainer>
            <SearchBar placeholder='Search Properties' onChange={props.onInputChange} />
            <SearchIcon src={ImgSearch} />
          </SearchBarContainer>
          <SearchText>Search by city name, wingnut serial number, property address, etc.</SearchText>
        </SearchContainerTop>
        <ResultContainer>
          <ResultHeader>Showing</ResultHeader>
          <ResultNumber>{props.tableData.length} Results</ResultNumber>
        </ResultContainer>
      </SearchContainer>
      <StatusContainer>
        <StatusPair>
          <StatusCard>
            <LeftContainer>
              <StatusIcon src={ImgProperty} />
              <CardTextContainer>
                <CardHeader>Properties</CardHeader>
                <CardNumber>{props.tableData.length}</CardNumber>
              </CardTextContainer>
            </LeftContainer>
            <EyeButton></EyeButton>
          </StatusCard>
          <StatusCard>
            <LeftContainer>
              <StatusIcon src={ImgPortfolio} />
              <CardTextContainer>
                <CardHeader>Portfolios</CardHeader>
                <CardNumber>{countPortfolio()}</CardNumber>
              </CardTextContainer>
            </LeftContainer>
            <EyeButton></EyeButton>
          </StatusCard>
        </StatusPair>
        <StatusPairRight>
          <StatusCard>
          <LeftContainer>
            <StatusIcon src={ImgCities} />
            <CardTextContainer>
              <CardHeader>Cities</CardHeader>
              <CardNumber>{countCities()}</CardNumber>
            </CardTextContainer>
          </LeftContainer>
            <EyeButton></EyeButton>
          </StatusCard>
          <PropertyStatusCard
            count={countPropertyStatus(false)}
            status={WingnutStatus.Unlinked.value}
            toggleStatus={props.toggleStatus}
          />
        </StatusPairRight>
      </StatusContainer>
    </Container>
  )
}
