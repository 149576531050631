import React, { useEffect, useState, useContext } from "react"
import { API, Auth } from "aws-amplify"
import styled from "styled-components"
import media from "styled-media-query"
import { useMediaQuery } from 'react-responsive'
import { COLORS } from "../../styles/colors"
import WingnutDetailsModule from "../analytic/WingnutDetailsModule"
import PropertyTimeData from "../analytic/PropertyTimeData"
import SystemOverview from "../analytic/SystemOverview"
import TimeSeriesModule from "./../analytic/TimeSeriesModule"
import CustomTimeModule from "./../analytic/CustomTimeModule"
import PlacesAutocomplete, {geocodeByAddress} from 'react-places-autocomplete';
import GetTimezoneFromLatLng from './../analytic/component/GetTimeZone'
import PropertyHeader from './../analytic/PropertyHeader'
import { useHistory } from 'react-router-dom'
import moment from 'moment-timezone';

export default function Dashboard(props) {
  const history = useHistory();

  const { params } = props.match
  const [properties, setProperties] = useState([])
  const [propertyDetails, setPropertyDetails] = useState(null)
  const [sensorLabels, setSensorLabels] = useState([])

  const [timeZone, setTimeZone] = useState();
  const [offset, setOffset] = useState(0);
  const [incrementTime, setIncrementTime] = useState(86400)     // Default 24 Hours
  const [startTime, setStartTime] = useState(new Date().getTime());    // Always in Milliseconds
  const [endTime, setEndTime] = useState(new Date().getTime());        // Always in Milliseconds

  const [state, setState] = useState({ value: "UTC", label:  "Property Time - (GMT-0) UTC", offset: "(GMT-0)"})

  const [isLocked24H, setIsLocked24H] = useState(false)
  const [isCustomDate, setIsCustomDate] = useState()
  const [isTimeRange, setIsTimeRange] = useState(false)
  const [isNewTime, setIsNewTime] = useState(false)
  const [isCustomRange, setIsCustomRange] = useState(false)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [isDefaultLoad, setIsDefaultLoad] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [name, setName] = useState("")
  const [externalId, setExternalId] = useState("")
  const [address, setAddress] = useState("")
  const [refresh, setRefresh] = useState(false)
  const [currentTab, setCurrentTab] = useState(1)

  const isDesktopSize = useMediaQuery({ query: '(min-width: 744px)' })
  // const [isEditNotificationOpen, setIsEditNotificationOpen] = useState(false)

  useEffect(() => {
    // Set to user timezone to begin with
    let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(timeZone)
  }, [])

  // Updated timeZone via User UI
  const updateSelectedTab = (tabIndex) => {
    setCurrentTab(tabIndex)
  }

  // Updated timeZone via User UI
  const updateTimeZone = (newTimeZone) => {
    applyNewTimeRange(newTimeZone)
  }

    // Updating TimeRange with Child Dropdown
    const loadTimeRange = (incrementTime) => {
      setIncrementTime(incrementTime)
  
      if(isDefaultLoad == true && isTimeRange == false){
        applyNewTimeRange()
        setIsDefaultLoad(false)
      } else {
        setIsNewTime(true) 
      }
  
      // If User selects Custom
      if(incrementTime == 0){
        setIsCustomRange(true)
        setIsTimeRange(false)
      } else {
        setIsCustomRange(false)
        setIsTimeRange(true)     
      }
  }

  const applyNewTimeRange = (newTimeZone) => {
    // Manually trigger data reload

    history.push(`/portal/dashboard/0`) // Cancel any custom month info
  
    var freshEndTime = new Date().getTime()
  
      let freshStartTime = freshEndTime - (incrementTime * 1000)

        // Only add offset if in the Properties TimeZone
        if (newTimeZone && (newTimeZone !== Intl.DateTimeFormat().resolvedOptions().timeZone)) {
          const localOffset = new Date().getTimezoneOffset() * 60000; // getTimezoneOffset() returns in minutes so convert to milliseconds
          
          const offsetDifference = getTimeZoneOffset(newTimeZone) - (-localOffset) //  Get difference - Local offset is negative if your local time is behind UTC
          
          const offset = 3600000
          setOffset(offsetDifference) 
          setTimeZone(newTimeZone)
        } else {
          setOffset(0)
        }
  
    setIsNewTime(false)
    setIsTimeRange(false)
    setStartTime(freshStartTime)
    setEndTime(freshEndTime)
    setRefresh(!refresh)
   }

   const getTimeZoneOffset = (newTimeZone) => {

    const now = moment.tz(newTimeZone);

    // Get the offset in minutes and convert it to milliseconds
    // The offset is negative if the timezone is behind UTC, positive if ahead
    const offsetMilliseconds = now.utcOffset() * 60 * 1000;
  
    return offsetMilliseconds;

   }

  const loadCustomMonth = (month, year) => {
    let startOfMonth = new Date(Date.UTC(year, month, 1));
    let startUTCMilliseconds = startOfMonth.getTime();

    let startOfNextMonth = new Date(Date.UTC(year, month + 1, 1));
    // Subtract 1 millisecond to get the end of the current month
    let endOfMonth = new Date(startOfNextMonth.getTime() - 1);
    let endUTCMilliseconds = endOfMonth.getTime();

    history.push(`/portal/dashboard/${startUTCMilliseconds + 86400000}`)
    setStartTime(startUTCMilliseconds)
    setEndTime(endUTCMilliseconds - 1)
    // setIsCustomDate(false)
    setRefresh(!refresh)
    // reloadProperty()
  }


  useEffect(() => {
    let cancelled = false

    async function getProperties() {
      const token = (await Auth.currentSession()).getIdToken()
      const jwt = token.getJwtToken()
      const init = {
        headers: { Authorization: `Bearer ${jwt}` },
      }
      const response = await API.get('FrontendAPI-properties', ``, init)
      if (response.success && !cancelled) {
        setProperties(response.data)
        setIsFirstLoad(false)
      }
    }
    getProperties()
    return () => {
      cancelled = true
    }
  }, [])

  const reloadProperty = () => {
    if(propertyDetails !== null){
      setPropertyDetails(null)   // TODO: COMMENTED OUT THE RESET DUE TO UX - NO PROBLEMS?
      setSensorLabels([])
      const item = {
        key : propertyDetails.id
      }
      loadProperty(item) 
    }
  }

  const loadProperty = async (item) => {

    if(item === null){
      // do nothing
    } else {
      setIsLoading(true)
      const token = (await Auth.currentSession()).getIdToken()
      const jwt = token.getJwtToken()
      const init = {
        headers: { Authorization: `Bearer ${jwt}` },
      }
      const response = await API.get('FrontendAPI-properties', `/${item.key}`, init)

      if (response.success) {
        setPropertyDetails(response.data)
        setRefresh(!refresh)                // Put this back in because switching properties needed it

        const a = response.data.config.address
        const address = `${a.streetAddress} ${a.city} ${a.provinceState} ${a.country} ${a.postalZip}`
        setAddress(address)
        discoverTimeZone(address)
        setName(`${response.data.config.name}`)
        setExternalId(`${response.data.externalId}`)

        var sensorLabels = []
        if(response.data.linkedWingnuts.length !== 0){
          response.data.linkedWingnuts.forEach((wingnut)=>{
            if(wingnut.config && wingnut.config.sensors){
              Object.entries(wingnut.config.sensors).forEach(([key, val]) => {
                sensorLabels.push(val.label)
              })
            }
          })

        }
        setSensorLabels(sensorLabels)
        setIsLoading(false)
      }
  }
  }

  // Discover timezone of Property
  const discoverTimeZone = async (address) => {
    try {
      // Look up the latitude and longitude of the selected address
      const results = await geocodeByAddress(address);
      const { lat, lng } = results[0].geometry.location;
  
      // Get the timezone label and UTC offset for the location
      const timezoneData = await GetTimezoneFromLatLng(lat(), lng());
      const { label, timezoneLabel, offset } = timezoneData;
  
      // Update state with the selected timezone label
      setState({ value: timezoneLabel, label: label, offset: offset });
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const toggleModule = (open, target) => {
    if(open){
        const element = isDesktopSize ? document.getElementById('top') : document.getElementById('cardTop');
        element.scrollIntoView({ behavior: 'smooth' });
        setTimeout(() => {
            // setIsEditNotificationOpen(false)
        }, 400)
    } else {
        // setIsEditNotificationOpen(true)
        setTimeout(() => {
            const element = document.getElementById(target);
            element.scrollIntoView({ behavior: 'smooth' });
        }, 100)
    }
}

  // TODO: Old Custom Date Code?
  const loadCustomDate = (date) =>{

    var newEndTimeInSeconds = 0

  // Input could be a date object or UTC timestamp depending on who calls it 
   if(typeof(date) !== Object){
      newEndTimeInSeconds = Math.floor(date / 1000)
   } else {
     newEndTimeInSeconds = Math.floor(date.getTime() / 1000)
   }

    let newStartTime = newEndTimeInSeconds - incrementTime

    setStartTime(newStartTime * 1000)
    setEndTime(newEndTimeInSeconds * 1000 - 1)
    setRefresh(!refresh)
    // reloadProperty()
  }

    return (
      <OuterContainer>
        <TestContainer>
          {/* <h1 style={{color: 'white'}}>{`${new Date(startTime).toISOString()}  `}</h1>
          <h1 style={{color: 'white'}}> {'------->'}</h1>
          <h1 style={{color: 'white'}}>{  `${new Date(endTime).toISOString()}`}</h1> */}
        </TestContainer>

          {propertyDetails ? 
            <PropertyHeader 
            propertyDetails={propertyDetails}
            isLoading={isLoading}
            />
            : null
            }

            <PropertyTimeData  
              isFirstLoad={isFirstLoad} 
              data={properties} 
              loadProperty={loadProperty}
              propertyDetails={propertyDetails}
              address={address}
              name={name}
              externalId={externalId}
              startTime={startTime}
              endTime={endTime}
              loadTimeRange={loadTimeRange}
              loadCustomDate={loadCustomDate}
              wingnuts={propertyDetails ? propertyDetails.linkedWingnuts : []}
              reloadProperty={reloadProperty}
              isLocked24H={isLocked24H}
              isLoading={isLoading}
              params={params}
              timeZone={timeZone}
              propertyTimeZone={state}
              updateTimeZone={updateTimeZone}
              isCustomRange={isCustomRange}
              isTimeRange={isTimeRange}
              isDefaultLoad={isDefaultLoad}
              applyNewTimeRange={applyNewTimeRange}
              loadCustomMonth={loadCustomMonth}
              isNewTime={isNewTime}
            />

             {/* ----Wingnut Stats---- */}
            {
              propertyDetails && propertyDetails.linkedWingnuts.length !== 0
              ? Object.values(propertyDetails.linkedWingnuts).map((wingnut, index)=>{
                  return <WingnutDetailsModule key={index} wingnut={wingnut} refresh={refresh}/>
                })
              : null
            }

              {/* Tab Bar Menu */}
              {propertyDetails ? 
                <Card>
                  <Container>
                    <BtnTab 
                      style={{height: currentTab === 1 ? 99 : 69, paddingBottom: currentTab === 1 ? 55 : 25, background: currentTab === 1 ? "#0e1c36" : "#0A162D"}}
                      onClick={()=>{updateSelectedTab(1)}}>
                        <HeaderText style={{color: currentTab === 1 ? COLORS.White : "#384C72"}}>System Overview</HeaderText>
                    </BtnTab>  
                    <BottomLeftInvertedRadius style={{boxShadow: currentTab === 1 ? "0 12px 0 0 #0e1c36" : "0 12px 0 0 transparent"}}/>
                    <BottomRightInvertedRadius style={{boxShadow: currentTab === 2 ? "0 12px 0 0 #0e1c36" : "0 12px 0 0 transparent"}}/>
                    <BtnTab 
                      style={{height: currentTab === 2 ? 99 : 69, paddingBottom: currentTab === 2 ? 55 : 25, background: currentTab === 2 ? "#0e1c36" : "#0A162D"}}
                      onClick={()=>{updateSelectedTab(2)}}>
                        <HeaderText style={{color: currentTab === 2 ? COLORS.White : "#384C72"}}>Boiler System</HeaderText>
                    </BtnTab>  
                    <BottomLeftInvertedRadius style={{boxShadow: currentTab === 2 ? "0 12px 0 0 #0e1c36" : "0 12px 0 0 transparent"}}/>
                    <BottomRightInvertedRadius style={{boxShadow: currentTab === 3 ? "0 12px 0 0 #0e1c36" : "0 12px 0 0 transparent"}}/>
                    <BtnTab 
                      style={{height: currentTab === 3 ? 99 : 69, paddingBottom: currentTab === 3 ? 55 : 25, background: currentTab === 3 ? "#0e1c36" : "#0A162D"}}
                      onClick={()=>{updateSelectedTab(3)}}>
                        <HeaderText style={{color: currentTab === 3 ? COLORS.White : "#384C72"}}>Domestic Hot Water</HeaderText>
                    </BtnTab>  
                    <BottomLeftInvertedRadius style={{boxShadow: currentTab === 3 ? "0 12px 0 0 #0e1c36" : "0 12px 0 0 transparent"}}/>
                  </Container>

                  {
                    (() => {
                      switch (currentTab) {
                        case 1:
                          return <SystemOverview 
                            id={1}
                            offset={offset}
                            startTime={startTime}
                            endTime={endTime}
                            propertyDetails={propertyDetails} 
                            refresh={refresh} 
                            />;
                        case 2:
                          return <TimeSeriesModule
                            id={1}
                            key={1}
                            offset={offset}
                            toggleModule={toggleModule}
                            label={`Boiler System`}
                            group={"boilerSeries1"}
                            boilerLabels={true}
                            propertyDetails={propertyDetails} 
                            chartURL={"boilersystemtimeseriesdata"} 
                            stateURL={"boilersystemstatedata"} 
                            analyticURL={"boilersystemstateanalytics"} 
                            refresh={refresh}
                            startTime={startTime}
                            endTime={endTime}
                            incrementTime={incrementTime}
                            timeZone={timeZone}
                            isLocked24H={isLocked24H}           
                        />;
                        case 3:
                          return <TimeSeriesModule
                          id={2}
                          key={2}
                          offset={offset}
                          toggleModule={toggleModule}
                          label={"Domestic Hot Water"}
                          group={"hotwaterSeries1"}
                          boilerLabels={false}
                          propertyDetails={propertyDetails} 
                          chartURL={"domestichotwatersystemtimeseriesdata"} 
                          stateURL={"domestichotwatersystemstatedata"} 
                          analyticURL={"domestichotwatersystemstateanalytics"} 
                          refresh={refresh}
                          startTime={startTime}
                          endTime={endTime}
                          incrementTime={incrementTime}
                          timeZone={timeZone}
                          isLocked24H={isLocked24H}
                        />;
                      }
                    })()
                  }
                </Card>

                :
                null
                }

      </OuterContainer>
    )
  
}

const TestContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const OuterContainer = styled.div`
  /* border: 1px yellow dashed;; */
  background-color: ${COLORS.Base};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  ${media.lessThan("743px")`
        justify-content: center;
        padding-top: 10px;
        align-content: center;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const PrimaryContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: column;
  width: 100%;
   max-width: 1460px;
   margin-right: 30px;
  ${media.lessThan("743px")`
      /* width: 90%; */
      align-self: center;
      flex-direction: column;
      margin-right: 0px;
  `}
  ${media.between("744px", "large")`
    flex-direction: column;
  `}
`

const Card = styled.div`
   /* border: 1px red dashed; */
   display: flex;
   width: 100%;
    max-width: 1440px;
   flex-direction: row;
   margin-right: 20px;
   padding-bottom: 20px;
   padding-top: 20px;
   flex-wrap: wrap;
   ${media.lessThan("743px")`
      width: 90%;
      align-self: center;
      margin-right: 0px;
    `}
   ${media.between("744px", "large")`
     `}
     ${media.greaterThan("large")`
     `}
 `

const Container = styled.div`
  /* border: 1px green dashed; */
  margin-right: 25px;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  width: 100%;
  /* padding-bottom: 20px; */
  border-radius: 10px;
`;

const HeaderText = styled.h2`
  /* border: 1px red dashed; */
  color: #384C72;
  text-align: center;
  font-family: Blinker;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 28.6px */
  letter-spacing: -0.26px;
     ${media.lessThan("743px")`
      font-size: 20px;
    `}
      ${media.between("744px", "large")`
      font-size: 22px;
    `}
`

const BtnTab = styled.button`
  border: none;
  outline: none;
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  background: ${COLORS.Primary};
  padding: 20px 25px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  /* margin-right: 20px; */
  ${media.lessThan("743px")`
      padding: 20px 20px;
  `}
  ${media.between("744px", "large")`
      padding: 20px 20px;
  `}
`;

const BottomLeftInvertedRadius = styled.div`
  /* border: 1px red dashed; */
  background-color: transparent;
  height: 83px;
  width: 10px;
  border-bottom-left-radius: 12px;
  box-shadow: 0 12px 0 0 #0e1c36; /* This is where the magic happens! */
    ${media.lessThan("743px")`
    `}
    ${media.between("744px", "1170px")`
    `}
    ${media.greaterThan("1171px")`
        /* display: none; */
    `}
`

const RightTabInvertedRadius = styled(BottomLeftInvertedRadius)`
    ${media.lessThan("743px")`
    display: none;
    `}
    ${media.between("744px", "1170px")`
      display: none;
    `}
    ${media.greaterThan("1171px")`
    `}
`

const BottomRightInvertedRadius = styled.div`
  /* border: 1px red dashed; */
  background-color: transparent;
  height: 83px;
  width: 10px;
  border-bottom-right-radius: 12px;
  box-shadow: 0 12px 0 0 #0e1c36; /* This is where the magic happens! */
    ${media.lessThan("743px")`
    `}
    ${media.between("744px", "1170px")`
    `}
    ${media.greaterThan("1171px")`
        /* display: none; */
    `}
`




            {/* <CustomTimeModule 
              isFirstLoad={isFirstLoad} 
              data={properties} 
              loadProperty={loadProperty}
              propertyDetails={propertyDetails}
              address={address}
              name={name}
              externalId={externalId}
              startTime={startTime}
              endTime={endTime}
              // startTime={convertTimeZone(startTime)}
              // endTime={convertTimeZone(endTime)}
              loadZero24HourDate={loadZero24HourDate}
              loadTimeRange={loadTimeRange}
              loadCustomDate={loadCustomDate}
              updateTimeZone={updateTimeZone}
              wingnuts={propertyDetails ? propertyDetails.linkedWingnuts : []}
              reloadProperty={reloadProperty}
              lockTo24H={lockTo24H}
              timeZone={timeZone}
              propertyTimeZone={state}
              refresh={refresh}/> */}