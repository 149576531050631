import React, { useEffect, useState, useContext } from "react"
import { API, Auth } from "aws-amplify"
import styled from "styled-components"
import media from "styled-media-query"
import { COLORS } from "../../styles/colors"
import { SpinnerContainer, StyledRelativeSpinner } from "../custom/Spinner"
import SystemOverviewUI from "./component/SystemOverviewUI"

export default function SystemOverview(props) {

  const [boilerPressureData, setBoilerPressureData] = useState()
  const [boilerTemperatureData, setBoilerTemperatureData] = useState()
  const [hotWaterTemperatureData, setHotWaterTemperatureData] = useState()

  const [isLoadingPressure, setIsLoadingPressure] = useState(false)
  const [isLoadingTemperature, setIsLoadingTemperature] = useState(false)
  const [isLoadingHotWater, setIsLoadingHotWater] = useState(false)

  const [isHovering, setIsHovering] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isFirstLoad, setIsFirstLoad] = useState(true)     // switch this in dev to false to not auto open

  useEffect(() => {
    if(boilerPressureData && boilerTemperatureData && hotWaterTemperatureData && isOpen || isFirstLoad){
      setIsFirstLoad(false)
      setBoilerPressureData()
      setBoilerTemperatureData()
      setHotWaterTemperatureData()
      loadChartData()
    }
  }, [props.refresh])

  const toggleChart = () => {
    if(isOpen){
      setIsOpen(false)
    } else {
      setBoilerPressureData()
      setBoilerTemperatureData()
      setHotWaterTemperatureData()
      loadChartData()
    }
}

useEffect(() => {
  if(boilerPressureData && boilerTemperatureData && hotWaterTemperatureData){
    setIsOpen(true)
    setIsLoadingTemperature(false)
    setIsLoadingPressure(false)
    setIsLoadingHotWater(false)
  }
}, [boilerPressureData, boilerTemperatureData, hotWaterTemperatureData])

const isDataLoading = () => {
  if(isLoadingTemperature || isLoadingPressure || isLoadingHotWater){
    return true
  } else {
    return false
  }
}

const loadChartData = async () => {

  const loadPressure = async () => {
    if(props.propertyDetails && props.startTime && props.endTime){
      setIsLoadingPressure(true)
      const token = (await Auth.currentSession()).getIdToken()
      const jwt = token.getJwtToken()
      const init = {
        headers: { Authorization: `Bearer ${jwt}` },
      }
      
      // Offset sent to back so API call will return but doesn't affect the returned calculations
      const response = await API.get('FrontendAPI-analytics', `/boilersystempressure?uuid=${props.propertyDetails.id}&startTime=${props.startTime}&endTime=${props.endTime}&offset=${props.offset}`, init)
      if (response.success) {
        setBoilerPressureData(response.data)
        setIsLoadingPressure(false)
      }
    }
  }

  const loadTemperature = async () => {
    if(props.propertyDetails && props.startTime && props.endTime){
      setIsLoadingTemperature(true)
      const token = (await Auth.currentSession()).getIdToken()
      const jwt = token.getJwtToken()
      const init = {
        headers: { Authorization: `Bearer ${jwt}` },
      }

      // Offset sent to back so API call will return but doesn't affect the returned calculations
      const response = await API.get('FrontendAPI-analytics', `/boilersystemtemperature?uuid=${props.propertyDetails.id}&startTime=${props.startTime}&endTime=${props.endTime}&offset=${props.offset}`, init)
      if (response.success) {
        setBoilerTemperatureData(response.data)
        setIsLoadingTemperature(false)
      }
    }
  }

  const loadHotWater = async () => {
    if(props.propertyDetails && props.startTime && props.endTime){
      setIsLoadingHotWater(true)
      const token = (await Auth.currentSession()).getIdToken()
      const jwt = token.getJwtToken()
      const init = {
        headers: { Authorization: `Bearer ${jwt}` },
      }
  
      // Offset sent to back so API call will return but doesn't affect the returned calculations
      const response = await API.get('FrontendAPI-analytics', `/domestichotwatersystemtemperature?uuid=${props.propertyDetails.id}&startTime=${props.startTime}&endTime=${props.endTime}&offset=${props.offset}`, init)
      if (response.success) {
        setHotWaterTemperatureData(response.data)
        setIsLoadingHotWater(false)
      }
    }
  }

  loadPressure()
  loadTemperature()
  loadHotWater()

}

  return (
    // <OuterContainer style={{display: props.show === true ? 'block' : "none"}}>
    <OuterContainer>
      <Card>
        <Container>
          <LeftContainer>
              { isDataLoading()
                ? <SpinnerContainer style={{justifyContent: "flex-start",  position: 'relative'}} ><StyledRelativeSpinner  style={{ width: "26px", height: "26px" }}  animation='border' /></SpinnerContainer>
                : null }

          </LeftContainer>  
        </Container>
          {
            isOpen
            ? <SystemOverviewUI isDataLoading={isDataLoading} boilerPressureData={boilerPressureData} boilerTemperatureData={boilerTemperatureData} hotWaterTemperatureData={hotWaterTemperatureData}  {...props}/>
            : null
          }

      </Card>
    </OuterContainer>
  )
}

const OuterContainer = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  margin-top: -16px;
  ${media.lessThan("743px")`
  justify-content: center;
  `}
  `;

const Card = styled.div`
   /* border: 1px red dashed; */
   display: flex;
   width: 100%;
   max-width: 1440px;
   flex-direction: row;
   justify-content: space-between;
   margin-right: 20px;
   background: #0e1c36;
   border-radius: 10px;
   min-height: 68px;
   height: 100%;
   /* padding: 20px; */
   flex-wrap: wrap;
   ${media.lessThan("743px")`
      /* width: 90%; */
      align-self: center;
      margin-right: 0px;
    `}
   ${media.between("744px", "large")`
     `}
     ${media.greaterThan("large")`
     `}
 `

const Container = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
`;

const HeaderText = styled.h2`
  /* border: 1px red dashed; */
  font-family: Blinker;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  /* line-height: 84.5%; */
  letter-spacing: -0.01em;
  color: ${COLORS.White};
`


const LeftContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: row;
  height: 30px;
`;

const RightContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: row;
  /* height: 100px; */
`;

const ArrowIcon = styled.img`
  width: 24px;
  height: 24px;
  align-self: center;
`

const HeaderContainer = styled.div`
    /* border: 1px blue dashed; */
    display: flex;
    flex-direction: row;
    align-items: center;
    ${media.lessThan("743px")`
        align-self: center;
        margin-right: 0px;
  `}
`