import React, { useEffect, useState, useContext } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import Image3 from "./../../images/ic_arrow_right_green.svg"
import ImgProperty from "./../../images/ic_property.svg"
import ImgClose from "./../../images/ic_close.svg"
import ImgAlert from "./../../images/ic_alert.svg"
import { DarkButtonSpinner } from "./../custom/Spinner"
import { COLORS } from "../../styles/colors"

const Container = styled.div`
  /* border: 1px yellow dashed; */
  width: 496px;
  height: 300px;
  display: flex;
  flex-direction: column;
  /* padding-bottom: 50px; */
  background-color: ${COLORS.Primary};
  border-radius: 20px;
  ${media.lessThan("743px")`
    justify-content: center;
    width: 350px;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const WingnutDetails = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: column;
  width: 350px;
  align-self: center;
  height: 100%;
  ${media.lessThan("743px")`
        padding-bottom: 27px;
        padding-right: 0px;
    `}
  ${media.between("744px", "large")`
        padding-right: 17px;
    `}
    ${media.greaterThan("large")`
    `}
`

const BtnClose = styled.button`
  height: 24px;
  width: 24px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 11px;
  background: transparent;
  border: none;
  outline: none;
  align-self: flex-end;
`

const CloseIcon = styled.img`
  width: 14px;
  height: 14px;
  align-self: center;
`

const WingnutIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-bottom: 32px;
  align-self: center;
  /* margin-top: 8px; */
`

const HeaderText = styled.div`
  font-family: Blinker;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 110%;
  display: flex;
  align-self: center;
  text-align: center;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
`

const InfoText = styled.h2`
  width: 300px;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  color: ${COLORS.White};
  margin-top: 12px;
  align-self: center;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 73px;
`

const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 40px;
  border: 1px solid #63c085;
  border-radius: 30px;
  background: #63c085;
  color: black;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
  margin-bottom: 10px;
  ${media.lessThan("743px")`
        align-self: flex-start;
        margin-top: 28px;
        height: 55px;
    `}
  ${media.between("744px", "large")`
        height: 55px;
    `}
    ${media.greaterThan("large")`
    `}
`

const BtnDiscard = styled(Button)`
  background: transparent;
  color: ${COLORS.White};
  margin-left: 5px;
  :hover {
    background: ${COLORS.Secondary};
    opacity: 1.0;
  }
  ${media.lessThan("743px")`
        align-self: center;
    `}
`

const ButtonText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
`

const IconArrow = styled.img`
  width: 6;
  height: 10px;
  align-self: center;
  transition-duration: 0.5s;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

export default function SaveOrAbandonPopup(props) {
  const [isHovering, setIsHovering] = useState(false)

  const saveAll = () => {

    props.saveAll()
    
    if (props.alertArray && props.alertArray.length){
      props.setError()
    } else {
      setTimeout(() => {
        props.closePopup()
        }, 500)
    }
  }

  const discardAll = () => {
    props.discardAll()
    props.closePopup()
  }

  return (
    <Container>
      <BtnClose onClick={props.closePopup}>
        <CloseIcon src={ImgClose} />
      </BtnClose>
      <WingnutDetails>
        <WingnutIcon src={ImgAlert} />
        <HeaderText>Save or Discard Configuration?</HeaderText>
        {/* <InfoText>Your changes will be saved</InfoText> */}

        <ButtonContainer>
          <Button disabled={props.saveDisabled} type='submit' onClick={saveAll}>
              <ButtonText style={{marginLeft: props.saveDisabled ? "22px" : null}}>SAVE</ButtonText>
              {props.showSpinner && <DarkButtonSpinner animation='border' />}
          </Button>
            <BtnDiscard onClick={discardAll}>
              <ButtonText>DISCARD</ButtonText>
            </BtnDiscard>
        </ButtonContainer>

      </WingnutDetails>
    </Container>
  )
}
