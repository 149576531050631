import React, { Component } from 'react'
import styled from "styled-components"
import media from "styled-media-query"
import { COLORS } from '../../styles/colors'
import ImgSearch from "./../../images/ic_search.svg"

// Notification search functions

export default function NotificationMenu(props){

    return (
      <Container>
        <TextContainer>
          <HeaderText>Search for a city or portfolio</HeaderText>
        </TextContainer>
        <Line />
        <InstructionText>Filter properties through either property name and/or portfolio</InstructionText>
        <SearchContainer>
            <SearchContainerTop>
                <Title>Property</Title>
            <SearchBarContainer>
                <SearchBar placeholder='All' onChange={props.onInputChange} />
                <SearchIcon src={ImgSearch} />
            </SearchBarContainer>
            </SearchContainerTop>
           
        </SearchContainer>
        <ResultContainer>
          <ResultHeader>Showing</ResultHeader>
          <ResultNumber>{`${props.tableData.length} Results`}</ResultNumber>
        </ResultContainer>
      </Container>
    )

}

const Container = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: column;
  ${media.lessThan("743px")`
      width: 90%;
      align-self: center;
      margin-right: 0px;
    `}
`

const TextContainer = styled.div`
  /* border: 1px yellow dashed; */
  width: 439px;
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  ${media.lessThan("743px")`
      width: 100%;
      align-self: center;
    `}
  ${media.between("744px", "large")`
        /* width: 384px; */
    `}
`

const HeaderText = styled.h2`
  font-family: Blinker;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 84.5%;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
  /* margin-bottom: 22px; */
`

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: ${COLORS.HighlightMedium};
  margin-top: 21px;
  margin-right: 30px;
  ${media.lessThan("743px")`
      align-self: center;
      /* width: 90% */
      margin-right: 0px;
    `}
`

const InstructionText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
  margin-bottom: 17px;
  font-size: 18px;
  padding-top: 19px;
  ${media.lessThan("743px")`
  `}
  ${media.between("744px", "large")`
  `}
  ${media.greaterThan("large")`
  `}
`
const SearchContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: auto;
  max-width: 1500px;
  ${media.lessThan("743px")`
        flex-direction: column;
    `}
  ${media.between("744px", "large")`
        flex-direction: row;
        /* width: 90%; */
        justify-content: flex-start;
    `}
    ${media.greaterThan("large")`
    `}
`

const SearchContainerTop = styled.div`
  /* border: 1px blue dashed; */
  display: flex;
  flex-direction: column;
  width: 400px;
  /* max-width: 400px; */
  /* max-width: 400px; */
  ${media.lessThan("743px")`
    margin-top: 10px;
    align-content: center;
    max-width: 400px;
  width: 100%;
    justify-self: center;
    `}
  ${media.between("744px", "large")`
        /* margin-top: 10px; */
        width: 90%;
        max-width: 400px;
    `}
    ${media.greaterThan("large")`
    `}
`;

// wraps search bar/icon and instruction
const SearchBarContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  align-self: center;
  width: 100%;
`
// wraps search bar and search icon

const Title = styled.h3`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 129.5%;
  display: flex;
  align-items: center;
  letter-spacing: 0.03em;
  text-transform: capitalize;
  color: ${COLORS.HighlightLight};
`

const SearchBar = styled.input`
  /* border: 1px blue dashed; */
  width: 377px;
  height: 45px;
  /* max-width: 600px; */
  outline: none;
  border: none;
  color: ${COLORS.White};
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 129.5%;
  font-weight: normal;
  background-color: ${COLORS.Secondary};
  border-radius: 8px;
  box-sizing: border-box;
  padding-left: 10px;
  ::placeholder {
    color: ${COLORS.HighlightMedium};
  }
  :focus {
    outline: ${COLORS.White};
    border: 2px solid ${COLORS.White};
    margin-left: -2px;
    margin-right: 2px;
  }
  ${media.lessThan("743px")`
        width: 100%;
    `}
  ${media.between("744px", "large")`
        width: 95%;
    `}
    ${media.greaterThan("large")`
    `}
`


const SearchIcon = styled.img`
  /* border: 1px red dashed; */
  height: 20px;
  width: 20px;
  transition-duration: 0.5s;
  color: ${COLORS.White};
  margin-left: -40px;
  margin-top: 15px;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const ResultContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: column;
  width: auto;
  margin-top: 11px;
  margin-bottom: 6px;
  ${media.lessThan("743px")`
        /* margin-bottom: 16px; */
  `}
  ${media.between("744px", "large")`
      /* margin-top: 10px; */
      /* margin-left: 20px; */
  `}
  ${media.greaterThan("large")`
  `}
`


const CardHeader = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 129.5%;
  letter-spacing: 0.03em;
  color: ${COLORS.HighlightMedium};
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
  /* font-size: 12pt; */
    `}
    ${media.greaterThan("large")`
    `}
`

const ResultHeader = styled(CardHeader)`
  font-size: 14px;
  ${media.lessThan("743px")`
    /* align-self: center; */
  `}
  ${media.between("744px", "large")`
  `}
  ${media.greaterThan("large")`
  `}
`

const ResultNumber = styled(CardHeader)`
  font-family: Blinker;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 110%;
  letter-spacing: -0.01em;
  color: ${COLORS.White} ;
  ${media.lessThan("743px")`
            /* align-self: center; */
        `}
  ${media.between("744px", "large")`
        `}
        ${media.greaterThan("large")`
        `}
`