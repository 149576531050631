import React, { Component, useMemo } from "react"
import { API, Auth } from "aws-amplify"
import { SpinnerContainer, StyledSpinner } from "../custom/Spinner"
import media from "styled-media-query"
import styled from "styled-components"
import Fuse from "fuse.js"
import PropertyController from "./PropertyController"
import PropertyTableStructure from "./PropertyTableStructure"
import WingnutStatus from "../wingnuts/WingnutStatus.enum"

const Container = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-right: 30px;
  ${media.lessThan("743px")`
    margin-right: 0px;
    `}
`

const fuzzyOptions = {
  // isCaseSensitive: false,
  // includeScore: false,
  // shouldSort: true,
  // includeMatches: false,
  // findAllMatches: false,
  // minMatchCharLength: 3,
  // location: 0,
  threshold: 0.1,
  // distance: 100,
  // useExtendedSearch: false,
  // ignoreLocation: false,
  // ignoreFieldNorm: false,
  // fieldNormWeight: 1,
  keys: ["propertyId", "propertyName", "portfolio", "linkedWingnuts", "address.streetAddress", "address.city", "address.postalZip", "address.provinceState", "address.country"],
}

export default class PropertyOverview extends Component {
  constructor() {
    super()
    this.fuseSearch = null
    this.showData = true

    this.state = {
        data: [],
        notificationData: [],
        search: "",
        isLoading: true,
        areTogglesLoading: true,
        didMerge: false,
        status: {
          Unconfigured: false,
        },
      }
  }

  componentDidMount() {
    this.getProperties()
    this.getNotificationToggles()
  }

  componentWillUnmount() {
    this.showData = false
  }

  componentDidUpdate(prevProps, prevState){
    if(prevState.isLoading === true && this.state.isLoading === false){
      this.filterData(this.state.search, this.state.status)
    } else if (this.state.isLoading === false && this.state.areTogglesLoading === false && this.state.didMerge === false){
      this.mergeData()
    }
  }

  mergeData(){
    const properties = this.state.data
    const notifications = this.state.notificationData
    const toggleProperties = []

    properties.forEach((property) => {
      var item = {}
      if (item = notifications.find(notification => notification.propertyId === property.uuid)) {
        const newProperty = {
          ...property,
          toggleStatus: item.entityStatus,
          notificationId: item.notificationId
        }
        toggleProperties.push(newProperty)
     }
    })

    this.setState({
      data: toggleProperties,
      didMerge: true
    })
  }

  searchForProperty(event) {
    if(this.state.isLoading === false){
      this.filterData(event.target.value, this.state.status)
    } else {
      this.setState({
        search: event.target.value,
      })
    }
  }

  filterData(fuzzySearch, wingnutState) {
    var data = []
    // If using the fuzzy search "bre.."
    if (fuzzySearch) {
      data = this.fuseSearch.search(fuzzySearch).map((s) => s.item)
    } else {
      // If using the eye filter icons
      if(this.fuseSearch){
        data = this.fuseSearch.getIndex().docs
      }
    }

    if (wingnutState.Unconfigured) {
      data = data.filter((item) => item.linkedWingnuts.length == 0)
    }

    this.setState({
      search: fuzzySearch,
      status: wingnutState,
      data: data,
      didMerge: false
    })
  }

  toggleStatus(status) {
    var wingnutStatus = this.state.status

    if (status === WingnutStatus.Unlinked) {
      wingnutStatus.Unconfigured = !wingnutStatus.Unconfigured
    }

    this.filterData(this.state.search, wingnutStatus)
  }

  setupTable(data) {
    this.fuseSearch = new Fuse(data, fuzzyOptions)
    this.setState({
      data: data,
    })
  }

  async getProperties() {
    const token = (await Auth.currentSession()).getIdToken()
    const jwt = token.getJwtToken()
    const init = {
      headers: { Authorization: `Bearer ${jwt}` },
    }

    API.get("FrontendAPI-properties", "", init)
      .then((response) => {
        if (response.success) {
          const mappedData = response.data.map((item) => {

            var alarmState = "OK"

            if(item.config && item.config.alarms !== null && item.config.alarms !== undefined){

              const alarms = item.config.alarms;

              // Check if any of the alarms are set to "ALARM"
              const isAnyAlarmSetToAlarm = Object.values(alarms).some(value => value === "ALARM");
              
              if (isAnyAlarmSetToAlarm) {
                alarmState = "ALARM"
              } else {
                alarmState = "OK"
              }

          }

            return {
              propertyId: parseInt(item.externalId),
              propertyIdDisplay: item.externalId,
              propertyName: item.config.name,
              uuid: item.id,
              alarmState: alarmState,
              address: item.config.address,
              portfolio: item.config.portfolio,
              entityStatus: item.entityStatus === "enabled" ? 1 : 0,  
              linkedWingnuts: item.linkedWingnuts,
            }
          })
          if (this.showData) {
            this.setupTable(mappedData)
          }
        }
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => this.setState({ isLoading: false }))
  }

  async getNotificationToggles() {
    const token = (await Auth.currentSession()).getIdToken()
    const jwt = token.getJwtToken()
    const init = {
      headers: { Authorization: `Bearer ${jwt}` },
    }

    API.get("FrontendAPI-notifications", "", init)
      .then((response) => {
        if (response.success) {
          const mappedNotifications = response.data.map((item) => {
            return {
              propertyId: item.config.propertyId,
              notificationId: item.id,
              entityStatus: this.translateToggle(item.entityStatus),
            }
          })
          if (this.showData) {
            this.setState({
              notificationData: mappedNotifications,
            })
          }
        }
      })
      .catch((error) => {
        console.error("THERE WAS ERROR", error)
      })
      .finally(() => this.setState({ areTogglesLoading: false }))
  }

  translateToggle(status) {
    if(status === "enabled"){
      return 1
    } else {
      return 0
    }
  }

  render() {

    return (
      <Container>
        <PropertyController
          tableData={this.state.data}
          toggleStatus={(s) => this.toggleStatus(s)}
          onInputChange={(e) => this.searchForProperty(e)}
        />
        { this.state.isLoading
        ? (<SpinnerContainer><StyledSpinner animation='border' /></SpinnerContainer>)
        : (<PropertyTableStructure tableData={this.state.data} />)}
      </Container>
    )
  }
}
