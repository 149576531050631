import React, { useEffect, useState, useContext } from "react"
import { API, Auth } from "aws-amplify"
import styled from "styled-components"
import media from "styled-media-query"
import IconLaunch from "./../../../images/ic_launch_green.svg"
import ImgGreen from "./../../../images/ic_status_green.svg"
import { Link } from "react-router-dom"
import { COLORS } from "./../../../styles/colors"
import PropertyDropdownUI from "./PropertyDropdownUI"
import TimeRangeDropdownUI from "./TimeRangeDropdownUI"
import { useMediaQuery } from 'react-responsive'
import TimeRangePickerUI from "./TimeRangePickerUI"
import IconRefreshBlue from "./../../../images/ic_refresh_blue.svg";
import IconRefresh from "./../../../images/ic_refresh_greenbox.svg";
import { StyledRelativeSpinner } from "./../../custom/Spinner"
import { ColorSet } from "@amcharts/amcharts5"
import PropertyCustomTimeUI from "./PropertyCustomTimeUI"

export default function PropertyTimeUI(props) {

  const [isRefresh, setIsRefresh] = useState(false)
  const [isRefreshAlertHovering, setIsRefreshAlertHovering] = useState(false)
  const [isLocalTime, setIsLocalTime] = useState(true)
  const [time, setTime] = useState(new Date());
  const [firstRecordDate, setFirstRecordDate] = useState("");
  const [firstMonth, setFirstMonth] = useState();
  const [firstYear, setFirstYear] = useState();
  const [isLoading, setIsLoading] = useState(false)


    useEffect(() => {
      loadFirstRecord()
    }, [props.propertyDetails]);

  const loadFirstRecord = async () => {

    if(props.propertyDetails && props.propertyDetails.linkedWingnuts.length !== 0){
      setIsLoading(true)
      const token = (await Auth.currentSession()).getIdToken()
      const jwt = token.getJwtToken()
      const init = {
        headers: { Authorization: `Bearer ${jwt}` },
      }

      const response = await API.get('FrontendAPI-analytics', `/firstrecord?firstRecordId=${props.propertyDetails.id}`, init)
      
      if (response.success) {

        // returned object is [{propertyId: "84bd1a5d-9700-4387-b092-ef81aec551c2", time: "2023-03-08 17:36:42.000000000"}]

        if(response.data[0]){
          // Assuming the timestamp is in a format that JavaScript's Date can parse
          const timestamp = new Date(response.data[0].time);
          const month = String(timestamp.getMonth()).padStart(2, '0');
          var year = timestamp.getFullYear();

          // Tinsel Town 2023-03-08 17:36:42.000000000
          // Brewery lands - 2023-03-15 21:37:08.000000000
          // console.log("timestamp", timestamp)
          // console.log("month", month)
          // Load in variables
          setFirstRecordDate(timestamp)
          setFirstMonth(month);
          setFirstYear(year);
          setIsLoading(false);
        }

      } else {
        setIsLoading(false)
        setFirstRecordDate("")
        setFirstMonth();
        setFirstYear();
      }
    } else {
      setIsLoading(false)
      setFirstRecordDate("")
      setFirstMonth();
      setFirstYear();
    }
  }

  const manualRefresh = () => {
    if(props.propertyDetails !== null){
      setIsRefresh(true)
      setIsRefreshAlertHovering(false)
      setFirstRecordDate("")
      setFirstMonth()
      setFirstYear()
      props.reloadProperty()
      setTimeout(() => {
        setIsRefresh(false)
        }, 1000)
    }
  }

  const setUserTime = () => {
    setIsLocalTime(true)
    props.updateTimeZone(Intl.DateTimeFormat().resolvedOptions().timeZone)
  }

  const setPropertyTime = () => {
    setIsLocalTime(false)
    props.updateTimeZone(props.propertyTimeZone && props.propertyTimeZone.value)
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(new Date());
    }, 60000); // Update every minute

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, []);


  const formatTime = (date, timezone) => {
    let formattedTime = new Intl.DateTimeFormat('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
      timeZone: timezone
    }).format(date);

    // Adjust hour formatting
    let [hour, minuteAndPeriod] = formattedTime.split(':');
    if (hour.startsWith('0')) {
      hour = hour.substring(1);
    }
    formattedTime = `${hour}:${minuteAndPeriod}`;

    // Changing AM/PM to lowercase
    formattedTime = formattedTime.replace('AM', 'am').replace('PM', 'pm');
    return formattedTime;
  };

  // Format timezone to GMT±0 format
  const formatTimeZone = (offset) => {
    const hours = Math.floor(Math.abs(offset) / 60);
    return `GMT${offset >= 0 ? '+' : '-'}${hours}`;
  };

  const localOffset = time.getTimezoneOffset();
  const localTimeZoneFormatted = formatTimeZone(-localOffset);  // getTimezoneOffset() returns the difference in minutes opposite to what we usually see
  const localTime = formatTime(time, Intl.DateTimeFormat().resolvedOptions().timeZone);
  const targetTime = formatTime(time, props.propertyTimeZone && props.propertyTimeZone.value);

  const applyTimeRange = () => {
    if(props.isTimeRange == true){
      props.applyNewTimeRange()
    }
  }

  return (
    <OuterContainer>
        <TopContainer>

        {props.propertyDetails ?
            <HeaderContainer>

              <HeaderTextContainer style={{marginRight: 10, cursor: 'pointer'}} onClick={setUserTime}>
                <HeaderText style={{color: isLocalTime == true ? COLORS.White : COLORS.HighlightLight}}>{localTime}</HeaderText>
                <Subheader>{`USER TIME (${localTimeZoneFormatted})`}</Subheader>
              </HeaderTextContainer>

              <HeaderTextContainer style={{cursor: 'pointer'}} onClick={setPropertyTime} >
                <HeaderText style={{ color: isLocalTime == false ? COLORS.White : COLORS.HighlightLight}}>{targetTime}</HeaderText>
                <Subheader>{`PROPERTY TIME ${props.propertyTimeZone ? props.propertyTimeZone.offset : ''}`}</Subheader>
              </HeaderTextContainer>

            </HeaderContainer>       
        : <div style={{height: 0}} />
        }

            { props.propertyDetails || props.isLoading ? 
              <UtilityContainer> 
                {props.isLoading 
                  ? <MiniSpinnerContainer><StyledRelativeSpinner animation='border' /></MiniSpinnerContainer>
                  : <Icon 
                  onMouseEnter={(e) => {
                    if(props.propertyDetails){
                      setIsRefreshAlertHovering(true)
                    }
                  }}
                  onMouseLeave={(e) => {
                    setIsRefreshAlertHovering(false)
                  }}
                  onClick={()=>{manualRefresh()}} 
                  src={isRefreshAlertHovering ? IconRefresh : IconRefresh} 
                  style={{marginLeft: -2, width: 32, height: 32, cursor: props.propertyDetails ? 'pointer' : null}} /> 
                }

              </UtilityContainer>
               :
                null
            } 

        </TopContainer>
         
        <Container>
            <Dropdown>
                <DropdownTitle>Property</DropdownTitle>
                <PropertyDropdownUI params={props.params} data={props.data} loadProperty={props.loadProperty} propertyDetails={props.propertyDetails}/>
            </Dropdown>
            <Dropdown>
                <DropdownTitle>Time Range</DropdownTitle>
                <TimeRangeDropdownUI {...props} loadFirstRecord={loadFirstRecord}/>
            </Dropdown>

            {props.isCustomRange ? 
              isLoading ? 
                <div style={{display: 'flex', alignSelf: 'center', marginTop: 10}}>
                  <MiniSpinnerContainer><StyledRelativeSpinner animation='border' /></MiniSpinnerContainer>
                </div>
                :
              <PropertyCustomTimeUI {...props} firstMonth={firstMonth} firstYear={firstYear}/>

            :<BtnApply onClick={applyTimeRange} disabled={!props.isTimeRange} style={{display: props.isNewTime == true ? 'block' : 'none', background: props.isTimeRange == true ? COLORS.Green : COLORS.Primary}} >
              <ButtonText2 style={{color: props.isTimeRange == true ? COLORS.White : COLORS.GreyUnavailable}}>
                  APPLY
              </ButtonText2>
            </BtnApply>
          }
        


        </Container>
            {
              props.propertyDetails && props.propertyDetails.linkedWingnuts.length === 0 
              ? 
                  <NoWingnutContainer>
                      <NoWingnutText>No Wingnuts currently linked to this Property. You must link a wingnut to start collecting data.</NoWingnutText>
                      <Link to={`/portal/property/link/${props.propertyDetails && props.propertyDetails.id}`} style={{ textDecoration: "none", width: 193 }}>
                        <BtnLinkWingnut>
                          <ButtonText2>
                            LINK WINGNUT
                          </ButtonText2>
                        </BtnLinkWingnut>
                     </Link>
                    </NoWingnutContainer>
              : null
            }


      </OuterContainer>
  )
}


  const OuterContainer = styled.div`
  /* border: 1px red dashed; */
    display: flex;
    flex-direction: column;
    width: 100%;
  `

const TopContainer = styled.div`
    /* border: 1px green dashed; */
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    ${media.lessThan("743px")`
      flex-direction: column;
    `}
`

const HeaderContainer = styled.div`
    /* border: 1px blue dashed; */
    display: flex;
    flex-direction: row;
    margin-bottom: 21px;
    ${media.lessThan("743px")`
        /* align-self: center; */
        margin-right: 0px;
        margin-bottom: 15px;
        /* justify-content: space-between; */
  `}
`

const HeaderTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const UtilityContainer = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  flex-direction: row;
  /* height: 30px; */
  /* justify-content: center; */
  ${media.lessThan("743px")`
    margin-bottom: 20px;
  `}
`;

const HeaderText = styled.h2`
    /* border: 1px red dashed; */
    font-family: Blinker;
    font-style: normal;
    font-weight: 600;
    font-size: 31px;
    line-height: 84.5%;
    letter-spacing: -0.01em;
    color: ${COLORS.White};
`

const Subheader = styled.h4`
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 12.13px;
    line-height: 130%;
    color: ${COLORS.HighlightLight};
    margin-top: 6px;
`;

const Container = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1440px;
  margin-right: 30px;
  margin-bottom: 20px;
  ${media.lessThan("743px")`
      align-self: center;
      margin-right: 0px;
        /* flex-direction: column; */
    `}
  `

const Dropdown = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`;

const DateContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
`;

const DropdownTitle = styled.p`
    /* border: 1px yellow dashed; */
    font-family: 'Roboto Condensed';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 129.5%;
    letter-spacing: 0.03em;
    text-transform: capitalize;
    color: ${COLORS.HighlightLight};
`;

const DropdownPlaceholder = styled.div`
    background: #1A2B4A;
    width: 384px;
    height: 45px;
    /* border: 1px solid #A1ADC2; */
    border-radius: 8px;
    ${media.lessThan("743px")`
    `}
`

const Button = styled.button`
  border: none;
  /* border: 1px yellow dashed; */
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  width: 130px;
  height: 20px;
  padding: 0px;
  background: transparent;
  margin-top: 7px;
  /* cursor: pointer;; */
  color: ${COLORS.White};
  /* :hover {
    color: #63C085;
  } */
  ${media.lessThan("743px")`
    margin-bottom: 15px;
    margin-left: -10px;
  `}
`

const Icon = styled.img`
  /* border: 1px red dashed; */
  width: 10px;
  height: 10px;
  margin-right: 6px;
  position: relative;
`

const ButtonText = styled.p`
  /* border: 1px blue dashed; */
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 129.5%;
  letter-spacing: 0.05em;
  color: ${COLORS.White};
  /* :hover {
    color: #63C085;
  } */
`

const NoWingnutContainer = styled.div`
    /* border: 1px red dashed; */
    display: flex;
    flex-direction: column;
`;

const NoWingnutText = styled.p`
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 300;
  font-size: 16px;
  line-height: 130%;
  color: ${COLORS.HighlightLight};
`;

const BtnLinkWingnut = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 40px;
    gap: 9px;
    background: ${COLORS.Green};
    width: 193px;
    height: 40px;
    border: 1px solid #63C085;
    border-radius: 30px;
    margin-right: 20px;
    margin-top: 16px;
    :hover {
  }
`;

const BtnApply = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* padding: 14px 40px; */
    gap: 9px;
    background: ${COLORS.Secondary};
    border: 1px solid #63C085;
    width: 140px;
    height: 40px;
    /* border: none; */
    border-radius: 30px;
    margin-right: 20px;
    margin-top: 20px;
    :hover {
  }
`;

const BtnSet = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: transparent;
    height: 40px;
    border: none;
    border-radius: 30px;
    :hover {
  }
`;

const ButtonText2 = styled.p`
  /* border: 1px red dashed; */
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${COLORS.Primary};
  align-self: center;
  transition-duration: 0.5s;
  ${media.lessThan("743px")`
        font-size: 15px;
        /* width: 170px; */
    `}
  ${media.between("744px", "large")`
        font-size: 15px;
    `}
    ${media.greaterThan("large")`
    `}
`;

const ButtonSetText = styled.p`
  /* border: 1px red dashed; */
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${COLORS.Green};
  align-self: center;
  transition-duration: 0.5s;
  ${media.lessThan("743px")`
        font-size: 15px;
        /* width: 170px; */
    `}
  ${media.between("744px", "large")`
        font-size: 15px;
    `}
    ${media.greaterThan("large")`
    `}
`;


const CalendarContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-top: 10px;
  width: 100%;
  /* height: 500px; */
  /* left: 454px; */
  /* top: 390px; */
  /* background: #1A2B4A; */
  /* border: 1px dashed red; */
  /* margin-top: 15px; 
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px; */
`;

const MiniSpinnerContainer = styled.div`
    /* border: 1px yellow dashed; */
  display: flex;
  width: 35px;
  height: 35px;
  /* justify-content: center; */
  /* align-self: center; */
  /* margin-bottom: 35px; */
  margin-right: 5px;
`