import React, { useEffect, useState, useContext } from "react"
import Select from "react-dropdown-select"
import styled from "styled-components"
import media from "styled-media-query"
import { COLORS } from "../../../styles/colors"

export default function CustomTimeZoneDropdownUI(props) {

  const [isLocal, setIsLocal] = useState(true)
  const [isSelected, setIsSelected] = useState(false)
  const [selectedTimeRange, setSelectedTimeRange] = useState(null)
  const [isHovering, setIsHovering] = useState(false)
  // const [keepOpen, setKeepOpen] = useState(false)

  const customContentRenderer = () => (
    // Set to Edmonton locally to start
    <div style={{ cursor: 'pointer', color: selectedTimeRange ? COLORS.White : COLORS.HighlightMedium}}>
      {selectedTimeRange ? selectedTimeRange :  "My Time - (GMT-6) America/Edmonton"}
      {/* {selectedTimeRange ? selectedTimeRange :  "My Time - (GMT-0) UTC"} */}
    </div>
  );

    const timeOptions = [
      {key: 1, value: props.propertyTimeZone ? props.propertyTimeZone.value : "America/UTC" , label: props.propertyTimeZone ? props.propertyTimeZone.label : "-" },
      {key: 2, value: "America/Edmonton", label: "My Time - (GMT-6) America/Edmonton" },
      // {key: 3, value: "UTC", label: "Reference Time - (GMT-0) UTC" },
      // {key: 3, value: -25200, label: "(GMT-7) America/Vancouver" }
    ];

  const onSelected = (item) => {
    if (item[0]) {
      props.updateTimeZone(item[0].value)
      setIsSelected(true)

      if(item[0].key === 2){
        setIsLocal(true)
        setSelectedTimeRange(`${item[0].label}`)
      } else {
        setIsLocal(false)
        setSelectedTimeRange(`${item[0].label}`)
      }

    } else {
      setSelectedTimeRange(null)
      setIsSelected(false)
    }
  }

  //    data.stopPropagation()??

  return (
    <Container>
      <TimeRangeDetails>
        <div                 
          onMouseEnter={(e) => {
            setIsHovering(true)
          }}
          onMouseLeave={(e) => {
            setIsHovering(false)
          }}
        >
          <StyledSelect
              dropdownGap={0}
              color={COLORS.White}
              keepOpen={false}
              placeholder={"Select or search for a date"}
              contentRenderer={customContentRenderer}
              onChange={(value) => onSelected(value)}
              options={timeOptions}
              values={isLocal ? [timeOptions[1]] : [timeOptions[0]]}
              dropdownHeight="500px"
              dropdownPosition="bottom"
              style={{
                border: isHovering ? "1px solid #d5d5d5" : isSelected ? "1px solid #d5d5d5" : "1px solid transparent",
                  borderRadius: 8,
                  color: COLORS.White,
                  fontWeight: "100",
                  paddingLeft: "12px",
                  fontFamily: "Roboto Condensed",
                  fontSize: "16px"
              }}
          />
        </div>
      </TimeRangeDetails>
    </Container>
  )
}

const Container = styled.div`
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
`

const CustomText = styled.p`
  color: ${COLORS.White};
  font-weight: 100,
  padding-left: 12px,
  font-family: "Roboto Condensed",
  font-size: 16px;
`;

const TimeRangeDetails = styled.div`
  border-radius: 10px;
  /* box-sizing: border-box; */
  display: flex;
  flex-direction: column;
  flex-wrap: none;
  width: 360px;
  ${media.lessThan("743px")`
        /* padding-bottom: 27px; */
        /* padding-right: 0px; */
        /* width: 100%; */
      max-width: 300px;
      min-width: 270px;
        /* height: 350px; */
    `}
  ${media.between("744px", "large")`
  /* height: 350px; */
    `}
    ${media.greaterThan("large")`
    `}
`

const StyledSelect = styled(Select)`
  height: 45px;
  width: 384px;
  color: ${COLORS.White};
  background: ${COLORS.Secondary};
  /* margin-top: 15px;  */

  ${media.lessThan("744px")`
        /* width: 90%; */
        height: 55px;
    `}
  ${media.between("medium", "large")`

        height: 55px;
    `}
    ${media.greaterThan("large")`
    `}

    .react-dropdown-select-clear,
    .react-dropdown-select-dropdown-handle {
    color: ${COLORS.White};
    height: 22px;
  }
  .react-dropdown-select-option {
  }
  .react-dropdown-select-item {

  }
  .react-dropdown-select-input {
    color: ${COLORS.White};             // cursor select
  }
  .react-dropdown-select-dropdown {
    background: ${COLORS.Secondary};            // card background
    border-radius: 8px;
    border: 1px solid ${COLORS.HighlightMedium};
  }
  .react-dropdown-select-item {
    color: ${COLORS.White};                     // list item
    border-bottom: none;
    padding-top: 12px;
    padding-bottom: 12px;
    :hover {
      background: ${COLORS.HighlightMedium};
    }
  }
  .react-dropdown-select-item.react-dropdown-select-item-selected{
    color: ${COLORS.White};                   // selected item
    background: ${COLORS.HighlightMedium};
    border-bottom: none;
  }
  .react-dropdown-select-item.react-dropdown-select-item-active {
    //background: #111;
    /* border-bottom: 1px solid #333; */

    /* font-weight: bold; */

  }
  .react-dropdown-select-item.react-dropdown-select-item-disabled {
    /* color: #ccc; */
  }
  ::placeholder {
    color: ${COLORS.White};

  }
  :focus {
    outline: ${COLORS.White};
    border: 1px solid ${COLORS.White};
  }

`

const CalendarContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* height: 500px; */
  /* left: 454px; */
  /* top: 390px; */
  /* background: #1A2B4A; */
  /* border: 1px solid #A1ADC2; */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
`;