import React, { Component, useEffect, useState, useContext } from "react"
import { API, Auth } from "aws-amplify"
import styled from "styled-components"
import media from "styled-media-query"
import Connected from "./../../images/ic_link.svg"
import NotConnected from "./../../images/ic_link_off.svg"
import WingnutDetailsCard from "./WingnutDetailsCard"
import PropertyDetailsCard from "./PropertyDetailsCard"
import LinkPropertySelectWingnut from "./LinkPropertySelectWingnut"
import LinkPropertyHeader from "./LinkPropertyHeader"
import { COLORS } from "../../styles/colors"

const Container = styled.div`
  max-width: 1260px;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  /* padding-bottom: 50px; */
  padding-top: 18px;
  background-color: ${COLORS.Base};
  ${media.lessThan("743px")`
        justify-content: center;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const StatusCard = styled.div`
  /* border: 1px yellow dashed; */
  width: auto;
  max-width: 1048px;
  min-height: 570px;
  height: auto;
  display: flex;
  flex-direction: column;
  background: #0e1c36;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 35px 35px;
  margin-right: 30px;
  ${media.lessThan("743px")`
        padding: 20px 20px;
        width: 95%;
        margin-right: 0px;
        align-self: center;
    `}
  ${media.between("744px", "large")`
        width: 95%;
        padding: 25px 25px;
    `}
    ${media.greaterThan("large")`
    `}
`

const HeaderContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${media.lessThan("743px")`
        flex-direction: column;
    `}
  ${media.between("744px", "large")`
        /* flex-direction: column; */
        flex-wrap: wrap;
        justify-content: flex-start;
    `}
    ${media.greaterThan("large")`
    `}
`

const DetailsContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  ${media.lessThan("743px")`
        flex-direction: column;
    `}
  ${media.between("744px", "large")`
        flex-direction: column;
        /* width: 350px; */
    `}
`

const WingnutDetails = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: column;
  width: 340px;
  padding-bottom: 5px;
  ${media.lessThan("743px")`
    width: 320px;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const WingnutIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 30px;
  margin-left: 30px;
  margin-top: 110px;
  ${media.lessThan("743px")`
        margin-top: 20px;
        margin-bottom: 20px;
        margin-right: 0px;
        margin-left: 155px;
    `}
  ${media.between("744px", "large")`
        margin-top: 20px;
        margin-bottom: 20px;
        margin-right: 0px;
        margin-left: 155px;
        /* align-self: center; */
    `}
    ${media.greaterThan("large")`
    `}
`

const Line = styled.div`
  /* width: 97.75%; */
  height: 1px;
  background: ${COLORS.HighlightMedium};
  margin-top: 14px;
  /* margin-right: 30px; */
  margin-bottom: 26px;
  ${media.lessThan("743px")`
        align-self: center;
        margin-right: 0px;
    `}
`

export default function LinkWingnutToProperty(props) {
  const {params} = props.match

  const [wingnuts, setWingnuts] = useState()
  const [wingnutDetails, setWingnutDetails] = useState()
  const [propertyDetails, setPropertyDetails] = useState()
  const [showSuccess, setShowSuccess] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [showSpinner, setShowSpinner] = useState(false)

  const enableLinkButton = () => {
      setDisabled(false)
  }

  const linkWingnut = async (id) => {
    setDisabled(true)
    setShowSpinner(true)
    if (id) {
      const token = (await Auth.currentSession()).getIdToken()
      const jwt = token.getJwtToken()
      const init = {
        headers: { Authorization: `Bearer ${jwt}` },
        body: { propertyId: propertyDetails.id }
      }
      const response = await API.post('FrontendAPI-wingnuts', `/${id}/link`, init)
      if (response.success) {
        setDisabled(false)
        setShowSpinner(false)
        setWingnutDetails(response.data)
        setShowSuccess(true)
      }
    }
  }

  useEffect(() => {
    var cancelled = false

    async function getWingnuts() {
      const token = (await Auth.currentSession()).getIdToken()
      const jwt = token.getJwtToken()
      const init = {
        headers: { Authorization: `Bearer ${jwt}` },
      }
      const response = await API.get('FrontendAPI-wingnuts', ``, init)
      if (response.success && !cancelled) {
        const wingnuts = response.data.filter(item => !item.property)
        setWingnuts(wingnuts)
      }
    }

    async function getPropertyDetails() {
      const token = (await Auth.currentSession()).getIdToken()
      const jwt = token.getJwtToken()
      const init = {
        headers: { Authorization: `Bearer ${jwt}` },
      }
      const response = await API.get('FrontendAPI-properties', `/${params.id}`, init)
      if (response.success && !cancelled) {
        setPropertyDetails(response.data)
      }
    }

    getWingnuts()
    getPropertyDetails()
    
    return () => {
      cancelled = true
    }
  }, [])

  return (
    <Container>
      <StatusCard>
        <HeaderContainer>
          {propertyDetails && (<LinkPropertyHeader  name={propertyDetails.config.name} updateSuccess={showSuccess}/>)}
        </HeaderContainer>
        <Line />
        <DetailsContainer>

          <WingnutDetails>
            {propertyDetails && (<PropertyDetailsCard propertyDetails={propertyDetails} onLeftSide={true}/>)}
          </WingnutDetails>

          {wingnutDetails ? <WingnutIcon src={Connected} /> : <WingnutIcon src={NotConnected} />}
          <WingnutDetails>
            {wingnutDetails ? <WingnutDetailsCard wingnutDetails={wingnutDetails} onLeftSide={false}/> : (wingnuts && <LinkPropertySelectWingnut enableLinkButton={enableLinkButton} showSpinner={showSpinner} disabled={disabled} linkWingnut={(id) => linkWingnut(id)} data={wingnuts} />)}
          </WingnutDetails>
        </DetailsContainer>
      </StatusCard>
    </Container>
  )
}
