import React, { useEffect, useState, useContext } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import ImgClose from "./../../images/ic_close.svg"
import ImgAlert from "./../../images/ic_alert.svg"
import { API, Auth } from "aws-amplify"
import { DarkButtonSpinner } from "./../custom/Spinner"
import { COLORS } from "../../styles/colors"

const Container = styled.div`
  /* border: 1px yellow dashed; */
  width: 496px;
  height: 340px;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.Primary};
  border-radius: 20px;
  ${media.lessThan("743px")`
    width: 350px;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const WingnutDetails = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: column;
  width: 350px;
  height: 240px;
  justify-content: space-between;
  align-self: center;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const BtnClose = styled.button`
  height: 24px;
  width: 24px;
  margin-right: 20px;
  margin-top: 20px;
  margin-bottom: 11px;
  background: transparent;
  border: none;
  outline: none;
  align-self: flex-end;
`

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CloseIcon = styled.img`
  width: 14px;
  height: 14px;
  align-self: center;
`

const WingnutIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-bottom: 21px;
  align-self: center;
`

const HeaderText = styled.div`
  font-family: Blinker;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 110%;
  display: flex;
  align-items: center;
  text-align: center;
  align-self: center;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
`

const InfoText = styled.h2`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  color: ${COLORS.White};
  margin-top: 12px;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* margin-top: 73px; */
`

const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 40px;
  border: 1px solid #63c085;
  border-radius: 30px;
  background: #63c085;
  color: black;
  cursor: pointer;
  transition-duration: 0.5s;
  :hover {
    opacity: 0.8;
  }
  ${media.lessThan("743px")`
        align-self: flex-start;
        height: 55px;
    `}
  ${media.between("744px", "large")`
        height: 55px;
    `}
    ${media.greaterThan("large")`
    `}
`

const BtnNo = styled(Button)`
  background: transparent;
  color: ${COLORS.White};
  margin-left: 5px;
  :hover {
    opacity: 1;
    background: ${COLORS.Secondary};
  }
  ${media.lessThan("743px")`
        align-self: center;
    `}
`

const ButtonText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  :hover {
  }
`

const IconArrow = styled.img`
  width: 6;
  height: 10px;
  align-self: center;
  transition-duration: 0.5s;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

export default function RemoveWingnutPopup(props) {

  const [isHovering, setIsHovering] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  const [disabled, setDisabled] = useState(false)


  const removeWingnut = async () => {
    setShowSpinner(true)
    setDisabled(true)
    const payload = {}
    const token = (await Auth.currentSession()).getIdToken()
    const jwt = token.getJwtToken()
    const init = {
      headers: { Authorization: `Bearer ${jwt}` }
    }
    try {
      const response = await API.del('FrontendAPI-wingnuts', `/${props.wingnutUUID}`, init)
      if (response.success) {
        setShowSpinner(false)
        setDisabled(false)
        props.setSuccess()
      }
    } catch (error) {
      setShowSpinner(false)
      setDisabled(false)
      props.closePopup()
    }
  }

  return (
    <Container>
      <BtnClose onClick={props.closePopup}>
        <CloseIcon src={ImgClose} />
      </BtnClose>
      <WingnutDetails>
        <TopContainer>
          <WingnutIcon src={ImgAlert} />
          <HeaderText>Remove Wingnut?</HeaderText>
          <InfoText>Related Wingnut data will be lost. This can't be undone</InfoText>
        </TopContainer>
        <ButtonContainer>

          <Button disabled={disabled} type='submit' onClick={() => removeWingnut()}>
            <ButtonText style={{marginLeft: disabled ? "22px" : null}}>YES</ButtonText>
            {showSpinner && <DarkButtonSpinner animation='border' />}
          </Button>

          <BtnNo onClick={props.closePopup}>
            <ButtonText>NO</ButtonText>
          </BtnNo>
        </ButtonContainer>
      </WingnutDetails>
    </Container>
  )
}
