import React, { useEffect, useState, useContext } from "react"
import { API, Auth } from "aws-amplify"
import styled from "styled-components"
import media from "styled-media-query"
import EmployeeCell from './EmployeeCell'
import NotificationEmployeeSelect from './NotificationEmployeeSelect'
import { AccountContext } from "./../../auth/Account"
import { COLORS } from "../../styles/colors"



export default function NotificationEmployee(props) {

  const {getSession } = useContext(AccountContext)
  const [employees, setEmployees] = useState([])
  const [viewEmployees, setViewEmployees] = useState([])
  const [recipients, setRecipients] = useState([])
  const [notificationDetails, setNotificationDetails] = useState([])
  const [selectDisabled, setSelectDisabled] = useState(true)

  const [isAuth, setIsAuth ] = useState(false)

    useEffect(() => {
      getSession().then(({ isAuth }) => {
        setIsAuth(isAuth)
      })
  }, [])

  useEffect(() => {
    if(props.saveAll === true){
      // saveConfiguration()
    }
}, [props.saveAll])


  // Get all users for the company
  useEffect(() => {

    async function getAllEmployees() {
      const token = (await Auth.currentSession()).getIdToken()
      const jwt = token.getJwtToken()
      const init = {
        headers: { Authorization: `Bearer ${jwt}` },
      }
      getSession().then(({ email }) => {
  
        API.get("FrontendAPI-users", "", init)
          .then((response) => {
            if (response.success) {
                const mappedEmployees = response.data.filter(item => {
                  var showEmployee = true
                  if(item.entityStatus === 'verified'){
                    // If logged in user is not @wingnutiot.ca, hide any @wingnutiot.ca users
                      if(email !== null && email !== undefined && item.externalId !== null && item.externalId !== undefined){
                        if(email.split("@").pop() !== "wingnutiot.ca"){
                          if(item.externalId.split("@").pop() === "wingnutiot.ca"){
                            showEmployee = false
                          } 
                        }
                      }
                  } else {
                    // not verified
                    showEmployee = false
                  } 

                  return (showEmployee)
                }).map((item, index) => {

                var formattedNumber = null

                if(item.config.phone){
                    const trimNumber = item.config.phone.slice(2)
                    formattedNumber = `(${trimNumber.substring(0, 3)}) ${trimNumber.substring(3, 6)}-${trimNumber.substring(6,10)}`
                  }
                      return {
                        id: item.id,
                        name: `${item.config.firstName ? item.config.firstName : "NOT SET" } ${item.config.lastName ? item.config.lastName : "NOT SET" }`,
                        email: item.externalId,
                        notifyByPhone: null,
                        notifyByEmail: null,
                        notifyBySlack: null,
                        isSelected: null,
                        phoneNumber: formattedNumber ? formattedNumber : "NOT SET"
                      }
              })
              //-----
              setEmployees(mappedEmployees)
              getNotifications()
            }
          })
          .catch((error) => {
            console.error("THERE WAS ERROR", error)
          })

      })
    }
        getAllEmployees()
  }, [props.propertyDetails])

    // Once employees load in, use PropertyDetails to get Notification Recipients
  const getNotifications = async () => {
    const token = (await Auth.currentSession()).getIdToken()
    const jwt = token.getJwtToken()
    const init = {
      headers: { Authorization: `Bearer ${jwt}` },
    }
    
     const response = await API.get('FrontendAPI-notifications', `?propertyId=${props.propertyDetails.id}`, init) 
      if (response.success) {

        if(response.data[0] && response.data[0].config)
        
        setRecipients(props.notificationDetails[0].config.recipients)
        setNotificationDetails(response.data)
      } 
  }

    // Use the notifications to update the employees
    useEffect(() => {
      if(employees.length !== 0){
        const filteredEmployees = employees.map((employee) => {

          recipients.forEach((recipient) => {       
                if(employee.id === recipient.id){
                    switch(recipient.type){
                      case "phone":
                        employee.notifyByPhone = true
                        return 
                      case "email":
                        employee.notifyByEmail = true
                        return 
                      case "slack": 
                        employee.notifyBySlack = true
                        return 
                      case "selected": 
                        employee.isSelected = true
                        return 
                    }
              }           
          })
          return employee
        })

        let localViewEmployees = []

        filteredEmployees.forEach((employee) => {
          if((employee.notifyByEmail || employee.notifyByPhone || employee.isSelected) ){
            localViewEmployees.unshift(employee) 
          }
        })
        setEmployees(filteredEmployees)
        setViewEmployees(localViewEmployees)
        setSelectDisabled(false)
        updateConfiguration()
      }
    }, [recipients])

  const addEmployeeToView = (id) => {
    let localViewEmployees = [...viewEmployees]
    // props.isActiveChange(true)
    employees.forEach((employee) => {
      if(employee.id === id ){
        employee.isSelected = true
        localViewEmployees.unshift(employee) 
      }
    })
    
    setViewEmployees(localViewEmployees)
    props.isActiveChange(true)
    updateConfiguration()
  }

  const removeEmployeeFromView = (id) => {
    let localEmployees = [...employees]
    props.isActiveChange(true)
    // set employee email notify to false
    let updatedEmployees = localEmployees.map((employee) => {
      if(employee.id === id){
        employee.notifyByEmail = false
        employee.notifyByPhone = false
        employee.isSelected = false
      } 
      return employee
    })

      setEmployees(updatedEmployees)
      let localViewEmployees = [...viewEmployees]

      const filteredViewEmployees = localViewEmployees.filter((employee) => {
        return employee.id !== id 
      })

      setViewEmployees(filteredViewEmployees)
      props.isActiveChange(true)
      updateConfiguration()
  }

  const setEmailNotification = (id, val) => {
    let localEmployees = [...employees]
    props.isActiveChange(true)
    let updatedEmployees = localEmployees.map((employee) => {
      if(employee.id === id){
        employee.notifyByEmail = val
      }
       return employee
    })

    setEmployees(updatedEmployees)
    updateConfiguration()
    props.isActiveChange(true)
  }

  const setPhoneNotification = (id, val) => {
    let localEmployees = [...employees]
    props.isActiveChange(true)
    let updatedEmployees = localEmployees.map((employee) => {
      if(employee.id === id){
        employee.notifyByPhone = val
      }
       return employee
    })
    setEmployees(updatedEmployees)
    updateConfiguration()
    props.isActiveChange(true)
  }

  const updateConfiguration = async () => {
    var newRecipients = []

    employees.forEach((employee)=> {

        if(employee.notifyByEmail === true){
            let emailRecipient = {
                  id: employee.id ,
                  type: "email", 
                }
              newRecipients.push(emailRecipient)
           } 

           if(employee.notifyByPhone === true){
            let phoneRecipient = {
                  id: employee.id ,
                  type: "phone", 
                }
              newRecipients.push(phoneRecipient)
           } 

           if(employee.isSelected === true){
            let phoneRecipient = {
                  id: employee.id,
                  type: "selected", 
                }
              newRecipients.push(phoneRecipient)
           } 
    })

    var newConfig =  {
      "config": {
        "recipients": {}
      }
   }

    newConfig['config'].recipients = newRecipients

    props.updateRecipients(newRecipients)

}

  return (
    <Container>
      <TitleText style={{display: isAuth ? "block" : "none"}}>Select who you want to notify</TitleText>
        <SearchContainer style={{visibility: isAuth ? "visible" : "hidden"}}>
                <NotificationEmployeeSelect disabled={selectDisabled} data={employees} viewEmployees={viewEmployees} addEmployee={addEmployeeToView} />
        </SearchContainer>
      <OuterContainer>
        { viewEmployees.count !== 0 ?
            viewEmployees.map(employee => {
                return (
                  <span key={employee.id}>
                      <ColumnContainer >
                          <EmployeeCell employee={employee} removeEmployee={removeEmployeeFromView} setEmail={setEmailNotification} setPhone={setPhoneNotification}/>
                      </ColumnContainer>
                  </span>
                );
            })
            :
            null
        }
      </OuterContainer>
    </Container>
  )
}


const Container = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: column;
  width: 95%;
  /* max-width: 985px; */
  background-color: #0e1c36;
  margin-bottom: 63px;
  ${media.lessThan("743px")`
      width: 90%;
      align-self: center;
      margin-right: 0px;
    `}
`

const TitleText = styled.p`
      font-family: 'Roboto Condensed';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 129.5%;
      letter-spacing: 0.03em;
      text-transform: capitalize;
      padding-top: 25px;
      color: ${COLORS.HighlightLight};
`;

const SearchContainer = styled.div`
  /* border: 1px red dashed; */
  flex-direction: row;
  justify-content: start;
  width: 377px;
  max-width: 1500px;
  ${media.lessThan("743px")`
        /* flex-direction: column; */
    `}
  ${media.between("744px", "large")`
        /* flex-direction: row; */
        /* width: 90%; */
        justify-content: flex-start;
    `}
    ${media.greaterThan("large")`
    `}
`

const OuterContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    ${media.lessThan("743px")`
    /* flex-direction: column; */
    `}
`;

const ColumnContainer = styled.div`
  /* border: 1px red dashed; */
    display: flex;
    flex-direction: column;
`;