import React, { useEffect, useState, useContext } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import IconLaunch from "./../../../images/ic_launch_green.svg"
import ImgGreen from "./../../../images/ic_status_green.svg"
import { Link } from "react-router-dom"
import { COLORS } from "../../../styles/colors"
import OneGauge from "../chart/OneGauge"
import OneGaugeValues from "../chart/OneGaugeValues"
// import OneCandlestick from "../chart/OneCandlestick"
// import OneBoxPlot from "../chart/OneBoxPlot"

export default function SystemGauge(props) {

  return (
    <WidgetContainer style={{opacity: props.isDataLoading() ? "0.5" : "1.0"}}>
        <HeaderText>{props.label}</HeaderText>
          <OneGauge {...props}/>
          <OneGaugeValues {...props}/>
     </WidgetContainer>
  )
}

const WidgetContainer = styled.div`
    /* border: 1px yellow dashed; */
    display: flex;
    flex-direction: column;
    background-color: #15233F;
    border-radius: 4px;
    width: 349px;
    height: 447px;
    padding: 22px;
    padding-top: 40px;
    padding-bottom: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
    ${media.lessThan("743px")`
    /* flex-wrap: wrap; */
    `}
    ${media.between("744px", "large")`
      /* flex-wrap: wrap; */
    `}
`

const HeaderText = styled.h2`
    /* border: 1px red dashed; */
    font-family: Blinker;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 110%;
    letter-spacing: -0.01em;
    color: ${COLORS.White};
    margin-bottom: 15px;
    margin-top: 10px;
    margin-left: 25px;
    text-align: center;
    max-width: 250px;
`

const EmptyText = styled.p`
  font-family: 'Roboto Condensed';
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 129.5%;
  display: flex;
  align-items: center;
  color: ${COLORS.HighlightLight};
`;






