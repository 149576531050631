import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import media from "styled-media-query"
import Image3 from "./../../images/ic_arrow_right_green.svg"
import ImgGreen from "./../../images/ic_status_green.svg"
import ImgPurple from "./../../images/ic_status_purple.svg"
import ImgRed from "./../../images/ic_status_red.svg"
import ImgArrowDown from "./../../images/ic_arrow_down_white.svg"
import ImgAlert from "./../../images/ic_alert.svg"
import ImgArrowRight from "./../../images/ic_arrow_right_blue.svg"
import ImgArrowRightWhite from "./../../images/ic_arrow_right_white.svg"
import Image from "./../../images/ic_settings_blue.svg"
import CustomSelect from "./WingnutConfigSensorSelect"
import Checkmark from "./../../images/ic_success.svg"
import { useMediaQuery } from 'react-responsive'
import { COLORS } from "../../styles/colors"

const Container = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  margin-right: 40px;
  border-top: 1px ${COLORS.HighlightDark} solid;
  ${media.lessThan("743px")`
        justify-content: flex-start;
        padding: 10px;
        margin-right: 0px;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const HeaderContainer = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${media.lessThan("743px")`
    justify-content: flex-start;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
  cursor: pointer;
`

const TabContainer = styled.div`
  /* border: 1px red dashed; */
  width: 100%;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  padding: 5px;
  ${media.lessThan("743px")`
    justify-content: flex-start;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const StatusIcon = styled.img`
  width: 11px;
  height: 11px;
  margin-right: 15px;
  align-self: center;
`

const CardTextContainer = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  flex-direction: row;
  margin-right: 64px;
  ${media.lessThan("743px")`
    margin-right: 14px;
    `}
`

const HeaderText = styled.h3`
  /* border: 1px green dashed; */
  font-family: Blinker;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 110%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
  ${media.lessThan("743px")`
    font-size: 18px;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const HeaderNumber = styled(HeaderText)`
  width: 30px;   
`

const ArrowIcon = styled.img`
  width: 20px;
  height: auto;
`

const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const AlertIcon = styled.img`
  width: 14px;
  /* height: 10px; */
  visibility: hidden;
  margin-right: 17px;
`

const ArrowIconRight = styled.img`
  width: auto;
  height: 20px;
  align-self: center;
`

const ArrowIconRightWhite = styled.img`
  width: auto;
  height: 16px;
  align-self: center;
`

const SubHeaderContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: row;
  cursor: pointer;
`

const WingnutIcon = styled.img`
  width: 16px;
  height: 16px;
  align-self: center;
  margin-right: 10px;
  /* margin-bottom: 20px; */
`

const InfoText = styled.h2`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  /* line-height: 130%; */
  display: flex;
  align-items: center;
  color: ${COLORS.HighlightLight};
`

const BodyContainer = styled.div`
  /* border: 1px yellow dashed; */
`

const ConfirmContainer = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  flex-direction: row;
  `

const InputContainer = styled.div`
  /* border: 1px blue dashed; */
  display: flex;
  width: 95%;
  flex-direction: row;
  justify-content: space-between;
  ${media.lessThan("743px")`
        flex-direction: column;
        width: 100%;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const Input = styled.input`
  /* border: 1px blue dashed; */
  width: 68%;
  height: 45px;
  outline: none;
  border: 1px solid ${COLORS.HighlightMedium};
  color: ${COLORS.White};
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 129.5%;
  font-weight: normal;
  border-radius: 8px;
  box-sizing: border-box;
  padding-left: 10px;
  background: ${COLORS.Secondary};
  /* margin-right: 20px; */
  margin-top: 15px;
  ::placeholder {
    color: ${COLORS.HighlightMedium};
  }
  :focus {
    outline: ${COLORS.White};
    border: 2px solid ${COLORS.White};
  }
  :hover {
    border: 1px solid ${COLORS.HighlightMedium};
  }
  ${media.lessThan("743px")`
        height: 55px;
        width: 100%;
    `}
  ${media.between("744px", "large")`
        height: 55px;
    `}
    ${media.greaterThan("large")`
    `}
`

const InputNumber = styled(Input)`
  width: 30%;
  ${media.lessThan("743px")`
        height: 55px;
        width: 100%;
    `}
`

const InputLabel = styled(Input)`
  width: 95%;
  margin-right: 0px;
`

const BtnConfirm = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 40px;
  border: 1px solid #63c085;
  border-radius: 30px;
  color: black;
  background: transparent;
  cursor: pointer;
  text-align: center;
  margin-top: 22px;
  :hover {
    background: ${COLORS.Secondary};
  }
  ${media.lessThan("743px")`
    margin-bottom: 5px;
    `}
  ${media.between("744px", "large")`
    margin-bottom: 50px;
    `}
    ${media.greaterThan("large")`
    `}
`

const ButtonText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transition-duration: 0.5s;
  color: ${COLORS.White};
  :hover {
  }
`

export default function WingnutSensorConfigFormCell(props) {
  const {id, icon, schema, wingnutDetails} = props

  const [isCellHovering, setIsCellHovering] = useState(false)
  const [isButtonHovering, setIsButtonHovering] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [configuredSensor, setConfiguredSensor] = useState()
  // const [showSuccess, setShowSuccess] = useState(false)
  const [clearAll, setClearAll] = useState(null)
  const [disableClear, setDisableClear] = useState(true)
  const [localAlert, setLocalAlert] = useState(false)

  const [selectedType, setSelectedType] = useState()
  const [selectedLabel, setSelectedLabel] = useState()
  const [selectedNumber, setSelectedNumber] = useState()
  const [numberOptions, setNumberOptions] = useState([
    {
      label: "1",
      value: "1",
    },
    {
      label: "2",
      value: "2",
    },
    {
      label: "3",
      value: "3",
    },
    {
      label: "4",
      value: "4",
    },
    {
      label: "5",
      value: "5",
    },
  ])

  const isDesktopSize = useMediaQuery({ query: '(min-width: 744px)' })

  const confirmSensorChange = () => {

      var sensor = {
        key: buildKey(),
        label: `${selectedLabel.value}${selectedNumber.value}`,
        type: selectedType.value
      }
      props.removeAlert(props.id)
      setConfiguredSensor(sensor)
      props.updateSensor(sensor)

  }

  const clearAllInputs = () => {
    setLocalAlert(false)
    props.removeAlert(props.id)
    setClearAll(!clearAll)
    setDisableClear(true)
    setConfiguredSensor(null)
    setSelectedLabel(null)
    setSelectedLabel(null)
    setSelectedType(null)

    setTimeout(() => {
      var sensor = {
        key: buildKey(),
        label: null,
        type: null
      }
      props.updateSensor(sensor)
      }, 500)

  }

  const buildKey = () => {
    const keyData = id.replace(/(\d+)/g, " $1").split(" ")
    const leading = keyData[0].toUpperCase()
    const keyNumber = parseInt(keyData[1])
    if (leading === 'A') {
      return leading + "N" + keyNumber
    } else {
      return leading + "I" + keyNumber
    }
  }

  useEffect(() => {
    const key = buildKey()
    if (wingnutDetails.config.sensors[key]) {
      setConfiguredSensor(wingnutDetails.config.sensors[key])
    }
  }, [])

  useEffect(() => {
    if (selectedType && selectedLabel && selectedNumber) {
      setLocalAlert(false)
      confirmSensorChange()
    } 
  }, [selectedType, selectedLabel, selectedNumber])

  useEffect(() => {
    const alert = props.checkAlert(props.id)
    setLocalAlert(alert)

    if (alert){
      setIsOpen(true)
    }
  }, [props.alertSignal])

  const getSelectedType = () => {
    var selected = []
    if (configuredSensor) {
      selected = schema.types.filter(type => type.value === configuredSensor.type)
    } else {
      if(selectedType != null){
        selected = [selectedType]
      }
    }
    return selected
  }

  const getSelectedLabel = () => {
    var selected = []
    if (configuredSensor) {
      selected = schema.labels.filter(label => label.value === configuredSensor.label.slice(0, -1))
    } else {
      if(selectedLabel != null){
        selected = [selectedLabel]
      }
    }
    return selected
  }

  const getSelectedNumber = () => {
    var selected = []
    if (configuredSensor) {
      const number = configuredSensor.label.charAt(configuredSensor.label.length - 1);
      selected = [{
        label: number,
        value: number
      }]
    } else {
      if(selectedNumber != null){
        selected = [selectedNumber]
      }
    }
    return selected
  }

  const loadSelectedType = (item) => {
    props.addAlert(props.id)
    props.unsavedChanges(true)
    setDisableClear(false)
    setSelectedType(item)
  } 

  const loadSelectedNumber = (item) => {
    props.addAlert(props.id)
    props.unsavedChanges(true)
    setDisableClear(false)
    setSelectedNumber(item)
  } 

  const loadSelectedLabel = (item) => {
    props.addAlert(props.id)
    props.unsavedChanges(true)
    setDisableClear(false)
    setSelectedLabel(item)
 
  } 

  const buildConfigurationTitle = () => {
    var title = ""
    if (configuredSensor) {
      title = `${getSelectedLabel()[0]?.label} ${getSelectedNumber()[0]?.label} - ${getSelectedType()[0]?.label}`
    }
    return title
  }

  useEffect(() => {
    const fullList = ['1', '2', '3', '4', '5']
    const usedNumbers = []
    
    if (selectedLabel) {
      Object.keys(wingnutDetails.config.sensors).forEach(key => {
        const label = wingnutDetails.config.sensors[key].label
        const number = label.charAt(label.length - 1);
        const sl = label.split(number)[0]
        if (selectedLabel.value === sl) {
          usedNumbers.push(number)
        }
      })

      const difference = fullList.filter(x => usedNumbers.indexOf(x) === -1);
      const options = difference.map(num => {
        return {label: num, value: num}
      })
      setNumberOptions(options)
    }
  }, [selectedLabel])

  return (
    <Container>
      <HeaderContainer 
        style={{cursor: props.isAuth ? 'pointer' : 'default'}}
        onClick={() => props.isAuth ? isOpen ? null : setIsOpen(true) : null}
        onMouseEnter={(e) => {
          setIsCellHovering(true)
        }}
        onMouseLeave={(e) => {
          setIsCellHovering(false)
        }}
      >
        <TabContainer onClick={() => setIsOpen(false)}>
          <CardTextContainer>
            <StatusIcon src={icon} />
            <HeaderNumber>{id}</HeaderNumber>
          </CardTextContainer>
          <CardTextContainer>

            {configuredSensor ?
                <HeaderText>{buildConfigurationTitle()}</HeaderText>
              :
                isOpen ? 
                  <HeaderText style={{cursor: props.isAuth ? 'pointer' : 'default'}}>Configure this sensor</HeaderText>
                  :
                  <SubHeaderContainer>
                    <WingnutIcon src={Image} />
                    <InfoText style={{color: isCellHovering && props.isAuth ? COLORS.White : null, cursor: props.isAuth ? 'pointer' : 'default'}}>Configure this sensor</InfoText>
                  </SubHeaderContainer>
             }
          </CardTextContainer>
        </TabContainer>

        {isOpen ?
          <IconContainer  style={{display: props.isAuth ? "flex" : "none"}} >
            <AlertIcon src={ImgAlert} style={{ marginRight: 9, visibility: localAlert ? "visible" : null }}/>
            <ArrowIcon src={ImgArrowDown} onClick={() => setIsOpen(false)}/>
          </IconContainer>
        : 
           <IconContainer style={{display: props.isAuth ? "flex" : "none"}} >
            <AlertIcon src={ImgAlert} style={{ visibility: localAlert ? "visible" : null }}/>
            <ArrowIconRight src={ isCellHovering ? ImgArrowRightWhite : ImgArrowRight}/>  
           </IconContainer>

        }
        
      </HeaderContainer>


      {isOpen ? 
        <BodyContainer>
          <InputContainer>
            <CustomSelect localAlert={localAlert} clearInput={clearAll} data={schema.types} selectedValue={getSelectedType()} onSelectedValue={(item) => {loadSelectedType(item)}} placeholder="Sensor Type" width={isDesktopSize ?  "68%" : "100"}/>
            <CustomSelect localAlert={localAlert} clearInput={clearAll} data={numberOptions} selectedValue={getSelectedNumber()} onSelectedValue={(item) => loadSelectedNumber(item)} placeholder="Sensor Number" width={isDesktopSize ?  "30%" : "100"}/>
          </InputContainer>
          <CustomSelect localAlert={localAlert} clearInput={clearAll} data={schema.labels} selectedValue={getSelectedLabel()} onSelectedValue={(item) => loadSelectedLabel(item)} placeholder="Sensor Label" width={isDesktopSize ?  "95%" : "100"}/>
          <ConfirmContainer style={{flexDirection: isDesktopSize ? null : 'column'}}>
              <BtnConfirm disabled={disableClear} onClick={() => clearAllInputs()} style={{backgroundColor: disableClear ? '#B6B6B6' : null, border: disableClear ? '#B6B6B6' : null}}>
                <ButtonText style={{color: disableClear ? COLORS.Base : null, opacity: disableClear ? '0.5' : null }}>
                  Clear Input
                </ButtonText>
              </BtnConfirm>
          </ConfirmContainer>
        </BodyContainer>
        : 
          null
      }
    </Container>
  )
}
