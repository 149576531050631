import React, { Component } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import { COLORS } from "../../styles/colors"
import AccountDetailsForm from "../form/AccountDetailsForm"
import HelpForm from "../form/HelpForm"

const Container = styled.div`
  /* border: 1px blue dashed; */
  display: flex;
  flex-direction: column;
  max-width: 1047px;
  max-height: 662px;
  height: 100%;
  background-color: #0e1c36;
  border-radius: 20px;
  padding: 34px;
  margin-top: 30px;
  ${media.lessThan("743px")`
        margin-right: 0px;
        width: 90%;
        height: 100%;
        align-self: center;
        margin-bottom: 40px;
    `}
  ${media.between("744px", "large")`
        width: 90%;
    `}
`

const HeaderText = styled.h2`
  font-family: Blinker;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 84.5%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
`

const DateText = styled.h2`
font-family: Blinker;
font-style: normal;
font-weight: normal;
font-size: 22px;
line-height: 110%;
letter-spacing: -0.01em;
color: ${COLORS.White};
`

export default class Help extends Component {
  render() {
    return (
      <Container>
        <DateText>Questions or Concerns? Send us a message.</DateText>
        <HelpForm />
      </Container>
    )
  }
}
