import React from 'react'
import Icon from "./../../images/ic_alert.svg";
import styled from "styled-components"
import media from "styled-media-query"
import { Error } from "./../custom/ValidatorStyles"

const IconAlertSm = styled.img`
  align-self: center;
  width: 11px;
  height: 11px;
  margin-bottom: 4px;
  margin-right: 6px;
  margin-top: 0px;
  ${media.lessThan("743px")`
    display: flex;
    `}
`;

const ErrorContainer = styled.div`
    display: flex;
    flex-direction: row;
    ${media.lessThan("743px")`
        min-width: 250px;
    `}
`;

export default function SensorCellAlertError(props) {

  return (
        <ErrorContainer>
            <IconAlertSm src={Icon} />
             <Error>{`Last measured value was ${props.alertValue ? Number(props.alertValue).toFixed(1) : 0} ${props.sensor.unit}`}</Error> 
        </ErrorContainer>
  )
}
