import React, { useEffect, useState, useContext, useRef } from "react"
import { API, Auth } from "aws-amplify"
import styled from "styled-components"
import { Link } from "react-router-dom"
import media from "styled-media-query"
import IconNotify from "./../../images/ic_notification_active.svg"
import ImgArrowRightWhite from "./../../images/ic_arrow_right_white.svg"
import ImgArrowRight from "./../../images/ic_arrow_right_blue.svg"
import ImgArrowDown from "./../../images/ic_arrow_down_white.svg"
import NotificationDetails from "./NotificationDetails"
import { useMediaQuery } from 'react-responsive'
import { AccountContext } from "../../auth/Account"
import { COLORS } from "../../styles/colors"
import  NotificationDefaults  from './NotificationDefaults'
import Alerts from "./../properties/Alerts"
import NotificationSaveDiscard from "./NotificationSaveDiscard"


export default function NotificationThresholds(props) {

    const { getSession } = useContext(AccountContext)
    const [isAuth, setIsAuth ] = useState(false)

    const [isEditNotificationHovering, setIsEditNotificationHovering] = useState(false)

    const [isHovering, setIsHovering] = useState(false)

    const [thresholds, setThresholds] = useState()
    const [activeThresholds, setActiveThresholds] = useState(0)
    const [sensorDefaults, setSensorDefaults] = useState([])
    
    const [activeChange, setActiveChange] = useState(false)
    const [refreshKey, setRefreshKey] = useState(0)

    const [saveAll, setSaveAll] = useState(false)
    const [saveAllPopup, setSaveAllPopup] = useState(false)
    const [discardAllPopup, setDiscardAllPopup] = useState(false)
    const [saveOrAbandonPopup, setSaveOrAbandonPopup] = useState(false)

      // ----------------------------------------------------
      const myRef = useRef();

      const handleClickOutside = e => {
        if(activeChange == true && saveAllPopup == false && discardAllPopup == false){
            if (!myRef.current.contains(e.target)) {
              setSaveOrAbandonPopup(true)
            }
          };
        }
  
        useEffect(() => {
          document.addEventListener('mousedown', handleClickOutside);
          return () => document.removeEventListener('mousedown', handleClickOutside);
        });
    // ----------------------------------------------------

    useEffect(() => {
        getSession().then(({ isAuth }) => {
            setIsAuth(isAuth)
        })
    }, [])

    const isActiveChange = (val) => {
        setActiveChange(val)
    }

    const willRefreshData = () => {
        setRefreshKey(refreshKey + 1)
        isActiveChange(false)
        setSaveAll(false)
        setSaveAllPopup(false)
        setDiscardAllPopup(false)
        setSaveOrAbandonPopup(false)
        props.willRefreshAlerts()
    }

    const didPushSaveData = () => {
        isActiveChange(false)
        setSaveAll(true)
        setSaveAllPopup(false)
        setDiscardAllPopup(false)        
    }

    const resetPopupState = () => {
        setSaveAll(false)
        setSaveAllPopup(false)
        setDiscardAllPopup(false)
        setSaveOrAbandonPopup(false)
    }

    const triggerSaveAllPopup = () => {
        setSaveAllPopup(true)
    } 

    const triggerDiscardAllPopup = () => {
        setDiscardAllPopup(true)
    }
    
    // Use propertyDetails to get the default values for the thresholds
     useEffect(() => {
        var fetchedSensorDefaults = []
           if(props.propertyDetails){
               props.propertyDetails.linkedWingnuts.map(wingnut => {
 
                 Object.keys(wingnut.config.sensors).map((key, sensorIndex) => {
                   var sensor = wingnut.config.sensors[key]
 
                   var sensorDefault  = NotificationDefaults.find((notificationDefault) => {
                     return notificationDefault.key === sensor.label.replace(/\d+/g, '')
                 }) 
 
                 // If default found and it's not a digital sensor
                 if(sensorDefault && key.substring(0,1) === "A"){
                   sensorDefault.id = sensorIndex
                   sensorDefault.label = sensor.label
                   fetchedSensorDefaults.push({...sensorDefault}) // pushed in as reference because array was overwriting values
                 } 
              })
             })
              setSensorDefaults(fetchedSensorDefaults)
           }
         }, [props.propertyDetails])
 
         // Use notificationDetails to get the users set thresholds
         useEffect(() => {
             if(props.notificationDetails){
                     if( props.notificationDetails[0] && props.notificationDetails[0].config){
                         setThresholds(props.notificationDetails[0].config.thresholds)
                   } 
               }
         }, [props.notificationDetails])
 
        // Use thresholds to get active thresholds
        useEffect(() => {
            if(thresholds){
                var active = 0
                Object.values(thresholds).map((value, sensorIndex) => {
                        if(value.thresholdStatus === 'enabled'){
                            active = active + 1
                        }
                    })
                    setActiveThresholds(active)
                }
        }, [thresholds])
 
         // Use the Notification thresholds to update the configured sensorDefaults
     useEffect(() => {
         if(thresholds){
           var thresholdKeys = []
 
           // Gather the threshold keys into an array
               Object.keys(thresholds).map ((thresholdKey, index) => { 
                 thresholdKeys.push(thresholdKey)
                })
 
                // Map through the configured SensorDefaults
                      var newState = sensorDefaults.map(obj => {
 
                       // If a default is found in the thresholds array, update that default
                               if(obj.label && thresholdKeys.includes(obj.label)){          
                                 return{...obj,
                                   userMinValue: parseInt(thresholds[obj.label].min, 10),
                                   userMaxValue: parseInt(thresholds[obj.label].max, 10),
                                   thresholdStatus: thresholds[obj.label].thresholdStatus === "enabled" ? 1 : 0
                                 }
                               }                    
                                 return obj                  
                         })                        
                     setSensorDefaults(newState)
                     props.updateCurrentThresholds(thresholds)
                    //  setDisabled(false)
          
            }
       }, [thresholds]) // refresh here if needed


       const shouldToggleMenu = () => {
        if(activeChange == false){
          props.toggleNotificationDetails(props.isEditNotificationOpen, "editThresholds") 
        }
       }

  return (
    <ConfigParent ref={myRef}>
        {/*----LABEL NAME------ */}
          <EditDetailsContainer style={{pointerEvents: props.disable? "none" : "auto", opacity: props.disable ? "0.4" : "1.0"}}>
            <ConfigContainer
                    // onClick={toggleDetails : null} 
                    onClick={shouldToggleMenu} 
                    style={{ cursor: activeChange === false ? 'pointer' : 'default', background: props.isEditNotificationOpen ? null : isHovering ? COLORS.Secondary : null}}
                    onMouseEnter={(e) => {setIsHovering(true)}}
                    onMouseLeave={(e) => {setIsHovering(false)}}>   
                <HeaderText>
                  {
                    isAuth ? "Edit Alarm Thresholds" : "Alarm Thresholds"
                  }
                  </HeaderText>
                <RightContainer>

                {/*----SAVE DESKTOP----*/}
                <NotificationSaveDiscard 
                    saveOrAbandonPopup={saveOrAbandonPopup}
                    saveAll={saveAll}
                    discardAllPopup={discardAllPopup}
                    didPushSaveData={didPushSaveData}
                    saveAllPopup={saveAllPopup}
                    resetPopupState={resetPopupState}
                    triggerSaveAllPopup={triggerSaveAllPopup}
                    triggerDiscardAllPopup={triggerDiscardAllPopup}
                    willRefreshData={willRefreshData}
                    activeChange={activeChange} 
                    sensorDefaults={sensorDefaults} 
                    notificationDetails={props.notificationDetails}/> 

                {/*----NOTIFY ICON----*/}
                {/* { activeThresholds !== 0 
                ?
                <NotifyContainer>
                <NotifyIcon src={IconNotify}/>
                <NotifyTag>
                  <NotifyText>{activeThresholds}</NotifyText>
                </NotifyTag>
              </NotifyContainer>
                :
                  null
                } */}


                {/*----ARROW ICON----*/}
                  <ArrowIcon 
                      onMouseEnter={(e) => {
                        setIsEditNotificationHovering(true)
                      }}
                      onMouseLeave={(e) => {
                        setIsEditNotificationHovering(false)
                      }}
                      onClick={() => {props.toggleNotificationDetails(props.isEditNotificationOpen, "editThresholds")}} 
                      src={props.isEditNotificationOpen? ImgArrowDown : ImgArrowRightWhite}  
                      style={{ cursor: isEditNotificationHovering ? 'pointer' : null}}/>
                </RightContainer>
            {/* </EditNotifyHeader> */}
            </ConfigContainer>
          </EditDetailsContainer> 

            {/*----SAVE MOBILE----*/}
            <SaveConfigContainerMobile style={{display: activeChange ? 'flex' : 'none'}}>
                <SaveConfigMobile onClick={triggerSaveAllPopup}>
                    <BtnTextMobile>SAVE CONFIGURATION</BtnTextMobile>
                </SaveConfigMobile>
                <ButtonText2Mobile onClick={triggerDiscardAllPopup}>DISCARD CHANGES</ButtonText2Mobile>
            </SaveConfigContainerMobile>

            {props.isEditNotificationOpen && (
                <NotificationDetails 
                    {...props}
                    key={refreshKey}
                    refreshKey={refreshKey}
                    saveAll={saveAll}
                    sensorDefaults={sensorDefaults} 
                    thresholds={thresholds} 
                    willRefreshData={willRefreshData}
                    isActiveChange={isActiveChange}/>
                )}
         </ConfigParent>
      )
    }


const HeaderText = styled.h1`
  font-family: Blinker;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 110%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
`

const EditDetailsContainer = styled.div`
  /* border: 1px blue dashed; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* padding: 20px; */
  height: 69px;
  border-radius: 10px;
  margin-top: 15px;
  margin-right: 20px;
  background-color: #0e1c36;
  ${media.lessThan("743px")`
    margin-right: 0px;
  `}
`

const ConfigParent = styled.div`
  /* border: 1px green dashed; */
`;

const ConfigContainer = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  z-index: 0;
`;

const RightContainer = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: row;
  /* height: 100px; */
`;

const ArrowIcon = styled.img`
  width: 24px;
  height: 24px;
  align-self: center;
`

const SaveConfigContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  ${media.lessThan("743px")`
    display: none;
    `}
  ${media.between("744px", "large")`
    display: none;
  `}
  ${media.greaterThan("large")`
    `}
`;

const SaveConfigContainerMobile = styled(SaveConfigContainer)`
  /* border: 1px red dashed; */
  background: #0e1c36;
  height: 60px;
  margin-top: -10px;
  padding-left: 20px;
  ${media.lessThan("743px")`
    display: flex;
    flex-wrap: wrap;
    min-height: 120px;
    padding-top: 20px;
    width: 100%;
    `}
  ${media.between("744px", "large")`
    display: flex;
  `}
  ${media.greaterThan("large")`
    display: none;
      height: 10px;
    `}
`;

const SaveConfig = styled.button`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 40px;
  gap: 9px;
  width: 241px;
  height: 40px;
  border: 1px solid #63C085;
  border-radius: 30px;
  background: none;
  align-self: center;
  transition-duration: 0.5s;
  :hover {
    opacity: 0.8; 
  }
  ${media.lessThan("743px")`
        display: none;
    `}
  ${media.between("744px", "large")`
      display: none;
    `}
    ${media.greaterThan("large")`
    `}
`

const SaveConfigMobile = styled(SaveConfig)`
      margin-right: 20px;
    ${media.lessThan("743px")`
      display: flex;
    `}
    ${media.between("744px", "large")`
      display: flex;

    `}
    ${media.greaterThan("large")`
      display: none;
    `}
`;

const BtnText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transition-duration: 0.5s;
  color: ${COLORS.White};
  ${media.lessThan("743px")`
      display: none;
    `}
    ${media.between("744px", "large")`
      display: none;
    `}
    ${media.greaterThan("large")`
      display: flex;
    `}
`

const BtnTextMobile = styled(BtnText)`
      ${media.lessThan("743px")`
      display: flex;
    `}
    ${media.between("744px", "large")`
      display: flex;
    `}
    ${media.greaterThan("large")`
      display: none;
    `}
`;

const ButtonText2 = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: ${COLORS.White};
  text-align: center;
  align-self: center;
  margin-right: 15px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transition-duration: 0.5s;
  cursor: pointer;
  :hover {
    color: #63C085; 
  }
    ${media.lessThan("743px")`
        display: none;
    `}
  ${media.between("744px", "large")`
        display: none;
    `}
    ${media.greaterThan("large")`
        display: flex;
    `}
  `;

  const ButtonText2Mobile = styled(ButtonText2)`
    /* margin-left: 20px; */
  ${media.lessThan("743px")`
    display: flex;
  `}
  ${media.between("744px", "large")`
    display: flex;
  `}
  ${media.greaterThan("large")`
    display: none;
  `}
  `;

