import React from "react"
import styled from "styled-components"
import media from "styled-media-query"
import MediaQuery from "react-responsive"
import MakeData from "./MakeData"
import WingnutTable from "./WingnutTable"
import IconLaunch from "./../../images/ic_launch_green.svg"

import IconActive from "./../../images/ic_status_green.svg"
import IconActiveDull from "./../../images/ic_status_green_dull.svg"
import IconDisconnected from "./../../images/ic_status_red.svg"
import IconDisconnectedDull from "./../../images/ic_status_red_dull.svg"
import IconUnlinked from "./../../images/ic_status_purple.svg"
import IconUnlinkedDull from "./../../images/ic_status_purple_dull.svg"
import IconUnconfigured from "./../../images/ic_status_yellow.svg"
import IconUnconfiguredDull from "./../../images/ic_status_yellow_dull.svg"

import IconSort from "./../../images/ic_arrows_sort.svg"
import { useHistory } from "react-router-dom"
import { Link } from "react-router-dom"
import ScreenSize from "./ScreenSize.enum"
import { COLORS } from "../../styles/colors"



// Handle the highlevel table construction
function WingnutTableStructure(props) {
  const history = useHistory()

  const handleSerialClick = (e, row) => {
    // history.push('/portal/account')
  }

  const handlePropertyNameClick = (e, row) => {
  }

  const handlePropertyIdClick = (e, row) => {
  }

  const handleSortClick = (e, row) => {
  }

  //----------------------------------------Custom Sort Functions
  const basicSort = React.useMemo(() => {
    return (rowA, rowB, columnId, desc) => {
        const a = rowA.original[columnId]
        const b = rowB.original[columnId]
        if(a > b) return 1
        if (b > a) return -1
        return 0
    }
}, [])

const addressSort = React.useMemo(() => {
  return (rowA, rowB, columnId, desc) => {
    const a = rowA.original[columnId] ? rowA.original[columnId].streetAddress : "0"
    const b = rowB.original[columnId] ? rowB.original[columnId].streetAddress : "0"
    if (a > b) return 1
    if (b > a) return -1
      return 0
  }
}, [])

  const statusSort = React.useMemo(() => {
    return (rowA, rowB, columnId, desc) => {

      if (rowA.original[columnId] == undefined || rowB.original[columnId] == undefined) {
        return 0; // Return 0 to indicate no change in order
      }
  
      const a = rowA.original[columnId];
      const b = rowB.original[columnId];
      const aId = rowA.original.uuid
      const bId = rowB.original.uuid

      // Custom sorting logic based on the 'status' object
      if (a.disconnected !== b.disconnected) {
        return a.disconnected ? -1 : 1;
      }
      if (a.live !== b.live) {
        return a.live ? -1 : 1;
      }
      if (a.unconfigured !== b.unconfigured) {
        return a.unconfigured ? -1 : 1;
      }
      if (a.unlinked !== b.unlinked) {
        return a.unlinked ? -1 : 1;
      }

      if (aId > bId) return -1
      if (bId > aId) return 1

      return 0;
    }
  }, [])

  // Pickup and drop columns here based on screen size?
  const tableColumns = React.useMemo(
    () => [
      {
        Header: (
          <HeaderDiv onClick={(e, row) => handleSortClick(e, row)}>
            <Text>Serial</Text>
            <BtnIcon>
              <SortIcon src={IconSort} />
            </BtnIcon>
          </HeaderDiv>
        ),
        accessor: "serial",
        sortType: basicSort,
        // maxWidth: 101,
        // minWidth: 99,
        width: '10%',
        Cell: (props) => {
          return (
            <Link to={`/portal/wingnut/detail/${props.uuid}`} style={{ textDecoration: "none", color: COLORS.White }}>
              <CellDiv>
                <ContentDiv>
                <BtnIcon onClick={(e, row) => handleSerialClick(e, row)}>
                  <Icon src={IconLaunch} />
                </BtnIcon>
                <Text>{props.serial}</Text>
                </ContentDiv>
              </CellDiv>
            </Link>
          )
        },
      },
      {
        Header: (
          <HeaderDiv onClick={(e, row) => handleSortClick(e, row)}>
            <Text>UUID</Text>
            <BtnIcon>
              <SortIcon src={IconSort} />
            </BtnIcon>
          </HeaderDiv>
        ),
        accessor: "uuid",
        sortType: basicSort,
        // maxWidth: 301,
        // minWidth: 299,
        width: '25%',
        Cell: (props) => {
          return(
            <CellDiv>
               <StaticText>{props.uuid}</StaticText>
            </CellDiv>
          )
        },
      },
      {
        Header: (
          <HeaderDiv onClick={(e, row) => handleSortClick(e, row)}>
            <Text>Property Name</Text>
            <BtnIcon>
              <SortIcon src={IconSort} />
            </BtnIcon>
          </HeaderDiv>
        ),
        accessor: "propertyName",
        sortType: basicSort,
        // maxWidth: 301,
        // minWidth: 299,
        width: '20%',
        Cell: (props) => {
          return (props.propertyUUID ? (
            <Link to={`/portal/property/detail/${props.propertyUUID}`} style={{ textDecoration: "none", color: COLORS.White }}>
              <CellDiv>
                <ContentDiv>
                  <BtnIcon onClick={(e, row) => handlePropertyNameClick(e, row)}>
                    <Icon src={IconLaunch} />
                  </BtnIcon>
                  <Text>{props.propertyName}</Text>
                </ContentDiv>
              </CellDiv>
            </Link>
          ) : (<EmptyCellDiv></EmptyCellDiv>))
        },
      },
      {
        Header: (
          <HeaderDiv onClick={(e, row) => handleSortClick(e, row)}>
            <Text>Portfolio</Text>
            <BtnIcon>
              <SortIcon src={IconSort} />
            </BtnIcon>
          </HeaderDiv>
        ),
        accessor: "portfolio",
        sortType: basicSort,
        // maxWidth: 301,
        // minWidth: 299,
        width: '10%',
        Cell: (props) => {
          return (
            <CellDiv>
              <StaticText style={{visibility: props.address ? 'visibile' : 'hidden' }} >{props.portfolio ? props.portfolio : "-" }</StaticText>
            </CellDiv>
          )
        },
      },
      {
        Header: (
          <HeaderDiv onClick={(e, row) => handleSortClick(e, row)}>
            <Text>PropertyId</Text>
            <BtnIcon>
              <SortIcon src={IconSort} />
            </BtnIcon>
          </HeaderDiv>
        ),
        accessor: "propertyId",
        sortType: basicSort,
        // maxWidth: 301,
        // minWidth: 299,
        width: '5%',
        Cell: (props) => {
          return (
            props.propertyUUID ? (
              <Link to={`/portal/property/detail/${props.propertyUUID}`} style={{ textDecoration: "none", color: COLORS.White }}>
                <CellDiv>
                  <ContentDiv>
                    <BtnIcon onClick={(e, row) => handlePropertyIdClick(e, row)}>
                      <Icon src={IconLaunch} />
                    </BtnIcon>
                    <Text>{props.propertyId}</Text>
                  </ContentDiv>
                </CellDiv>
              </Link>
            ) : (<EmptyCellDiv></EmptyCellDiv>)
          )
        },
      },
      {
        Header: (
          <HeaderDiv onClick={(e, row) => handleSortClick(e, row)}>
            <Text>Address</Text>
            <BtnIcon>
              <SortIcon src={IconSort} />
            </BtnIcon>
          </HeaderDiv>
        ),
        accessor: "address",
        sortType: addressSort,
        // maxWidth: 301,
        // minWidth: 299,
        width: '30%',
        Cell: (props) => {
          return (
            <CellDiv>
                <StaticText style={{visibility: props.address ? 'visibile' : 'hidden' }} >{props.address ? `${props.address.streetAddress} ${props.address.city} ${props.address.provinceState} ${props.address.country} ${props.address.postalZip}` : "-" }</StaticText>
            </CellDiv>
          )
        },
      },
      {
        Header: (
          <HeaderDiv onClick={(e, row) => handleSortClick(e, row)}>
            <Text>Status</Text>
            <BtnIcon>
              <SortIcon src={IconSort} />
            </BtnIcon>
          </HeaderDiv>
        ),
        accessor: "status",
        sortType: statusSort,
        // maxWidth: 301,
        // minWidth: 299,
        width: '10%',
        Cell: (props) => {
          return (
              <CellStatusDiv>
                <StatusIcon src={props.status.live ? IconActive: IconActiveDull} />
                <StatusIcon src={props.status.disconnected ? IconDisconnected : IconDisconnectedDull} />
                <StatusIcon src={props.status.unconfigured ? IconUnconfigured : IconUnconfiguredDull} />
                <StatusIcon src={props.status.unlinked ? IconUnlinked : IconUnlinkedDull} />
              </CellStatusDiv>
          )
        },
      },
    ],
    []
  )

  const hiddenColumns = (screenSize) => {
    // serial, uuid, propertyName, portfolio, propertyId, address, status
    var columnsToHide = []
    if (screenSize === ScreenSize.Medium) {
      // propertyId, propertyName, portfolio, linkedWingnuts, status
      columnsToHide = ["address", "propertyId"]
    }
    if (screenSize === ScreenSize.Small) {
      // propertyId, propertyName, status
      columnsToHide = ["address", "portfolio", "uuid"]
    }
    return columnsToHide
  }

  return (
    <Container>
      <Styles>
        <MediaQuery minWidth={1171}>
          <WingnutTable
            columns={tableColumns}
            columnsToHide={hiddenColumns(ScreenSize.Large)}
            data={props.tableData}
            headerHeight={130}
            rowHeight={130}
          />
        </MediaQuery>

        <MediaQuery minWidth={744} maxWidth={1170}>
          <WingnutTable
            columns={tableColumns}
            columnsToHide={hiddenColumns(ScreenSize.Medium)}
            data={props.tableData}
            headerHeight={130}
            rowHeight={130}
          />
        </MediaQuery>

        <MediaQuery maxWidth={743}>
          <WingnutTable
            columns={tableColumns}
            columnsToHide={hiddenColumns(ScreenSize.Small)}
            data={props.tableData}
            headerHeight={130}
            rowHeight={130}
          />
        </MediaQuery>
      </Styles>
    </Container>
  )
}

export default WingnutTableStructure


const Container = styled.div`
  /* border: 1px solid red; */
  overflow: auto;
  height: 100%;
  width: 100%;
  background-color: ${COLORS.Base};
  margin-top: 0px;
  ${media.lessThan("743px")`
    align-self: center;
    margin-top: 0px;  
    height: 50%;
    padding-left: 16px;  
    padding-right: 16px;  
    `}
  ${media.between("744px", "large")`
      margin-top: 20px;
      width: 98%;
    `}
    ${media.greaterThan("large")`
    `}
`

const Styles = styled.div`
  color: ${COLORS.White};
  overflow: none;

  table {
    border-spacing: 0;

    thead {
      ${"" /* These styles are required for a scrollable body to align with the header properly */}
      overflow-y: auto;
      overflow-x: hidden;

      // Below create a fixed header
      position: sticky;
      top: 0;
      z-index: 2;
    }

    tbody {
      ${"" /* These styles are required for a scrollable table body */}
      overflow-y: scroll;
      overflow-x: hidden;
    }

    tr {
      :last-child {
        td {
          border-bottom: 1;
        }
      }
    }

    th,
    td {
      margin: 0;
      border-bottom: 1px solid ${COLORS.HighlightDark};
      :last-child {
        border-right: 0;
      }
    }
  }
`
const HeaderDiv = styled.div`
  /* border: 1px green dashed; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 5px;
  /* height: auto; */
  height: 45px;
`

const CellDiv = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  bottom: 1px;
  height: 45px;
`

const EmptyCellDiv = styled.div`
  /* border: 1px red dashed; */
`

const ContentDiv = styled.div`
    /* border: 1px yellow dashed; */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-self: center;
`;

const CellStatusDiv = styled.div`
  display: flex;
  /* border: 1px green dashed; */
`

const BtnIcon = styled.button`
  /* border: 1px green dashed; */
  background: transparent;
  /* outline: none; */
  border: none;
`

const Icon = styled.img`
  /* border: 1px red dashed; */
  width: 10px;
  height: 10px;
  position: relative;
  margin-right: 4px;

  /* align-self: center; */
  top: 1px;
`

const StatusIcon = styled.img`
  /* border: 1px red dashed; */
  width: 10px;
  height: 10px;
  /* position: relative; */
  margin-right: 6px;

  /* align-self: center; */
  top: 1px;
`

const SortIcon = styled.img`
  /* border: 1px red dashed; */
  width: 18px;
  height: 18px;
  position: relative;
  /* align-self: center; */
  top: 1px;
  margin-right: 10px;
`

const Text = styled.h5`
  /* border: 1px green dashed; */
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 120%;
  display: flex;
  align-self: center;
  /* align-items: center; */
  letter-spacing: -0.01em;
  color: ${COLORS.White};
`


const StaticText = styled(Text)`
  /* border: 1px green dashed; */
  padding-left: 5px;
  color: ${COLORS.HighlightLight};
  align-self: center;
`