import React, { useState, useContext, useEffect } from "react"
import { AccountContext } from "./../../auth/Account"
import { useForm } from "react-hook-form"
import styled from "styled-components"
import media from "styled-media-query"
import Image from "./../../images/ic_alert.svg"
import Image2 from "./../../images/eye.svg"
import Image3 from "./../../images/ic_success.svg"
import ImageBlueEye from "./../../images/eye_blue.svg"
import { ButtonSpinner } from "./../custom/Spinner"
import { ValidatorStrings, ServerStrings } from "./../util/VaildatorStrings"
import {
  ErrorContainer,
  Icon,
  Error,
  EmptyError,
  SuccessContainer,
  SuccessIcon,
  SuccessIcon2,
  Success,
  EmptySuccess,
} from "./../custom/ValidatorStyles"
import { COLORS } from "../../styles/colors"

const Container = styled.div`
  /* border: 1px yellow dashed; */
  display: flex;
  flex-direction: column;
  align-content: center;
  width: auto;
  margin-bottom: 30px;
  ${media.lessThan("743px")`
      align-self: center;
      width: 100%;

    `}
`

const InputContainer = styled.div`
  /* border: 1px red dashed; */
  display: flex;
  flex-direction: row;
  width: 100%;
`

const EyeIcon = styled.img`
  /* border: 1px red dashed; */
  align-self: center;
  transition-duration: 0.5s;
  color: ${COLORS.White};
  margin-left: -50px;
  /* margin-top: 46px;
    margin-bottom: 8px; */
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const Form = styled.form`
  /* border: 1px blue dashed; */
  /* width: 500px; */
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  ${media.lessThan("743px")`
  align-self: center;
  width: 100%;
  flex-direction: column;
  margin-top: 30px;
  `}
  ${media.between("744px", "large")`
  margin-top: 49px;
        /* width: 384px; */
    `}
`

const HeaderText = styled.h1`
  font-family: Blinker;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 84.5%;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
  margin-bottom: 20px;
  ${media.lessThan("743px")`
        /* align-self: center; */
    `}
`

const Label = styled.label`
  /* border: 1px blue dashed; */
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 129.5%;
  letter-spacing: 0.03em;
  color: ${COLORS.HighlightLight};
  margin-bottom: 2px;
  /* margin-top: 6px; */
`

const Input = styled.input`
  /* border: 1px blue dashed; */
  width: 100%;
  max-width: 378px;
  height: 45px;
  outline: none;
  border: none;
  color: ${COLORS.White};
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 129.5%;
  font-weight: 400;
  background-color: ${COLORS.Secondary};
  border-radius: 8px;
  box-sizing: border-box;
  padding-left: 10px;
  ::placeholder {
    color: ${COLORS.HighlightMedium};
  }
  :hover {
    border: 1px solid ${COLORS.HighlightMedium};
    padding-left: 9px;
  }
  :focus {
    outline: ${COLORS.White};
    border: 1px solid ${COLORS.HighlightLight};
    padding-left: 9px;
  }
  ${media.lessThan("743px")`
        width: 100%;
        height: 55px;
    `}
  ${media.between("744px", "large")`
        width: 100%;
        height: 55px;
    `}
    ${media.greaterThan("large")`
    `}
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  ${media.lessThan("743px")`
    `}
`

const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 133px;
  height: 40px;
  border: 1px solid #63c085;
  border-radius: 30px;
  background: transparent;
  color: ${COLORS.White};
  cursor: pointer;
  text-align: center;
  transition-duration: 0.5s;
  :hover {
    background: ${COLORS.Secondary};
  }
  ${media.lessThan("743px")`
        align-self: flex-start;
        margin-bottom: 18px;
        height: 55px;
    `}
  ${media.between("744px", "large")`
        height: 55px;
    `}
    ${media.greaterThan("large")`
    `}
`

const ButtonText = styled.p`
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${COLORS.White};
  :hover {
  }
  ${media.lessThan("743px")`
        font-size: 15px;
    `}
  ${media.between("744px", "large")`
        font-size: 15px;
    `}
    ${media.greaterThan("large")`
    `}
`

const IconArrow = styled.img`
  width: 6;
  height: 10px;
  /* margin-left: 20px; */
  align-self: center;
  transition-duration: 0.5s;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

export default function ChangePassword() {
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    formState: { errors, isSubmitSuccessful },
  } = useForm({ reValidateMode: "onSubmit" })

  const { getSession } = useContext(AccountContext)
  const [showSpinner, setShowSpinner] = useState(false)
  const [currentPasswordShown, setCurrentPasswordShown] = useState(false)
  const [newPasswordShown, setNewPasswordShown] = useState(false)
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [validatorError, setValidatorError] = useState(null)
  const [success, setSuccess] = useState(false)
  const [isHovering, setIsHovering] = useState(false)

  const toggleCurrentPasswordVisiblity = () => {
    setCurrentPasswordShown(currentPasswordShown ? false : true)
  }

  const toggleNewPasswordVisiblity = () => {
    setNewPasswordShown(newPasswordShown ? false : true)
  }

  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(confirmPasswordShown ? false : true)
  }

  const onSubmit = async (data) => {
    setShowSpinner(true)
    setValidatorError(null)
    setSuccess(false)
    setDisabled(true)

    getSession().then(({ user }) => {
      user.changePassword(data.currentPassword, data.newPassword, (err, result) => {
        if (err) {
          console.error(err)
          setValidatorError(ServerStrings(err.name))
          setShowSpinner(false)
          setSuccess(false)
          setDisabled(false)
        } else {
          reset({ currentPassword: "", newPassword: "", confirmPassword: "" })
          setValidatorError(null)
          setSuccess(true)
          setShowSpinner(false)
          setDisabled(false)
        }
      })
    })
  }

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess(false)
      }, 2000)
      return () => clearTimeout(timer)
    }
  }, [success])

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <HeaderText>Change Password</HeaderText>
        <Label>Current Password</Label>
        <div style={{ display: "flex" }}>
          {" "}
          <InputContainer>
            <Input
              type={currentPasswordShown ? "text" : "password"}
              name='currentPassword'
              placeholder='••••••••'
              {...register("currentPassword", { required: true, maxLength: 45 })}
              style={{ border: errors.currentPassword ? "1px solid #D25353" : null, paddingLeft: errors.currentPassword ? '9px' : null }}
            />
            <EyeIcon onClick={toggleCurrentPasswordVisiblity} src={currentPasswordShown ? Image2 : ImageBlueEye} />
          </InputContainer>{" "}
        </div>
        {errors.currentPassword ? <Error>{ValidatorStrings(errors.currentPassword?.type)}</Error> : <EmptyError />}
        <Label>New Password</Label>
        <div style={{ display: "flex" }}>
          {" "}
          <InputContainer>
            <Input
              type={newPasswordShown ? "text" : "password"}
              name='newPassword'
              placeholder='••••••••'
              {...register("newPassword", {required: true,maxLength: 45,validate: { emailEqual: (value) => value === getValues().confirmPassword },})}
              style={{ border: errors.newPassword ? "1px solid #D25353" : null, paddingLeft: errors.newPassword ? '9px' : null }}
            />
            <EyeIcon onClick={toggleNewPasswordVisiblity} src={newPasswordShown ? Image2 : ImageBlueEye} />
          </InputContainer>{" "}
        </div>
        {errors.newPassword ? <Error>{ValidatorStrings(errors.newPassword?.type)}</Error> : <EmptyError />}
        <Label>Confirm New Password</Label>
        <div style={{ display: "flex" }}>
          {" "}
          <InputContainer>
            <Input
              type={confirmPasswordShown ? "text" : "password"}
              name='confirmPassword'
              placeholder='••••••••'
              {...register("confirmPassword", {required: true,maxLength: 45,validate: { emailEqual: (value) => value === getValues().newPassword },})}
              style={{ border: errors.confirmPassword ? "1px solid #D25353" : null, paddingLeft: errors.confirmPassword ? '9px' : null }}
            />
            <EyeIcon onClick={toggleConfirmPasswordVisiblity} src={confirmPasswordShown ? Image2 : ImageBlueEye} />
          </InputContainer>{" "}
        </div>
        {errors.confirmPassword ? (
          <Error>{ValidatorStrings(errors.confirmPassword?.type)}</Error>
        ) : validatorError ? (
          <ErrorContainer>
            <Icon src={Image} />
            <Error>{validatorError}</Error>
          </ErrorContainer>
        ) : (
          <EmptyError />
        )}
        <ButtonContainer>
        <Button disabled={disabled} type='submit'>
            <ButtonText style={{marginLeft: disabled ? "22px" : null}}>SAVE</ButtonText>
            {showSpinner && <ButtonSpinner animation='border' />}
        </Button>
          {success ? (
            <SuccessContainer style={{ alignSelf: 'start', marginTop: 5 }}>
              <SuccessIcon2 src={Image3} style={{ marginLeft: 0 }}/>
              <Success>Your settings have been saved!</Success>
            </SuccessContainer>
          ) : (
            <EmptySuccess></EmptySuccess>
          )}
        </ButtonContainer>
      </Form>
    </Container>
  )
}

// https://codesandbox.io/s/showhide-password-on-toggle-in-react-hooks-95qcz?file=/src/App.js:1079-1084
// https://dev.to/detoner777/show-hide-password-on-toggle-in-react-hooks-1lph
