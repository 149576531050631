import React, { useState } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import { Link } from "react-router-dom"
import AddWingnutForm from "../form/AddWingnutForm"
import Image2 from "./../../images/ic_check_circle.svg"
import Image3 from "./../../images/ic_arrow_right.svg"
import HelpForm from "../form/HelpForm"
import SkipConfigPopup from "./../popup/SkipConfigPopup"
import Modal from "react-modal"
import { COLORS } from "../../styles/colors"

const Container = styled.div`
  /* border: 1px blue dashed; */
  display: flex;
  flex-direction: column;
  ${media.lessThan("743px")`
      margin-bottom: 30px;
    `}
`

const Icon = styled.img`
  width: 35px;
  height: 35px;
  transition-duration: 0.5s;
  color: ${COLORS.White};
  margin-top: 37.5px;
  margin-bottom: 12px;
  ${media.lessThan("743px")`
    align-self: center;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
  `}
`

const Title = styled.h1`
  font-family: Blinker;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 84.5%;
  letter-spacing: -0.01em;
  color: ${COLORS.White};
  ${media.lessThan("743px")`
        align-self: center;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
  `}
`

const BlurbText = styled.p`
  /* width: 430px; */
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: ${COLORS.White};
  margin-top: 12px;
  ${media.lessThan("743px")`
        align-self: center;
        text-align: center;
        width: 50%;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
  `}
`

const ButtonContainer = styled.div`
  /* border: 1px blue dashed; */
  display: flex;
  flex-direction: row;
  height: auto;
  width: auto;
  margin-top: 39px;
  ${media.lessThan("743px")`
        flex-direction: column;
        align-self: center;
    `}
`

const BtnBack = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 230px;
  height: 40px;
  border: 1px solid #63c085;
  border-radius: 30px;
  color: ${COLORS.White};
  background: transparent;
  cursor: pointer;
  text-align: center;
  :hover {
    background: ${COLORS.Secondary};
  }
  ${media.lessThan("743px")`
        /* align-self: flex-start; */
        margin-top: 10px;
        height: 55px;
    `}
  ${media.between("744px", "large")`
        height: 55px;
    `}
    ${media.greaterThan("large")`
    `}
`

const BtnConfigure = styled(BtnBack)`
  width: 230px;
  border-radius: 30px;
  background: #63c085;
  border: none;
  color: black;
  margin-right: 10px;
  :hover {
    opacity: 0.8;
    background: #63c085;
  }
  ${media.lessThan("743px")`
        margin-right: 0px;
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const ButtonText = styled.p`
  font-family: "Roboto Condensed", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  transition-duration: 0.5s;
  :hover {
  }
  ${media.lessThan("743px")`
        font-size: 15px;
    `}
  ${media.between("744px", "large")`
        font-size: 15px;
    `}
    ${media.greaterThan("large")`
    `}
`

const IconArrow = styled.img`
  width: 6;
  height: 10px;
  /* margin-left: 20px; */
  align-self: center;
  transition-duration: 0.5s;
  ${media.lessThan("743px")`
    `}
  ${media.between("744px", "large")`
    `}
    ${media.greaterThan("large")`
    `}
`

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: COLORS.Primary,
    borderRadius: "30px",
    border: "none",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.7)",
    zIndex: 10,
  },
}


export default function AddWingnutSuccess(props) {
  const {params} = props.match

  const [isHovering, setIsHovering] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const closePopup = () => {
    setIsModalOpen(false)
  }

  return (
    <div>
      <Modal isOpen={isModalOpen} onRequestClose={closePopup} style={customStyles} contentLabel='Skip Config'>
        <SkipConfigPopup closePopup={closePopup}/>
      </Modal>

    <Container>
      <Icon src={Image2} />
      <Title>Success!</Title>
      <BlurbText>Wingnut successfully added. Configure it now to start collecting data.</BlurbText>
      <ButtonContainer>
        <Link to={{ pathname: `/portal/wingnut/detail/${params.id}`, state: {openConfig: true} }} style={{ textDecoration: "none" }}>
          <BtnConfigure>
            <ButtonText>
              CONFIGURE WINGNUT
            </ButtonText>
          </BtnConfigure>
        </Link>

          <BtnBack onClick={() => setIsModalOpen(true)}>
            <ButtonText>BACK TO WINGNUTS OVERVIEW</ButtonText>
          </BtnBack>

      </ButtonContainer>
    </Container>
    </div>
  )
}
